import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import moment from 'moment';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Skeleton } from '@mui/material';
import usePermissions from 'hooks/usePermissions';
import StatusBadge from 'ui-component/StatusBadge';

const DetailsTab = ({
    serialDetails,
    repairLists,
    dataLoaded,
}) => {

	const [allPermissions, hasEveryPermission] = usePermissions();

	const history = useNavigate();
    
    const handleOpenNewTab = (pageModule, id) => {
        let url = '';
        if (pageModule == 'sales_order') {
            url = `/sales/sales-orders/${id}`;
        } else if (pageModule == 'invoice') {
            url = `/sales/invoices/${id}`;
        } 

        // if (pageModule == 'products') {
        //     url = `/products/${id}`;
        // } else if (pageModule == 'contacts') {
        //     url = `/contacts/${id}`;
        // } else if (pageModule == 'purchase_orders') {
        //     url = `/purchasing/view/${id}`;
        // } else if (pageModule == 'sales_orders') {
        //     url = `/sales/sales-orders/${id}`;
        // } else if (pageModule == 'invoices') {
        //     url = `/sales/invoices/${id}`;
        // } else if (pageModule == 'quotations') {
        //     url = `/sales/quotations/${id}`;
        // }
        if (url) {
            history(url);
            // window.open(url, '_blank');
        }
    };
    return (
        <>
            <div className="content mt15">


                <div className="row align-items-top">
                    <div className="col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray serial-details-basic-info-container" style={{ width: '100px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        Product
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                        <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        { serialDetails?.product?.name ?? '-----' }
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="row align-items-top">
                    <div className="col-md-4 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray serial-details-basic-info-container" style={{ width: '100px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        SKU
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                        <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        { serialDetails?.product?.sku ?? '-----' }
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="row align-items-top">
                    <div className="col-md-4 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray serial-details-basic-info-container" style={{ width: '100px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        Category
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                        <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        { serialDetails?.product?.category ?? '-----' }
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="row align-items-top">
                    <div className="col-md-4 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray serial-details-basic-info-container" style={{ width: '100px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        Storage
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                        <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        { serialDetails?.storage ?? '-----' }
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="row align-items-top">
                    <div className="col-md-4 col-12 form-group" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                        <div className="textGray serial-details-basic-info-container" style={{ width: '100px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        Grade
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                        <div style={{ maxWidth: '275px', marginLeft: '10px' }}>
                            {
                                dataLoaded ? ( 
                                    <>
                                        { serialDetails?.grade ?? '-----' }
                                    </>
                                ) : (
                                    <>
                                        <Skeleton variant="rounded" width={'200px'} height={20}></Skeleton>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>

                {
                    serialDetails?.transactions?.length > 0 && (
                        <>
                            <div style={{ padding: '10px 0px 20px 0px' }}>
                    
                                <div className="row mb10">
                                    <div className="col-12">
                                        <h2 className="d-flex font600" style={{ fontSize: '18px' }}>Order Details</h2>
                                    </div>
                                </div>

                                <div className="dynamic-listing">
                                    <div className="dynamic-listing-header">
                                        <div className="row">
                                            <div className="col-md-2 font600">Sales Order</div>
                                            <div className="col-md-2 font600">Invoice</div>
                                            <div className="col-md-3 font600">Customer</div>
                                            <div className="col-md-3 font600">Date</div>
                                            <div className="col-md-2 font600">Warranty</div>
                                        </div>
                                    </div>
                                    <hr className="mt8 mb8" />

                                    {
                                        serialDetails?.transactions?.map((row, index) => (
                                            <>

                                                <div className="row">
                                                    <div className="col-md-2 d-flex align-items-center">
                                                        <div className="d-flex gap-4">

                                                            <div className="dynamic-listing-label-container" style={{ marginRight: '10px' }}>
                                                                <label className="dynamic-listing-label textGray">Sales Order</label> 
                                                            </div>
                                                            <div
                                                                className={`${hasEveryPermission(['View Sales Orders Details']) ? 'text-primary cursor-pointer' : '' }`}
                                                                onClick={() => {
                                                                    if (hasEveryPermission(['View Sales Orders Details'])) {
                                                                        handleOpenNewTab('sales_order', row?.sales_order?.id)
                                                                    }
                                                                }}
                                                            >
                                                                {row?.sales_order?.sales_order_number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 d-flex align-items-center">
                                                        <div className="d-flex gap-4">
                                                            <div className="dynamic-listing-label-container" style={{ marginRight: '38px' }}>
                                                                <label className="dynamic-listing-label textGray">Invoice</label> 
                                                            </div>
                                                            <div
                                                                className={`${hasEveryPermission(['View Estimation Details (Page)']) ? 'text-primary cursor-pointer' : '' }`}
                                                                onClick={() => {
                                                                    if (hasEveryPermission(['View Estimation Details (Page)'])) {
                                                                        handleOpenNewTab('invoice', row?.invoice?.id)
                                                                    }
                                                                }}
                                                            >
                                                                {row?.invoice?.invoice_number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 d-flex align-items-center">
                                                        <div className="d-flex gap-4">
                                                            <div className="dynamic-listing-label-container" style={{ marginRight: '20px' }}>
                                                                <label className="dynamic-listing-label textGray">Customer</label> 
                                                            </div>
                                                            <div
                                                                className={`${hasEveryPermission(['View Contact Details (Page)']) ? 'text-primary cursor-pointer' : '' }`}
                                                                onClick={() => {
                                                                    if (hasEveryPermission(['View Contact Details (Page)'])) {
                                                                        handleOpenNewTab('contacts', row?.customer?.id)
                                                                    }
                                                                }}
                                                            >
                                                                {row?.customer?.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 ">
                                                        <div className="dynamic-listing-two-rows-horizontal-view">

                                                            <div className="d-flex justify-left items-center gap-4 text-12">
                                                                <div className="textGray ">Shipped</div>
                                                                <div className="font600">
                                                                    {
                                                                        row?.shipped_date ? (
                                                                            <>
                                                                                {moment(row?.shipped_date).format(CommonDateOnlyFormat)}
                                                                            </>
                                                                        ) : (
                                                                            <>N/A</>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-left items-center gap-4 text-12">
                                                                <div className="textGray ">Inbound</div>
                                                                <div className="font600">
                                                                    {
                                                                        serialDetails?.inbound_date ? (
                                                                            <>
                                                                                {moment(serialDetails?.inbound_date).format(CommonDateOnlyFormat)}
                                                                            </>
                                                                        ) : (
                                                                            <>N/A</>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dynamic-listing-two-rows-vertical-view">
                                                            <div className="row">

                                                                <div className="col-md-12 d-flex align-items-center">
                                                                    <div className="d-flex gap-4">
                                                                        <div className="dynamic-listing-label-container" style={{ marginRight: '31px' }}>
                                                                            <label className="dynamic-listing-label textGray">Shipped</label> 
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                row?.shipped_date ? (
                                                                                    <>
                                                                                        {moment(row?.shipped_date).format(CommonDateOnlyFormat)}
                                                                                    </>
                                                                                ) : (
                                                                                    <>N/A</>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 d-flex align-items-center">
                                                                    <div className="d-flex gap-4">
                                                                        <div className="dynamic-listing-label-container" style={{ marginRight: '14px' }}>
                                                                            <label className="dynamic-listing-label textGray">Inbounded</label> 
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                serialDetails?.inbound_date ? (
                                                                                    <>
                                                                                        {moment(serialDetails?.inbound_date).format(CommonDateOnlyFormat)}
                                                                                    </>
                                                                                ) : (
                                                                                    <>N/A</>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 d-flex">
                                                        <div className="d-flex gap-4">
                                                            <div className="dynamic-listing-label-container" style={{ marginRight: '25px' }}>
                                                                <label className="dynamic-listing-label textGray">Warranty</label> 
                                                            </div>
                                                            <div className="d-flex justify-left items-center" style={{ width: '110px'}}>
                                                                {
                                                                    row?.warranty === 'Expired' ? (
                                                                        <>
                                                                            <StatusBadge itemStatus={'Expired'} />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {row?.warranty}
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-2 d-flex align-items-center">{moment(details?.qoutation?.date).format(CommonDateOnlyFormat)}</div>
                                                    <div className="col-md-2 "><div style={{ width: '90px' }}><StatusBadge itemStatus={details?.qoutation?.status} /></div></div>
                                                    <div className="col-md-2 d-flex align-items-center"><CurrencyValue currencyObject={details?.qoutation?.converted_currency_total} /></div> */}
                                                </div>
                                                <hr className="mt8 mb8" />
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    )
                }   

                

                {/* <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 form-group">
                        <div className="card p-2">
                            <div className="product-card minHeight198 pad10-25">
                                <div className="form-section">
                                    <div className="product-section-head font18">
                                        <b>Invoice</b>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Invoice</label>
                                        <span className="fw600" style={{ marginLeft: '77px' }}> {serialDetails?.estimation?.invoice_number} </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Customer Name </label>
                                        <span className="fw600" style={{ marginLeft: '59px' }}> <PrivacyModeContent>{serialDetails?.customer?.name}</PrivacyModeContent></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Contact Person</label>
                                        <span className="fw600" style={{ marginLeft: '24px' }}> <PrivacyModeContent>{serialDetails?.customer?.contact_name}</PrivacyModeContent></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Supplier</label>
                                        <span className="fw600" style={{ marginLeft: '69px' }}> {serialDetails?.supplier?.name ? (<>{serialDetails?.supplier?.name}</>) : (<>N/A</>)}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Inbounded</label>
                                        <span className="fw600" style={{ marginLeft: '50px' }}> {serialDetails?.inbound_date ? (<>{moment(serialDetails?.inbound_date).format(CommonDateOnlyFormat)}</>) : (<>N/A</>)}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 font14">
                                        <label htmlFor="">Outbounded</label>
                                        <span className="fw600" style={{ marginLeft: '39px' }}> {serialDetails?.outbound_date ? (<>{moment(serialDetails?.outbound_date).format(CommonDateOnlyFormat)}</>) : (<>N/A</>)}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 form-group">
                        <div className="card p-2">
                            <div className="product-card pad10-25" style={{ minHeight: '206px' }}>
                                <div className="form-section">
                                    <div className="product-section-head font18">
                                        <b>Repairs</b>
                                    </div>
                                </div>
                                {repairLists?.length > 0 ? (
                                    <>
                                        {
                                            repairLists?.map((repair) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-8 font14">
                                                            <label htmlFor="">{repair?.name} </label>
                                                        </div>
                                                        <div className="col-4 font14">
                                                            <span className="fw600" >: $ {repair?.cost}</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                    </>
                                ) : (
                                    <>
                                        <div className="not-found-container" style={{ height: '10vh' }}>
                                            <div className="not-found-icon">
                                                <TbDatabaseExclamation />
                                            </div>
                                            <h6 className="not-found-text">No Data Available</h6>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default DetailsTab;

import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaChevronLeft, FaPaperclip } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { ColoredDeliveryIcon, CloseIcon, DeleteIcon2, ColoredScanIcon } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import StatusBadge from 'ui-component/StatusBadge';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import errorSound from '../../../assets/audio/error.mp3';
import useSound from 'use-sound';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import Select from 'react-select';
import usePermissions from 'hooks/usePermissions';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDropzone } from 'react-dropzone';


const PicklistCreateShipmentModal = ({ openPopup, details, handleClose, handleReloadData }) => {

    const [processingPurchaseOrderButton, setProcessingPurchaseOrderButton] = useState(false);
    const [collectionDetails, setCollectionDetails] = useState(null);
    const [disabledPurchaseOrderButton, setDisabledPurchaseOrderButton] = useState(true);
    const [disabledPicklistButton, setDisabledPicklistButton] = useState(true);
    const [disabledOperationsButton, setDisabledOperationsButton] = useState(true);
    const [processOrderStep, setProcessOrderStep] = useState(1);
    const [supplierList, setSupplierList] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const currentDate = moment().format('YYYY-MM-DD');
    const [defaultCurrency, setDefaultCurrency] = useState('usd');
    const [availableProductLists, setAvailableProductLists] = useState([]);
    // const [selectedProducts, setSelectedProducts] = useState({});
    const [proceeedToCreatePurchaseOrder, setProceedToCreatePurchaseOrder] = useState(false);
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [imeiExistsInInventory, setImeiExistsInInventory] = useState(true);
    const [enteredSerial, setEnteredSerial] = useState('');
    const [scannedSerials, setScannedSerials] = useState([]);
    const [errorSoundPlay] = useSound(errorSound);
    const [shippingCompanies, setShippingCompanies] = useState([]);
    const [shipmentReferenceNo, setShipmentReferenceNo] = useState([]);
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
    const [defaultWeightUnit, setDefaultWeightUnit] = useState('');

    const [currencyList, setCurrencyList] = useState([]);
    const [attachmentCollections, setAttachmentCollections] = useState([]);
    const [serials, setSerials] = useState([]);

    // const [shipmentDetails, setShipmentDetails] = useState([])
    const [shipmentDetails, setShipmentDetails] = useState({
        picklist_id: details?.picklist_id,
        shipping_company_id: '',
        tracking_number: '',
        weight: '',
        weight_unit: 'kg',
        shipping_currency_id: '',
        shipping_cost: '',
        // attachments: [],
        // items: []
    });

    const weightUnits = [
        { label: 'Kilogram', value: 'kg' },
        { label: 'Gram', value: 'g' },
    ];

    const formData = new FormData();

    useEffect(() => {
        console.log('details', details)

        api.getSelectOptionsList('currency').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.currency, value: item.id }));
            setCurrencyList(collection);
            const defaultCurrency = res?.data?.find((item) => item.currency === 'USD ($)');
            setShipmentDetails({
                ...shipmentDetails,
                shipping_currency_id: defaultCurrency?.id
            })
		});

        api.getNextReferenceNumber('shipment').then((res) => {
            if (res?.status) {
                setShipmentReferenceNo(res?.data?.next_reference_no);
            }
        });
		api.getSelectOptionsList('shipping_company').then((res) => {
			// option_product_name: "11P256MIXAA+ - iPhone 11 Pro 256GB Mixed AA+ - 0 stock"
			const data = res.data.map((item) => ({
				...item,
				label: `${item.name}`,
				value: item.id,
			}));
			setShippingCompanies(data);
        });
        setDefaultWeightUnit('Kilogram');
        setShipmentDetails({
            ...shipmentDetails,
            picklist_id: details?.picklist_id,
            weight_unit: 'kg',
        })
        setSerials(details?.items);
    }, []);

    const disablepopup = () => {
        handleClose();
    };

    const handleChangeWeight = (e) => { 
        
        const weightValue = parseFloat(e.target.value);

        if (!isNaN(weightValue)) {
            setShipmentDetails({
                ...shipmentDetails,
                weight: weightValue
            })
        } else {
            setShipmentDetails({
                ...shipmentDetails,
                weight: 0
            })
        }
    }


	const onDrop = useCallback((acceptedFiles) => {
        toast.error('Invalid file extension. Please select a JPEG, JPG, PNG, or PDF file.');
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: '.pdf, .jpeg, .jpg, .png',
		multiple: false
    });
    


    const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png'];
    const validateFileExtension = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };


    
    const uploadAttachment = async (attachment) => {
        // formData.append('attachment[]', attachment);
        // formData.append('shipment_id', details?.id);
        // setUploadedFileName(attachment.name);
        console.log('attachment', attachment)
        
        let sizeStr = "";
        const sizeInKB = attachment.size / 1024;
        if (sizeInKB >= 1024) {
            const sizeInMB = sizeInKB / 1024;
            sizeStr = `${sizeInMB.toFixed(2)} MB`;
        } else {
            sizeStr = `${sizeInKB.toFixed(2)} KB`;
        }
        const attachmentCollection = {
            file: attachment,
            size: sizeStr,
            attachment: attachment
        }
        
        formData.append('attachments[]', attachment);

        // setUploadedFileSize(sizeStr);
        // try {
        //     setHasAttachment(true)
        //     setIsAttachmentLoading(true)
        //     // setTimeout(() => {
        //     //     // setIsAttachmentLoading(false)
 
        //     // }, 2000);
        //         try {

        //             toast.promise(
        //                 api
        //                     .uploadShipmentAttachment(details?.id, formData)
        //                     .then(
        //                         (res) => {
        //                             if (res.status) {
        //                                 setIsAttachmentLoading(false)
        //                                 setInformation({
        //                                     ...information,
        //                                     shipmentAttachmentId: res.data.id
        //                                 });
        //                                 // initiate();
        //                                 // setUploadInvoice(false);
        //                             }
        
        //                             return res?.status ? res : null;
        //                         },
        //                         () => {}
        //                     ),
        //                 {
        //                     loading: 'uploading file...',
        //                     success: (data) => `${data.message}`,
        //                     error: <b>Failed to upload file</b>
        //                 }
        //             );
                    
        //         } catch (error) {
        //             toast.error('Error uploading file');
        //         }
            
            
        // } catch (error) {
        //     console.error('Error uploading file:', error);
        //     toast.error('Error uploading file');
        // }
    };
    
	const onPaymentDrop = useCallback((acceptedFiles) => {
		console.log('onPaymentDrop triggered', acceptedFiles);

        formData.append('picklist_id', shipmentDetails?.picklist_id);
        const attachments = acceptedFiles.map((file, index) => {
            let sizeStr = "";
            const sizeInKB = file.size / 1024;
            if (sizeInKB >= 1024) {
                const sizeInMB = sizeInKB / 1024;
                sizeStr = `${sizeInMB.toFixed(2)} MB`;
            } else {
                sizeStr = `${sizeInKB.toFixed(2)} KB`;
            }

            if (validateFileExtension(file?.name)) {
                // uploadAttachment(file);

                formData.append('attachments[]', file);
                return {
                    id: '',
                    filename: file?.name,
                    size: sizeStr,
                    attachment: file,
                    allowed: true
                }

            } else {
                return {
                    id: '',
                    filename: file?.name,
                    size: sizeStr,
                    attachment: file,
                    allowed: false
                }
            }
        })
        

        try {
            toast.promise(
                api
                    .uploadShipmentAttachment(formData)
                    .then(
                        (res) => {
                            if (res.status) {
                                const datas = res.data.map(item => {
                                    return {
                                        id: item.id,
                                        name: item.filename,
                                    }
                                })
                                setAttachmentCollections([...attachmentCollections, ...datas]);
                                // setShipmentDetails({
                                //     ...shipmentDetails,
                                //     attachments: res.data
                                // })
                                // formData = new FormData();
                                formData.append('attachments', null);
                                // setAttachmentCollections(attachments);
                                // setIsAttachmentLoading(false)
                                // setInformation({
                                //     ...information,
                                //     shipmentAttachmentId: res.data.id
                                // });
                                // initiate();
                                // setUploadInvoice(false);
                            }

                            return res?.status ? res : null;
                        },
                        () => {}
                    ),
                {
                    loading: 'uploading file...',
                    success: (data) => `${data.message}`,
                    error: <b>Failed to upload file</b>
                }
            );
            
        } catch (error) {
            toast.error('Error uploading file');
        }

        return false;
        // formData.append('attachments', attachments);

        // Append each file individually to formData
        // attachments.forEach((attachment) => {
        //     if (attachment.allowed) {
        //         formData.append('attachments[]', attachment.attachment);
        //     }
        // });
        setAttachmentCollections(attachments);
        // if (validateFileExtension(acceptedFiles[0].name)) {
        //     uploadAttachment(acceptedFiles[0]);
		// 	// setPaymentData((prevData) => ({ ...prevData, attachment: acceptedFiles[0] }));
		// 	// setPaymentAttachment(acceptedFiles[0].name);
		// } else {
		// 	toast.error('Invalid file extension. Please select a PDF, JPEG, JPG, or PNG file.');
		// }
	}, []);
	const { getRootProps: getPaymentRootProps, getInputProps: getPaymentInputProps } = useDropzone({ onDrop: onPaymentDrop });




    const submitShipmentForm = () => {
        // console.log('shipmentDetails', shipmentDetails)
        // console.log('setSerials', serials)
        // // setShipmentDetails({
        // //     ...shipmentDetails,
        // //     items: serials
        // // })
        let requests = shipmentDetails;
        requests.items = serials;
        requests.attachment_ids = attachmentCollections

        // console.log('shipmentDetails', requests)
        // return false;

        if (!requests?.picklist_id) {
            toast.error('Picklist is required');
            return;
        } else if (!requests?.shipping_company_id) {
            toast.error('Shipping Company is required');
            return;
        } else if (!requests?.tracking_number) {
            toast.error('Airway bill is required');
            return;
        } else if (!requests?.shipping_currency_id) {
            toast.error('Currency is required');
            return;
        } else if (!requests.shipping_cost.toString().trim()) {
            toast.error('Shipping cost is required');
            return;
        } else if (!requests.weight.toString().trim()) {
            toast.error('Weight is required');
            return;
        }
        // formData.append('picklist_id', shipmentDetails?.picklist_id);
        // formData.append('shipping_company_id', shipmentDetails?.shipping_company_id);
        // formData.append('tracking_number', shipmentDetails?.tracking_number);
        // formData.append('shipping_currency_id', shipmentDetails?.shipping_currency_id);
        // formData.append('shipping_cost', shipmentDetails?.shipping_cost);
        // formData.append('weight', shipmentDetails?.weight);
        // formData.append('weight_unit', shipmentDetails?.weight_unit);
        // // formData.append('items', serials);
        // serials.map(item => {
        //     const collection = {
        //         id: item.id,
        //         picked_quantity: item.picked_quantity,
        //         show_scanner: item.show_scanner,

        //     }
        //     formData.append('items[]', item);
        // })
        // attachmentCollections.map(item => {
        //     formData.append('attachment_ids[]', item.id);
        // });


    console.log('shipmentDetails', shipmentDetails?.picklist_id)

    // .createShipmentFromPicklist(details?.id, requests)
        toast.promise(
            api
            .createShipmentFromPicklist(shipmentDetails?.picklist_id, requests)
            .then(
                (res) => {
                        if (res.status) {
                            handleReloadData()
                        }
    
                        return res?.status ? res : null;
                    },
                    () => { }
                ),
            {
                loading: 'Creating shipment...',
                success: (data) => `${data.message}`,
                error: <b>Failed to add shipment</b>
            }
        );
    }



    return (
        <>
            <Modal show={openPopup} onHide={disablepopup} centered size="md">
                <Modal.Body>
                   
                    <div className="p-2 swipe-rtl-animation">
                        <div style={{ padding: '20px 20px 0 20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <ColoredDeliveryIcon height='60px' width='60px' />
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    <CloseIcon height={'16px'} width={'16px'} />
                                </button>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-center " style={{ padding: '10px 0 20px 0' }}>
                                    <div style={{ fontSize: '24px' }}>
                                        Create Shipment
                                    </div>
                                    {/* <div>
                                        Fill up the information needed in creating shipment
                                    </div> */}
                                    <div className='textGray'>
                                        Manage shipment details for accurate <br />tracking and smooth delivery.
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '5px 0' }}>
                                    <label>Reference No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={shipmentReferenceNo}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '5px 0' }}>
                                    <label>Shipping Provider</label>
                                    <Select
                                        options={shippingCompanies}
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        placeholder="Select or type..."
                                        isMulti={false}
                                        onChange={(e) => {
                                            setShipmentDetails({
                                                ...shipmentDetails,
                                                shipping_company_id: e.value
                                            })
                                        }}
                                        // value={selectedRepairType}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '5px 0' }}>
                                    <label>Airway bill code</label>
                                    
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                        value={shipmentDetails?.tracking_number}
                                        placeholder='Enter airway bill code'
                                        // onChange={(e) => {
                                        //     // setInformation({
                                        //     //     ...information,
                                        //     //     referenceNo: e.target.value
                                        //     // })
                                        //     setAwbNumber(e.target.value)
                                        // }}
                                        onChange={(e) => {
                                            setShipmentDetails({
                                                ...shipmentDetails,
                                                tracking_number: e.target.value
                                            })
                                        }}
                                        // onKeyDown={(e) => {
                                        //     handleProcessEnter(e);
                                        // }}
                                    />
                                </div>
                            </div>
                            
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                    <label>Shipping cost</label>
                                    <div className="row">

                                        <div className="col-12 d-flex input-group-2-form input-group-2-form-currency">
                                            <Select
                                                options={currencyList}
                                                className="rs-container left-input"
                                                classNamePrefix="rs"
                                                placeholder="Select"
                                                isMulti={false}
                                                onChange={(e) => {
                                                    // handleChangeCovertedCurrency(index, e)
                                                    setShipmentDetails({
                                                        ...shipmentDetails,
                                                        shipping_currency_id: e.value
                                                    })
                                                }}
                                                value={currencyList?.find(option => option.id === shipmentDetails?.shipping_currency_id) || null}
                                            />
                                            <input
                                                type="number"
                                                className="form-control h36 right-input ml6"
                                                id="inputName"
                                                value={shipmentDetails?.shipping_cost}
                                                placeholder='0.00'
                                                onChange={(e) => {
                                                    // handleChangeRateCurrency(index, e)
                                                    setShipmentDetails({
                                                        ...shipmentDetails,
                                                        shipping_cost: e.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                    <label>Weight</label>
                                    <div className="custom-input-group">

                                        <InputGroup className="mb-3 d-flex">
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={defaultWeightUnit}
                                                id="input-group-dropdown-1"
                                            >
                                                {
                                                    weightUnits.map((unit) => { 
                                                        return (
                                                            <Dropdown.Item
                                                                href="#"
                                                                key={unit.value}
                                                                onClick={() => { 
                                                                    // handleChangeCurrency(unit)
                                                                    setShipmentDetails({
                                                                        ...shipmentDetails,
                                                                        weight_unit: unit.value
                                                                    })
                                                                    // setSelectedRepairs({
                                                                    //     ...selectedRepairs,
                                                                    //     repairCurrency: currency.value
                                                                    // })
                                                                    setDefaultWeightUnit(unit.label);
                                                                }}
                                                            >
                                                                {unit.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            <input
                                                type="number"
                                                id="input-example"
                                                name="input-name"
                                                className="form-control"
                                                placeholder="0"
                                                onChange={(e) => {
                                                    handleChangeWeight(e)
                                                }}
                                                value={shipmentDetails?.weight}
                                                autoComplete={'off'}
                                                autoFocus
                                            />
                                        </InputGroup>
                                    </div>

                                </div>
                            </div>
                            <div className="row align-items-top">
                                <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                   
                                    <div {...getPaymentRootProps()}>
                                        
                                        <div
                                            className="add-item-empty-div"
                                        >
                                            <button type="button" className="btn btn-primary btn-outlined-main-primary dragText" >
                                                <FaPlus />
                                                Add file here
                                            </button>
                                        </div>
                                        <input {...getPaymentInputProps({ disabled: false })} style={{ display: 'none' }} />{' '}
                                    </div>
                                </div>
                            </div>
                            {
                                attachmentCollections?.length > 0 && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-12 text-left " style={{ padding: '10px 0' }}>
                                                {
                                                    attachmentCollections?.map((attachment, index) => (
                                                        <>
                                                            <div className="payment-modal-attachment-container">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <div className="col-12" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div className="payment-modal-attachment-icon" style={{ padding: '5px 0px', border: '0'}}>
                                                                                    <FaPaperclip />
                                                                                </div>
                                                                                <div className="payment-modal-attachment-name">
                                                                                    <div>{attachment?.name}</div>
                                                                                    {/* <div>{attachment?.size}</div> */}
                                                                                </div>
                                                                                <div className="payment-modal-attachment-delete">
                                                                                    <svg id="Component_127_1" height="12" width="12" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                                                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                            {/* {
                                                                                isAttachmentLoading && (
                                                                                    <div className="col-12">
                                                                                        <div className="animated-progress-bar"></div>
                                                                                    </div>
            
                                                                                )
                                                                            } */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                                
                            {/* <div className="btnarea" style={{ justifyContent: 'center' }}> */}

                                <div className="row align-items-top">
                                    <div className="col-12 text-left " style={{ padding: '10px 0 0 0' }}>
                                        <button
                                            className="btn btn-main-primary btn-outlined-main-primary btn-block"
                                            style={{ marginRight: '12px' }}
                                            onClick={() => { submitShipmentForm() }}
                                        >
                                            Create Shipment
                                        </button>
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PicklistCreateShipmentModal;

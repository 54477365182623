import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import ReactSelect from 'react-select';
import { CloseIcon, QouteStart, ColoredChatIcon, QouteEnd } from 'ui-component/custom-icons/icons'


const PaymentCommentModal = ({ openPopup, details, onHandleReloadData, handleClose }) => {
    const [isFormSaving, setIsFormSaving] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [formInfo, setFormInfo] = useState({
        id: '',
        purchase_invoice_number: '',
    });

    useEffect(() => {
        console.log('details', details)
        // setFormInfo({
        //     ...formInfo, 
        //     id: details?.id,
        // })
    }, []);



    const disablepopup = () => {
        handleClose();
    };

    
    // const submitForm = () => {
    //     if (!formInfo.purchase_invoice_number) {
    //         toast.error('Please enter purchase invoice number');
    //         return;
    //     }
    //     setIsFormDisabled(true)
    //     if (details?.master_status?.name === 'Concept') {
    //         toast.promise(
    //             api
    //                 .markAsPurchased(formInfo?.id, formInfo)
    //                 .then(
    //                     (res) => {
    //                         setIsFormDisabled(false)
    //                         if (res.status) {
    //                             onHandleReloadData()
    //                         }
        
    //                         return res?.status ? res : null;
    //                     },
    //                     () => { }
    //                 ),
    //             {
    //                 loading: 'Saving...',
    //                 success: (data) => `${data.message}`,
    //                 error: <b>Failed to mark as purchased</b>
    //             }
    //         );
    //     } else {

    //         toast.promise(
    //             api
    //                 .assignPurchaseInvoiceNumber(formInfo?.id, formInfo)
    //                 .then(
    //                     (res) => {
    //                         setIsFormDisabled(false)
    //                         if (res.status) {
    //                             onHandleReloadData()
    //                         }
        
    //                         return res?.status ? res : null;
    //                     },
    //                     () => { }
    //                 ),
    //             {
    //                 loading: 'Saving...',
    //                 success: (data) => `${data.message}`,
    //                 error: <b>Failed to add purchase invoice number</b>
    //             }
    //         );
    //     }
    // }



    return (
        <>
        <Modal show={openPopup}  centered size="sm">
            <Modal.Body>
                <div className="p-2">
                    <div style={{ padding: '20px' }}>
                        <div className="mb-2 ml-0 pl-0">

                            <div className="row">
                                <div className="col-12 text-center">
                                    <ColoredChatIcon height={'60px'} width={'60px'} />
                                </div>
                            </div>
                            <button className="btn modal-close-icon" onClick={handleClose}>
                                <CloseIcon height={'16px'} width={'16px'} />
                            </button>
                        </div>

                        <div className="row align-items-top">
                            <div className="col-12 text-center " style={{ padding: '10px 0 10px 0' }}>
                                <div style={{ fontSize: '24px' }}>
                                    Payment Note
                                </div>
                                
                            </div>
                        </div>
                        <div className="row align-items-top">
                            <div className="col-12 text-left textGray font18" style={{ padding: '10px 0' }}>
                                <div >
                                    <span className="mt-10 textGray">
                                        <QouteStart width='18px' height='18px' />
                                    </span>
                                        <span
                                            className=" font18"
                                            dangerouslySetInnerHTML={{
                                                __html: details?.note
                                            }}
                                        />
                                    <span className="mt-10 textGray">
                                        <QouteEnd width='18px' height='18px' />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row align-items-top">
                            <div className="col-12 text-left " style={{ padding: '20px 0px 0px 0px' }}>
                                
                                <button
                                    className={`${isFormDisabled && 'disabled' } btn btn-block btn-main-primary btn-outlined-main-primary`}
                                    disabled={isFormDisabled}
                                    onClick={() => {
                                        if (!isFormDisabled) {
                                            submitForm()
                                        }
                                    }}
                                >
                                    {
                                        isFormDisabled ? (
                                            <>
                                                <FaSpinner className="fa-spin" /> Processing...
                                            </>
                                        ) : (
                                            <>
                                                Save
                                            </>
                                        )
                                    }
                                </button>
                            </div>
                        </div> */}

                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default PaymentCommentModal;

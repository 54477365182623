import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { storeSearchReturn, storeFilterReturn } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Tableheader from 'ui-component/Tableheader';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Scroller from 'ui-component/Scroller';
// import NewShipments from './AddShipments';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { TbDatabaseExclamation } from 'react-icons/tb';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import WarrantyCheckerModal from './components/modals/WarrantyCheckerModal';
import ReturnsReportPopupModal from './components/modals/ReturnsReportPopupModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

const Index = () => {
	const [setPageTitle] = usePageTitle('Returns');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const [params, setParams] = useSearchParams();
	const history = useNavigate();
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [openWarrantyCheckerPopup, setOpenWarrantyCheckerPopup] = useState(false);
	const [openReportPopup, setOpenReportPopup] = useState(false);
	const [returnsList, setReturnsList] = useState([]);
	const [show, setShow] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const [isDisable, setDisable] = useState(false);
	const [pagination, setpagination] = useState([]);
	const [newShow, setNewShow] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [hasResult, setHasResult] = useState(false);

	const storedSearch = useSelector((state) => state.main.searchReturn);
	const storedFilter = useSelector((state) => state.main.filterReturn);

    useEffect(() => {
        // if (storedSearch) {
        //     handleSearch({ target: { value: storedSearch } });
        // }

        const fetchData = async () => {
            if (storedSearch) {
                setIsLoading(true);
                await handleSearch({ target: { value: storedSearch } });
            } else if (!storedSearch) {
                if (storedFilter && Object.keys(storedFilter).length > 0) {
                    setIsLoading(true);
                    await handleFilterRedux();
                } else {
                    handleFilter()
                }
            }
        };

        fetchData();
    }, [storedSearch, searchField, activeFilters, storedFilter]);

	const handleNewClose = () => setNewShow(false);

	const getReturnsList = () => {
		setIsLoading(true);
		api
			.getReturnsList()
			.then((res) => {
				setIsLoading(false);
				if (res.status) {
					setReturnsList(res.data.data);
					setpagination(res.data);
				} else {
					setReturnsList([]);
				}
				isFirstLoad && setIsFirstLoad(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setReturnsList([]);
				console.error('Failed to fetch returns list:', error);
			});
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

        dispatch(storeSearchReturn(text));
		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterReturns(activeFilters, text).then((res) => {
					setIsLoading(false);
					setReturnsList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterReturns(activeFilters, null).then((res) => {
					setIsLoading(false);
					setReturnsList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	const viewData = (id) => {
		history(`/returns/${id}`);
	};

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterReturns(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setReturnsList(res.data.data);
			setpagination(res.data);
		});
	};

    const handleFilterRedux = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterReturns(storedFilter, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setReturnsList(res.data.data);
			setpagination(res.data);
        });
    };


	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const handleOpenWarrantyCheckerModal = () => {
		setOpenWarrantyCheckerPopup(true);
	};
	const handleOpenReportPopupModal = () => {
		setOpenReportPopup(true);
	};

	const closePopup = () => {
		setOpenWarrantyCheckerPopup(false);
		setOpenReportPopup(false);
	};

	const handleClose = () => setShow('');

	const handleData = (data) => {
		setReturnsList(data.data);
		setpagination(data);
	};

	useEffect(() => {
        if (storedSearch) {
        } else if (storedFilter && Object.keys(storedFilter).length > 0) {
        } else {
			console.log('andito')
			getReturnsList();
        }
		getFiltersList();
	}, []);

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getWarehouseFilters().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	const handleDelete = (id) => {
		toast.promise(
			api.deleteReturns(id).then((res) => {
				if (res.status) {
					setShow('');
					api.getReturnsList().then((res) => {
						setIsLoading(false);
						setReturnsList(res.data.data);
						setpagination(res.data);
						isFirstLoad && setIsFirstLoad(false);
					});
				}
				return res?.status ? res : abc;
			}),
			{
				loading: 'Deleting Returns...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete returns</b>
			}
		);
	};

	const deleteReturnsData = (data) => {
		setShow(data);
	};

	return (
		<>
			<div className="row">
				{/* Area Chart */}
				<div className="col-xl-12 col-lg-12">
					<div className="section-head-new" style={{ marginTop: '10px' }}></div>
					<div className="card shadow-sm bg-white" style={{ paddingBottom: '7px' }}>
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<div className="actions" style={{ display: 'flex', width: '100%' }}>
									<div style={{ margin: '0 5px' }}>
										<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} multi={undefined} source={'return'} />
									</div>
									<div style={{ margin: '0 5px' }}>
										<PermissionAllowed permissions={['Create Returns (Button)']}>
											<Link to={'/returns/create'} className="btn btn-primary btn-outlined-main-primary white-var">
												<FaPlus /> New Returns
											</Link>
										</PermissionAllowed>
									</div>
									<div style={{ margin: '0 5px' }}>
										<Link
											to={'#'}
											className="btn btn-primary btn-outlined-main white-var"
											onClick={() => {
												handleOpenWarrantyCheckerModal();
											}}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="15.992" height="16.271" viewBox="0 0 59.992 61.271">
												<path
													id="features-alt"
													d="M55.261,14.148l-8.9-8.9A17.746,17.746,0,0,0,33.727.015H22.2A12.766,12.766,0,0,0,9.852,9.543a2.552,2.552,0,1,0,4.937,1.292A7.664,7.664,0,0,1,22.2,5.121H33.727c.421,0,.825.082,1.238.12V17.886a7.668,7.668,0,0,0,7.659,7.659h12.64c.041.414.125.822.125,1.238V48.521A7.668,7.668,0,0,1,47.73,56.18H22.2A7.593,7.593,0,0,1,17.413,54.5a2.553,2.553,0,1,0-3.194,3.983,12.8,12.8,0,0,0,7.978,2.8h25.53A12.779,12.779,0,0,0,60.492,48.521V26.783a17.753,17.753,0,0,0-5.234-12.635ZM42.624,20.439a2.556,2.556,0,0,1-2.553-2.553V6.865a12.723,12.723,0,0,1,2.681,1.994l8.9,8.9a12.624,12.624,0,0,1,1.981,2.681H42.621ZM24.753,40.824H45.177a2.553,2.553,0,1,1,0,5.106H24.753a2.553,2.553,0,1,1,0-5.106Zm-7.659,7.268V35.966A10.191,10.191,0,1,0,.5,28.059a10.155,10.155,0,0,0,3.829,7.907V48.092A1.663,1.663,0,0,0,7.314,49.1l3.4-4.45,3.4,4.45A1.663,1.663,0,0,0,17.094,48.092ZM10.712,22.953a5.106,5.106,0,1,1-5.106,5.106A5.111,5.111,0,0,1,10.712,22.953ZM27.306,35.718a2.553,2.553,0,1,1,0-5.106H45.177a2.553,2.553,0,1,1,0,5.106Z"
													fill="currentColor"
													transform="translate(-0.5 -0.015)"
												/>
											</svg>
											Warranty Check
										</Link>
									</div>
									<div style={{ margin: '0 5px' }}>
										<Link
											to={'#'}
											className="btn btn-primary btn-outlined-main white-var"
											onClick={() => {
												handleOpenReportPopupModal();
											}}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="15.632" height="20.689" viewBox="0 0 55.632 60.689">
												<path
													id="file-medical-alt"
													d="M16.437,40.46H2.529a2.529,2.529,0,1,1,0-5.057H15.084l4.306-6.461a2.5,2.5,0,0,1,2.261-1.12,2.532,2.532,0,0,1,2.106,1.393L29.394,40.49l2.64-3.96a2.532,2.532,0,0,1,2.1-1.125H45.517a2.529,2.529,0,1,1,0,5.057H35.491l-4.306,6.461a2.529,2.529,0,0,1-2.1,1.125c-.053,0-.106,0-.157-.005a2.532,2.532,0,0,1-2.106-1.393L21.181,35.374l-2.64,3.96a2.532,2.532,0,0,1-2.1,1.125Zm39.2-13.946V48.046A12.658,12.658,0,0,1,42.988,60.689H17.7A12.658,12.658,0,0,1,5.057,48.046a2.529,2.529,0,1,1,5.057,0A7.6,7.6,0,0,0,17.7,55.632H42.988a7.6,7.6,0,0,0,7.586-7.586V26.514c0-.412-.02-.822-.058-1.226H37.931A7.6,7.6,0,0,1,30.345,17.7V5.116c-.4-.038-.814-.058-1.226-.058H17.7a7.6,7.6,0,0,0-7.586,7.586V27.816a2.529,2.529,0,0,1-5.057,0V12.644A12.658,12.658,0,0,1,17.7,0H29.118A17.59,17.59,0,0,1,41.635,5.184L50.448,14a17.586,17.586,0,0,1,5.184,12.517ZM48.908,20.23a12.658,12.658,0,0,0-2.036-2.655L38.06,8.762A12.723,12.723,0,0,0,35.4,6.726V17.7a2.533,2.533,0,0,0,2.529,2.529H48.911Z"
													fill="currentColor"
												/>
											</svg>
											Reports
										</Link>
									</div>
								</div>
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
										style={{ width: '100%' }}
                                        value={searchField}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl tableData estimationList">
									<Scroller extraHeight={30}>
										<table className="table pm-table">
											<thead>
												<tr className="sticky-header">
													<th scope="col">Returns</th>
													<th scope="col text-left">Customer</th>
													<th scope="col text-left">Supplier</th>
													<th scope="col text-left">Products</th>
													<th scope="col text-left" style={{ width: '110px' }}>
														Status
													</th>
													<th scope="col text-left">Created Date</th>

													<PermissionAllowed permissions={['Delete Returns (Button)']}>
														<th scope="col" className="text-center">
															Action
														</th>
													</PermissionAllowed>
												</tr>
											</thead>
											<tbody>
												{isLoading ? (
													<>
														{[...Array(7)].map((e, i) => (
															<tr>
																{[...Array(7)].map((e, i) => (
																	<td>
																		<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																	</td>
																))}
															</tr>
														))}
													</>
												) : (
													<>
														{
															returnsList?.map((row, index) => {
																return (
																<>
																	<tr key={index}>
																		<td
																			className="align-middle font14"
																			onClick={() => {
																				if (hasEveryPermission(['View Returns (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{row?.reference_no}
																		</td>
																		<td
																			className="align-middle font14"
																			onClick={() => {
																				if (hasEveryPermission(['View Returns (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			<PrivacyModeContent>{row?.customer?.name}</PrivacyModeContent>
																		</td>
																		<td
																			className="align-middle font14"
																			onClick={() => {
																				if (hasEveryPermission(['View Returns (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			<PrivacyModeContent>
																				{
																					// row?.return_items?.map(item => item.supplier).join(', ')
																					Array.from(new Set(row?.return_items?.map((item) => item.supplier))).join(', ')
																				}
																			</PrivacyModeContent>
																		</td>
																		<td
																			className="align-middle font14"
																			onClick={() => {
																				if (hasEveryPermission(['View Returns (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{row?.total_returns}
																		</td>
																		<td
																			className="align-middle font14"
																			onClick={() => {
																				if (hasEveryPermission(['View Returns (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			<div
																				className={`badge ${row?.status?.name == 'Received'
																					? 'badge-primary'
																					: row?.status?.name == 'In Repair'
																						? 'badge-info'
																						: row?.status?.name == 'For Credit Note' || row?.status?.name == 'For Approval'
																							? 'badge-warning'
																							: row?.status?.name == 'Completed'
																								? 'badge-success'
																								: 'badge-dark-gray'
																					}`}
																				style={{ width: '100%' }}
																			>
																				{row?.status?.name === 'For Approval' ? 'Pending' : row?.status?.name}
																			</div>
																		</td>
																		<td
																			className="align-middle font14"
																			onClick={() => {
																				if (hasEveryPermission(['View Returns (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Returns (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{moment(row?.created_at).format(CommonDateOnlyFormat)}
																		</td>
																		<PermissionAllowed permissions={['Delete Returns (Button)', 'Edit Returns (Button)']} hasSome>
																			<td className="align-middle action-col font14" style={{ marginTop: '7px' }}>
																				<Tooltip title={'Edit'} arrow placement="top">
																					<a
																						href="javascript:void(0);"
																						onClick={() => {
																							if (hasEveryPermission(['View Returns (Button)'])) {
																								viewData(row.id);
																							}
																						}}
																						style={{ marginRight: '3px' }}
																					>
																						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 19.875 21.722">
																							<g id="Group_40056" data-name="Group 40056" transform="translate(-2.982 -1.847)">
																								<path
																									id="Vector"
																									d="M14.452,1.27c2.195,2.076,1.559,3.774.149,5.263l-8.153,8.63a2.851,2.851,0,0,1-1.38.745l-3.2.546A1.517,1.517,0,0,1,.013,14.7l.367-3.218a3.029,3.029,0,0,1,.665-1.42L9.2,1.429c1.41-1.49,3.069-2.215,5.253-.159Z"
																									transform="translate(4.972 2.868)"
																									fill="none"
																									stroke="#06f"
																									stroke-linecap="round"
																									stroke-linejoin="round"
																									stroke-width="2"
																								/>
																								<path
																									id="Vector-2"
																									data-name="Vector"
																									d="M5.412,5.114A6.084,6.084,0,0,1,0,0"
																									transform="translate(12.81 5.737)"
																									fill="none"
																									stroke="#06f"
																									stroke-linecap="round"
																									stroke-linejoin="round"
																									stroke-width="2"
																								/>
																								<path
																									id="Vector-3"
																									data-name="Vector"
																									d="M0,0H17.875"
																									transform="translate(3.982 22.569)"
																									fill="none"
																									stroke="#06f"
																									stroke-linecap="round"
																									stroke-linejoin="round"
																									stroke-width="2"
																								/>
																							</g>
																						</svg>
																					</a>
																				</Tooltip>
																				<Tooltip title={'Delete'} arrow placement="top">
																					<a
																						href="javascript:void(0);"
																						onClick={() => {
																							if (hasEveryPermission(['Delete Returns (Button)'])) {
																								deleteReturnsData(row);
																							}
																						}}
																						style={{ marginLeft: '3px' }}
																					>
																						<svg
																							id="vuesax_linear_trush-square"
																							data-name="vuesax/linear/trush-square"
																							xmlns="http://www.w3.org/2000/svg"
																							width="20"
																							height="20"
																							viewBox="0 0 31.855 31.855"
																						>
																							<g id="trush-square">
																								<path
																									id="Vector"
																									d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z"
																									transform="translate(2.655 2.655)"
																									fill="none"
																									stroke="#ff342d"
																									stroke-linecap="round"
																									stroke-linejoin="round"
																									stroke-width="2"
																								/>
																								<g id="Group" transform="translate(8.097 8.548)">
																									<path
																										id="Vector-2"
																										data-name="Vector"
																										d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438"
																										transform="translate(0 3.026)"
																										fill="none"
																										stroke="#ff342d"
																										stroke-linecap="round"
																										stroke-linejoin="round"
																										stroke-width="2"
																									/>
																									<path
																										id="Vector-3"
																										data-name="Vector"
																										d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128"
																										transform="translate(4.792)"
																										fill="none"
																										stroke="#ff342d"
																										stroke-linecap="round"
																										stroke-linejoin="round"
																										stroke-width="2"
																									/>
																									<path
																										id="Vector-4"
																										data-name="Vector"
																										d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0"
																										transform="translate(1.858 3.57)"
																										fill="none"
																										stroke="#ff342d"
																										stroke-linecap="round"
																										stroke-linejoin="round"
																										stroke-width="2"
																									/>
																								</g>
																								<path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
																							</g>
																						</svg>
																					</a>
																				</Tooltip>
																			</td>
																		</PermissionAllowed>
																	</tr>
																</>
																);
															})
														}
														{
															returnsList?.length < 1 && (
																<tr className="no-hover">
																	<td colSpan={7} className="whitebg">
																		<div className="not-found-container">
																			<div className="not-found-icon">
																				<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																			</div>
																			<h6 className="not-found-text">No Data Available</h6>

																			<PermissionAllowed permissions={['Create New Returns (Action)']}>
																				<Link to={'/returns/create'} className="btn btn-primary btn-outlined-main">
																					Create Returns
																				</Link>
																			</PermissionAllowed>
																		</div>
																	</td>
																</tr>
															)
														}
													</>
												)}
											</tbody>
										</table>
									</Scroller>
								</div>
								<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
							</div>
						</div>
					</div>
				</div>
			</div>

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					handleDelete={handleDelete}
					title="Confirm"
					message={`Are you sure you want to delete ${show?.reference_no}?`}
				/>
			)}

			{openWarrantyCheckerPopup && (
				<>
					<WarrantyCheckerModal
						openPopup={openWarrantyCheckerPopup}
						// returnsDetail={returnsDetail}
						// handleReplacementImeis={handleReplacementImeis}
						handleClose={closePopup}
						// handleSave={handleSave}
						disableStatus={isDisable}
					/>
				</>
			)}
			{openReportPopup && (
				<>
					<ReturnsReportPopupModal
						openPopup={openReportPopup}
						// returnsDetail={returnsDetail}
						// handleReplacementImeis={handleReplacementImeis}
						handleClose={closePopup}
						// handleSave={handleSave}
						disableStatus={isDisable}
					/>
				</>
			)}
		</>
	);
};

export default Index;

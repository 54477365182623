import { createSlice } from '@reduxjs/toolkit';
import { initialState } from 'store/state';

const setInitialState = () => {
	const user = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : sessionStorage.getItem('user'));
	const client = JSON.parse(localStorage.getItem('client') ? localStorage.getItem('client') : sessionStorage.getItem('client'));
	const privacyMode = localStorage.getItem('privacyMode') ? JSON.parse(localStorage.getItem('privacyMode')) : false;
	const darkMode = localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : false;
	const permissions = JSON.parse(localStorage.getItem('user'))
		? JSON.parse(localStorage.getItem('user'))?.permissions
		: JSON.parse(sessionStorage.getItem('user'))
		? JSON.parse(sessionStorage.getItem('user'))?.permissions
		: [];
	const selectedCurrency = localStorage.getItem('selectedCurrency') || 'eur';
	const tasksList = localStorage.getItem('tasksList') ? JSON.parse(localStorage.getItem('tasksList')) : [];

	let temp = { ...initialState };

	if (user) {
		temp = { ...temp, user, selectedCurrency, permissions, privacyMode, tasksList, darkMode };
	} else {
		temp = { ...temp, selectedCurrency, permissions, privacyMode, tasksList, darkMode };
	}

	if (client) {
		temp = { ...temp, client };
	}

	return temp;
	// if (client) {
	// 	return { ...initialState, client };
	// }
};

export const mainSlice = createSlice({
	name: 'main',
	initialState: setInitialState(),
	reducers: {
		storeUserDetails: (state, action) => {
			state.user = action.payload;
			if (state?.user?.stayLoggedIn) {
				localStorage.setItem('user', JSON.stringify(state.user));
			} else {
				sessionStorage.setItem('user', JSON.stringify(state.user));
			}
		},
		storeClientDetails: (state, action) => {
			state.client = action.payload;
			if (state?.client?.stayLoggedIn) {
				localStorage.setItem('client', JSON.stringify(state.client));
			} else {
				sessionStorage.setItem('client', JSON.stringify(state.client));
			}
		},
		store_approved: (state, action) => {
			state.approved = action.payload;
		},
		storeMasterStatuses: (state, action) => {
			state.masterStatuses = action.payload;
		},
		storeStatusTypes: (state, action) => {
			state.statusTypes = action.payload;
		},
		storeHeaderActions: (state, action) => {
			state.headerActions = action.payload;
		},
		storePermissions: (state, action) => {
			state.permissions = action.payload;
		},
		storePageTitle: (state, action) => {
			state.pageTitle = action.payload;
		},
		storeHasNewNotification: (state, action) => {
			state.hasNewNotification = action.payload;
		},
		storePrivacyMode: (state, action) => {
			state.privacyMode = action.payload;
			localStorage.setItem('privacyMode', state.privacyMode);
		},
		setCustomerId: (state, action) => {
			state.selectedId = action.payload;
			localStorage.setItem('selectedId', state.selectedId);
		},
		setDisplayTheme: (state, action) => {
			state.displayTheme = action.payload;
			localStorage.setItem('displayTheme', state.displayTheme);
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setLogout: (state, action) => {
			state.logout = action.payload;
		},
		setClientLogout: (state, action) => {
			state.clientlogout = action.payload;
		},
		setOpened: (state, action) => {
			state.opened = action.payload;
		},
		setSelectedItem: (state, action) => {
			state.selectedItem = action.payload;
		},
		setReturncomponent: (state, action) => {
			state.returncomponent = action.payload;
		},
		setEstimatedata: (state, action) => {
			state.estimatedata = action.payload;
		},
		setIndexOpen: (state, action) => {
			state.openIndex = action.payload;
		},
		storeInLocalStore: (state, action) => {
			state.user = { ...state.user, ...action.payload };
			if (state?.user?.stayLoggedIn) {
				localStorage.setItem('user', JSON.stringify({ ...state.user, ...action.payload }));
				sessionStorage.setItem('user', JSON.stringify({ ...state.user, ...action.payload }));
			}
		},
		setNavMobileActions: (state, action) => {
			state.navMobileActions = action.payload;
		},
		setstateInspectionList: (state, action) => {
			state.setstateinspectionlist = action.payload;
		},
		setstateCollectionList: (state, action) => {
			state.setstatecollectionList = action.payload;
		},
		setMessageData: (state, action) => {
			state.setmessagedata = action.payload;
		},
		setCustomerlist: (state, action) => {
			state.setcustomerlist = action.payload;
		},
		setSupplierlist: (state, action) => {
			state.supplierlist = action.payload;
		},
		setTransitList: (state, action) => {
			state.transitlist = action.payload;
		},
		setStateUserList: (state, action) => {
			state.setstateuserlist = action.payload;
		},
		setStateUserRole: (state, action) => {
			state.setstateuserrole = action.payload;
		},
		setStateModellist: (state, action) => {
			state.setstatemodellist = action.payload;
		},
		setStateProductlist: (state, action) => {
			state.setstateproductlist = action.payload;
		},
		setStateProductRepairlist: (state, action) => {
			state.setstateproductrepairlist = action.payload;
		},
		setSearchBar: (state, action) => {
			state.searchBar = action.payload;
		},
		setStateSelectedCurrency: (state, action) => {
			state.selectedCurrency = action.payload;
			localStorage.setItem('selectedCurrency', action.payload);
		},
		setTasksList: (state, action) => {
			state.tasksList = action.payload;
			localStorage.setItem('tasksList', JSON.stringify(action.payload));
		},
		setCartValue: (state, action) => {
			state.cartValue = action.payload;
		},
		storeDarkMode: (state, action) => {
			state.darkMode = action.payload;
			localStorage.setItem('darkMode', state.darkMode);
		},
		storeSearchPicklist: (state, action) => {
			state.searchPicklist = action.payload;
		},
		storeSearchPurchaseOrder: (state, action) => {
			state.searchPurchaseOrder = action.payload;
		},
		storeSearchReturn: (state, action) => {
			state.searchReturn = action.payload;
		},
		storeSearchOperation: (state, action) => {
			state.searchOperation = action.payload;
		},
		storeSearchSalesOrder: (state, action) => {
			state.searchSalesOrder = action.payload;
		},
		storeSearchInvoice: (state, action) => {
			state.searchInvoice = action.payload;
		},
		storeSearchQuotation: (state, action) => {
			state.searchQuotation = action.payload;
		},
		storeSearchShipment: (state, action) => {
			state.searchShipment = action.payload;
		},
		storeSearchBackOrder: (state, action) => {
			state.searchBackOrder = action.payload;
		},
		storeSearchVendorCredit: (state, action) => {
			state.searchVendorCredit = action.payload;
		},
		storeFilterPicklist: (state, action) => {
			state.filterPicklist = action.payload;
		},
		storeFilterPurchaseOrder: (state, action) => {
			state.filterPurchaseOrder = action.payload;
		},
		storeFilterReturn: (state, action) => {
			state.filterReturn = action.payload;
		},
		storeFilterOperation: (state, action) => {
			state.filterOperation = action.payload;
		},
		storeFilterSalesOrder: (state, action) => {
			state.filterSalesOrder = action.payload;
		},
		storeFilterInvoice: (state, action) => {
			state.filterInvoice = action.payload;
		},
		storeFilterQuotation: (state, action) => {
			state.filterQuotation = action.payload;
		},
		storeFilterShipment: (state, action) => {
			state.filterShipment = action.payload;
		},
		storeFilterBackOrder: (state, action) => {
			state.filterBackOrder = action.payload;
		},
		storeFilterVendorCredit: (state, action) => {
			state.filterVendorCredit = action.payload;
		},
		storeActiveBackOrderTab: (state, action) => {
			state.activeBackOrderTab = action.payload;
		},
	}
});

// Action creators are generated for each case reducer function
export const {
	storeUserDetails,
	storeClientDetails,
	store_approved,
	setLoading,
	setSelectedItem,
	setLogout,
	setClientLogout,
	setOpened,
	storeInLocalStore,
	setReturncomponent,
	setEstimatedata,
	setIndexOpen,
	setstateInspectionList,
	setstateCollectionList,
	setMessageData,
	setCustomerlist,
	setSupplierlist,
	setTransitList,
	setStateUserList,
	setStateUserRole,
	setStateModellist,
	setStateProductlist,
	setStateProductRepairlist,
	setDisplayTheme,
	setStateSelectedCurrency,
	storePermissions,
	storeHasNewNotification,
	storePrivacyMode,
	storeMasterStatuses,
	storePageTitle,
	storeStatusTypes,
	setNavMobileActions,
	storeHeaderActions,
	setSearchBar,
	setTasksList,
	setCartValue,
	storeDarkMode,
	storeSearchPicklist,
	storeSearchPurchaseOrder,
	storeSearchReturn,
	storeSearchOperation,
	storeSearchSalesOrder,
	storeSearchInvoice,
	storeSearchQuotation,
	storeSearchShipment,
	storeSearchBackOrder,
	storeSearchVendorCredit,
	storeFilterPicklist,
	storeFilterPurchaseOrder,
	storeFilterReturn,
	storeFilterOperation,
	storeFilterSalesOrder,
	storeFilterInvoice,
	storeFilterQuotation,
	storeFilterShipment,
	storeFilterBackOrder,
	storeFilterVendorCredit,
	storeActiveBackOrderTab,
} = mainSlice.actions;

export default mainSlice.reducer;

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import Scroller from 'ui-component/Scroller';
import Select from 'react-select';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import ReactSelect from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import MentionInput from 'ui-component/MentionInput/MentionInput';


const ApplyToBillModal = ({ openPopup, data, handleClose, handleReloadData }) => {

    const [purchaseOrderLists, setPurchaseOrderLists] = useState([]);   
    const [currencyList, setCurrencyList] = useState([]);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
	const [clearInput, setClearInput] = useState(false);
	const [inputFocus, setInputFocus] = useState(false);
	const [noteInputValue, setNoteInputValue] = useState('');
	const [noteInputPlain, setNoteInputPlain] = useState('');
	const [noteMentions, setNoteMentions] = useState([]);
    const [noteValue, setNoteValue] = useState('');

	const creditDate = moment().format('YYYY-MM-DD');
    


    const [request, setRequest] = useState({
        vendor_credit_id: '',
        purchase_invoice_id: '',
        vendor_credit_currency: '',
        vendor_credit_currency_label: '',
        vendor_credit_balance: '',
        currency: '',
        currency_label: '',
        amount: '',
        bill_date: '',
        bill_balance_base: '',
        bill_balance: '',
        bill_amount_base: '',
        bill_amount: '',
        bill_currency: '',
        bill_currency_label: '',
        note: '',
    });

    const initializeData = () => {
        setRequest({
            vendor_credit_id: data?.id,
            purchase_invoice_id: '',
            currency: data?.currency,
            currency_label: data?.currency_symbol,
            vendor_credit_currency: data?.currency,
            vendor_credit_currency_label: data?.currency_symbol,
            vendor_credit_balance: data?.converted_currency_balance[data?.currency],
            amount: '0.00',
            bill_date: creditDate,
            bill_balance_base: '0.00',
            bill_balance: '0.00',
            bill_amount_base: '0.00',
            bill_amount: '0.00',
            bill_currency: data?.currency,
            bill_currency_label: data?.currency_symbol,
            note: '',
        });


		api.getSelectOptionsListWithId('open_purchase_order_by_vendor', data?.vendor?.id).then((res) => {
			const value = res.data;
			const valueArray = value.map((item) => ({ label: item.purchase_order_id, value: item.id, ...item }));
            setPurchaseOrderLists(valueArray);
		});

        api.getSelectOptionsList('currency').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.currency, value: item.value }));
            setCurrencyList(collection);
		});
    }

    const handlePurchaseOrderSelect = (val) => {
		api.getSelectOptionsListWithId('vendor_purchase_order_payment', val?.value).then((res) => {
            setRequest({
                ...request,
                purchase_invoice_id: val?.value,
                currency: res.data.currency,
                currency_label: res.data.currency_label,
                bill_currency: res.data.currency,
                bill_currency_label: res.data.currency_symbol,
                bill_amount_base: res.data.total,
                bill_amount: res.data.total,
                bill_balance: res.data.balance,
                bill_balance_base: res.data.balance,
                note: ''
            });
			// const value = res.data;
			// const valueArray = value.map((item) => ({ label: item.purchase_order_id, value: item.id, ...item }));
            // setPurchaseOrderLists(valueArray);
		});
    }

    const handleChangeCurrency = (val) => {
        const selectedCurrencyValue = val?.value;
        const selectedCurrencyLabel = val?.label;
        setRequest({
            ...request,
            currency_label: selectedCurrencyLabel,
            currency: selectedCurrencyValue,
        })
    }

    const handleChangeAmount = (val) => {
        setRequest({ ...request, amount: val })
    }


    const submitForm = () => {
        if (request?.purchase_invoice_id == '') {
            toast.error('Please select purchase order');
            return false;
        }
        if (request?.amount == '' || request?.amount == '0.00') {
            toast.error('Please enter amount');
            return false;
        }
        // if (parseFloat(request?.amount) > parseFloat(request?.vendor_credit_balance)) {
        //     toast.error('Amount should be less than or equal to available credits');
        //     return false;
        // }

        const params = {
            vendor_credit_id: request?.vendor_credit_id,
            purchase_invoice_id: request?.purchase_invoice_id,
            currency: request?.currency,
            amount: request?.amount,
            bill_date: request?.bill_date,
            comment: noteValue,
        };

        setIsFormSubmitting(true)
		toast.promise(
			api
				.vendorApplyCreditToBill(data?.id, params)
				.then(
					(res) => {
						if (res.status) {
                            handleReloadData();
                            setIsFormSubmitting(false)
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Apply to bill...',
				success: (data) => `${data.message}`,
				error: <b>Failed to apply to bill</b>
			}
		);

        // api.vendorApplyCreditToBill(data?.id, params).then((res) => {
        //     if (res?.data?.status == 'success') {
        //         toast.success(res?.data?.message);
        //         handleClose();
        //     } else {
        //         toast.error(res?.data?.message);
        //     }
        // });
    }

    useEffect(() => {
        const balanceAmount = data?.converted_currency_balance[request?.currency] - request.amount;
        const billBalance = request?.bill_balance_base - request.amount;
        const billAmount = request?.bill_amount_base - request.amount;
        setRequest({ 
            ...request, 
            vendor_credit_balance: parseFloat(balanceAmount).toFixed(2), 
            bill_balance: parseFloat(billBalance).toFixed(2),
            bill_amount: parseFloat(billAmount).toFixed(2),
        })


    }, [request.amount, request?.currency])

    useEffect(() => {
        initializeData();
    }, []);


    // const [emailDetails, setEmailDetails] = useState({
    //     receiver: '',
    //     subject: '',
    //     message: '',
    //     id: selectedForSending,
    //     type: openPopup,
    // });



    // useEffect(() => {
    //     let sendType = '';
    //     if (openPopup == 'quotation') {
    //         sendType = 'send_estimate';
    //         setLabelType('quotation');
    //     } else if (openPopup == 'sales_order') {
    //         sendType = 'send_sales_order';
    //         setLabelType('sales order');
    //     } else {
    //         sendType = 'send_invoice';
    //         setLabelType('invoice');
    //     }
    //     // const sendType = openPopup == 'estimate' ? 'send_estimate' : 'send_invoice';
    //     api.getEmailTemplate(`send_vendor_credit`).then((res) => {
            
    //         let salesType = '';

    //         if (openPopup == 'quotation') {
    //             salesType = 'Quotation';
    //         } else if (openPopup == 'sales_order') {
    //             salesType = 'Sales Order';
    //         } else if (openPopup == 'invoice') {
    //             salesType = 'Invoice';
    //         } else {
    //             salesType = 'Credit Note';
    //         }


    //         let subject = res?.data?.subject?.replace(/{purchase_credit_number}/g, data?.purchase_credit_number);

    //         let message = res?.data?.template?.replace(/{purchase_credit_number}/g, data?.purchase_credit_number);

    //         const email = (data?.transit_company) ? data?.transit_company?.email : data?.customer?.email;

    //         setEmailDetails({ ...emailDetails, subject: subject, message: message, receiver: email });
    //     });
    // }, []);
    // const handleSendingType = (type) => {
    //     setSendingType(type)
    //     if (type == 'auto') {
    //         setEmailDetails({ ...emailDetails, receiver: '' });
    //     }
    // }


    // const sendEmail = () => {
    //     if (sendingType == 'auto') {

    //         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //         if (!emailRegex.test(emailDetails?.receiver)) {
    //             toast.error(`Please enter valid email address`);
    //             return false;
    //         } else if (emailDetails?.subject.trim() == '') {
    //             toast.error(`Please enter email subject`);
    //             return false;
    //         } else {
    //             const data = {
    //                 receiver: emailDetails?.receiver,
    //                 subject: emailDetails?.subject,
    //                 message: emailDetails?.message,
    //                 sending_type: sendingType,
    //                 id: selectedForSending,
    //                 type: openPopup
    //             };
    //             sendEmailSubmit(data);
    //         }
    //     } else {
    //         const data = {
    //             receiver: emailDetails?.receiver,
    //             subject: emailDetails?.subject,
    //             message: emailDetails?.message,
    //             sending_type: sendingType,
    //             id: selectedForSending,
    //             type: openPopup
    //         };
    //         sendEmailSubmit(data);
    //     }
    // }

    // const disablepopup = () => {
    //     handleClose();
    // };




    return (
        <>
            <Modal show={openPopup} centered size="md">
                <Modal.Body>
                    <div className="p-2">
                        <div style={{ padding: '20px' }}>
                            <div className="mb-2 ml-0 pl-0">

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center">
                                            <h2 className="h4 mb-0 font-weight-bold">Apply Credit</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ margin: '15px 0' }}>
                                    <div className="col-12 text-center">
                                        You are going to apply a credit from <span className='font500'>{data?.purchase_credit_number}</span>.
                                    </div>
                                </div>
                                <button className="btn modal-close-icon" onClick={handleClose}>
                                    {/* <FaTimes /> */}
                                    <svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
                                        <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
                                    </svg>


                                </button>
                            </div>

                            <div className="row align-items-top">


                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="font400" >Select Purchase Order</label>
                                        <ReactSelect
                                            className="rs-container"
                                            classNamePrefix="rs"
                                            options={purchaseOrderLists}
                                            value={purchaseOrderLists?.find((item) => item?.value === request?.purchase_invoice_id) || null}
                                            onChange={handlePurchaseOrderSelect}
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            placeholder="Select or type..."
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group" >
                                        <label className="font400" >Bill Date</label>
                                        <div>
                                            <input
                                                type="text"
                                                className='form-control'
                                                value={request?.bill_date}
                                                disabled={true}
                                                // onChange={(e) => setEmailDetails({ ...emailDetails, receiver: e.target.value })}
                                                // placeholder=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md style={{ paddingBottom: '7px'}}-6">
                                    <div className="form-group" >
                                        <label className="font400" >Bill Amount	</label>
                                        <div className="d-flex input-group-2-form">

                                            <input
                                                type="text"
                                                className="form-control left-input"
                                                id="inputName"
                                                value={request?.currency_label}
                                                disabled={true}
                                                style={{ width: '75px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control right-input"
                                                id="inputName"
                                                value={isNaN(request?.bill_amount) ? '0.00' : request?.bill_amount}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md style={{ paddingBottom: '7px'}}-6">
                                    <div className="form-group" >
                                        <label className="font400" >Bill Balance</label>
                                        
                                        <div className="d-flex input-group-2-form">

                                            <input
                                                type="text"
                                                className="form-control left-input"
                                                id="inputName"
                                                value={request?.currency_label}
                                                disabled={true}
                                                style={{ width: '75px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control right-input"
                                                id="inputName"
                                                value={isNaN(request?.bill_balance) ? '0.00' : request?.bill_balance}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group"  style={{ paddingBottom: '7px'}}>
                                        <label className="font400" >Credits to Apply</label>
                                        
                                        <div className=" d-flex input-group-2-form input-group-2-form-currency">

                                            <input
                                                type="text"
                                                className="form-control left-input"
                                                id="inputName"
                                                value={request?.currency_label}
                                                disabled={true}
                                                style={{ width: '75px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control right-input"
                                                id="inputName"
                                                value={request?.amount}
                                                onChange={(e) => {
                                                    handleChangeAmount(e.target.value)
                                                }}
                                                onClick={(e) => {
                                                    if (e.target.value === '0' || e.target.value === '0.00') {
                                                        setRequest({ ...request, amount: '' });
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        setRequest({ ...request, amount: '0.00' });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group"  style={{ paddingBottom: '7px'}}>
                                        <label className="font400" >Available Credits</label>
                                        
                                        <div className="d-flex input-group-2-form">

                                            <input
                                                type="text"
                                                className="form-control left-input"
                                                id="inputName"
                                                value={request?.currency_label}
                                                disabled={true}
                                                style={{ width: '75px' }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control right-input"
                                                id="inputName"
                                                value={isNaN(request?.vendor_credit_balance) ? '0.00' : request?.vendor_credit_balance}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-12">
                                    <label htmlFor="paymentAmount">Note</label>
                              
                                    <MentionInput
                                        onContentChange={(e, x, y) => {
                                            // setNoteInputValue(JSON.stringify(e));
                                            // setNoteInputPlain(x);
                                            // setNoteMentions(y);
                                            // setRequest({ ...request, note: value });
                                            setNoteValue(x);
                                            // setRequest({ ...request, note: x });
                                        }}
                                        clear={clearInput}
                                        inputFocus={inputFocus}
                                        setInputFocus={(e) => {
                                            setInputFocus(e);
                                        }}
                                        triggerSave={() => {
                                            // if(noteInputPlain.length > 0) {
                                            //     // addNote && addNote(noteInputValue, selectedNoteType, noteMentions, noteInputPlain);
                                            //     // clearInputField();
                                            // }
                                        }}
                                        enableMention={false}
                                    />
                                </div>
                                {/* <div className="col-12">

                                    <p style={{ color: '#535355' }}>
                                        <input
                                            type="checkbox"
                                            checked={sendingType == 'auto' ? true : false}
                                            onClick={() => handleSendingType('auto')}
                                        />{' '}
                                        <span
                                            style={{ fontWeight: '600', marginLeft: '5px', fontSize: '14px', cursor: 'pointer' }}
                                            onClick={() => handleSendingType('auto')}
                                        >
                                            Send vendor credit by email
                                        </span>{' '}
                                        <span
                                            style={{ fontStyle: 'italic', color: '#989898', cursor: 'pointer' }}
                                            onClick={() => handleSendingType('auto')}
                                        >
                                            (the vendor credit will be added as an attachment to the email message)
                                        </span>
                                    </p>
                                </div> */}
                            </div>
                            {/* {
                                sendingType == 'auto' && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-md-2 offset-md-1 col-11">
                                                <label className="font400">Send to</label>
                                            </div>
                                            <div className="col-md-9 col-12 form-group">
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    value={emailDetails?.receiver}
                                                    onChange={(e) => setEmailDetails({ ...emailDetails, receiver: e.target.value })}
                                                    placeholder="Enter recipient's email address"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-md-2 offset-md-1 col-11">
                                                <label className="font400">Subject</label>
                                            </div>
                                            <div className="col-md-9 col-12 form-group">
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    value={emailDetails?.subject}
                                                    onChange={(e) => setEmailDetails({ ...emailDetails, subject: e.target.value })}
                                                    placeholder="Enter email subject"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-md-2 offset-md-1 col-11">
                                                <label className="font400">Email message</label>
                                            </div>
                                            <div className="col-md-9 col-12 form-group">
                                                <textarea
                                                    className='form-control'
                                                    rows="10"
                                                    value={emailDetails?.message}
                                                    onChange={(e) => setEmailDetails({ ...emailDetails, message: e.target.value })}
                                                    style={{ resize: 'none' }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                data?.email_sent === null && (
                                    <>
                                        <div className="row align-items-top">
                                            <div className="col-12">

                                                <p style={{ color: '#535355' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={sendingType == 'manual' ? true : false}
                                                        onClick={() => handleSendingType('manual')}
                                                    />{' '}
                                                    <span
                                                        style={{ fontWeight: '600', marginLeft: '5px', fontSize: '14px', cursor: 'pointer' }}
                                                        onClick={() => handleSendingType('manual')}
                                                    >
                                                        Send vendor credit manually
                                                    </span>{' '}
                                                    <span
                                                        style={{ fontStyle: 'italic', color: '#989898', cursor: 'pointer' }}
                                                        onClick={() => handleSendingType('manual')}
                                                    >
                                                        (the vendor credit will be marked as sent)
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            } */}
                            <div className="btnarea" style={{ justifyContent: 'right' }}>
                                <div className="">

                                    <div className="row">
                                        <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'right' }}>
                                            <button
                                                className="btn btn-main-primary btn-outlined-main"
                                                style={{ marginRight: '4px' }}
                                                onClick={() => submitForm()}
                                            >
                                                {
                                                    isFormSubmitting ? (
                                                        <>
                                                            <FaSpinner className='spinner' /> Saving...
                                                        </>
                                                    ) : (
                                                        <>
                                                            Save
                                                        </>
                                                    )
                                                }
                                            </button>
                                            <button onClick={handleClose} className="btn btn-main-primary btn-outlined-main" style={{ marginLeft: '4px' }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ApplyToBillModal;

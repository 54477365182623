import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	setEstimatedata,
	setNavMobileActions,
	setstateCollectionList,
	storeHeaderActions,
	storeSearchSalesOrder
} from 'store/reducers/mainSlice';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
import OrderItemsTable from 'views/estimation/statusComponents/OrderItemsTable';

// import Acceptpopup from './Acceptpopup';
import { FaArrowRight, FaCheck, FaInfoCircle, FaPen, FaPlus, FaSave, FaSearch, FaTimesCircle, FaTrash } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import moment from 'moment';
import { LiaTimesSolid } from 'react-icons/lia';
import { Avatar, Tooltip } from '@mui/material';
import CurrencyValue from 'ui-component/CurrencyValue';
import AddToPurchaseListConfirmation from 'ui-component/confirmation-dialogs/addToPurchaseListConfirmation/AddToPurchaseListConfirmation';
import toast from 'react-hot-toast';
// import PaymentFormModal from './paymentComponents/PaymentFormModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill, PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { LuChevronDown } from 'react-icons/lu';
import { CommonDateOnlyFormat, getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import { TbDatabaseExclamation, TbReportMoney } from 'react-icons/tb';
import { useQuery, useQueryClient } from 'react-query';
import md5 from 'md5-hash';
import { ImSpinner2 } from 'react-icons/im';
import {
	AcceptedIcon,
	AllIcon,
	ClosedIcon,
	DraftIcon,
	EstimateIcon,
	OpenOrderIcon,
	PendingOrderIcon,
	SourcingIcon,
	PaymentTransactionsIcon,
} from 'ui-component/custom-icons/icons';
import usePageTitle from 'hooks/usePageTitle';
import { FaArrowsRotate, FaChartBar, FaMoneyBill, FaUserTie } from 'react-icons/fa6';
import { MdKeyboardArrowDown, MdOutlineSettingsBackupRestore } from 'react-icons/md';
import SortableHeader from 'ui-component/SortableHeader';
import ExportListItemsModal from 'ui-component/ExportListItemsModal';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import QuickStatsBar from 'ui-component/QuickStatsBar';
import AcceptOrderModal from 'ui-component/confirmation-dialogs/acceptOrderModal/AcceptOrderModal';

import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import StatusBadge from 'ui-component/StatusBadge';

const index = () => {
	const [setPageTitle] = usePageTitle('Orders');
	// useEffect(() => {
	// 	return () => {
	// 		setPageTitle(null);
	// 	};
	// }, []);
	const history = useNavigate();
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
	const [isDisabled, setIsDisabled] = useState(false);
    const [allPermissions, hasEveryPermission] = usePermissions();
	const [show, setShow] = useState('');
	const masterStatuses = useSelector((state) => state.main.masterStatuses);
	const statusTypes = useSelector((state) => state.main.statusTypes);
	const statusTypeList = statusTypes?.map((item) => ({ id: item.id, name: item.name }));
	const [isFiltersLoading, setIsFiltersloading] = useState(false);
	const [activeComponent, setActiveComponent] = useState('All');
	const [currentitem, setCurrentItem] = useState([]);
	const [openRP, setOpenRP] = useState(false);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [sortByFilter, setSortByFilter] = useState();
	const navMobileActions = useSelector((state) => state.main.navMobileActions);
	const headerActions = useSelector((state) => state.main.headerActions);
	const [moduleName, setModuleName] = useState('');

	const location = useLocation();
	const pathParts = location.pathname.split('/');
	const currentPath = pathParts[pathParts.length - 1];


	const storedSearch = useSelector((state) => state.main.searchSalesOrder);
	const storedFilter = useSelector((state) => state.main.filterSalesOrder);

    useEffect(() => {
        // if (storedSearch) {
        //     handleSearch({ target: { value: storedSearch } });
        // }

        const fetchData = async () => {
            if (storedSearch) {
                // setIsLoading(true);
                await handleSearch({ target: { value: storedSearch } });
            } else if (!storedSearch) {
                // if (storedFilter && Object.keys(storedFilter).length > 0) {
                //     // setIsLoading(true);
                //     // await handleFilterRedux();
                // } else {
                //     handleFilter()
                // }
            }
        };

        fetchData();
    }, [storedSearch, searchField, activeFilters, storedFilter]);

	useEffect(() => {
		initialRender()
		setPageTitle('Sales Orders')
		setActiveComponent('Sales Orders');

	}, [location, activeComponent]);

	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
	const updateSortByStatus = (name) => {
		if (sortByFilter?.field == name) {
			setSortByFilter((e) => ({
				...e,
				direction: e?.direction == 'asc' ? 'desc' : 'asc'
			}));
		} else {
			setSortByFilter((e) => ({
				field: name,
				direction: 'asc'
			}));
		}
	};

	const [selectedEstimate, setSelectedEstimate] = useState(null);

	const [addToPl, setAddToPl] = useState(null);
	const [addToPlId, setAddToPlId] = useState(null);
	const [paginationUrl, setPaginationUrl] = useState();

	const queryClient = useQueryClient();

	const {
		data: estReqData,
		isLoading,
		isFetching
	} = useQuery(
		['estimations', activeComponent, activeFilters, storedFilter, searchField, paginationUrl, sortByFilter],
		() => {
			return paginationUrl
				? api.plainAPIRequest(paginationUrl)
				: api.getSalesOrdersLists(
					activeComponent == 'Archived' ? 'Archived' : activeComponent == 'Draft' ? 'Draft' : statusTypeList?.find((item) => item?.name == activeComponent)?.id,
					activeFilters,
					searchField?.length > 2 ? searchField : null,
					sortByFilter
				);
		},
		{
			enabled: !!statusTypeList,
			initialData: (e) => {
				let item = null;
				if (paginationUrl) {
					item = getLocalStorageCacheItem(md5(paginationUrl));
				} else {
					item = getLocalStorageCacheItem(
						md5(
							api.listingEstimationApiURL(
								activeComponent == 'Archived' ? 'Archived' : activeComponent == 'Draft' ? 'Draft' : statusTypeList?.find((item) => item?.name == activeComponent)?.id,
								// thisStatus,
								activeFilters,
								searchField?.length > 2 ? searchField : null,
								sortByFilter
							)
						)
					);
				}
				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5(e?.config?.url), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const { data: counterReqData } = useQuery(
		['estimation-counter'],
		() => {
			return api.getPageOverviewCounter();
		},
		{
			keepPreviousData: true,
			enabled: true,
			initialData: (e) => {
				const item = getLocalStorageCacheItem(md5('estimation-counter'));

				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5('estimation-counter'), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => { },
			handleClose: () => { }
		});
	};

	const getItemList = estReqData?.data?.data?.data;
	const paginationData = estReqData?.data?.data;
	const counterData = counterReqData?.data?.data;

	const [showQuickStats, setShowQuickStats] = useState(false);
	const [quickStatsLoading, setQuickStatsLoading] = useState(false);
	const [quickStatsData, setQuickStatsData] = useState(null);

	useEffect(() => {
		if (showQuickStats && !quickStatsLoading) {
			setQuickStatsLoading(true);
			api
				.quickSummarySalesOrder(
					// statusTypeList?.find((item) => item?.name == activeComponent)?.id,
					activeFilters,
					searchField?.length > 2 ? searchField : null,
					sortByFilter
				)
				.then((res) => {
					setQuickStatsData(res.data);
					setQuickStatsLoading(false);
				});
		}
	}, [showQuickStats]);

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => { },
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	const initialRender = () => {
		getFiltersList();
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getSalesOrdersFilter().then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	// const handleComponentChange = (component) => {
	// 	setActiveComponent(component);
	// 	component === 'Draft' || component === 'Archived' ? setIsDisabled(true) : setIsDisabled(false);
	// 	setSearchField('');
	// 	setSortByFilter(null);
	// 	setSelectedEstimate(null);
	// };

	useEffect(() => {
		setActiveComponent('Sales Orders');
		setModuleName('Sales Orders');
		// console.log(currentPath)
		// Get a specific query parameter
	}, []);

	const handleData = (data) => {
		setPaginationUrl(data);
	};

	useEffect(() => {
		setPaginationUrl(null);
		setShowQuickStats(false);
		dispatch(
			storeHeaderActions({
				showQuickStats: false,
				showExport: headerActions?.showExport
			})
		);
	}, [activeComponent, activeFilters, searchField, sortByFilter]);

	const invalidateEstQuery = () => {
		queryClient.invalidateQueries({ queryKey: ['estimations', activeComponent, activeFilters, searchField, paginationUrl, sortByFilter] });
		queryClient.invalidateQueries({ queryKey: ['estimation-counter'] });
		selectedEstimate && setSelectedEstimate(null);
	};

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['estimation-counter'] });
	}, [activeComponent]);

	const handleClose = () => setShow(null);

	const restoreEstimation = (data) => {
		showConfirmationDialog('Restore Estimation', 'Are you sure you want to restore this estimation?', () => {
			toast.promise(
				api.restoreEstimation(data.id).then((res) => {
					if (res.status) {
						handleCloseConfirmationDialog();
						invalidateEstQuery();
						setSelectedEstimate(null);
					}
				}),
				{
					loading: 'Restoring Estimation...',
					success: <b>Estimation Restored!</b>,
					error: <b>Could not restore estimation.</b>
				}
			);
		});
	};

	const deleteDraft = (data) => {
		setShow(data);
	};
	const deleteEstimate = (data) => {
		setShow(data);
	};
	const handleDeleteDraft = (id) => {
		toast.promise(
			api.deleteDraft(id).then((res) => {
				if (res.status) {
					setShow('');
					invalidateEstQuery();
					setSelectedEstimate(null);
				}

				return res?.status ? res : abc;
			}),
			{
				loading: 'Deleting Draft...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete draft</b>
			}
		);
	};

	const handleCancelEstimate = (id) => {
		toast.promise(
			api.cancelEstimation(id).then((res) => {
				if (res.status) {
					setShow('');
					invalidateEstQuery();
					setSelectedEstimate(null);
				}

				return res?.status ? res : abc;
			}),
			{
				loading: 'Canceling Estimate...',
				success: (data) => `${data.message}`,
				error: <b>Failed to cancel estimate</b>
			}
		);
	};

	const [showAcceptPopup, setShowAcceptPopup] = useState(null);

	const acceptPopup = (item) => {
		setShowAcceptPopup(item);
	};



	const changeStatus = (data) => {
		const id = data.id;
		const status = data.status;
		showConfirmationDialog('Update Status', 'Are You sure you want to update the status of this order?', () => {
			toast.promise(
				api.changeestimateStatus({ estimation_id: id, status: status }).then((res) => {
					if (res.status) {
						handleCloseConfirmationDialog();
						invalidateEstQuery();
					}
				}),
				{
					loading: 'Updating Order Status...',
					success: <b>Order Updated!</b>,
					error: <b>Could not Update order status.</b>
				}
			);
		});
	};
	const addToPurchaseList = (id) => {
		setAddToPlId(id);
		console.log(id)
		setAddToPl(
			getItemList
				.find((item) => item?.id == id)
				?.estimation_details?.map((prod) => {
					return {
						id: prod?.product?.id,
						name: prod?.product?.product_name,
						qty: prod?.qty
					};
				}) || null
		);
	};

	const handleAddToPurchaseList = (showId, items) => {
		toast.promise(
			api.createbackorder(addToPlId, items).then((res) => {
				console.log('res', res);
				if (res.status) {
					invalidateEstQuery();
					setAddToPl(null);
					setAddToPlId(null);
				}

				return res?.status == true ? res : null;
			}),
			{
				loading: 'Adding to purchase list...',
				success: (data) => `${data.message}`,
				error: <b>Could not add to purchase list</b>
			}
		);
	};

	const handlePlDialogClose = () => {
		setAddToPl(null);
		setAddToPlId(null);
	};

	const addPayment = (item) => {
		setOpenRP(true);
		setCurrentItem(item);
	};


	const closeRpPopup = (data) => {
		if (data == 'reload') {
			invalidateEstQuery();
			setOpenRP(false);
		} else {
			setOpenRP(false);
		}
	};

	const viewDetails = (id) => {
		history(`${location.pathname}/${id}`);
	};

	const updateEstimate = (id) => {
		history(`${location.pathname}/edit/${id}`);
	};

	const updateDraft = (id) => {
		history(`${location.pathname}/draft/${id}`);
	};

	const [exportCompIsLoading, setExportCompIsLoading] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);

	const exportListSubmitHandler = (selectedValues) => {
		setExportCompIsLoading(true);
		api.downloadListingSalesOrder('', selectedValues, '', selectedCurrency, currentPath).then((res) => {
			const newFileName = `Projectmix - Sales Orders`;
			if (res != 404) {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `${newFileName}.xlsx`);
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.success('Downloaded Completed!');
				setShowExportModal(false);
				dispatch(
					storeHeaderActions({
						showQuickStats: headerActions?.showQuickStats,
						showExport: false
					})
				);
			} else {
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.error(res == 404 ? 'No Records Found!' : 'Download Failed!');
			}
		});
	};

    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);

        dispatch(storeSearchSalesOrder(text));
		setSearchField(text)
    };

	useEffect(() => {
		if (headerActions?.showQuickStats) {
			setShowQuickStats(true);
		} else {
			setShowQuickStats(false);
		}

		if (headerActions?.showExport) {
			setShowExportModal(true);
		} else {
			setShowExportModal(false);
		}
	}, [headerActions]);

	return (
		<>
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="section-head-new pl-0 mar10201316">
						<ul className="topListCom">
							<PermissionAllowed permissions={['Create Estimation (Button)']}>


								<button
									className="btn btn-primary btn-outlined-main-primary btn-create-report"
									onClick={() => {
										history(`${location.pathname}/create`);
									}}
								>
									<FaPlus /> New
								</button>

							</PermissionAllowed>
						</ul>
						<div className="actions d-none d-md-flex">
							<button
								className="btn btn-primary btn-outlined-main white-var"
								style={{ minWidth: 'unset', paddingBlock: '4.1px' }}
								onClick={() => {
									setShowQuickStats(!showQuickStats);
								}}
							>
								<span style={{ fontSize: '1.1em' }}>
									<FaChartBar />
								</span>{' '}
								<span
									className="d-flex"
									style={{ transition: '0.3s ease-in-out', ...(showQuickStats ? { transform: 'rotate(180deg)' } : {}) }}
								>
									<MdKeyboardArrowDown />
								</span>
							</button>
							<PermissionAllowed permissions={['Export Estimation (Button)']}>
								{isDisabled ? (
									<button className="btn btn-primary btn-outlined-main white-var btn-create-report" disabled>
										{' '}
										<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
									</button>
								) : (
									<button
										className="btn btn-primary btn-outlined-main white-var btn-create-report"
										onClick={() => {
											setShowExportModal(true);
										}}
									>
										<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
									</button>
								)}
							</PermissionAllowed>
						</div>
					</div>
					<div className="card shadow-sm  bg-white mb-4 table-card">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} multi={undefined} source={'sales_order'} />
							</div>
							<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
								{isFetching && (
									<p className="m-0" style={{ display: 'flex', gap: '3px', alignItems: 'center', fontSize: '10px' }}>
										<ImSpinner2 className="fa-spin" />
									</p>
								)}

								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										value={searchField}
										onChange={handleSearch}
										// onChange={(e) => {
										// 	setSearchField(e.target.value);
										// }}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="estimation-table-area">
							<div className="table-side">
								<div className="table-responsive-xl tableData estimationList">
									<Scroller extraHeight={60}>
										{showQuickStats && (
											<div className="order-quick-stats">
												<div className="row w-100 m-0 p-0">
													<div className="col-12 col-md-6 m-0 p-0 mb-2 mb-md-none">
														<h5>
															Total Sales Orders {' '}
															<b>
																<CurrencyValue currencyObject={ quickStatsData?.total } />
															</b>
														</h5>
														<p className="hide-on-mobile">in current selection</p>
													</div>
												</div>
												<QuickStatsBar
													data={[
														{ name: 'Drafts', color: '#acacac', value: quickStatsData?.draft, count: quickStatsData?.total },
														{ name: 'Open', color: '#ffc007', value: quickStatsData?.open, count: quickStatsData?.total },
														{ name: 'Paid', color: '#3ac780', value: quickStatsData?.paid, count: quickStatsData?.total },
														{ name: 'Canceled', color: '#343a3e', value: quickStatsData?.canceled, count: quickStatsData?.total },
												
													]}
												/>
											</div>
										)}
										<table className="table  min-width-unset tbl-space pm-table d-none d-md-table">
									
											<thead>
												<tr className="sticky-header">
													<th scope="col" width="">
														<SortableHeader
															name="estimation_number"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('estimation_number');
															}}
														>
															Reference
														</SortableHeader>
													</th>

													<th scope="col" width="">
														<SortableHeader
															name="customer"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('customer');
															}}
														>
															Customer
														</SortableHeader>
													</th>
													<th scope="col" width="">
														<SortableHeader
															name="manager"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('manager');
															}}
														>
															Manager
														</SortableHeader>
													</th>
													<th scope="col">
														<SortableHeader
															name="date"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('date');
															}}
														>
															Date
														</SortableHeader>
													</th>
													<th scope="col" width={''}>
														<SortableHeader
															name="transit_company"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('transit_company');
															}}
														>
															Transit
														</SortableHeader>
													</th>

													<th scope="col">
														Qty
													</th>

													<th scope="col">
														<SortableHeader
															name="total"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('total');
															}}
														>
															Total
														</SortableHeader>
													</th>

													<th scope="col">
														<SortableHeader
															name="status"
															currentSortBy={sortByFilter}
															onClick={() => {
																updateSortByStatus('status');
															}}
															style={{ maxWidth: '100px' }}
														>
															Status
														</SortableHeader>
													</th>
												</tr>
											</thead>
											<tbody>
												{
													getItemList?.length > 0 && (
														<>
															{
																getItemList?.map((item, index) => { 
																	return (
																		<>
																			<tr>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>

																					{
																						item?.master_status?.name == 'Draft' ? (
																							<>
																								<span style={{ fontStyle: 'italic', color: '#929598' }}>{item?.sales_order_number}</span>
																							</>
																						) : (
																							<>
																								{item?.sales_order_number}
																							</>
																						)
																					}
																				</td>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					{item?.customer?.name}
																				</td>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					{item?.sales_person?.first_name} {item?.sales_person?.last_name}
																				</td>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					{moment(item?.start_at).format(CommonDateOnlyFormat)}
																				</td>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					{item?.transit_company?.name}
																				</td>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					{item?.item_qty}
																				</td>
																				<td
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					<CurrencyValue currencyObject={item?.converted_total_price} />
																				</td>
																				<td
																					style={{ width: '110px' }}
																					className={hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer align-middle font14' : 'align-middle font14'}
																					onClick={(e) => {
																						if (hasEveryPermission(['View Sales Orders Details'])) {
																							history(`/sales/sales-orders/${item?.id}`);
																						}
																						// handleToggle(item);
																					}}
																				>
																					<StatusBadge itemStatus={ item?.master_status?.name } />
																				</td>
																			</tr>
																		</>
																	);
																})
															}
														</>
													)
												}
												{
													getItemList?.length < 1 && (
														<tr className="no-hover">
															<td colSpan={7}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>
																	<Link to={'/sales/sales-orders/create'} className="btn btn-primary btn-outlined-main">
																		Create new {currentPath} ?
																	</Link>
																</div>
															</td>
														</tr>
													)
												}
											</tbody>
										</table>
									</Scroller>
								</div>
								<Pagination pagination={paginationData} data={handleData} onLoading={(e) => { }} reactQuery />
							</div>

						</div>
					</div>
				</div>
			</div>

			<AddToPurchaseListConfirmation show={addToPl} handleClose={handlePlDialogClose}
				handleConfirm={(showId, items) => handleAddToPurchaseList(showId, items)} />
			{/* {showAcceptPopup && (
				<AcceptOrderModal show={showAcceptPopup} handleClose={() => setShowAcceptPopup(null)} handleConfirm={handleAcceptConfirm} />
			)} */}
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
			{/* {currentitem && openRP && (
				<PaymentFormModal
					showPaymentModal={openRP}
					handleClose={closeRpPopup}
					onFormSubmit={handlePaymentFormSubmit}
					estimate={currentitem}
				/>
			)} */}

			{show && (
				<DeleteConfirmation
					show={show}
					handleClose={handleClose}
					customDeleteButtonText={show?.master_status?.name == 'Estimated' ? 'Confirm' : 'Delete'}
					handleDelete={() => {
						// if (show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending Customer') {
						if (show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending') {
							handleCancelEstimate(show?.id);
						} else {
							handleDeleteDraft(show?.id);
						}
					}}
					// title={(show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending Customer') ? 'Confirm Cancel' : 'Confirm Delete'}
					title={(show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending') ? 'Confirm Cancel' : 'Confirm Delete'}
					message={
						// (show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending Customer')
						(show.master_status?.name == 'Estimated' || show.master_status?.name == 'Pending Manager' || show.master_status?.name == 'Pending')
							? `Are you sure you want to cancel estimate ${show.estimation_number}`
							: `Are you sure you want to delete ${show.draft_number || show.estimation_number}`
					}
				/>
			)}

			{showExportModal && (
				<ExportListItemsModal
					openpopup={true}
					disablepopup={() => {
						setShowExportModal(false);
						dispatch(
							storeHeaderActions({
								showQuickStats: headerActions?.showQuickStats,
								showExport: false
							})
						);
					}}
					filters={filters}
					moduleName={'Orders'}
					onSubmit={exportListSubmitHandler}
					historyApi={api.getExportsHistory('orders')}
					isLoading={exportCompIsLoading}
					exportHistoryPerms={['Show Estimation Export History (Button)']}
				/>
			)}


		</>
	);
};

export default index;

import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, convertToRaw, ContentState, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { Avatar } from '@mui/material';

export default function MentionInput({ onContentChange, clear, inputFocus, setInputFocus, triggerSave, enableMention = true }) {
	const ref = useRef(null);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const [open, setOpen] = useState(false);
	const [suggestions, setSuggestions] = useState([]);

	const handleKeyCommand = (command, editorState) => {
        if (command === 'note-save') {
            // Custom command action
            console.log('Custom save command executed');
            return 'handled';
        }
        return 'not-handled';
	};
	
	const { hasCommandModifier } = KeyBindingUtil;

	const myKeyBindingFn = (e) => {
        if (e.keyCode === 13 /* `Enter` key */ && hasCommandModifier(e)) {
            return triggerSave && triggerSave();
        }
        return getDefaultKeyBinding(e);
    };

	useEffect(() => {
		const mentions = [];
		const entityMap = convertToRaw(editorState.getCurrentContent())?.entityMap;
		for (let key in entityMap) {
			const ent = entityMap?.[key];
			if (ent.type === 'mention') {
				mentions.push(ent.data.mention.id);
			}
		}
		onContentChange && onContentChange(convertToRaw(editorState.getCurrentContent()), editorState.getCurrentContent().getPlainText(), mentions);
	}, [editorState]);

	useEffect(() => {
		if (clear) {
			setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
		}
	}, [clear]);

	const { MentionSuggestions, plugins } = useMemo(() => {
		api.getMentionUser().then((res) => {
			setSuggestions(res?.data?.map((item) => ({ id: item?.id, name: `${item?.first_name} ${item?.last_name}` })));
		});
		const mentionPlugin = createMentionPlugin({
			mentionComponent: ({ className, mention, children }) => {
				return <span className={`${className} mentioned-user`}>{children}</span>;
			}
		});
		// eslint-disable-next-line no-shadow
		const { MentionSuggestions } = mentionPlugin;
		// eslint-disable-next-line no-shadow
		const plugins = [mentionPlugin];
		return { plugins, MentionSuggestions };
	}, []);

	const onOpenChange = useCallback((_open) => {
		setOpen(_open);
	}, []);
	const onSearchChange = useCallback(({ value }) => {
		api.getMentionUser(value).then((res) => {
			setSuggestions(res?.data?.map((item) => ({ id: item?.id, name: `${item?.first_name} ${item?.last_name}`, avatar: item?.avatar })));
		});
	}, []);

	return (
		<div
			// className={editorStyles.editor}
			onClick={() => {
				ref.current?.focus();
			}}
			className="form-control"
			style={{
				height: 'auto',
				minHeight: inputFocus ? '100px' : '0',
				transition: 'all 0.3s ease-in-out',
				zIndex: '0',
				// zIndex: '15',
				position: 'relative'
			}}
		>
			<Editor
				editorKey={'editor'}
				editorState={editorState}
				onChange={setEditorState}
				plugins={plugins}
				ref={ref}
				onFocus={() => {
					setInputFocus(true);
				}}
				onBlur={() => {
					setInputFocus(false);
				}}
				keyBindingFn={myKeyBindingFn}
				handleKeyCommand={handleKeyCommand}
			/>
			{
				enableMention && (
					<>
						<MentionSuggestions
							open={open}
							entryComponent={({
								mention,
								theme,
								searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
								isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
								...parentProps
							}) => {
								return (
									<div {...parentProps} style={{ background: isFocused ? 'lightblue' : 'white', padding: '5px 10px', zIndex: '20' }}>
										<div className={theme?.mentionSuggestionsEntryContainer} style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
											<div className={theme?.mentionSuggestionsEntryContainerLeft}>
												<Avatar sx={{ width: 20, height: 20 }} style={{ fontSize: 10 }} src={mention?.avatar}>
													{mention.name?.split(' ')?.[0]?.slice(0, 1)}
													{mention.name?.split(' ')?.[1]?.slice(0, 1)}
												</Avatar>
											</div>
											<div className={theme?.mentionSuggestionsEntryContainerRight}>
												<div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>
												{/* <div className={theme?.mentionSuggestionsEntryTitle} style={{fontSize: '10px'}}>Sales Person</div> */}
											</div>
										</div>
									</div>
								);
							}}
							popoverContainer={({ children, theme }) => {
								return (
									<div className={theme?.mentionSuggestionsPopup} style={{ position: 'absolute', borderRadius: '15px', overflow: 'hidden' }}>
										{children}
									</div>
								);
							}}
							onOpenChange={onOpenChange}
							suggestions={suggestions}
							onSearchChange={onSearchChange}
							onAddMention={() => {
								// get the mention object selected
							}}
						/>
					</>
				)
			}
		</div>
	);
}

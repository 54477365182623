import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { FaSpinner, FaTimes, FaPlus, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { WarningIcon, CloseIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import Select from 'react-select';
import CurrencyValue from 'ui-component/CurrencyValue';
import StatusBadge from 'ui-component/StatusBadge';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePermissions from 'hooks/usePermissions';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaPaperclip } from 'react-icons/fa';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';

const CreditPaymentComponent = ({ details, onHandleReloadCreditNote }) => {

	const [allPermissions, hasEveryPermission] = usePermissions();
	const history = useNavigate();
	const [showDeletePayment, setShowDeletePayment] = useState(false);
	const [selectedPaymentId, setSelectedPaymentId] = useState(null);
    
    const handleOpenNewTab = (pageModule, id) => {
        let url = '';
        if (pageModule == 'products') {
            url = `/products/${id}`;
        } else if (pageModule == 'contacts') {
            url = `/contacts/${id}`;
        } else if (pageModule == 'purchase_orders') {
            url = `/purchasing/view/${id}`;
        } else if (pageModule == 'sales_orders') {
            url = `/sales/sales-orders/${id}`;
        } else if (pageModule == 'invoices') {
            url = `/sales/invoices/${id}`;
        } else if (pageModule == 'quotations') {
            url = `/sales/quotations/${id}`;
        }
        if (url) {
            history(url);
            // window.open(url);
        }
    };

	const deletePayment = (id) => { 
		setShowDeletePayment(true);
		setSelectedPaymentId(id);
	}
	const handleClose = (data) => {
		setShowDeletePayment(false)
	};


	const handleDeletePayment = (data) => {
		toast.promise(
			api.deleteEstimatePayment(selectedPaymentId).then((res) => {
				if (res.status) {
					handleClose()
                    setSelectedPaymentId(null);
                    onHandleReloadCreditNote()
				}

				return res?.status == true ? res : null;
			}),

			{
				loading: 'Deleting Payment...',
				success: (data) => `${data.message}`,
				error: <b>Payment Delete Failed</b>
			}
		);
		// invalidateEstQuery();
    };
    
    return (
        <>
            
            <div style={{ padding: '0px 20px 20px 16px' }}>
			
                <div className="row mb10">
                    <div className="col-12">
                        <h2 className="d-flex font600" style={{ fontSize: '18px' }}>Payments</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 font600">Date</div>
                    <div className="col-md-3 font600">Invoice</div>
                    <div className="col-md-3 font600">Amount</div>
                    <div className="col-md-3 font600">Attachment</div>
                </div>

                <hr className="mt8 mb8" />

                {
                    details?.credit_payments?.map((payment, index) => (
                        <>
                            <div className="row hover-row">
                                <div className="col-md-3">{moment(payment?.payment_date).format(CommonDateOnlyFormat)}</div>
                                <div className="col-md-3">
                                    {
                                        payment?.invoice?.id ? (
                                            <>
                                                
                                                <div
                                                    className={`${hasEveryPermission(['View Estimation Details (Page)']) ? 'text-primary cursor-pointer' : '' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Estimation Details (Page)'])) {
                                                            handleOpenNewTab('invoices', payment?.invoice?.id)
                                                        }
                                                    }}
                                                >
                                                    {payment?.invoice?.invoice_number}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`${hasEveryPermission(['View Sales Orders Details']) ? 'text-primary cursor-pointer' : '' }`}
                                                    onClick={() => {
                                                        if (hasEveryPermission(['View Sales Orders Details'])) {
                                                            handleOpenNewTab('sales_orders', payment?.sales_order?.id)
                                                        }
                                                    }}
                                                >
                                                    {payment?.sales_order?.sales_order_number}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="col-md-3 text-success">
                                    {getCurrencySymbol(payment?.currency)} {numberFormatter.format(payment?.amount_paid)}
                                </div>
                                <div className="col-md-3">
                                    {payment?.user_created?.first_name}
                                </div>
                                <div className="hover-button-container-lg mr-20 mt0">
                                    <div>
                                        <div className="hover-button">
                                            
                                            {
                                                payment?.attachment && (
                                                    <>
                                                        <div
                                                            className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
                                                            style={{ marginLeft: '8px' }}
                                                            onClick={() => {
                                                                window.open(payment.attachment, '_blank');
                                                            }}
                                                        >
                                                            <div style={{ margin: 'auto 5px auto 3px' }}>
                                                                <FaPaperclip  />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <PermissionAllowed permissions={['Delete Payment Transaction']}>
                                                <div
                                                    className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
                                                    style={{ marginLeft: '8px' }}
                                                    onClick={() => {
                                                        deletePayment(payment?.id);
                                                    }}
                                                >
                                                    <div>
                                                        <DeleteIcon2 width={'20px'} height={'20px'} />
                                                    </div>
                                                </div>
                                            </PermissionAllowed>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt8 mb8" />
                        </>
                    ))
                }
				{
					showDeletePayment && (
						<>
							<DeleteConfirmation
								key="paymentDelete"
								show={showDeletePayment}
								handleClose={handleClose}
								handleDelete={handleDeletePayment}
								title="Confirm"
								message={`Are you sure you want to delete this payment?`}
								customDeleteButtonText={'Yes, Delete this payment'}
							/>
						</>
					)
				}
                {/* <div className="row">
                    <div className="col-md-3 d-flex align-items-center">
                        <div
                            className={`${hasEveryPermission(['View Sales Orders Details']) ? 'text-primary cursor-pointer' : '' }`}
                            onClick={() => {
                                if (hasEveryPermission(['View Sales Orders Details'])) {
                                    handleOpenNewTab('sales_orders', details?.sales_order?.id)
                                }
                            }}
                        >
                            {details?.sales_order?.sales_order_number}
                        </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-center">{moment(details?.sales_order?.accepted_on ?? details?.sales_order?.start_at).format(CommonDateOnlyFormat)}</div>
                    <div className="col-md-3 "><div style={{ width: '90px' }}><StatusBadge itemStatus={details?.sales_order?.status} /></div></div>
                    <div className="col-md-3 d-flex align-items-center"><CurrencyValue currencyObject={details?.sales_order?.converted_currency_total} /></div>
                </div>
                <hr className="mt8 mb8" /> */}
            </div>
            
        </>
    );
};

export default CreditPaymentComponent;

import { borderRadius } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

const VendorModal = ({ onClose, onSubmit, preLoadedData, transactionType }) => {
	const compRef = useRef(null);
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);

	const [data, setData] = useState({
		// logistic: '',
		vendorId: '',
        purchaseOrderId: '',
        referenceNumber: '',
		// transitCompany: '',
		// salesManager: '',
		creditDate: moment().format('YYYY-MM-DD'),
		currency: 'eur'
	});

	const [selectLoading, setSelectLoading] = useState({
		vendor: false,
        purchaseOrder: false,


		logistic: false,
		transitCompany: false,
		salesManager: false,
		currency: false
	});

	const anyLoading = Object.values(selectLoading).some((item) => item);

	// ## Dropdown Data ## //

	const [vendors, setVendors] = useState([]);
	const [purchaseOrders, setPurchaseOrders] = useState([]);
	const currencies = [
		{ label: 'EUR', value: 'eur' },
		{ label: 'USD', value: 'usd' },
		{ label: 'AED', value: 'aed' }
	];

    useEffect(() => {
        
		setData((oldData) => ({
			...oldData,
			purchaseOrderId: '',
			// salesManager: selectedOption?.sales_manager_id
		}));
		api.getSelectOptionsListWithId('open_purchase_order_by_vendor', data?.vendorId).then((res) => {
			const value = res.data;
			const valueArray = value.map((item) => ({ label: item.purchase_order_id, value: item.id, ...item }));
            setPurchaseOrders(valueArray);
			setSelectLoading((oldData) => ({
				...oldData,
				purchaseOrder: false
			}));
		});


    }, [data?.vendorId])

	// ## Initiate Data ## //

	useEffect(() => {
		if (preLoadedData) {
			if (preLoadedData?.selections) {
				setVendors(preLoadedData?.selections?.vendors);
                setPurchaseOrders(preLoadedData?.selections?.purchaseOrders);
			} else {
				getSelectOptions();
			}
            console.log('purchaseOrders', purchaseOrders)
			setData({
				vendorId: preLoadedData?.vendor?.id,
                purchaseOrderId: preLoadedData?.purchaseOrderId,
                referenceNumber: preLoadedData?.referenceNumber,
				creditDate: preLoadedData?.creditDate,
				currency: preLoadedData?.currency,
			});
		} else {
			getSelectOptions();
		}
	}, []);

    useEffect(() => {   
		// if (preLoadedData) {
            setData((oldData) => ({
                ...oldData,
                purchaseOrderId: preLoadedData?.purchaseOrder?.id,
            }))
        // }
    }, [purchaseOrders]);

	const getSelectOptions = () => {
		// ## Get Customers ## //
		setSelectLoading((oldData) => ({
			...oldData,
			vendor: true
		}));
        
		api.getSelectOptionsList('supplier').then((res) => {
			const custData = res.data;
			const customerArray = custData.map((item) => ({ label: item.name, value: item.id, ...item }));
			setVendors(customerArray);
			setSelectLoading((oldData) => ({
				...oldData,
				vendor: false
			}));
		});

		// // ## Get Transit Companies ## //
		// setSelectLoading((oldData) => ({
		// 	...oldData,
		// 	transitCompany: true
		// }));
		// api.getSelectOptionsList('transit_company').then((res) => {
		// 	const defaultSelect = { value: 0, label: 'No Transit Company' };
		// 	const tempSelect = res.data.map((item) => ({ label: item.name, value: item.id, ...item }));
		// 	const mergedSelect = [defaultSelect, ...tempSelect];
		// 	setTransitCompanies(mergedSelect);
		// 	setSelectLoading((oldData) => ({
		// 		...oldData,
		// 		transitCompany: false
		// 	}));
		// });

		// // ## Get Sales Managers ## //
		// setSelectLoading((oldData) => ({
		// 	...oldData,
		// 	salesManager: true
		// }));
		// api.getSelectOptionsList('salesperson').then((res) => {
		// 	const tempsalesPerson = res.data.map((item) => ({
		// 		label: `${item.first_name} ${item.last_name || ''}`,
		// 		value: item.id,
		// 		...item
		// 	}));
		// 	setSalesManagers(tempsalesPerson);
		// 	setSelectLoading((oldData) => ({
		// 		...oldData,
		// 		salesManager: false
		// 	}));
		// });
	};

	// ## To close the popup ## //
	const handleClose = () => {
		onClose && onClose();
	};

	// ## To close the popup when clicked outside ## //
	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	// ## Handle Submit ## //
	const handleSubmit = () => {
		const preparedData = {
			// logistic: logistics?.find((item) => item?.value === data?.logistic),
			// customer: vendors?.find((item) => item?.value === data?.customer),
			// transitCompany: transitCompanies?.find((item) => item?.value === data?.transitCompany),
			// salesManager: salesManagers?.find((item) => item?.value === data?.salesManager),
            
			vendor: vendors?.find((item) => item?.value === data?.vendorId),
            purchaseOrder: purchaseOrders?.find((item) => item?.value === data?.purchaseOrderId),
            referenceNumber: data?.referenceNumber,
			creditDate: data?.creditDate,
			currency: data?.currency,
			selections: {
				// logistics,
				vendors,
                purchaseOrders,
				// transitCompanies,
				// salesManagers
			}
		};

		onSubmit && onSubmit(preparedData);
	};

	// ## Handle Customer Select ## //
	const handleVendorSelect = (selectedOption) => {
		setData((oldData) => ({
			...oldData,
			vendorId: selectedOption?.value,
			// salesManager: selectedOption?.sales_manager_id
		}));
	};

	// // ## Handle Transit Company Select ## //
	// const handleLogisticSelect = (selectedOption) => {
	// 	setData((oldData) => ({
	// 		...oldData,
	// 		logistic: selectedOption?.value
	// 	}));
	// };

	// // ## Handle Transit Company Select ## //
	// const handleTransitCompanySelect = (selectedOption) => {
	// 	setData((oldData) => ({
	// 		...oldData,
	// 		transitCompany: selectedOption?.value
	// 	}));
	// };

	// // ## Handle Sales Manager Select ## //
	// const handleSalesManagerSelect = (selectedOption) => {
	// 	setData((oldData) => ({
	// 		...oldData,
	// 		salesManager: selectedOption?.value
	// 	}));
	// };

	// ## Handle Currency Select ## //
	const handleCurrencySelect = (selectedOption) => {
		setData((oldData) => ({
			...oldData,
			currency: selectedOption?.value
		}));
	};

    const handlePurchaseOrderSelect = (selectedOption) => {
		setData((oldData) => ({
			...oldData,
			purchaseOrderId: selectedOption?.value,
            currency: selectedOption?.currency,
		}));
    }

	// ## Handle Date Change ## //
	const handleDateChange = (e) => {
		setData((oldData) => ({
			...oldData,
			creditDate: e?.target?.value
		}));
	};
    const handleReferenceNumberChange = (e) => {
        setData((oldData) => ({
            ...oldData,
            referenceNumber: e?.target?.value
        }))
    }
	return (
		<div className='popup-container'>
			<div className="popup contact-popup" ref={compRef}>
				<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0" style={{ padding: '15px 10px' }}>
					<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0" style={{ margin: '-4px 10px 0 10px ' }}>
						{data?.customer ? 'Edit' : 'Add'} Vendor
					</h5>
					<button className="btn modal-close-icon" onClick={handleClose}>

						<svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
							<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
						</svg>
					</button>
				</div>

				<div className="row m-0 p-0" style={{ marginTop: '10px' }}>
					{/* <div className="col-12 col-md-6">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Select Logistics</label>
							<ReactSelect
								className="rs-container"
								classNamePrefix="rs"
								options={logistics}
								value={logistics?.find((item) => item?.value === data?.logistic)}
								onChange={handleLogisticSelect}
								menuPlacement="auto"
								menuPosition="fixed"
								style={{ borderRadius: '18px' }}
							/>
						</div>
					</div> */}
					<div className="col-12 col-md-6">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Select Vendor</label>
							<ReactSelect
								className="rs-container"
								classNamePrefix="rs"
								options={vendors}
								value={vendors?.find((item) => item?.value === data?.vendorId)}
								onChange={handleVendorSelect}
								menuPlacement="auto"
								menuPosition="fixed"
								placeholder="Select or type..."
							/>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Select Purchase Order</label>
							<ReactSelect
								className="rs-container"
								classNamePrefix="rs"
								options={purchaseOrders}
								value={purchaseOrders?.find((item) => item?.value === data?.purchaseOrderId) || null}
								onChange={handlePurchaseOrderSelect}
								menuPlacement="auto"
								menuPosition="fixed"
								placeholder="Select or type..."
							/>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Reference Number</label>
							<input type="text" className="form-control" onChange={handleReferenceNumberChange} value={data?.referenceNumber} />
						</div>
					</div>
					{/* <div className="col-12 col-md-6">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Select Transit Company</label>
							<ReactSelect
								className="rs-container"
								classNamePrefix="rs"
								options={transitCompanies}
								value={transitCompanies?.find((item) => item?.value === data?.transitCompany)}
								onChange={handleTransitCompanySelect}
								placeholder="No Transit Company"
								menuPlacement="auto"
								menuPosition="fixed"
							/>
						</div>
					</div> */}
					{/* <div className="col-12 col-md-6">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Sales Manager</label>
							<ReactSelect
								className="rs-container"
								classNamePrefix="rs"
								options={salesManagers}
								value={salesManagers?.find((item) => item?.value === data?.salesManager)}
								onChange={handleSalesManagerSelect}
								menuPlacement="auto"
								menuPosition="fixed"
								placeholder="Select or type..."
							/>
						</div>
					</div> */}
					<div className="col-6 col-md-3">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >
								Credit Date
							</label>
							<input type="date" className="form-control" onChange={handleDateChange} value={data?.creditDate} />
						</div>
					</div>
					<div className="col-6 col-md-3">
						<div className="form-group" style={{ paddingBottom: '10px' }}>
							<label className="font500" >Currency</label>
							<ReactSelect
								className="rs-container"
								classNamePrefix="rs"
								options={currencies}
								onChange={handleCurrencySelect}
								value={currencies?.find((item) => item?.value === data?.currency)}
								menuPlacement="auto"
								menuPosition="fixed"
								style={{ width: '100px' }}
								placeholder="Select or type..."
							/>
						</div>
					</div>
				</div>

				<div className="btnarea mt-3">
					<button className="btn btn-main" onClick={handleSubmit} disabled={anyLoading}>
						<FaSave /> Save
					</button>
				</div>
			</div>
		</div>
	);
};

export default VendorModal;

import React, { useEffect, useState, useRef } from 'react';
import '../estimation/estimateInvoice.scss';
import { Link, useNavigate } from 'react-router-dom';
import mgLogo from 'assets/images/mglogo.svg';
import utLogo from 'assets/images/used-trading-logo.png';
import glpLogo from 'assets/images/glplogo.png';
import cmLogo from 'assets/images/cmlogo.svg';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import config from '../../config';
import NoImage from 'assets/images/no-image.png';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import ProductCommentModal from '../estimation/modals/ProductCommentModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { EditIcon } from 'ui-component/custom-icons/icons';

function SalesOrderDetailInvoice({ salesOrder }) {

	const [settingsDetails, setSettingsDetails] = useState();
	const [productComment, setProductComment] = useState('');
	const [productName, setProductName] = useState('');
	const [productImage, setProductImage] = useState('');
    const [commentModal, setCommentModal] = useState(false);
    const [isInternalLoading, setIsInternalLoading] = useState(true);
	const history = useNavigate();

	const totalQty = salesOrder?.sales_order_items?.reduce((total, detail) => total + (detail?.qty || 0), 0);

	const handleCommentModal = (product, image, comment) => {
		setProductComment(comment);
		setProductName(product);
		setProductImage(image);
		setCommentModal(true);
	}
	const handleCloseComment = () => {
		setProductComment('');
		setProductName('');
		setProductImage('');
		setCommentModal(false);
	}

	useEffect(() => {

		api.getSettings().then((res) => {
			setSettingsDetails(res?.data)
        });
        setIsInternalLoading(false);
	}, []);
	return (
		<>
			{/* <div className="est-inv-container" style={{ padding: '15px', maxWidth: '700px' }}> */}
			<div className="est-inv-container" style={{ padding: '15px' }}>
				<div className="row m-0">
					{salesOrder ? (
						<>

                            <div className="col-8 p-0">
                                <div className="est-detail-logo" style={{ height: '36px' }}>
                                    {
                                        settingsDetails?.logo_url ? (
                                            <img src={settingsDetails?.logo_url} height={'36px'} />
                                        ) : (
                                            <h3>{settingsDetails?.client_full_name}</h3>
                                        )
                                    }
                                    {/* {
                                        settingsDetails?.client_code == 'usedtrading' ? (
                                            <>
                                                <img src={utLogo} height={'36px'} />
                                            </>
                                        ) : (
                                            <>
                                                <img src={mgLogo} height={'36px'} />
                                            </>
                                        )
                                    } */}
                                </div>

                                <div className="customer-details" style={{ marginTop: '20px' }}>
                                    {
                                        salesOrder?.transit_company?.name ? (
                                            <>
                                                <p className="m-0">

                                                    <b>
                                                        <PrivacyModeContent>{salesOrder?.transit_company?.name}</PrivacyModeContent>
                                                    </b>{' '}
                                                    <br />
                                                    <PrivacyModeContent>{salesOrder?.transit_company?.address}</PrivacyModeContent> <br />
                                                    <PrivacyModeContent>
                                                        {(salesOrder?.transit_company?.city == 'null' ? salesOrder?.transit_company?.city + ', ' : '') + salesOrder?.transit_company?.zipcode}
                                                    </PrivacyModeContent>{' '}
                                                    <br />
                                                    <PrivacyModeContent>{salesOrder?.transit_company?.country}</PrivacyModeContent>
                                                    {salesOrder?.transit_company?.shipping_address ? (
                                                        <>
                                                            <br />
                                                            <br />
                                                            <b>Ship To: </b>
                                                            <br />
                                                            <PrivacyModeContent>{salesOrder?.transit_company?.shipping_address}</PrivacyModeContent> <br />
                                                            <PrivacyModeContent>
                                                                {salesOrder?.transit_company?.shipping_city + ', ' + salesOrder?.transit_company?.shipping_zipcode}
                                                            </PrivacyModeContent>{' '}
                                                            <br />
                                                            <PrivacyModeContent>{salesOrder?.transit_company?.shipping_country}</PrivacyModeContent>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="m-0">
                                                    <b>
                                                        <PrivacyModeContent>{salesOrder?.customer?.name}</PrivacyModeContent>
                                                    </b>{' '}
                                                    <br />
                                                    <PrivacyModeContent>{salesOrder?.customer?.address}</PrivacyModeContent> <br />
                                                    <PrivacyModeContent>
                                                        {(salesOrder?.customer?.city == 'null' ? salesOrder?.customer?.city + ', ' : '') + salesOrder?.customer?.zipcode}
                                                    </PrivacyModeContent>{' '}
                                                    <br />
                                                    <PrivacyModeContent>{salesOrder?.customer?.country}</PrivacyModeContent>
                                                    {salesOrder?.customer?.shipping_address ? (
                                                        <>
                                                            <br />
                                                            <br />
                                                            <b>Ship To: </b>
                                                            <br />
                                                            <PrivacyModeContent>{salesOrder?.customer?.shipping_address}</PrivacyModeContent> <br />
                                                            <PrivacyModeContent>
                                                                {salesOrder?.customer?.shipping_city + ', ' + salesOrder?.customer?.shipping_zipcode}
                                                            </PrivacyModeContent>{' '}
                                                            <br />
                                                            <PrivacyModeContent>{salesOrder?.customer?.shipping_country}</PrivacyModeContent>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-4">

                                <PermissionAllowed permissions={['Edit Sales Order']}>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-outlined-main white-var"
                                        style={{ minWidth: 'unset', position: 'absolute', right: '-15px', top: '-20px' }}
                                        disabled={isInternalLoading || !salesOrder?.sales_order_number}
                                        onClick={() => {
                                            history(`/sales/sales-orders/edit/${salesOrder?.id}`);
                                        }}
                                    >
                                        <EditIcon height='17px' width='17px' />
                                        Edit
                                    </button>
                                </PermissionAllowed>
                            </div>
						</>
					) : (
						<>
							<div className="col-6 p-0">
								<div className="est-detail-logo" style={{ height: '36px' }}>
									<Skeleton sx={{ height: '36px', width: '100px' }} />
								</div>

								<div className="customer-details" style={{ marginTop: '20px' }}>
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
								</div>
							</div>
							<div className="col-6 p-0">
								<div className="company-details text-right">
									<p className="m-0">
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
									</p>
								</div>
							</div>
						</>
					)}
				</div>

				<div className="row mx-0" style={{ marginTop: '20px', alignItems: 'center' }}>
					<div className="col-6 col-md-8 p-0">
						{salesOrder ? (
							<h3 className="est-ref m-0">
                                {
                                    salesOrder?.master_status?.name == 'Draft' ? (
                                        <>
                                            <span style={{ fontStyle: 'italic', color: '#929598', fontSize: '20px', fontWeight: '400' }}> {salesOrder?.sales_order_number}</span>
                                        </>
                                    ) : (
                                        <>
                                            Sales Order {salesOrder?.sales_order_number}
                                        </>
                                    )
                                }
							</h3>
						) : (
							<Skeleton variant="rounded" />
						)}
					</div>
					<div className="col-6 col-md-4 p-0">
						<table className="w-100">
                            <tr>
                                <td>Date Ordered:</td>
                                <td className="text-right">{moment(salesOrder?.start_at).format(CommonDateOnlyFormat)}</td>
                            </tr>
                            <tr>
                                <td>Due Date: </td>
                                <td className="text-right">{moment(salesOrder?.expires_at).format(CommonDateOnlyFormat)}</td>
                            </tr>
						</table>
					</div>
				</div>

				<div className="est-inv-items" style={{ marginTop: '20px' }}>
					<table className="w-100">
						<thead>
							<tr>
                                <th colSpan={2}>Model</th>
								<th className="hide-on-mobile text-center">Comment</th>
								<th className="text-center">Quantity</th>
								<th className="text-right d-none d-md-block">Price</th>
								<th className="text-right">Total</th>
							</tr>
						</thead>
						<tbody>
							{salesOrder?.sales_order_items?.map((item) => (
								<tr>
                                    <td className="hide-on-mobile">
                                        {
                                            item?.product?.thumbnail_url ? (
                                                <img src={item?.product?.thumbnail_url} style={{ width: '30px' }} />
                                            ) : (
                                                <img src={NoImage} style={{ width: '20px' }} />
                                            )
                                        }
                                    </td>
									<td>
										<p className="p-0 m-0">{item?.product?.product_name}</p>
                                        {
                                            item?.comment ? (
											    <p className="hide-on-non-mobile mobile-prod-comment-ui">{item?.comment}</p>
                                            ) : (
                                                ''
                                            )
                                        }
									</td>
									{salesOrder?.master_status?.name != 'Credit Note' && (
										<td style={{ maxWidth: '80px' }} className="hide-on-mobile text-center">

											<div
												style={{ cursor: 'pointer' }}
												onClick={() => {
													handleCommentModal(item?.product?.product_name, item?.product?.thumbnail_url, item?.comment);
												}}
											>
												{
													item?.comment ? (
														<>
															<MdOutlineStickyNote2 size={18} />
														</>
													) : (
														<>
															-
														</>
													)
												}
											</div>
											{/* {item?.comment || '-'} */}
										</td>
									)}
									<td className="text-center">{item?.qty}</td>
									<td className="text-right d-none d-md-block">
										<div style={{ marginTop: '5px' }}>
											<CurrencyValue currencyObject={item?.converted_currency_unit_price} />
										</div>
									</td>
									<td className="text-right">
										<CurrencyValue currencyObject={item?.converted_currency_total_price} />
									</td>
								</tr>
							))}

                            <tr style={{ height: '10px' }}>
								<td></td>
							</tr>
							{salesOrder?.shipping_fee > 0 && (
								<tr>

									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile text-center">{totalQty}</td>
									<td className="d-md-none"></td>
									<td className="text-right">Shipping</td>
									<td className="text-right">
										<CurrencyValue currencyObject={salesOrder?.converted_currency_shipping_fee} />
									</td>
								</tr>
							)}

							{salesOrder?.total_discount > 0 && (
								<tr>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">Discount {salesOrder?.discount_type == 'percentage' ? `(${salesOrder?.discount}%)` : ''}</td>
									<td className="text-right">
										<CurrencyValue currencyObject={salesOrder?.converted_total_discount} />
									</td>
								</tr>
							)}

							{salesOrder?.vat_type == 'Excluding VAT' && (
								<tr>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">VAT ({salesOrder?.vat_percent}%)</td>
									<td className="text-right font-weight-bold">
										<CurrencyValue currencyObject={salesOrder?.converted_currency_vat_amount} />
									</td>
								</tr>
							)}
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">Subtotal</td>
								<td className="text-right font-weight-bold">
									<CurrencyValue currencyObject={salesOrder?.converted_currency_subtotal} />
								</td>
							</tr>
							{
								salesOrder?.transit_fee && (
									<tr>
										<td className="hide-on-mobile"></td>
										<td className="hide-on-mobile"></td>
										<td className="hide-on-mobile"></td>
										<td className="hide-on-mobile"></td>
										<td className="d-md-none"></td>
										<td className="text-right">Transit Fee</td>
										<td className="text-right font-weight-bold">
											<CurrencyValue currencyObject={salesOrder?.converted_currency_transit_fee} />
										</td>
									</tr>
								)
							}
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">Total</td>
								<td className="text-right font-weight-bold">
									<CurrencyValue currencyObject={salesOrder?.converted_currency_total} />
								</td>
							</tr>
							{
								salesOrder?.open_balance?.open > 0 && (
									<>
										<tr>
											<td className="hide-on-mobile"></td>
											<td className="hide-on-mobile"></td>
											<td className="hide-on-mobile"></td>
											<td className="hide-on-mobile"></td>
											<td className="d-md-none"></td>
											<td className="text-right">Balance due</td>
											<td className="text-right font-weight-bold">
												<CurrencyValue currencyObject={salesOrder?.converted_currency_open_balance} />
											</td>
										</tr>
									</>
								)
							}
						</tbody>
					</table>
				</div>
			</div>

			{productComment && (
				<ProductCommentModal
					name={productName}
					image={productImage}
					comment={productComment}
					// show={commentModal}
					handleClose={() => handleCloseComment()}
				/>
			)}
		</>
	);
}

export default SalesOrderDetailInvoice;

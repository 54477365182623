import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ModalComponent from './ModalComponent';
import ColapsibleTable from './components/ColapsibleTable';
import { FaSearch, FaRegFileExcel, FaRegComments } from 'react-icons/fa';
import { LuChevronDown } from 'react-icons/lu';
import './backorder.scss';
import { TfiReload } from 'react-icons/tfi';
import Filter from 'ui-component/filter-menu/Filter';
import { Dropdown } from 'react-bootstrap';
import Scroller from 'ui-component/Scroller';
import Pagination from 'ui-component/Pagination';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePageTitle from 'hooks/usePageTitle';
import { SettingsDownloadIcon, ReadyIcon, EstimateIcon } from 'ui-component/custom-icons/icons';
import toast from 'react-hot-toast';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { Skeleton, Checkbox } from '@mui/material';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';

import { storeSearchBackOrder, storeActiveBackOrderTab } from 'store/reducers/mainSlice';

const Backorders = () => {
	const [setPageTitle] = usePageTitle('Backorders');
	const history = useNavigate();
	const [alignment, setAlignment] = useState('v1');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);
    const dispatch = useDispatch();

	const storedSearch = useSelector((state) => state.main.searchBackOrder);
	const storedFilter = useSelector((state) => state.main.filterBackOrder);
	const activeBackOrdertab = useSelector((state) => state.main.activeBackOrderTab);

	const [activeComponent, setActiveComponent] = useState('order');
	const [filters, setFilters] = useState([]);
	const [searchField, setSearchField] = useState();
	const [activeFilters, setActiveFilters] = useState({});
	const [pagination, setpagination] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);
	// const [foundOpen, setFoundOpen] = useState(false);
	// const [selectedRows, setSelectedRows] = useState([]);
	// const [products, setProducts] = useState([]);
	const [data, setData] = useState(null);
	// const [invoiceList, setInvoiceList] = useState([]);
	// const [selectedPurchaseList, setPurcahseListId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
	// const [isFiltersloading, setIsFiltersloading] = useState(false);

	// const [showModal, setShowModal] = useState(false);
	// const [modelType, setModelType] = useState(null);
	// const [modalOrders, setModalOrders] = useState([]);
	const handleComponentChange = (component) => {
		setIsLoading(true)
		setActiveComponent(component);
		setSearchField('');
		// setProducts([]);
        dispatch(storeActiveBackOrderTab(component));
        dispatch(storeSearchBackOrder(''));
		// setActiveFilters({});
	};
	const handleData = (data) => {
		setData(data?.data);
		// setProductList(data.data);
		setpagination(data);
	};


    useEffect(() => {

        const fetchData = async () => {
            if (storedSearch) {
                setIsLoading(true);
                await handleSearch({ target: { value: storedSearch } });
            } else if (!storedSearch) {
                if (storedFilter && Object.keys(storedFilter).length > 0) {
                    setIsLoading(true);
                    await handleFilterRedux();
                } else {
                    handleFilter()
                }
            }
        };
        fetchData();
		// setActiveComponent(activeBackOrdertab ?? 'order')
		// console.log('ang nakasave', activeBackOrdertab)
		if (activeBackOrdertab) {
			setActiveComponent(activeBackOrdertab)
		} else {
			setActiveComponent('order')
		}
    }, [storedSearch, searchField, activeFilters, storedFilter, activeBackOrdertab]);

	const getBackOrderLists = async () => {
		console.log('activeBackOrdertab', activeBackOrdertab)
		const storedActiveTab = activeBackOrdertab || 'order';
		api.getbackorders(storedActiveTab).then((res) => {
			// console.log(res);
			// console.log('dito')
			setIsLoading(false)
			setData(res?.data?.data);
			// setInvoiceList(res?.data?.invoiceList);
			setpagination(res?.data);
		});
	}


    useEffect(() => {
        if (storedSearch) {
        } else if (storedFilter && Object.keys(storedFilter).length > 0) {
        } else {
            getBackOrderLists();
        }
        getFiltersList();
    }, []);

    const getFiltersList = () => {
        // setIsFiltersloading(true);
        api.getBackOrderFilter().then((res) => {
            setFilters(res.data);
            // setIsFiltersloading(false);
        });
    };


	// useEffect(() => {
	// 	// console.log('activeComponent', activeComponent)
	// 	api.getbackorders(activeComponent).then((res) => {
	// 		// console.log(res);
	// 		// console.log('dito')
	// 		setIsLoading(false)
	// 		setData(res?.data?.data);
	// 		setInvoiceList(res?.data?.invoiceList);
	// 		setpagination(res?.data);
	// 	});
	// }, [activeComponent]);

	// const organizeDataByOrders = () => {
	// 	const ordersDict = {};
	// 	data?.forEach((order) => {
	// 		const { estimation_id } = order;
	// 		if (!ordersDict[estimation_id]) {
	// 			ordersDict[estimation_id] = {
	// 				estimation: order?.estimation?.invoice_number,
	// 				estimation_id: order?.estimation_id,
	// 				customer_name: order?.estimation?.customer?.name,
	// 				date_created: order.date_created,
	// 				purcahse_List_id: order.purchase_list_id,
	// 				comments: order.notes,
	// 				orders: []
	// 			};
	// 		}
	// 		ordersDict[estimation_id].orders.push({
	// 			product_id: order.product_id,
	// 			product: order?.product?.product_name,
	// 			quantity: order.quantity,
	// 			available_quantity: order?.product?.free_stock_quantity,
	// 			expected_date: order.expected_arrival_date,
	// 			ordered_at: order.date_created
	// 		});
	// 	});
	// 	const sortedOrders = Object.values(ordersDict).sort((a, b) => b.estimation_id - a.estimation_id);
	// 	return sortedOrders;
	// };

	const downloadReport = (type) => {
		console.log('params', type, activeComponent)
		// const sortedOrders = organizeDataByOrders().sort((a, b) => {
		// 	const numA = Number(a.estimation.replace(/[^0-9]/g, ''));
		// 	const numB = Number(b.estimation.replace(/[^0-9]/g, ''));
		// 	return numB - numA;
		// });
		// console.log(sortedOrders);
		// if (type === 1) {
		// 	setShowModal(true);
		// 	setModelType('excel');
		// 	setModalOrders(sortedOrders);
		// } else if (type === 0) {
		// 	setShowModal(true);
		// 	setModelType('pdf');
		// 	setModalOrders(sortedOrders);
		// }
	};

	const handleClose = () => setShowModal(false);

	// const organizeDataByProduct = () => {
	// 	const productsDict = {};
	// 	data?.forEach((order) => {
	// 		const { product_id } = order;
	// 		if (!productsDict[product_id]) {
	// 			productsDict[product_id] = {
	// 				product: order?.product?.product_name,
	// 				product_id: order.product_id,
	// 				purcahse_List_id: order.purchase_list_id,
	// 				comments: false,
	// 				orders: []
	// 			};
	// 		}
	// 		const matchingComments = order?.estimation?.estimation_details
	// 			.filter((detail) => detail.product_id === product_id)
	// 			.map((detail) => detail.comment);
	// 		if (matchingComments?.length > 0) {
	// 			productsDict[product_id].hasComments = true;
	// 		}
	// 		productsDict[product_id].orders.push({
	// 			estimation_id: order?.estimation_id,
	// 			estimation: order?.estimation?.invoice_number,
	// 			customer_name: order?.estimation?.customer?.name,
	// 			date_created: order.date_created,
	// 			quantity: order.quantity,
	// 			available_quantity: order.available_quantity,
	// 			expected_date: order.expected_arrival_date,
	// 			ordered_at: order.date_created
	// 		});
	// 	});
	// 	const sortedProducts = Object.values(productsDict).sort((a, b) => b.estimation_id - a.estimation_id);
	// 	return sortedProducts;
	// };

	// const organizedOrders = activeComponent === 'order' ? organizeDataByOrders() : organizeDataByProduct();

	// const handleSelectRow = (purchase_list_id) => {
	// 	setFoundOpen(true);
	// 	// setPurcahseListId(purchase_list_id);
	// };
	// const closeFound = (data) => {
	// 	if (data == 'reload') {
	// 		setFoundOpen(false);
	// 		setPurcahseListId(null);
	// 		// this.forceUpdate();
	// 	} else {
	// 		setFoundOpen(false);
	// 		setPurcahseListId(null);
	// 	}
	// };
	// const SearchBackOrders = (search) => {
	// 	setSearchField(search);
	// 	api.getbackorders(activeComponent, { search }).then((res) => {
	// 		setData(res?.data?.data);
	// 		setpagination(res?.data);
	// 	});
	// };
    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);

        dispatch(storeSearchBackOrder(text));
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        let timeout = setTimeout(() => {
            setIsLoading(true);
			console.log('dito')
            if (text.length > 2) {
                api.filterBackOrders(activeComponent, activeFilters, text).then((res) => {
                    setIsLoading(false);
                    setData(res.data.data);
                    setpagination(res.data);
                });
            } else if (text.length > 0 && text.length <= 2) {
                setIsLoading(false);
            } else {
                api.filterBackOrders(activeComponent, activeFilters, null).then((res) => {
                    setIsLoading(false);
                    setData(res.data.data);
                    setpagination(res.data);
                });
            }
        }, 1000);

        setSearchTimeout(timeout);
    };


    const handleFilter = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterBackOrders(activeComponent, activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setData(res.data.data);
            setpagination(res.data);
        });
    };

    const handleFilterRedux = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterBackOrders(activeComponent, storedFilter, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setData(res.data.data);
            setpagination(res.data);
        });
    };
	
	// const processBackorders = () => {
	// 	api.processBackorders().then((res) => {
	// 		api.getbackorders().then((res) => {
	// 			if (res?.status) {
	// 				setSearchField('')
	// 				toast.success('Backorders processed successfully');
	// 				setData(res?.data?.data);
	// 				setpagination(res);
	// 			} else {
	// 				toast.error('Failed to process backorders');
	// 			}
	// 		});
	// 	});
	// };
	// const handleCheckboxUnchecked = (isChecked, group) => {
	// 	if (!isChecked) {
	// 		console.log('Checkbox is unchecked, performing action on group:', group);
	// 		setProducts(
	// 			products.filter((selectedGroup) => {
	// 				if (typeof selectedGroup === 'object') {
	// 					return selectedGroup.product_id !== group.product_id;
	// 				}
	// 				return selectedGroup !== group;
	// 			})
	// 		);
	// 	}
	// };
	// const addTopurchaseOrder = () => {
	// 	history('/purchaseall/create');
	// };

	// const dataToPass = { message: 'hello world' };

	// const handleSelectAll = () => {
	// 	console.log('Select all');
	// };
	// const handleCheckboxChecked = (isChecked, group) => {
	// 	let data = group.orders;
	// 	console.log('Checked', group);
	// 	if (group?.product_id) {
	// 		data = {
	// 			product_id: group.product_id,
	// 			product: group.product,
	// 			quantity: group.orders.reduce((accumulator, order) => accumulator + (order.quantity || 0), 0)
	// 		};
	// 	}
	// 	if (isChecked) {
	// 		console.log('Checkbox is checked, performing action on group:', group);
	// 		//    setSelectedRows([...selectedRows, group]);
	// 		setProducts([...products, data]);
	// 	}
	// };

	// useEffect(() => {
	// 	setPurcahseListId(null);
	// }, [activeComponent, activeFilters, searchField]);

	return (
		<div className="row">
			<div className="col-xl-12 col-lg-12">
				<div className="section-head-new" style={{ margin: '10px 0px' }}>
					<ul className="topListCom">
						<li
						 
							className={`${activeComponent === 'order' ? 'active' : ''}`}
							onClick={() => {
								handleComponentChange('order');
							}}
						>
							<span className="topListIcon">
								<EstimateIcon width={14} height={14} />
							</span>
							<span className="topListText">Per order </span>
						</li>
						<li
							className={`${activeComponent === 'product' ? 'active' : ''}`}
							onClick={() => {
								handleComponentChange('product');
							}}
						>
							<span className="topListIcon">
								<ReadyIcon width={14} height={14} />
							</span>
							<span className="topListText">Per product </span>
						</li>
					</ul>
					<div className="actions">
						<PermissionAllowed permissions={['List Estimation (Page)']}>
							<Dropdown className="btn-export-backorder iconbtn" align={'end'}>
								<Dropdown.Toggle as="span">
									<Link to={''} className="btn btn-primary btn-outlined-main white-var btn-create-report" style={{ minWidth: 'unset' }}>
										<SettingsDownloadIcon height='17px' width='17px' /> Export <LuChevronDown />
									</Link>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => downloadReport('excel')}>Excel</Dropdown.Item>
									<Dropdown.Item onClick={() => downloadReport('pdf')}>Pdf</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</PermissionAllowed>
						{/* <PermissionAllowed permissions={['List Credit Note (Page)']}>
								<button
									className="bulk-action btn-outlined-main"
									onClick={() => {
										history('/credit-note');
									}}
								>
									<span className="menuIcon">
										<TfiReload style={{ fontSize: '1.4em' }} />
									</span>
									<span>Run Autosplit again</span>
								</button>
							</PermissionAllowed> */}
						{/* <PermissionAllowed permissions={['Create Estimation (Button)']}>
							<button className="bulk-action btn-outlined-main" onClick={() => processBackorders()}>
								<span className="menuIcon">
									<TfiReload style={{ fontSize: '1.4em' }} />
								</span>
								<span>Process backorders</span>
							</button>
						</PermissionAllowed> */}
						{/* <PermissionAllowed permissions={['Create Estimation (Button)']}>
							<Link
								to={`/purchaseall/create?data=${encodeURIComponent(JSON.stringify(products))}`}
								className={`bulk-action  btn-outlined-main-primary ${products.length === 0 ? 'disabled' : ''}`}
								style={{ textDecoration: 'none' }}
								disabled={products.length === 0}
							>
								<span className="menuIcon">
									<BiSolidPurchaseTag style={{ fontSize: '1.4em' }} />
								</span>
								<span>Add to Purchase Order</span>
							</Link>
						</PermissionAllowed> */}
					</div>
				</div>
				<div className="card shadow-sm  bg-white mb-4 table-card">
					<div className="d-flex align-items-center justify-content-between TableSerachBar">
						<div>
							<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} source={'backorder'} />
						</div>
						<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
							<div className="input-group">
								<input
									type="text"
									className="form-control bg-white border"
									placeholder="Search"
									aria-label="Search"
									aria-describedby="basic-addon2"
									value={searchField}
									// onChange={(e) => {
									// 	SearchBackOrders(e.target.value);
									// }}
									onChange={handleSearch}
									style={{ width: '100%' }}
								/>
								<span className="searchIcon">
									<FaSearch />
								</span>
							</div>
						</form>
					</div>
					<div style={{ margin: '0 15px' }}>

						<div className="row">
							{
								activeComponent === 'order' ? (
									<>
										<div className="col-2">Sales Order</div>
										<div className="col-4">Receiver</div>
										<div className="col-4">Product</div>
										<div className="col-2">Quantity</div>
									</>
								) : (
									<>
										<div className="col-5">Product</div>
										<div className="col-2">Quantity</div>
										<div className="col-2">Sales Order</div>
										<div className="col-3">Receiver</div>
									</>
								)
							}
						</div>
						<hr />
						<Scroller extraHeight={100}>
							{
								isLoading ? (
									<>

										{
											[...Array(25)].map((e, i) => (
												<>
													<div className="row">
														{
															activeComponent === 'order' ? (
																<>
																	<div className="col-2"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																	<div className="col-4"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																	<div className="col-4"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																	<div className="col-2"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																</>
															) : (
																<>
																	<div className="col-5"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																	<div className="col-2"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																	<div className="col-2"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																	<div className="col-3"><Skeleton variant="rounded" width={'100%'} height={20}></Skeleton></div>
																</>
															)
														}
														{/* {[...Array(7)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))} */}
													</div>
													<hr style={{ margin: '8px 0px 13px 0px'}} />
												</>
											))
										}
									</>
								) : (
									<>
										{
											data?.length > 0 ? (
												<>
													{
														data?.map((item, index) => (
															<>
																<div className="row">
																	{
																		activeComponent === 'order' ? (
																			<>
																				<div className="col-2">
																					<Link to={`/sales/sales-orders/${item.id}`} className="Order-number">
																						{item?.sales_order_number}
																					</Link>
																				</div>
																				<div className="col-4">
																					<Link to={`/contacts/${item.receiver_id}`} className="Order-name">
																						<PrivacyModeContent>{item?.receiver}</PrivacyModeContent>
																					</Link>
																				</div>
																				<div className="col-4" style={{ marginTop: '-7px' }}>
																					{
																						item?.products?.length > 0 && (
																							<>
																								{ 
																									item?.products?.map((product, index) => (
																										<>
																											<div  style={{ padding: '5px 0'}}>
																												<div className=" d-flex justify-left items-center">
			
																													<Link to={`/products/${product.product_id}`} className='Order-number'>
																														{product?.product_name}
																													</Link>
																												</div>
																											</div>
																										</>
																									))
																								}
																							</>
																						)
																					}
																				</div>
																				<div className="col-2" style={{ marginTop: '-7px' }}>
																					
																					{
																						item?.products?.length > 0 && (
																							<>
																								{ 
																									item?.products?.map((product, index) => (
																										<>
																											<div  style={{ padding: '5px 0'}}>
																												<div className=" d-flex justify-left items-center textGray">
																													{ (product?.quantity > 1) ? `${ (parseFloat(product?.quantity)).toLocaleString() } pcs` : `${ (parseFloat(product?.quantity)).toLocaleString() } pc` }
																												</div>
																											</div>
																										</>
																									))
																								}
																							</>
																						)
																					}
																				</div>
																				
																			</>
																		) : (
																			<>
																				<div className="col-5">
																					<Link to={`/products/${item.product_id}`} className="Order-number">
																						{item.product_name}
																					</Link>
																				</div>
																				<div className="col-2 textGray">
																					{ (item?.quantity > 1) ? `${ (parseInt(item?.quantity)).toLocaleString() } pcs` : `${ (parseInt(item?.quantity)).toLocaleString() } pc` }
																				</div>
																				<div className="col-2" style={{ marginTop: '-7px' }}>
																					{
																						item?.sales_orders?.length > 0 && (
																							<>
																								{ 
																									item?.sales_orders?.map((sales_order, index) => (
																										<>
																											<div  style={{ padding: '5px 0'}}>
																												<div className=" d-flex justify-left items-center">
			
																													<Link to={`/sales/sales-orders/${sales_order.id}`} className='Order-number'>
																														{sales_order?.sales_order_number}
																													</Link>
																												</div>
																											</div>
																										</>
																									))
																								}
																							</>
																						)
																					}
																				</div>
																				<div className="col-3" style={{ marginTop: '-7px' }}>
																					{
																						item?.sales_orders?.length > 0 && (
																							<>
																								{ 
																									item?.sales_orders?.map((sales_order, index) => (
																										<>
																											<div  style={{ padding: '5px 0'}}>
																												<div className=" d-flex justify-left items-center">
			
																													<Link to={`/contacts/${sales_order.receiver_id}`} className='Order-number'>
																														{sales_order?.receiver}
																													</Link>
																												</div>
																											</div>
																										</>
																									))
																								}
																							</>
																						)
																					}
																				
																				</div>
																			</>
																		)
																	}
																</div>
																<hr style={{ margin: '8px 0px 13px 0px'}} />
															</>
														))
													}
												</>
											) : (
												<>
													<div className="row">
			
														<div className="col-12">
			
															<div className="not-found-container">
																<div className="not-found-icon">
																	<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																</div>
																<h6 className="not-found-text">No Data Available</h6>
															</div>
														</div>
													</div>
												</>
											)
										}
									</>
								)
							}
						</Scroller>

					</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => { }} />
					{/* <div className="estimation-table-area">
						<div className="table-side">
							<div className="table-responsive-xl tableData estimationList">
								<Scroller extraHeight={55}>
									<table className="table  min-width-unset tbl-space pm-table">
										<thead>
											<tr className="sticky-header">
												{
													activeComponent === 'order' ? (
														<>
															<th scope="col-3" className="check-head">Sales Order</th>
															<th scope="col-5" className="check-head">Receiver</th>
														</>
													) : (
														<>
															<th scope="col-3" className="check-head">Product </th>
															<th scope="col-5" className="check-head">Orders</th>
														</>
													)
												}
												<th scope="col-2">Quantity</th>
												<th scope="col-2">Created</th>
											</tr>
										</thead>
										<tbody>
											{
												data?.length > 0 ? (
													<>
														{
															data?.map((item, index) => (
																<>
																	<tr key={index}>
																		<td className="check-head">
																			{
																				activeComponent === 'order' ? (
																					<>
																						<Link to={`/sales/sales-orders/${item.id}`} className="Order-number">
																							{item?.sales_order_number}
																						</Link>
																						<Link to={`/contacts/${item.receiver_id}`} className="Order-name">
																							<PrivacyModeContent>{item?.receiver}</PrivacyModeContent>
																						</Link>
																						
																					</>
																				) : (
																					<>
																						<Link to={`/products/${item.product_id}`} className="Order-number">
																							{item.product_name}
																						</Link>
																					</>
																				)
																			}
																		</td>
																		
																	</tr>
																</>
															))	
														}
													</>
												) : (
													<>
														<tr className="no-hover">
															<td colSpan={5}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>
																</div>
															</td>
														</tr>
													</>
												)
											}
										</tbody>
									</table>
								</Scroller>
							</div>
							<Pagination pagination={pagination} data={handleData} onLoading={(e) => { }} />
						</div>
						{foundOpen && (
							<Modal show={foundOpen} onHide={closeFound} centered className="custom-modal-two">
								<Modal.Body>
									<div className="p-4">
										<ColapsibleTable close={closeFound} purchaseList={selectedPurchaseList} />
									</div>
								</Modal.Body>
							</Modal>
						)}
					</div> */}
				</div>
			</div>
			{/* <ModalComponent showModal={showModal} handleClose={handleClose} modelType={modelType} modalOrders={modalOrders} /> */}
		</div>
	);
};

export default Backorders;

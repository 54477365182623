import React, { useEffect, useRef, useState } from 'react';
import { FaFilter } from 'react-icons/fa6';
import './Filter.scss';
import ReactSelect from 'react-select';
import { Outlet } from 'react-router';
import { FaTimes } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';
import { DateRangePicker } from 'react-date-range';
import { BsCalendar2Date } from 'react-icons/bs';
import moment from 'moment';
import { CommonDateOnlyFormat } from 'helpers/common.helper';

import { useDispatch, useSelector } from 'react-redux';

import { 
	storeFilterPicklist, 
	storeFilterPurchaseOrder,
	storeFilterReturn, 
	storeFilterOperation,
	storeFilterSalesOrder,
	storeFilterInvoice,
	storeFilterQuotation,
	storeFilterShipment,
	storeFilterBackOrder,
	storeFilterVendorCredit
} from 'store/reducers/mainSlice';

function Filter({ filters, activeFilters, onSubmit, multi = undefined, source = '' }) {
	const compRef = useRef(null);
	const [showFilter, setShowFilter] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState({});
	const user = useSelector((state) => state.main.user);
	const dateRef = useRef(null);
    const dispatch = useDispatch();

	const [dateRange, setDateRange] = useState([]);

	const [showPicker, setShowPicker] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [isAccessorySelected, setIsAccessorySelected] = useState(false);
	
	const storedFilterPicklist = useSelector((state) => state.main.filterPicklist);
	const storedFilterPurchaseOrder = useSelector((state) => state.main.filterPurchaseOrder);
	const storedFilterReturn = useSelector((state) => state.main.filterReturn);
	const storedFilterOperation = useSelector((state) => state.main.filterOperation);
	const storedFilterSalesOrder = useSelector((state) => state.main.filterSalesOrder);
	const storedFilterInvoice = useSelector((state) => state.main.filterInvoice);
	const storedFilterQuotation = useSelector((state) => state.main.filterQuotation);
	const storedFilterShipment = useSelector((state) => state.main.filterShipment);
	const storedFilterBackOrder = useSelector((state) => state.main.filterBackOrder);
	const storedFilterVendorCredit = useSelector((state) => state.main.filterVendorCredit);

    useEffect(() => {
		if (source === 'picklist') {
        	if (storedFilterPicklist) {
				setSelectedFilters(storedFilterPicklist);
				handleSubmitWithRedux()
			}
        } else if (source === 'purchase_order') {
        	if (storedFilterPurchaseOrder) {
				setSelectedFilters(storedFilterPurchaseOrder);
				handleSubmitWithRedux()
			}
		} else if (source === 'return') {
			if (storedFilterReturn) {
				setSelectedFilters(storedFilterReturn);
				handleSubmitWithRedux()
			}
		} else if (source === 'operation') {
			if (storedFilterOperation) {
				setSelectedFilters(storedFilterOperation);
				handleSubmitWithRedux()
			}
		} else if (source === 'sales_order') {
			if (storedFilterSalesOrder) {
				setSelectedFilters(storedFilterSalesOrder);
				handleSubmitWithRedux()
			}
		} else if (source === 'invoice') {
			if (storedFilterInvoice) {
				setSelectedFilters(storedFilterInvoice);
				handleSubmitWithRedux()
			}
		} else if (source === 'quotation') {
			if (storedFilterQuotation) {
				setSelectedFilters(storedFilterQuotation);
				handleSubmitWithRedux()
			}
		} else if (source === 'shipment') {
			if (storedFilterShipment) {
				setSelectedFilters(storedFilterShipment);
				handleSubmitWithRedux()
			}
		} else if (source === 'backorder') {
			if (storedFilterBackOrder) {
				setSelectedFilters(storedFilterBackOrder);
				handleSubmitWithRedux()
			}
		} else if (source === 'vendor_credit') {
			if (storedFilterVendorCredit) {
				setSelectedFilters(storedFilterVendorCredit);
				handleSubmitWithRedux()
			}
		}
		console.log('source', source);
    }, [storedFilterPicklist, storedFilterPurchaseOrder, storedFilterReturn, storedFilterOperation, storedFilterSalesOrder, storedFilterInvoice, storedFilterQuotation, storedFilterShipment, storedFilterBackOrder, storedFilterVendorCredit, source]);


	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};
		// console.log('multi', multi);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	const handleSelectDate = (ranges) => {
		const rangesData = [ranges?.selection];
		setSelectedFilters({
			...selectedFilters,
			...(rangesData?.[0]?.startDate ? { startDate: moment(rangesData?.[0]?.startDate).startOf('day').format('YYYY-MM-DD HH:mm') } : {}),
			...(rangesData?.[0]?.endDate ? { endDate: moment(rangesData?.[0]?.endDate).endOf('day').format('YYYY-MM-DD HH:mm') } : {})
		});
	};

	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			setShowFilter(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const handleToggleShowFilter = () => {
		if (!showFilter) {
			setSelectedFilters(activeFilters);
		}
		setShowFilter(!showFilter);
	};

	const handleFilterSelect = (name, value) => {
		setSelectedFilters({
			...selectedFilters,
			[name]: value
		});

		if (name === 'category_id' && value?.label === 'Accessories') {
			setIsAccessorySelected(true);
		} else {
			setIsAccessorySelected(false);
		}
	};

	const handleDateRangeRemove = (name) => {
		const copy = { ...selectedFilters };
		delete copy['startDate'];
		delete copy['endDate'];
		setSelectedFilters(copy);

		if (source === 'picklist') {
			dispatch(storeFilterPicklist(copy));
		} else if (source === 'purchase_order') {
			dispatch(storeFilterPurchaseOrder(copy));
		} else if (source === 'return') {
			dispatch(storeFilterReturn(copy));
		} else if (source === 'operation') {
			dispatch(storeFilterOperation(copy));
		} else if (source === 'sales_order') {
			dispatch(storeFilterSalesOrder(copy));
		} else if (source === 'invoice') {
			dispatch(storeFilterInvoice(copy));
		} else if (source === 'quotation') {
			dispatch(storeFilterQuotation(copy));
		} else if (source === 'shipment') {
			dispatch(storeFilterShipment(copy));
		} else if (source === 'backorder') {
			dispatch(storeFilterBackOrder(copy));
		} else if (source === 'vendor_credit') {
			dispatch(storeFilterVendorCredit(copy));
		}
		

		onSubmit(copy);
	};

	const handleFilterRemove = (name) => {
		const copy = { ...selectedFilters };
		delete copy[name];

		setSelectedFilters(copy);

		if (source === 'picklist') {
			dispatch(storeFilterPicklist(copy));
		} else if (source === 'purchase_order') {
			dispatch(storeFilterPurchaseOrder(copy));
		} else if (source === 'return') {
			dispatch(storeFilterReturn(copy));
		} else if (source === 'operation') {
			dispatch(storeFilterOperation(copy));
		} else if (source === 'sales_order') {
			dispatch(storeFilterSalesOrder(copy));
		} else if (source === 'invoice') {
			dispatch(storeFilterInvoice(copy));
		} else if (source === 'quotation') {
			dispatch(storeFilterQuotation(copy));
		} else if (source === 'shipment') {
			dispatch(storeFilterShipment(copy));
		} else if (source === 'backorder') {
			dispatch(storeFilterBackOrder(copy));
		} else if (source === 'vendor_credit') {
			dispatch(storeFilterVendorCredit(copy));
		}

		onSubmit(copy);
	};

	const handleSubmit = () => {

		// console.log('submit trigggered')
		if (source === 'picklist') {
			dispatch(storeFilterPicklist(selectedFilters));
		} else if (source === 'purchase_order') {
			dispatch(storeFilterPurchaseOrder(selectedFilters));
		} else if (source === 'return') {
			dispatch(storeFilterReturn(selectedFilters));
		} else if (source === 'operation') {
			dispatch(storeFilterOperation(selectedFilters));
		} else if (source === 'sales_order') {
			dispatch(storeFilterSalesOrder(selectedFilters));
		} else if (source === 'invoice') {
			dispatch(storeFilterInvoice(selectedFilters));
		} else if (source === 'quotation') {
			dispatch(storeFilterQuotation(selectedFilters));
		} else if (source === 'shipment') {
			dispatch(storeFilterShipment(selectedFilters));
		} else if (source === 'backorder') {
			dispatch(storeFilterBackOrder(selectedFilters));
		} else if (source === 'vendor_credit') {
			dispatch(storeFilterVendorCredit(selectedFilters));
		}

		onSubmit(selectedFilters);
		setShowFilter(false);
	};
	const handleSubmitWithRedux = () => {

		// // console.log('submit trigggered')
		if (source === 'picklist') {
			onSubmit(storedFilterPicklist);
		} else if (source === 'purchase_order') {
			onSubmit(storedFilterPurchaseOrder);
		} else if (source === 'return') {
			onSubmit(storedFilterReturn);
		} else if (source === 'operation') {
			onSubmit(storedFilterOperation);
		} else if (source === 'sales_order') {
			onSubmit(storedFilterSalesOrder);
		} else if (source === 'invoice') {
			onSubmit(storedFilterInvoice);
		} else if (source === 'quotation') {
			onSubmit(storedFilterQuotation);
		} else if (source === 'shipment') {
			onSubmit(storedFilterShipment);
		} else if (source === 'backorder') {
			onSubmit(storedFilterBackOrder);
		} else if (source === 'vendor_credit') {
			onSubmit(storedFilterVendorCredit);
		}

		// onSubmit(selectedFilters);
		setShowFilter(false);
	};

	return (
		<>
			{showFilter && <div className="filter-bg">&nbsp;</div>}
			<div className="filter-container" ref={compRef}>
				<div className="filter-buttons">
					<button className="btn btn-primary btn-outlined-main filter-btn" onClick={handleToggleShowFilter}>
						<FaFilter /> Filter
					</button>
					{Object.keys(activeFilters || {}).map((key) => {
						const values = activeFilters[key];

						if (key != 'startDate' && key != 'endDate') {
							return (
								<div className="selected-filter-pill" key={key}>
									<span>{Array.isArray(values) ? values.map((value) => value.label).join(', ') : values?.label}</span>
									<span className="d-flex">
										<FaTimes style={{ cursor: 'pointer' }} onClick={() => handleFilterRemove(key)} />
									</span>
								</div>
							);
						}
					})}
					{(!!activeFilters?.startDate || !!activeFilters?.endDate) && (
						<div className="selected-filter-pill" key={'dateRange'}>
							<span>
								{!!activeFilters?.startDate && `From ${moment(activeFilters?.startDate).format(CommonDateOnlyFormat)}`}
								{!!activeFilters?.startDate && !!activeFilters?.endDate && <>&nbsp; - &nbsp;</>}
								{!!activeFilters?.startDate && `Till ${moment(activeFilters?.endDate).format(CommonDateOnlyFormat)}`}
							</span>
							<span className="d-flex">
								<FaTimes style={{ cursor: 'pointer' }} onClick={() => handleDateRangeRemove()} />
							</span>
						</div>
					)}
				</div>
				{showFilter && (
					<div className="filter-menu">
						<div className="filter-content">
							<button
								className="btn modal-close-icon d-md-none"
								onClick={() => {
									setShowFilter(false);
								}}
								style={{ position: 'absolute', top: '5px', right: '5px' }}
							>
								<svg
									id="Component_127_1"
									height="16"
									width="16"
									data-name="Component 127 – 1"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 19.295 19.289"
								>
									<path
										id="Icon_ionic-ios-close"
										data-name="Icon ionic-ios-close"
										d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z"
										transform="translate(-11.285 -11.289)"
									/>
								</svg>
							</button>
							<div className="filter-children">
								{filters?.map((filter) => {
									// Check if the selected category is not 'Accessories' or the filter is not 'Storage' or 'Grade'
									const isStorageOrGrade = ['storage_id', 'grade_id'].includes(filter.name);

									if (!isAccessorySelected || !isStorageOrGrade) {
										return (
											filter?.name != 'date_range' && (
												<>
													{filter.label == 'Customer' ? (
														<>
															{user?.email != 'accounts@glpwireless.com' && (
																<div className="filter-item" key={filter.name}>
																	<label htmlFor="">{filter.label}</label>
																	<ReactSelect
																		isMulti={multi != undefined ? true : false}
																		className="rs-filter-container"
																		classNamePrefix="rs-filter"
																		options={filter.options}
																		onChange={(e) => {
																			handleFilterSelect(filter.name, e);
																		}}
																		defaultValue={activeFilters?.[filter.name]}
																		placeholder="Select or type..."
																	/>
																</div>
															)}
														</>
													) : (
														<div className="filter-item" key={filter.name}>
															<label htmlFor="">{filter.label}</label>
															<ReactSelect
																isMulti={multi != undefined ? true : false}
																className="rs-filter-container"
																classNamePrefix="rs-filter"
																options={filter.options}
																onChange={(e) => {
																	handleFilterSelect(filter.name, e);
																}}
																defaultValue={activeFilters?.[filter.name]}
																placeholder="Select or type..."
															/>
														</div>
													)}
												</>
											)
										);
									}
								})}
							</div>
							{filters?.find((filter) => filter?.name == 'date_range') && (
								<div className="filter-date-time">
									<DateRangePicker
										rangeColors={['#0066FF']}
										ranges={[
											{
												startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).toDate() : null,
												endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).toDate() : null,
												key: 'selection'
											}
										]}
										onChange={handleSelectDate}
										staticRanges={[
											{
												label: 'All',
												range: () => ({
													startDate: null,
													endDate: null
												}),
												isSelected: (e) => {
													return !selectedFilters?.startDate && !selectedFilters?.endDate;
												}
											},
											{
												label: 'Today',
												range: () => ({
													startDate: new Date(),
													endDate: new Date()
												}),
												isSelected: (e) => {
													return moment(e.startDate).isSame(moment(), 'day') && moment(e.endDate).isSame(moment(), 'day');
												}
											},
											{
												label: 'This Week',
												range: () => ({
													startDate: moment().startOf('week').toDate(),
													endDate: moment().endOf('week').toDate()
												}),
												isSelected: (e) => {
													return (
														moment(e.startDate).isSame(moment().startOf('week').toDate(), 'day') &&
														moment(e.endDate).isSame(moment().endOf('week').toDate(), 'day')
													);
												}
											},
											{
												label: 'This Month',
												range: () => ({
													startDate: moment().startOf('month').toDate(),
													endDate: moment().toDate()
												}),
												isSelected: (e) => {
													return (
														moment(e.startDate).isSame(moment().startOf('month').toDate(), 'day') &&
														moment(e.endDate).isSame(moment().toDate(), 'day')
													);
												}
											},
											{
												label: 'Last Month',
												range: () => ({
													startDate: moment().subtract(1, 'month').startOf('month').toDate(),
													endDate: moment().subtract(1, 'month').endOf('month').toDate()
												}),
												isSelected: (e) => {
													return (
														moment(e.startDate).isSame(moment().subtract(1, 'month').startOf('month').toDate(), 'day') &&
														moment(e.endDate).isSame(moment().subtract(1, 'month').endOf('month').toDate(), 'day')
													);
												}
											},
											{
												label: 'This Year',
												range: () => ({
													startDate: moment().startOf('year').toDate(),
													endDate: moment().toDate()
												}),
												isSelected: (e) => {
													return (
														moment(e.startDate).isSame(moment().startOf('year').toDate(), 'day') &&
														moment(e.endDate).isSame(moment().toDate(), 'day')
													);
												}
											},
											{
												label: 'Last Year',
												range: () => ({
													startDate: moment().subtract(1, 'year').startOf('year').toDate(),
													endDate: moment().subtract(1, 'year').endOf('year').toDate()
												}),
												isSelected: (e) => {
													return (
														moment(e.startDate).isSame(moment().subtract(1, 'year').startOf('year').toDate(), 'day') &&
														moment(e.endDate).isSame(moment().subtract(1, 'year').endOf('year').toDate(), 'day')
													);
												}
											}
										]}
									/>
								</div>
							)}
						</div>
						<div className="filter-submit">
							<button className="btn btn-sm btn-main" onClick={handleSubmit}>
								Submit
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default Filter;

import React, { useEffect, useState, useRef, useCallback } from 'react';
//import './estimateInvoice.scss';
import { Link } from 'react-router-dom';
import mgLogo from 'assets/images/mglogo.svg';
import glpLogo from 'assets/images/glplogo.png';
import cmLogo from 'assets/images/cmlogo.svg';
import { Modal } from 'react-bootstrap';
import { taskboardProgresses } from 'assets/helperdata/client/order-client-progress';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { FaPlus, FaTimes } from 'react-icons/fa';
import agreementImage from 'assets/images/agreement.svg';
import Accordion from 'react-bootstrap/Accordion';
import {
    TimelineIcon,
	AttachmentIcon,
} from 'ui-component/custom-icons/icons';

function Timeline({ id, source }) {

    const [data, setData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const handleAccordionToggle = () => {
        setIsOpen(!isOpen);
    };

    const fetchData = useCallback(async () => {
        console.log('fetch data from timeline')
		const request = {
			id: id,
			type: source
		}
		api.getTimeline(request).then((res) => {
			if (res?.status) {
				setData(res?.data);
				setIsDataLoaded(true);
			} else {
				// setIsDrawerOpen(false)
				// setSelectedData(null)
			}
		});

    }, [id]);


	const viewData = (type, id) => {
		if (type === 'quotation') {
			window.open(`/sales/quotations/${id}`, '_blank');
		} else if (type === 'invoice') {
			window.open(`/sales/invoices/${id}`, '_blank');
		} else if (type === 'sales_order') {
			window.open(`/sales/sales-orders/${id}`, '_blank');
		}
    };

    useEffect(() => {
        fetchData()
    }, [id]);

	return (
		<>
            <div className="timeline-accordion">
                
                <Accordion defaultActiveKey={null} >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header 
                        className={isOpen ? 'closed' : 'open'}
                            onClick={() => {
                                handleAccordionToggle()
                            }}
                        >
                            <div className="d-flex" style={{ gap: '4px' }}>
                                <div style={{ marginTop: '-1px' }}>
                                    <TimelineIcon width={'15px'} height={'15px'} />
                                </div>
                                <div>
                                    Timeline

                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="row" style={{ marginTop: '-8px' }}>
                                <div className="col-12">


                                    {
                                        data?.qoutation?.create?.status === true && (
                                            <>
                                                <div className="row drawer-container-header-row">
                                                    <div className="col-12 drawer-content-header-container">
                                                        <div className="row">
                                                            <div className="col-12 pl-20 text-primary">
                                                                Quotations
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt10">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            
                                                            <div class="rb-container">
                                                                <ul class="rb">
                                                                    <li class={`rb-item ${ data?.qoutation?.create?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.qoutation?.create?.status ? data?.qoutation?.create?.title : 'Quotation created' }</div>
                                                                            {
                                                                                data?.qoutation?.create?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.qoutation?.create?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content text-gray-2">{ data?.qoutation?.create?.status ? `${data?.qoutation?.create?.message}.` : 'No details' }</div>
                                                                    </li>
                                                                    <li class={`rb-item ${ data?.qoutation?.approval?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.qoutation?.approval?.status ? data?.qoutation?.approval?.title : 'Approval sent' }</div>
                                                                            {
                                                                                data?.qoutation?.approval?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.qoutation?.approval?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content">{ data?.qoutation?.approval?.status ? `${data?.qoutation?.approval?.message}.` : 'No details' }</div>
                                                                    </li>
            
                                                                    <li class={`rb-item ${ data?.qoutation?.accepted?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.qoutation?.accepted?.status ? data?.qoutation?.accepted?.title : 'Approved' }</div>
                                                                            {
                                                                                data?.qoutation?.accepted?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.qoutation?.accepted?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </> 
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content">{ data?.qoutation?.accepted?.status ? `${data?.qoutation?.accepted?.message}.` : 'No details' }</div>
                                                                    </li>
                                                                </ul>
            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        source === 'invoice' && (
                                            <>
                                                <div className="row drawer-container-header-row">
                                                    <div className="col-12 drawer-content-header-container">
                                                        <div className="row">
                                                            <div className="col-12 pl-20 text-primary">
                                                                Invoice
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt10">

                                                    <div className="row">
                                                        <div className="col-12">
                                                                        
                                                            <div class="rb-container">
                                                                <ul class="rb">
                                                                    <li class={`rb-item ${ data?.invoice?.create?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.invoice?.create?.status ? data?.invoice?.create?.title : 'Invoice created' }</div>
                                                                            {
                                                                                data?.invoice?.create?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.invoice?.create?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content text-gray-2">
                                                                            
                                                                            { 
                                                                                data?.invoice?.create?.status ? (
                                                                                    <>
                                                                                        {
                                                                                            data?.invoice?.create?.details?.source === 'invoice' ? (
                                                                                                <>
                                                                                                    {/* { data?.invoice?.create?.details?.reference } has been created created under quotation <span class="text-primary cursor-pointer" onClick={() => { viewData('quotation', data?.sales_order?.create?.details?.id) }}>{ data?.sales_order?.create?.details?.quotation_number }</span>. */}
                                                                                                    { data?.invoice?.create?.details?.reference } has been created.
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    { data?.invoice?.create?.details?.reference } has been processed under { data?.invoice?.create?.details?.source } <span class="text-primary cursor-pointer" onClick={() => { viewData(data?.invoice?.create?.details?.source, data?.invoice?.create?.details?.id) }}>{ data?.invoice?.create?.details?.reference } </span>.
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        
                                                                                    </>
                                                                                ) : (
                                                                                'No details'
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                    </li>
                                                                    <li class={`rb-item ${ data?.invoice?.approved?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.invoice?.approved?.status ? data?.invoice?.approved?.title : 'Approved' }</div>
                                                                            {
                                                                                data?.invoice?.approved?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.invoice?.approved?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content">{ data?.invoice?.approved?.status ? `${data?.invoice?.approved?.message}.` : 'No details' }</div>
                                                                    </li>
                                                                    
                                                                    <li class={`rb-item ${ data?.invoice?.payment?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.invoice?.payment?.status ? data?.invoice?.payment?.title : 'Payment received' }</div>
                                                                            {
                                                                                data?.invoice?.payment?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.invoice?.payment?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content">
                                                                            { 
                                                                                data?.invoice?.payment?.status ? (
                                                                                    <>
                                                                                        Payment of {data?.invoice?.payment?.details?.currency} {data?.invoice?.payment?.details?.total} has been registered.
                                                                                    </>
                                                                                ) : (
                                                                                    'No details'
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                    
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </>
                                        )
                                    }

                                    <div className="row drawer-container-header-row">
                                        <div className="col-12 drawer-content-header-container">
                                            <div className="row">
                                                <div className="col-12 pl-20 text-primary">
                                                    Sales Order
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt10">

                                        <div className="row">
                                            <div className="col-12">
                                                            
                                                <div class="rb-container">
                                                    <ul class="rb">
                                                        <li class={`rb-item ${ data?.sales_order?.create?.status && 'active' }`} ng-repeat="itembx">
                                                            <div class="timestamp">
                                                                <div className="drawer-title-container">{ data?.sales_order?.create?.status ? data?.sales_order?.create?.title : 'Sales order created' }</div>
                                                                {
                                                                    data?.sales_order?.create?.status && (
                                                                        <>
                                                                            <div className="drawer-date-container">{ moment(data?.sales_order?.create?.date).format(CommonDateOnlyFormat) }</div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div class="item-content text-gray-2">
                                                                
                                                                { 
                                                                    data?.sales_order?.create?.status ? (
                                                                        <>
                                                                            {
                                                                                data?.sales_order?.create?.details?.source === 'quotation' ? (
                                                                                    <>
                                                                                        { data?.sales_order?.create?.details?.quotation_number } was created under quotation <span class="text-primary cursor-pointer" onClick={() => { viewData('quotation', data?.sales_order?.create?.details?.id) }}>{ data?.sales_order?.create?.details?.quotation_number }</span>.
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span class="text-primary cursor-pointer" onClick={() => { viewData('sales_order', data?.sales_order?.create?.details?.id) }}>{ data?.sales_order?.create?.details?.sales_order_number } </span> was processed under invoice { data?.sales_order?.create?.details?.invoice_number }.
                                                                                    </>
                                                                                )
                                                                            }
                                                                            
                                                                        </>
                                                                    ) : (
                                                                       'No details'
                                                                    )
                                                                }
                                                                
                                                            </div>
                                                        </li>
                                                        <li class={`rb-item ${ data?.sales_order?.approved?.status && 'active' }`} ng-repeat="itembx">
                                                            <div class="timestamp">
                                                                <div className="drawer-title-container">{ data?.sales_order?.approved?.status ? data?.sales_order?.approved?.title : 'Approved' }</div>
                                                                {
                                                                    data?.sales_order?.approved?.status && (
                                                                        <>
                                                                            <div className="drawer-date-container">{ moment(data?.sales_order?.approved?.date).format(CommonDateOnlyFormat) }</div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div class="item-content">{ data?.sales_order?.approved?.status ? `${data?.sales_order?.approved?.message}.` : 'No details' }</div>
                                                        </li>
                                                        {
                                                            source === 'sales_order' && (
                                                                <>
                                                                    <li class={`rb-item ${ data?.sales_order?.billed?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.sales_order?.billed?.status ? data?.sales_order?.billed?.title : 'Sales order billed' }</div>
                                                                            {
                                                                                data?.sales_order?.billed?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.sales_order?.billed?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content">
                                                                            { 
                                                                                data?.sales_order?.billed?.status ? (
                                                                                    <>
                                                                                        Invoice <span class="text-primary cursor-pointer" onClick={() => { viewData('invoice', data?.sales_order?.billed?.details?.id) }}>{data?.sales_order?.billed?.details?.invoice_number}</span> created.
                                                                                    </>
                                                                                    // `${data?.sales_order?.billed?.message}.`
                                                                                ) : (
                                                                                    'No details'
                                                                                ) 
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                    
                                                                    <li class={`rb-item ${ data?.sales_order?.payment?.status && 'active' }`} ng-repeat="itembx">
                                                                        <div class="timestamp">
                                                                            <div className="drawer-title-container">{ data?.sales_order?.payment?.status ? data?.sales_order?.payment?.title : 'Payment received' }</div>
                                                                            {
                                                                                data?.sales_order?.payment?.status && (
                                                                                    <>
                                                                                        <div className="drawer-date-container">{ moment(data?.sales_order?.payment?.date).format(CommonDateOnlyFormat) }</div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div class="item-content">
                                                                            { 
                                                                                data?.sales_order?.payment?.status ? (
                                                                                    <>
                                                                                        Payment of {data?.sales_order?.payment?.details?.currency} {data?.sales_order?.payment?.details?.total} for invoice <span class="text-primary cursor-pointer" onClick={() => { viewData('invoice', data?.sales_order?.payment?.details?.id) }}>{data?.sales_order?.payment?.details?.invoice_number}</span> has been registered.
                                                                                    </>
                                                                                ) : (
                                                                                    'No details'
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="row drawer-container-header-row">
                                        <div className="col-12 drawer-content-header-container">
                                            <div className="row">
                                                <div className="col-12 pl-20 text-primary">
                                                    Operations
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt10">

                                        <div className="row">
                                            <div className="col-12">
                                                            
                                                <div class="rb-container">
                                                    <ul class="rb">
                                                        <li class={`rb-item ${ data?.operation?.processing?.status && 'active' }`} ng-repeat="itembx">
                                                            <div class="timestamp">
                                                                <div className="drawer-title-container">{ data?.operation?.processing?.status ? data?.operation?.processing?.title : 'Order processed' }</div>
                                                                {
                                                                    data?.operation?.processing?.status && (
                                                                        <>
                                                                            <div className="drawer-date-container">{ moment(data?.operation?.processing?.date).format(CommonDateOnlyFormat) }</div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div class="item-content text-gray-2">
                                                                {data?.operation?.processing?.status ? 'Order started to process' : 'No details'}
                                                            </div>
                                                        </li>

                                                        {
                                                            data?.operation?.processing?.purchase_orders?.length > 0 && (
                                                                <>
                                                                    {
                                                                        data?.operation?.processing?.purchase_orders?.map((po, index) => (
                                                                            <>
                                                                                <li class={`rb-item active`} ng-repeat="itembx">
                                                                                    <div class="timestamp">
                                                                                        <div className="drawer-title-container">{po?.title}</div>
                                                                                    </div>
                                                                                    <div class="item-content text-gray-2">
                                                                                        {
                                                                                            po?.details?.map((item, index) => (
                                                                                                <>
                                                                                                    <div>
                                                                                                        {item?.qty} pcs of {item?.product} has been ordered for the amount of {item?.currency} {item?.total}
                                                                                                    </div>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                        {
                                                            data?.operation?.processing?.payments?.length > 0 && (
                                                                <>
                                                                    {
                                                                        data?.operation?.processing?.payments?.map((pp, index) => (
                                                                            <>
                                                                                <li class={`rb-item active`} ng-repeat="itembx">
                                                                                    <div class="timestamp">
                                                                                        <div className="drawer-title-container">
                                                                                            Payment for {' '}
                                                                                            <span 
                                                                                                className="text-primary"
                                                                                            >
                                                                                                {pp?.reference_no}
                                                                                            </span> created.
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="item-content text-gray-2">
                                                                                        <div>
                                                                                            Payment registered for the amount of {pp?.currency} {pp?.total} using {pp?.payment_method}.
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                pp?.filename && (
                                                                                                    <>
                                                                                                        <button 
                                                                                                            className="btn btn-primary btn-outlined-main white-var"
                                                                                                            onClick={() => {
                                                                                                                window.open(pp?.attachment_url, '_blank')
                                                                                                            }}
                                                                                                        >
                                                                                                            <AttachmentIcon width={'12px'} height={'12px'} />
                                                                                                            {pp?.filename}
                                                                                                        </button>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        <li class={`rb-item ${ data?.operation?.picklist?.status && 'active' }`} ng-repeat="itembx">
                                                            <div class="timestamp">
                                                                <div className="drawer-title-container">{ data?.operation?.picklist?.status ? data?.operation?.picklist?.title : 'Waiting for shipment' }</div>
                                                                {
                                                                    data?.operation?.picklist?.status && (
                                                                        <>
                                                                            <div className="drawer-date-container">{ moment(data?.operation?.picklist?.date).format(CommonDateOnlyFormat) }</div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div class="item-content">{ data?.operation?.picklist?.status ? data?.operation?.picklist?.message : 'No details' }</div>
                                                        </li>


                                                        {
                                                            data?.operation?.picklist?.picklists?.length > 0 && (
                                                                <>
                                                                    {
                                                                        data?.operation?.picklist?.picklists?.map((pl, index) => (
                                                                            <>
                                                                                <li class={`rb-item active`} ng-repeat="itembx">
                                                                                    <div class="timestamp">
                                                                                        <div className="drawer-title-container">{pl?.title}</div>
                                                                                    </div>
                                                                                    <div class="item-content text-gray-2">
                                                                                        {
                                                                                            pl?.details?.map((item, index) => (
                                                                                                <>
                                                                                                    {
                                                                                                        item?.qty > 0 && (
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    {item?.qty} pcs of {item?.product} has been collected and waiting to ship.
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                        <li class={`rb-item ${ data?.operation?.shipment?.status && 'active' }`} ng-repeat="itembx">
                                                            <div class="timestamp">
                                                                <div className="drawer-title-container">{ data?.operation?.shipment?.status ? data?.operation?.shipment?.title : 'Order shipped' }</div>
                                                                {
                                                                    data?.operation?.shipment?.status && (
                                                                        <>
                                                                            <div className="drawer-date-container">{ moment(data?.operation?.shipment?.date).format(CommonDateOnlyFormat) }</div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div class="item-content">{ data?.operation?.shipment?.status ? data?.operation?.shipment?.message : 'No details' }</div>
                                                        </li>
                                                        {/* <li class={`rb-item ${ data?.operation?.shipment?.status && 'active' }`} ng-repeat="itembx">
                                                            
                                                            <div class="timestamp">
                                                                <div className="drawer-title-container">Track shipment</div>
                                                            </div>
                                                            <div class="item-content">
                                                                Track your shipment by clicking the button below.
                                                            </div>
                                                        </li> */}
                                                        {
                                                            data?.operation?.shipment?.shipments?.length > 0 && (
                                                                <>
                                                                    {
                                                                        data?.operation?.shipment?.shipments?.map((shipment, index) => (
                                                                            <>
                                                                                <li class={`rb-item active`} ng-repeat="itembx">
                                                                                    <div class="timestamp">
                                                                                        <div className="drawer-title-container">Shipped via {shipment?.shipping_company}</div>
                                                                                    </div>
                                                                                    <div class="item-content text-gray-2">
                                                                                        <div>
                                                                                            {shipment?.shipping_company} has been selected as shipping provider to deliver the order. Track order {' '}
                                                                                            <span  className="cursor-pointer text-primary" onClick={() => { window.open(shipment?.tracking_url, '_blank') }} >
                                                                                                {/* {shipment?.tracking_no} */}
                                                                                                here
                                                                                            </span>.
                                                                                            
                                                                                        </div>
                                                                                       
                                                                                        <div>
                                                                                            {
                                                                                                shipment?.filename && (
                                                                                                    <>
                                                                                                        <button 
                                                                                                            className="btn btn-primary btn-outlined-main white-var"
                                                                                                            onClick={() => {
                                                                                                                window.open(shipment?.attachment_url, '_blank')
                                                                                                            }}
                                                                                                        >
                                                                                                            <AttachmentIcon width={'12px'} height={'12px'} />
                                                                                                            {shipment?.filename}
                                                                                                        </button>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

		</>
	);
}

export default Timeline;

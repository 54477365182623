import { textToSentenceCase } from 'helpers/common.helper';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Pagination from 'ui-component/Pagination';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Scroller from 'ui-component/Scroller';
import Notes from 'ui-component/notes';
import { LinkIcon, CloseIcon, SettingsLabelIcon } from 'ui-component/custom-icons/icons';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import { Skeleton, Tooltip } from '@mui/material';
import usePageTitle from 'hooks/usePageTitle';
import usePermissions from 'hooks/usePermissions';
import ShipmentTrackingModal from 'ui-component/confirmation-dialogs/awbModal/ShipmentTrackingModal';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import StatusBadge from 'ui-component/StatusBadge';
import AwbModal from './modals/AwbModal';
import PicklistComponent from './components/PicklistComponent';
import PdfViewer from 'ui-component/PdfViewer';
import CurrencyValue from 'ui-component/CurrencyValue';

function ShipmentDetails() {
	const { id } = useParams();
    const [setPageTitle] = usePageTitle('Shipment');
    useEffect(() => {
        return () => {
            setPageTitle('Shipment');
        };
    }, []);
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const history = useNavigate();
	const [isLoading, setLoading] = useState(false);
	const [isItemLoading, setItemLoading] = useState(false);
	const [invoices, setInvoices] = useState([]);
	const [transitShipmentItemList, setTransitShipmentItemList] = useState([]);
	const [shippingDetails, setShippingDetails] = useState();
	const [shippingCompanies, setShippingCompanies] = useState();
	const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false)
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [privacyMode, setPrivacyMode] = useState(false);
    const [allPermissions, hasEveryPermission] = usePermissions();
	const [showTrackingNoModal, setShowTrackingNoModal] = useState(false);
	const [selectedAttachment, setSelectedAttachment] = useState(null);
	const [showDeleteAttachmentPopup, setShowDeleteAttachmentPopup] = useState(false);

	const [showAwbModal, setShowAwbModal] = useState(null);

	const topSection = useRef(null);
	const getShippingDetails = (callback = () => { }) => {
		setLoading(true);
		api.getShipmentDetails(id).then((res) => {
			if (res?.status) {
				setLoading(false);
				setDataLoaded(true)
				setShippingDetails(res.data);
				callback(res.data);
			} else {
				history('/shipments');
			}
		});

	};

	const getShippingCompanies = () => {
		api.getSelectOptionsList('shipping_company').then((res) => {
			const val = res.data.map((item) => ({ id: item.id, name: `${item.name}` }));
			setShippingCompanies(val);
		});
	};
	const handleTrackingNoModalClose = () => {
		handlePaymentModal(false, null);
		setShowAddPayment && setShowAddPayment(false);
	};

	const handleFormSubmit = (e) => {
		toast.promise(
			api
				.updateShipmentAwb(id, {
					tracking_no: e?.trackingNo,
					shipping_company_id: e?.shippingCompanyId,
					shipment_id: showTrackingNoModal
				})
				.then((res) => {
					if (res.status) {
						getShippingDetails();
						handleCloseShipmentTrackingModal();
					}
				}),
			{
				loading: 'Updating Tracking...',
				success: <b>Tracking Detail updated!</b>,
				error: <b>Could not Update Tracking Detail.</b>
			}
		);
	};

	const handleUpdateTransitShipmentStatus = (transit_shipment_invoice_id) => {
		let cnfrmTtl = 'Update Shipment Status';
		let cnfrmMsg = 'Are you sure this package has been received by transit company?';

		showConfirmationDialog(cnfrmTtl, cnfrmMsg, () => {
			toast.promise(
				api.updateTransitShipmentInvoiceStatus(id).then(
					(res) => {
						if (res.status) {
							getShippingDetails();
						}

						handleCloseConfirmationDialog();

						return res?.status ? res : null;
					},
					() => { }
				),
				{
					loading: 'Updating status...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update shipment status</b>
				}
			);
		});
	};

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => { },
		handleClose: () => { }
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => { },
			handleClose: () => { }
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => { },
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};

	useEffect(() => {
		if (id) {
			getShippingDetails();
			getShippingCompanies();
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			verifyPrivacyMode();
		}, 100);
	}, []);

	const verifyPrivacyMode = () => {
		const getPrivacyMode = sessionStorage.getItem('privacyMode');
		if (!getPrivacyMode) {
		} else {
			if (getPrivacyMode === 'true') {
				setPrivacyMode(true);
			} else {
				setPrivacyMode(false);
			}
		}
	};

	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	};

	const handleDownloadImei = (id) => {
		api.downloadShipmentImei(id).then((res) => {
			// Create a workbook
			const wb = XLSX.utils.book_new();
			// Create a worksheet
			const ws = XLSX.utils.json_to_sheet(res?.data);
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// Convert the workbook to a binary string
			const wbBinaryString = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

			// Convert the binary string to a Blob
			const blob = new Blob([s2ab(wbBinaryString)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			saveAs(blob, `Projectmix Shipment ${shippingDetails?.tracking_number} Serials.xlsx`);
		});
	};
	const handleEditAwb = (shipmentId) => {
		setShowTrackingNoModal(shipmentId);
	};
	const handleCloseShipmentTrackingModal = (data) => {
		setShowTrackingNoModal('');
	};
	const redirectFunc = (data) => {
		setTimeout(() => {
			history(`/shipments/${id}`);
		}, 1000);
	};

	const addNote = (noteInputValue) => {
		toast.promise(
			api
				.newShipmentNote(id, {
					note: noteInputValue
				})
				.then(
					(res) => {
						if (res.status) {
							setShippingDetails((oldData) => ({
								...oldData,
								notes: [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at || undefined).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at || undefined).format('HH:mm'),
										user_name: userName,
										expand: false,
										isDeletable: res?.data?.is_deletable
									},
									...(oldData?.notes || [])
								]
							}));
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeTransitShipmentNote(id, shippingDetails?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setShippingDetails((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}
					return res?.status ? res : null;
				},
				() => { }
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setShippingDetails((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
						...note,
						expand: !note?.expand
					}
					: note
			)
		}));
	};

	const exportSerials = () => {
		handleDownloadImei(id)
	}

	const handleClose = () => {
		setShowAwbModal(false);
		// showDeleteAttachmentPopup(false)
		setShowDeleteAttachmentPopup(false);
	}

	const handleReloadList = () => {
		getShippingDetails();
		setShowAwbModal(false);
	}

	const handleRemoveAttachment = (data) => {
		setSelectedAttachment(data);
		setShowDeleteAttachmentPopup(true);
	}

	const handleDeleteAttachment = () => {
		toast.promise(
			api.deleteShipmentAttachment(selectedAttachment?.id).then(
				(res) => {
					if (res.status) {
						getShippingDetails();
						handleClose();
					}
					return res?.status ? res : null;
				},
				() => { }
			),
			{
				loading: 'Deleting Attachment...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete attachment</b>
			}
		)
	}

	const viewData = (source, id) => {
		if (source === 'shipment') {
			history(`/shipments/${id}`);
		} else if (source === 'picklist') {
			window.open(`/picklists/${id}`, '_blank');
		} else if (source === 'sales_order') {
			window.open(`/sales/sales-orders/${id}`, '_blank');
		} else if (source === 'contact') {
			window.open(`/contacts/${id}`, '_blank');
		} else if (source === 'invoice') {
			window.open(`/sales/invoices/${id}`, '_blank');
		}
	};

	return (
		<>
			
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="section-head-new" style={{ marginTop: '10px', paddingLeft: '0' }}>

                        <h2 style={{ gap: '5px', fontSize: '18px', marginLeft: '6px' }} className="col-md-4 d-flex items-center">
                            {/* P123891 */}
                            {
                                dataLoaded ? ( 
                                    <>
										<div>
                                        	{shippingDetails?.tracking_number}
										</div>
										<Tooltip title={'Track order'} arrow placement="right">
											<div className="text-primary cursor-pointer">
												<LinkIcon width="16px" height="16px" />
											</div>
										</Tooltip>
                                        {/* <div style={{ width: '75px'}}>
                                            <StatusBadge itemStatus={shippingDetails?.status?.name} />
                                        </div> */}
                                    </>
                                ): (
                                    <>
                                        <Skeleton variant="rounded" width={'90px'} height={20}></Skeleton>
                                        <div style={{ width: '75px'}}>
                                            <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                        </div>
                                    </>
                                )
                            }
                            
                            
                        </h2>
                        <div className="actions">


                            {/* <PermissionAllowed permissions={['View Shipment (Button)']} hasSome>

                                <button
                                    type="button"
									onClick={() => {
										setShowAwbModal(true)
									}}
                                    className="btn btn-primary btn-outlined-main"
                                    disabled={!dataLoaded}
                                >
                                   	<SettingsLabelIcon height='16px' width='16px' />
                                    Add AWB
                                </button>
                            </PermissionAllowed> */}
                            <PermissionAllowed permissions={['View Shipment (Button)']} hasSome>

                                <button
                                    type="button"
                                    onClick={() => exportSerials()}
                                    className="btn btn-primary btn-outlined-main"
                                    disabled={!dataLoaded}
                                >
                                    <svg
                                        id="fi_2767144"
                                        enable-background="new 0 0 128 128"
                                        height="18"
                                        viewBox="0 0 128 128"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            id="Download"
                                            d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                    Export
                                </button>
                            </PermissionAllowed>
                        </div>
                    </div>
					{
						shippingDetails?.shipments?.length > 0 && (
							<>
								{
									shippingDetails?.shipments?.map((shipment, index) => (
										<>

											<div className="row">
												<div className="col-lg-12 form-group">

													<div className="card shadow-sm bg-white mb-4">
														<div className="card-body">
															<div className="parent-group">
																<h5 style={{ fontSize: '16px' }}><span className="textGray" style={{ fontSize: '16px' }}>Reference:</span> {shipment?.reference_no}</h5>

																	
															
																<div
																	className="text-right"
																	style={{position: 'absolute', right: '20px' }}
																>
																	<div className="btn-group-actions" style={{ display: 'flex' }}>
																		<div
																			style={{ marginRight: '10px', marginTop: '2px' }}
																		>
																			<StatusBadge itemStatus={shippingDetails?.status} />
																		</div>
																	</div>
																</div>
														</div>
															<hr />
															<div className="row align-items-top">
																<div className="col-md-4 col-12 form-group">
																	<div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
																		<div className="textGray" style={{ width: '151px' }}>Picklist No</div>
																		<div style={{ maxWidth: '275px', marginLeft: '10px' }}>
																		
																			{
																				!dataLoaded ? (
																					<>
																						<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																					</>
																				) : (
																					<>
																						<span
																							className={`${hasEveryPermission(['View Picklists (Button)']) ? 'cursor-pointer text-primary' : ''}`}
																							onClick={() => {
																								if (hasEveryPermission(['View Picklists (Button)'])) {
																									viewData('picklist', shipment?.picklist?.id);
																								}
																							}}
																						>
																							{shipment?.picklist?.picklist_no ?? '-----'}
																						</span>
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
																<div className="col-md-8 col-12 form-group" >
																	<div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
																		<div className="textGray" style={{ width: '151px' }}>Shipping Cost</div>
																		<div style={{ maxWidth: '275px', marginLeft: '10px' }}>
																		
																			{
																				!dataLoaded ? (
																					<>
																						<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																					</>
																				) : (
																					<>
																						<span>
																							{ 
																								shipment?.shipping_cost ? (
																									<>
																										<CurrencyValue currencyObject={shipment?.shipping_cost} />
																									</>
																								) : (
																									'-----'
																								) 
																							}
																						</span>
																						{/* <span>{shippingDetails?.shipping_company?.name ?? '-----'}</span> */}
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
															</div>
															
															<div className="row align-items-top">
																<div className="col-md-4 col-12 form-group">
																	<div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
																		<div className="textGray" style={{ width: '151px' }}>Sales Order</div>
																		<div style={{ maxWidth: '275px', marginLeft: '10px' }}>
																			{
																				!dataLoaded ? (
																					<>
																						<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																					</>
																				) : (
																					<>
																						<span
																							className={`${hasEveryPermission(['View Sales Orders Details']) ? 'cursor-pointer text-primary' : ''}`}
																							onClick={() => {
																								if (hasEveryPermission(['View Sales Orders Details'])) {
																									viewData('sales_order', shipment?.sales_order?.id);
																								}
																							}}
																						>
																							{shipment?.sales_order?.sales_order_number ?? '-----'}
																						</span>
																						{/* <span>{shippingDetails?.shipping_company?.name ?? '-----'}</span> */}
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
																<div className="col-md-8 col-12 form-group" >
																	<div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
																		<div className="textGray" style={{ width: '151px' }}>Invoice</div>
																		<div style={{ maxWidth: '275px', marginLeft: '10px' }}>
																		
																		
																			{
																				!dataLoaded ? (
																					<>
																						<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																					</>
																				) : (
																					<>
																						<span
																							className={`${hasEveryPermission(['View Estimation (Button)']) ? 'cursor-pointer text-primary' : ''}`}
																							onClick={() => {
																								if (hasEveryPermission(['View Estimation (Button)'])) {
																									viewData('invoice', shipment?.invoice?.id);
																								}
																							}}
																						>
																							{shipment?.invoice?.invoice_number ?? '-----'}
																						</span>
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
															</div>
															
															<div className="row align-items-top">
																<div className="col-md-4 col-12 form-group">
																	<div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
																		<div className="textGray" style={{ width: '151px' }}>Customer</div>
																		<div style={{ maxWidth: '275px', marginLeft: '10px' }}>
																		
																		
																			{
																				!dataLoaded ? (
																					<>
																						<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																					</>
																				) : (
																					<>
																						<span
																							className={`${hasEveryPermission(['View Contact (Button)']) ? 'cursor-pointer text-primary' : ''}`}
																							onClick={() => {
																								if (hasEveryPermission(['View Contact (Button)'])) {
																									viewData('contact', shipment?.receiver?.id);
																								}
																							}}
																						>
																							{shipment?.receiver?.name ?? '-----'}
																						</span>
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
																<div className="col-md-8 col-12 form-group" >
																	<div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
																		<div className="textGray" style={{ width: '151px' }}>Weight</div>
																		<div style={{ maxWidth: '275px', marginLeft: '10px' }}>
																		
																		{
																			!dataLoaded ? (
																				<>
																					<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																				</>
																			) : (
																				<>
																					<span>
																						{
																							shipment?.weight ? (
																								<>
																									{shipment?.weight}{shipment?.weight_unit}
																								</>
																							) : (
																								<>
																									-----
																								</>
																							)
																						}
																					</span>
																				</>
																			)
																		}
																		</div>
																	</div>
																</div>
															</div>
															
															<div className="row align-items-top">
																<div className="col-12 form-group">
																	<div style={{ display: 'flex', alignItems: 'center' }} >
																		<div className="textGray" style={{ width: '151px', textAlign: 'right' }}>Shipping Address</div>
																		<div style={{ marginLeft: '10px' }}>
																		
																			{
																				!dataLoaded ? (
																					<>
																						<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
																					</>
																				) : (
																					<>
																						<span>
																							{shipment?.receiver?.address.address} { ' ' }
																							{shipment?.receiver?.address.city} { ' ' }
																							{shipment?.receiver?.address.zipcode} { ' ' }
																							{shipment?.receiver?.address.country}
																						</span>
																					</>
																				)
																			}
																		</div>
																	</div>
																</div>
															</div>

															
															{
																shippingDetails?.picklist?.id && (
																	<>
																		<PicklistComponent details={shippingDetails?.picklist} />
																	</>
																)
															}
															
															
														</div>
													</div>
												</div>

											</div>
										</>
									))
								}
							</>
						)
					}
					{
						shippingDetails?.shipments?.length > 0 && (
							<>
								<div className="row m-0">
									{
										shippingDetails?.shipments?.map((shipment, index) => (
											<>
												{
													shipment?.attachments?.length > 0 && (
														<>
															{
																shipment?.attachments?.map((attachment, index) => (
																	<>
																		{
																			attachment?.filename && (
																				<>
																					<div className="col-12 col-md-4" style={{ padding: '0px 5px'}}>
				
																						<div className="card shadow-sm bg-white mb-4 w-100 border card-border-gray">
																							<div className="card-header-radius-0" >
																								<div className="row">
																									<div className="col-11">
																										<div 
																											className="text-primary cursor-pointer"
																											onClick={() => {
																												const url = `${attachment.attachment_url}`;
																												window.open(url, '_blank');
																											}}
																										>
																											{attachment?.filename}
																										</div>
																									</div>
																									<div className="col-1 text-right">
				
																										<div>
																										
																											<button className="btn modal-close-icon mt-19" onClick={() => handleRemoveAttachment(attachment)}>
																												<CloseIcon height={'16px'} width={'16px'} />
																											</button>
																										</div>
																									</div>
																								</div>
																							</div>
																							<div className="card-body text-center">
				
																								{
																									attachment?.attachment_url && (
																										<>
																											{attachment?.extension == 'pdf' ? (
																												<PdfViewer src={attachment?.attachment_url + '#toolbar=0&navpanes=0&zoom=FitW'} />
																											) : (
																												<img src={attachment?.attachment_url} className="img-fluid" />
																											)}
																										</>
																									)
																								}
																								
																							</div>
																						</div>
																					</div>
																				</>
																			)
																		}
																	</>
																))
															}
													</>
												)
											}



											</>
										))
									}
								</div>
							</>
						)
					}
					

                            
							<div style={{ marginBottom: '8px'}}></div>
                </div>
			</div>
			
			{showTrackingNoModal && (
				<ShipmentTrackingModal
					shipmentId={showTrackingNoModal}
					shippingCompanies={shippingCompanies}
					redirectionData={redirectFunc}
					onFormSubmit={handleFormSubmit}
					handleCloseShipmentTrackingModal={handleCloseShipmentTrackingModal}
				/>
			)}

			{showAwbModal && (
				<AwbModal
					openPopup={showAwbModal}
					details={shippingDetails}
					handleClose={handleClose}
					handleReloadList={handleReloadList}
				/>
			)}
            {showDeleteAttachmentPopup && (
                <DeleteConfirmation
                    show={showDeleteAttachmentPopup}
                    handleClose={handleClose}
                    handleDelete={handleDeleteAttachment}
                    title="Confirm"
                    message={`Are you sure you want to delete ${selectedAttachment?.filename}?`}
                    customDeleteButtonText={`Yes, Delete ${selectedAttachment?.filename}`}
                />
            )}
			{confirmationDialog?.show && (
				<ActionConfirmationModal
					show={confirmationDialog?.show}
					message={confirmationDialog?.message}
					title={confirmationDialog?.title}
					customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
					customCancelText={confirmationDialog?.customCancel || 'Cancel'}
					customIcon={<PiInfoFill />}
					handleConfirm={confirmationDialog?.handleConfirm}
					handleClose={confirmationDialog?.handleClose}
				/>
			)}
		</>
	);
}

export default ShipmentDetails;

// project imports
import config from 'config';

export const initialState = {
	user: {},
	client: {},
	loading: false,
	opened: true,
	selectedItem: {},
	logout: false,
	clientlogout: false,
	selectedCurrency: 'eur',
	hasNewNotification: false,
	privacyMode: false,
	searchBar: false,
	tasksList: [],
	searches: [],
};

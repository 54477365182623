import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import Scroller from 'ui-component/Scroller';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus, FaSave, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import { TbDatabaseExclamation } from 'react-icons/tb';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { Skeleton, Tooltip } from '@mui/material';
import { CgNotes } from 'react-icons/cg';
import { IoIosArrowDown } from 'react-icons/io';
import { ImSpinner2 } from 'react-icons/im';
import usePageTitle from 'hooks/usePageTitle';
import ProductList from './components/ProductList';
import { IoIosClose } from 'react-icons/io';
import { SelectionProvider } from './components/SelectionContext';
import ReactSelect from 'react-select';
import { flexbox } from '@mui/system';
import NoImage from 'assets/images/no-image.png';
import ReactSelectCreatable from 'react-select/creatable';

import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import MentionInput from 'ui-component/MentionInput/MentionInput';

const PurchasingForm = ({ edit }) => {
    const location = useLocation();
	const { id } = useParams();
    const params = new URLSearchParams(location.search);
    const productData = JSON.parse(decodeURIComponent(params.get('data')));

    const [setPageTitle] = usePageTitle(`${edit ? 'Edit' : 'New'} Purchase order`);
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);

    const [isInternalLoading, setInternalLoading] = useState(true);
    const history = useNavigate();
    const currencies = [
        { label: 'EUR', value: 'eur' },
        { label: 'USD', value: 'usd' },
        { label: 'AED', value: 'aed' }
    ];
    const paymentMethods = [
        { label: 'In Credit', value: 'in_credit' },
        { label: 'Upfront Payment', value: 'upfront_payment' }
    ];
    const [defaultCurrency, setDefaultCurrency] = useState('eur');

    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [hasProducts, setHasProducts] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [supplierList, setsupplierList] = useState([]);
    const [warehouseList, setwarehouseList] = useState([]);
    const [salesOrderList, setSalesOrderList] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const currentDate = moment().format('YYYY-MM-DD');
    const [alertMessage, setAlertMessage] = useState();
    const [convertedTotalPrice, setConvertedTotalPrice] = useState({ 'usd': 0, 'eur': 0, 'aed': 0 });
    const [currency, setCurrency] = useState('');
    const textareaRef = useRef(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedProductToDelete, setSelectedProductToDelete] = useState(null);

	const [inputFocus, setInputFocus] = useState(false);
	const [noteInputValue, setNoteInputValue] = useState('');
	const [noteInputPlain, setNoteInputPlain] = useState('');
	const [noteMentions, setNoteMentions] = useState([]);

    const [purchaseOrder, setPurchaseOrder] = useState({
        id: '',
        referenceNo: [],
        purchaseOrderId: '',
        supplierId: '',
        expectedDate: currentDate,
        warehouseId: '',
        currency: defaultCurrency,
        paymentMethod: '',
        shippingFee: '0.00',
        taxAmount: '0.00',
        notes: '',
        purchaseInvoiceDetails: []
    });
    const [items, setItems] = useState([]);

    const handlePurchaseOrderInfo = (val) => {
        setPurchaseOrder(val);
    };

  
    
    const [purchaseList, setPurchaseList] = useState({
        supplier_id: '',
        warehouse_id: '',
        expectedDate: currentDate,
        invoiceNumber: '',
        currency: 'eur'
    });
    const [defaultWarehouse, setDefaultWarehouse] = useState(warehouseList?.[0]);
    // Row Area
    const [rows, setRows] = useState([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0, comment: '' }]);

    useEffect(() => {
        if (selectedRowsData.length === 0) {
            if (productData?.length > 0) {
                const allOrders = productData.flatMap((product) => product);
                setSelectedRowsData(allOrders);
            }
        }
    }, [productData]);
    useEffect(() => {
        if (selectedRowsData && selectedRowsData.length > 0) {
            const calculatedTotalQuantity = selectedRowsData.reduce((total, row) => total + row.quantity, 0);
            setTotalQuantity(calculatedTotalQuantity);

            const calculatedTotalPrice = selectedRowsData.reduce((total, row) => total + row.quantity * row.price, 0);
            setTotalPrice(calculatedTotalPrice);
        } else {
            setTotalQuantity(0);
            setTotalPrice(0);
        }
    }, [selectedRowsData]);
    // const totalQuantity = selectedRowsData?.reduce((total, row) => total + row.quantity, 0);
    // const totalPrice = selectedRowsData?.reduce((total, row) => total + (row.quantity * row.price), 0);

    const handleDoneClick = (data) => {
        // return false;
        closeFound(data);
        setHasProducts(true)
        // setSelectedRowsData((prevData) => [...prevData, ...data]);


        const modifiedData = data?.map((item) => {
            return {
                currency: item?.currency,
                free_stock: item?.free_stock,
                is_selected: item?.is_selected,
                price: item?.price,
                product_id: item?.product_id,
                product_name: item?.product_name,
                quantity: item?.quantity,
                reserved_stock: item?.reserved_stock,
                purchased_quantity: item?.purchased_quantity,
                sku: item?.sku,
                thumbnail_url: item?.thumbnail_url,
                converted_currency_price: item?.converted_currency_price,
                id: item?.id,
                // converted_currency_total: item?.converted_currency_total

                converted_currency_total: {
                    'usd': item?.converted_currency_price['usd'] * item?.quantity,
                    'eur': item?.converted_currency_price['eur'] * item?.quantity,
                    'aed': item?.converted_currency_price['aed'] * item?.quantity,
                }
            }
        });
        setConvertedTotalPrice({
            'usd': modifiedData.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['usd']; }, 0),
            'eur': modifiedData.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['eur']; }, 0),
            'aed': modifiedData.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['aed']; }, 0)
        })
        setSelectedRowsData(modifiedData);
    };

    const handleextimatevalue = (val) => {
        setPurchaseList(val);
    };

    const handleSelectRow = () => {
        setOpenPopup(true);
        // setPurcahseListId(purchase_list_id);
    };
    const closeFound = (data) => {
        if (data == 'reload') {
            setOpenPopup(false);
            // setPurcahseListId(null);
            // this.forceUpdate();
        } else {
            setOpenPopup(false);
            // setPurcahseListId(null);
        }
    };

    useEffect(() => {
        setPurchaseList({
            supplier_id: '',
            warehouse_id: '',
            expectedDate: currentDate
        });
        setRows([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0, comment: '' }]);
        initiateDatas();
    }, [edit]);

    const [loaderStatus, setLoaderStatus] = useState({
        suppliers: false,
        warehouse: false,
        sales_orders: false
    });

    const [editDataLoader, setEditDataLoader] = useState(false);

    const initiateDatas = () => {
        
        api.getSelectOptionsList('warehouse').then((res) => {
            const customerArray = res?.data?.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setwarehouseList(customerArray);
            setLoaderStatus((oldData) => ({
                ...oldData,
                warehouse: false
            }));
        });
        api.getSelectOptionsList('supplier').then((res) => {
            const customerArray = res?.data?.map((item) => ({ id: item.id, label: item.name, value: item.id }));
            setsupplierList(customerArray);
            setLoaderStatus((oldData) => ({
                ...oldData,
                suppliers: false
            }));
        });
        api.getSelectOptionsList('sales_order').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.sales_order_number, value: item.id }));
            setSalesOrderList(collection);
            setLoaderStatus((oldData) => ({
                ...oldData,
                sales_orders: false
            }));
        });
        setInternalLoading(false)
        if (edit)
        {
            api.getPurchaseOrderDetails(id).then((res) => {

                const purchaseOrderDetails = res?.data?.purchase_invoice_details?.map((item) => {
                    return {
                        label: item?.reference_no,
                        value: item?.sales_order_id
                    }
                })
                setPurchaseOrder({
                    ...purchaseOrder,
                    id: res?.data?.id,
                    referenceNo: res?.data?.reference_no,
                    purchaseOrderId: res?.data?.purchase_order_id,
                    supplierId: res?.data?.supplier?.id,
                    expectedDate: res?.data?.date,
                    warehouseId: res?.data?.warehouse?.id,
                    currency: res?.data?.currency,
                    paymentMethod: res?.data?.payment_method,
                    shippingFee: res?.data?.shipping_fee,
                    notes: res?.data?.notes,
                    purchaseInvoiceDetails: purchaseOrderDetails
                });
                
                setCurrency(res?.data?.currency);

                const modifiedData = res?.data?.purchase_invoice_items?.map((item) => {
                    return {
                        converted_currency_total: {
                            'usd': item?.converted_currency_price['usd'] * item?.quantity,
                            'eur': item?.converted_currency_price['eur'] * item?.quantity,
                            'aed': item?.converted_currency_price['aed'] * item?.quantity,
                        }
                    }
                });
                setConvertedTotalPrice({
                    'usd': modifiedData.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['usd']; }, 0),
                    'eur': modifiedData.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['eur']; }, 0),
                    'aed': modifiedData.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['aed']; }, 0)
                })
                if (res?.data?.purchase_invoice_items.length > 0)
                {
                    setHasProducts(true)
                        
                    const items = res?.data?.purchase_invoice_items?.map(item => {

                        const currency = res?.data?.currency;
                        const purchasePrice = item?.price !== '0.00' ? item?.price : '0.00';
                        setIsSaveButtonDisabled(false)
                        setInternalLoading(false)
                        return {
                            currency: currency,
                            free_stock: item?.product?.available_quantity,
                            id: item?.id,
                            price: item?.price,
                            product_id: item?.product?.id,
                            product_name: item?.product?.name,
                            quantity: item?.quantity,
                            reserved_stock: 0, //item?.reserved_stock,
                            purchased_quantity: item?.quantity,
                            sku: item?.product?.sku,
                            thumbnail_url: item?.product?.thumbnail_url,
                            converted_currency_price: item?.converted_currency_price,
                            converted_currency_total: {
                                'usd': item?.converted_currency_price['usd'] * item?.quantity,
                                'eur': item?.converted_currency_price['eur'] * item?.quantity,
                                'aed': item?.converted_currency_price['aed'] * item?.quantity,
                            },
                            is_selected: true,
                        }
                    });

                    setSelectedRowsData(items);
                }
            });
        }
    };


    const handleCustomerinfo = (selected) => {
        handleextimatevalue({
            ...purchaseList,
            supplier_id: selected?.id
        });
    };

    const removeRow = (id, productId) => {
        if (id) {
            setShowDeleteModal(true)
            setSelectedProductToDelete(id)
        } else {
            const updatedRows = selectedRowsData.filter((row) => row.product_id !== productId);
            setConvertedTotalPrice({
                'usd': updatedRows.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['usd']; }, 0),
                'eur': updatedRows.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['eur']; }, 0),
                'aed': updatedRows.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['aed']; }, 0)
            })
            setSelectedRowsData(updatedRows);
            if (updatedRows.length < 1) {
                setHasProducts(false);
            }
        }
    };


	const handleClose = () => {
		setShowDeleteModal(false);
	}

    const handleDelete = async () => {
        // selectedProductToDelete
        // setShowDeleteModal(false)


        toast.promise(
            api.deletePurchasingProduct(selectedProductToDelete).then((res) => {
                if (res.status) {
                    setSelectedProductToDelete(null);
                    setShowDeleteModal(false)

                const updatedRows = selectedRowsData.filter((row) => row.id !== selectedProductToDelete);
                setConvertedTotalPrice({
                    'usd': updatedRows.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['usd']; }, 0),
                    'eur': updatedRows.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['eur']; }, 0),
                    'aed': updatedRows.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['aed']; }, 0)
                })
                setSelectedRowsData(updatedRows);
                if (updatedRows.length < 1) {
                    setHasProducts(false);
                }
                }
                return res?.status ? res : abc;
            }),
            {
                loading: 'Removing product...',
                success: (data) => `${data.message}`,
                error: <b>Failed to remove product</b>
            }
        );


        // setSelectedProductToDelete(id)
    }
    
    const handleQuantityChange = (id, value) => {

        const collections = [...selectedRowsData];
        const enteredQty = parseFloat(value);
        const itemIndex = collections.findIndex(product => product.product_id === id);
        const selectedCurrentConvertedPrice = {
            'usd': parseFloat(collections[itemIndex].converted_currency_price['usd']),
            'eur': parseFloat(collections[itemIndex].converted_currency_price['eur']),
            'aed': parseFloat(collections[itemIndex].converted_currency_price['aed']),
        }

        if (!isNaN(enteredQty)) {
            collections[itemIndex].quantity = enteredQty;

            collections[itemIndex].converted_currency_total = {
                'usd': parseFloat(selectedCurrentConvertedPrice['usd']) * parseInt(collections[itemIndex].quantity),
                'eur': parseFloat(selectedCurrentConvertedPrice['usd']) * parseInt(collections[itemIndex].quantity),
                'aed': parseFloat(selectedCurrentConvertedPrice['usd']) * parseInt(collections[itemIndex].quantity),
            };
        } else {
            collections[itemIndex].quantity = 0;

            collections[itemIndex].converted_currency_total = {
                'usd': parseFloat(selectedCurrentConvertedPrice['usd']) * parseInt(0),
                'eur': parseFloat(selectedCurrentConvertedPrice['usd']) * parseInt(0),
                'aed': parseFloat(selectedCurrentConvertedPrice['usd']) * parseInt(0),
            };
        }

        setConvertedTotalPrice({
            'usd': collections.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['usd']; }, 0),
            'eur': collections.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['eur']; }, 0),
            'aed': collections.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['aed']; }, 0)
        })
        setSelectedRowsData(collections);
    };

    const handleChangeCurrency = (value) => {
        setPurchaseOrder({
            ...purchaseOrder,
            currency: value, // Update the currency value
        });
    }

    const handleUnitPriceChange = (id, value) => {

        const collections = [...selectedRowsData];
        const enteredQty = parseFloat(value);
        const itemIndex = collections.findIndex(product => product.product_id === id);
        const rowCurrency = purchaseOrder?.currency;

        if (!isNaN(enteredQty)) {

            // updatedItems[index].margin_price = {
            //     'usd': (parseFloat(updatedItems[index].sales_price['usd']) - enteredQty).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            //     'eur': (parseFloat(updatedItems[index].sales_price['eur']) - enteredQty).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            //     'aed': (parseFloat(updatedItems[index].sales_price['aed']) - enteredQty).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            // };
            // collections[itemIndex].converted_currency_price[rowCurrency] = enteredQty
            collections[itemIndex].converted_currency_price = {
                'usd': parseFloat(enteredQty),
                'eur': parseFloat(enteredQty),
                'aed': parseFloat(enteredQty),
            };
            collections[itemIndex].converted_currency_total = {
                'usd': parseFloat(enteredQty) * parseInt(collections[itemIndex].quantity),
                'eur': parseFloat(enteredQty) * parseInt(collections[itemIndex].quantity),
                'aed': parseFloat(enteredQty) * parseInt(collections[itemIndex].quantity),
            };


        } else {
            collections[itemIndex].converted_currency_price = {
                'usd': parseFloat(0),
                'eur': parseFloat(0),
                'aed': parseFloat(0),
            };
            collections[itemIndex].converted_currency_total = {
                'usd': parseFloat(0) * parseInt(collections[itemIndex].quantity),
                'eur': parseFloat(0) * parseInt(collections[itemIndex].quantity),
                'aed': parseFloat(0) * parseInt(collections[itemIndex].quantity),
            };
        }
        collections[itemIndex].price = enteredQty;
        setConvertedTotalPrice({
            'usd': collections.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['usd']; }, 0),
            'eur': collections.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['eur']; }, 0),
            'aed': collections.reduce((accumulator, currentItem) => { return accumulator + currentItem.converted_currency_total['aed']; }, 0)
        })
        setSelectedRowsData(collections);
    };

    const validateRows = () => {
        for (let i = 0; i < selectedRowsData.length; i++) {
            const { product_id, quantity, price } = selectedRowsData[i];
            if (i != selectedRowsData.length - 1) {
                if (!product_id || !quantity || !price) {
                    console.log('may kulang', selectedRowsData[i]);
                    return false;
                }
            }
        }
        return true;
    };

    const closePopup = () => {
        setOpenPopup(false);
    };
    const formValidation = () => {
        let isValid = true;
        if (typeof purchaseOrder?.referenceNo?.length < 1) {
            isValid = false;
            toast.error('Reference number is required');
        } else if (typeof purchaseOrder?.supplierId === 'undefined' || purchaseOrder?.supplierId == '') {
            isValid = false;
            toast.error('Supplier is required');
        } else if (typeof purchaseOrder?.expectedDate === 'undefined' || purchaseOrder?.expectedDate == '') {
            isValid = false;
            toast.error('Expected delivery date is required');
        } else if (typeof purchaseOrder?.warehouseId === 'undefined' || purchaseOrder?.warehouseId == '') {
            isValid = false;
            toast.error(typeof purchaseOrder?.warehouseId === 'undefined' || 'Warehouse is required');
        } else if (typeof purchaseOrder?.currency === 'undefined' || purchaseOrder?.currency == '') {
            isValid = false;
            toast.error('Currency is required');
        } else if (typeof purchaseOrder?.paymentMethod === 'undefined' || purchaseOrder?.paymentMethod == '') {
            isValid = false;
            toast.error('Payment method is required');
        } else if (selectedRowsData.length < 1) {
            isValid = false;
            toast.error('Please add at least one product');
        } else if (!validateRows()) {
            isValid = false;
            toast.error('Please fill in all fields for each product');
        }

        return isValid;
    };

    const updateSubmit = () => { 
        if (formValidation()) {
            const data = {
                ...purchaseOrder,
                products: selectedRowsData,
                reference_no: purchaseOrder?.referenceNo,
                supplier_id: purchaseOrder?.supplierId,
                expected_date: purchaseOrder?.expectedDate,
                warehouse_id: purchaseOrder?.warehouseId,
                shipping_fee: purchaseOrder?.shippingFee,
                currency: purchaseOrder?.currency ? purchaseOrder?.currency : 'eur',
                payment_method: purchaseOrder?.paymentMethod,
                notes: purchaseOrder?.notes,
                purchase_invoice_details: purchaseOrder?.purchaseInvoiceDetails
                // invoiceNumber: purchaseList?.invoiceNumber,
            };
            setIsSaveButtonDisabled(true);
            api.updatePurchaseOrder(id, data).then(
                (res) => {
                    console.log(res);
                    if (res.status) {
                        toast.success(res.message);
                        history(`/purchasing/view/${id}`);
                    } else {
                        toast.error(res.message);
                    }
                    setIsSaveButtonDisabled(false);
                },
                () => { }
            );
        }
    };

    const savePurchaseOrder = () => {
        if (formValidation()) {
            const data = {
                ...purchaseOrder,
                products: selectedRowsData,
                reference_no: purchaseOrder?.referenceNo,
                supplier_id: purchaseOrder?.supplierId,
                expected_date: purchaseOrder?.expectedDate,
                warehouse_id: purchaseOrder?.warehouseId,
                shipping_fee: purchaseOrder?.shippingFee,
                currency: purchaseOrder?.currency ? purchaseOrder?.currency : 'eur',
                payment_method: purchaseOrder?.paymentMethod,
                notes: purchaseOrder?.notes,
                // invoiceNumber: purchaseList?.invoiceNumber,
            };
            setIsSaveButtonDisabled(true);
            api.postPurchaseOrder(data).then(
                (res) => {
                    console.log(res);
                    if (res.status) {
                        toast.success(res.message);
                        history(`/purchasing/view/${res.data.id}`);
                    } else {
                        toast.error(res.message);
                    }
                    setIsSaveButtonDisabled(false);
                },
                () => { }
            );
        }
    };

	const [clearInput, setClearInput] = useState(false);

	const clearInputField = () => {
		setClearInput(true);
		setTimeout(() => {
			setClearInput(false);
		}, 1);
	};

    // useEffect(() => {
    //     const textarea = textareaRef.current;
    
    //     const adjustHeight = () => {
    //         textarea.style.height = 'auto';
    //         textarea.style.height = `${textarea.scrollHeight}px`;
    //         textarea.style.minHeight = '100px';
    //     };
    
    //     const adjustHeightOnFocus = () => {
    //         textarea.style.height = '100px';
    //         // textarea.style.height = `${textarea.scrollHeight}px`;
    //     };
    
    //     const resetHeightOnBlur = () => {
    //         textarea.style.height = 'auto';
    //         textarea.style.minHeight = `${textarea.scrollHeight}px`;
    //     };
    
    //     const handleFocus = () => {
    //         adjustHeightOnFocus();
    //     };
    
    //     const handleInput = () => {
    //         adjustHeight();
    //     };
    
    //     const handleBlur = () => {
    //         resetHeightOnBlur();
    //     };
    
    //     textarea.addEventListener('focus', handleFocus);
    //     textarea.addEventListener('input', handleInput);
    //     textarea.addEventListener('blur', handleBlur);
    
    //     return () => {
    //         textarea.removeEventListener('focus', handleFocus);
    //         textarea.removeEventListener('input', handleInput);
    //         textarea.removeEventListener('blur', handleBlur);
    //     };
    // }, []);
    return (
        <>
            {/* <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-12">
                    <div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                asd
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row m-0 w-100" style={{ marginTop: '20px' }}>
                <div className="col-12 col-md-12">
                    <div className="section-head">
                        <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', marginLeft: '-10px' }}>
                            {edit && (purchaseOrder?.purchaseOrderId)}
                        </h2>
                        <div className="actions">
                            {!edit ? (
                                <>
                                    <PermissionAllowed permissions={['Create Purchase Order']}>
                                        <button
                                            type="button"
                                            onClick={() => savePurchaseOrder()}
                                            className="btn btn-primary btn-outlined-main"
                                            disabled={isInternalLoading || isSaveButtonDisabled}
                                        >
                                            { isSaveButtonDisabled ? (<><FaSpinner className="fa-spin" /> Saving...</>) : (<><FaSave /> Save</>) }  
                                        </button>
                                    </PermissionAllowed>
                                </>
                            ) : (
                                <PermissionAllowed permissions={['Edit Purchase Order']}>
                                    <button
                                        type="button"
                                        onClick={() => updateSubmit()}
                                        className="btn btn-primary btn-outlined-main"
                                        disabled={isInternalLoading || isSaveButtonDisabled}
                                    >
                                        { isSaveButtonDisabled ? (<><FaSpinner className="fa-spin" /> Updating...</>) : (<><FaSave /> Update</>) }        
                                    </button>
                                </PermissionAllowed>
                            )}
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
                            <div className="row">
                                <div className="col-md-12 col-lg-12">


                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Reference Number</div>
                                            <div style={{ width: '300px', marginLeft: '10px', textAlign: 'left' }}>
                                                {
                                                    edit ? (
                                                        <>
                                                            {/* {purchaseOrder?.referenceNo} */}
                                                            <ReactSelectCreatable
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                options={salesOrderList}
                                                                onChange={(e) => {
                                                                    handlePurchaseOrderInfo({
                                                                        ...purchaseOrder,
                                                                        purchaseInvoiceDetails: e
                                                                    });
                                                                    console.log('e', e)
                                                                }}
                                                                isMulti={true}
                                                                value={
                                                                    purchaseOrder?.purchaseInvoiceDetails?.map(detail => {
                                                                        const existingOption = salesOrderList?.find(option => option?.label === detail?.reference_no);
                                                                        return existingOption || { label: detail?.label, value: detail?.label };
                                                                    }) || []
                                                                }
                                                                menuPlacement="auto"
                                                                menuPosition="fixed"
                                                                placeholder="Select or type..."
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ReactSelectCreatable
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                options={salesOrderList}
                                                                onChange={(e) => {
                                                                    handlePurchaseOrderInfo({
                                                                        ...purchaseOrder,
                                                                        referenceNo: e
                                                                    });
                                                                }}
                                                                isMulti={true}
                                                                // value={
                                                                // 	item.model
                                                                // 		? item?.modelOptions?.find((v) => v?.value == item.model)
                                                                // 		: { label: item.customValue, value: item.customValue }
                                                                // }
                                                                menuPlacement="auto"
                                                                menuPosition="fixed"
                                                                placeholder="Select or type..."
                                                            />
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Supplier</div>
                                            <div style={{ width: '300px', marginLeft: '10px' }}>


                                                {loaderStatus?.customers || editDataLoader ? (
                                                    <Skeleton
                                                        style={{
                                                            minHeight: '32px',
                                                            height: '32px',
                                                            transformOrigin: '0 0',
                                                            transform: 'scale(1,1)',
                                                            width: '250px'
                                                        }}
                                                    ></Skeleton>
                                                ) : (
                                                    <ReactSelect
                                                        className="rs-container"
                                                        classNamePrefix="rs"
                                                        name="customer"
                                                        options={supplierList}
                                                        value={supplierList?.find((c) => c?.value == purchaseOrder?.supplierId)}
                                                        // onChange={(selected) => handleCustomerinfo(selected)}
                                                        onChange={(e) => {
                                                            handlePurchaseOrderInfo({
                                                                ...purchaseOrder,
                                                                supplierId: e?.value
                                                            });
                                                        }}
                                                        menuPlacement="auto"
                                                        menuPosition="fixed"
                                                        placeholder="Select"
                                                        style={{ borderRadius: '18px' }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Expected Delivery Date</div>
                                            <div style={{ width: '300px', marginLeft: '10px' }}>


                                                <input
                                                    type="date"
                                                    value={purchaseOrder?.expectedDate}
                                                    style={{ cursor: 'pointer' }}
                                                    className="form-control form-inp"
                                                    onClick={(e) => e.target.focus()}
                                                    onChange={(e) => {
                                                        handleChangeCurrency(e?.target?.value)
                                                        // handlePurchaseOrderInfo({
                                                        //     ...purchaseOrder,
                                                        //     expectedDate: e?.target?.value
                                                        // });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Warehouse</div>
                                            <div style={{ width: '300px', marginLeft: '10px' }}>


                                                <ReactSelect
                                                    className="rs-container"
                                                    classNamePrefix="rs"
                                                    name="transit-company"
                                                    options={warehouseList}
                                                    value={warehouseList?.find((c) => c?.value == purchaseOrder?.warehouseId)}
                                                    onChange={(e) => {
                                                        handlePurchaseOrderInfo({
                                                            ...purchaseOrder,
                                                            warehouseId: e?.value
                                                        });
                                                    }}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    placeholder="Select"
                                                    style={{ borderRadius: '18px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Currency</div>
                                            <div style={{ maxWidth: '300px', marginLeft: '10px' }}>

                                                <Select
                                                    className="rs-container-est-white"
                                                    classNamePrefix="rs"
                                                    isSearchable={true}
                                                    name="customer"
                                                    options={currencies}
                                                    value={currencies?.find((c) => c?.value == purchaseOrder?.currency)}
                                                    // onChange={(e) => {
                                                    //     handlePurchaseOrderInfo({
                                                    //         ...purchaseInvoice,
                                                    //         currency: e.value
                                                    //     });
                                                    //     setCurrency(e);
                                                    // }}
                                                    onChange={(e) => {
                                                        handlePurchaseOrderInfo({
                                                            ...purchaseOrder,
                                                            currency: e?.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Payment Method</div>
                                            <div style={{ width: '300px', marginLeft: '10px' }}>

                                                <Select
                                                    className="rs-container-est-white"
                                                    classNamePrefix="rs"
                                                    isSearchable={true}
                                                    name="customer"
                                                    options={paymentMethods}
                                                    value={paymentMethods?.find((c) => c?.value == purchaseOrder?.paymentMethod)}
                                                    onChange={(e) => {
                                                        handlePurchaseOrderInfo({
                                                            ...purchaseOrder,
                                                            paymentMethod: e?.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-top form-group">
                                        <div className="col-12" style={{ display: 'flex', textAlign: 'right' }} >
                                            <div className="textGray" style={{ width: '150px' }}>Notes</div>
                                            <div style={{textAlign: 'left'}}>
                                                <div style={{ width: '300px', marginLeft: '10px' }}>

                                                    {/* <textarea
                                                        ref={textareaRef}
                                                        className='form-control'
                                                        // rows="5"
                                                        value={purchaseOrder?.notes}
                                                        onChange={(e) => {
                                                            handlePurchaseOrderInfo({
                                                                ...purchaseOrder,
                                                                notes: e?.target?.value
                                                            });
                                                        }}
                                                        // style={{ resize: 'none' }}
                                                    /> */}

                                                    <MentionInput
                                                        onContentChange={(e, x, y) => {
                                                            setNoteInputValue(JSON.stringify(e));
                                                            setNoteInputPlain(x);
                                                            setNoteMentions(y);
                                                            handlePurchaseOrderInfo({
                                                                ...purchaseOrder,
                                                                notes: x
                                                            })
                                                        }}
                                                        clear={clearInput}
                                                        inputFocus={inputFocus}
                                                        setInputFocus={(e) => {
                                                            setInputFocus(e);
                                                        }}
                                                        triggerSave={() => {
                                                            // if(noteInputPlain.length > 0) {
                                                            //     // addNote && addNote(noteInputValue, selectedNoteType, noteMentions, noteInputPlain);
                                                            //     // clearInputField();
                                                            // }
                                                        }}
                                                        enableMention={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="productsTable estlist" style={{ fontSize: '12px' }}>
                                        <div className="table-responsive-xl tableData">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h2 className="font500 font18 font500">Products</h2>
                                                    </div>
                                                    {
                                                        hasProducts && (
                                                            <div className="col-6 text-right" style={{ display: 'flex', justifyContent: 'right', marginTop: '-10px' }}>
                                                                <button type="button" className="btn btn-primary btn-outlined-main" onClick={handleSelectRow}>
                                                                    <FaPlus />
                                                                    Add Products
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                !hasProducts ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="add-item-empty-div">

                                                                    <button type="button" className="btn btn-primary btn-outlined-main-primary" onClick={handleSelectRow}>
                                                                        <FaPlus />
                                                                        Add Products
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>

                                                        <table className="table table-md text-gray-900 formActBtns border-0" style={{ marginTop: '10px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="align-middle" style={{ width: '5%' }}></th>
                                                                    <th className="align-middle" style={{ width: '45%' }}>Name</th>
                                                                    <th className="align-middle" style={{ width: '12%' }}>Free Stock</th>
                                                                    <th className="align-middle" style={{ width: '12%' }}>Ordered</th>
                                                                    <th className="align-middle" style={{ width: '12%' }}>Price</th>
                                                                    <th className="align-middle" style={{ width: '12%' }}>Total</th>
                                                                    <th className="align-middle text-center" style={{ width: '2%' }}></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {selectedRowsData?.length > 0 ? (
                                                                    selectedRowsData?.map((row, index) => (
                                                                        <>
                                                                            <tr key={row?.product_id}>
                                                                                <td className="align-middle estimateCName">
                                                                                    {
                                                                                        row?.thumbnail_url ? (
                                                                                            <img src={row?.thumbnail_url} style={{ width: '30px' }} alt="product" />
                                                                                        ) : (
                                                                                            <img src={NoImage} style={{ width: '30px' }} alt="product" />
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                <td className="align-middle estimateCName">
                                                                                    <div className="">{row?.product_name || row.product}</div>
                                                                                    <div className='textGray'>
                                                                                        {row?.sku}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle estimateCName">
                                                                                    {row?.free_stock} {parseInt(row?.purchased_quantity) > 0 && (<><Tooltip title={<span style={{ fontSize: "13px" }}>Already purchased</span>} arrow placement="top"><span className="textGray">+ <span>{row?.purchased_quantity}</span></span></Tooltip></>)}
                                                                                </td>
                                                                                <td className="align-middle ">
                                                                                    <input
                                                                                        className="numField"
                                                                                        type="number"
                                                                                        value={row?.quantity}
                                                                                        maxLength={10}
                                                                                        onChange={(e) => {
                                                                                            if (row.product_id) {
                                                                                                const newVal = e.target.value;
                                                                                                if (newVal.length <= 10) {
                                                                                                    handleQuantityChange(row.product_id, newVal);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        style={{ paddingInline: '3px' }}
                                                                                        onFocus={(event) => {
                                                                                            // event.target.select();
                                                                                            if (event.target.value === '0') {
                                                                                                event.target.value = '';
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td className="align-middle">

                                                                                    <div class="input-group" style={{ display: 'flex' }}>
                                                                                        <div class="input-group-prepend">
                                                                                            <span class="input-group-text" id="basic-addon1">

                                                                                                {purchaseOrder?.currency === 'usd'
                                                                                                    ? '$'
                                                                                                    : purchaseOrder?.currency === 'eur'
                                                                                                        ? '€'
                                                                                                        : purchaseOrder?.currency === 'aed'
                                                                                                            ? 'AED'
                                                                                                            : purchaseOrder?.currency
                                                                                                }
                                                                                            </span>
                                                                                        </div>

                                                                                        <input
                                                                                            className="numField form-control"
                                                                                            type="number"
                                                                                            value={row?.converted_currency_price[purchaseOrder?.currency]}
                                                                                            placeholder="0"
                                                                                            maxLength={10}
                                                                                            // onChange={e => handleQuantityChange(index, e.target.value)}
                                                                                            // onChange={e => handlePurchasePriceChange(e, item, index)}

                                                                                            onChange={(e) => {
                                                                                                if (row.product_id) {
                                                                                                    const newVal = e.target.value;
                                                                                                    // if (newVal.length <= 6) {
                                                                                                    handleUnitPriceChange(row.product_id, newVal);
                                                                                                    // }
                                                                                                }
                                                                                            }}
                                                                                            style={{ paddingInline: '3px' }}
                                                                                            onFocus={(event) => {
                                                                                                // event.target.select();
                                                                                                if (event.target.value === '0') {
                                                                                                    event.target.value = '';
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>



                                                                                    {/* <input
                                                                                        className="numField"
                                                                                        type="number"
                                                                                        value={row?.price}
                                                                                        onChange={(e) => {
                                                                                            if (row.product_id) {
                                                                                                const newVal = e.target.value;
                                                                                                if (newVal.length <= 6) {
                                                                                                    handleUnitPriceChange(row.product_id, newVal);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        style={{ width: '60px', paddingInline: '3px' }}
                                                                                        onFocus={(event) => {
                                                                                            // event.target.select();

                                                                                            if (event.target.value == '0.00' || event.target.value == '0') {
                                                                                                // handleUnitPriceChange(index, '');
                                                                                            }
                                                                                            event.target.select();
                                                                                        }}
                                                                                    /> */}
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    {purchaseOrder?.currency === 'usd' ? '$ ' : purchaseOrder?.currency === 'aed' ? 'AED ' : '€ '}{' '}
                                                                                    {
                                                                                        parseFloat(row.converted_currency_total[purchaseOrder?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'
                                                                                    }
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    {row.product_id ? (
                                                                                        <span 
                                                                                            style={{ cursor: 'pointer' }} 
                                                                                            onClick={() => {
                                                                                                removeRow(row?.id, row.product_id)
                                                                                            }}
                                                                                        >
                                                                                            <svg
                                                                                                id="vuesax_linear_trush-square"
                                                                                                data-name="vuesax/linear/trush-square"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width="20"
                                                                                                height="20"
                                                                                                viewBox="0 0 31.855 31.855"
                                                                                            >
                                                                                                <g id="trush-square">
                                                                                                    <path
                                                                                                        id="Vector"
                                                                                                        d="M0,17.255V9.291C0,2.655,2.655,0,9.291,0h7.964c6.637,0,9.291,2.655,9.291,9.291v7.964c0,6.637-2.655,9.291-9.291,9.291H9.291C2.655,26.546,0,23.891,0,17.255Z"
                                                                                                        transform="translate(2.655 2.655)"
                                                                                                        fill="none"
                                                                                                        stroke="#ff342d"
                                                                                                        stroke-linecap="round"
                                                                                                        stroke-linejoin="round"
                                                                                                        stroke-width="2"
                                                                                                    />
                                                                                                    <g id="Group" transform="translate(8.097 8.548)">
                                                                                                        <path
                                                                                                            id="Vector-2"
                                                                                                            data-name="Vector"
                                                                                                            d="M0,.438,1.779.265A51.533,51.533,0,0,1,6.942,0a86.992,86.992,0,0,1,8.72.438"
                                                                                                            transform="translate(0 3.026)"
                                                                                                            fill="none"
                                                                                                            stroke="#ff342d"
                                                                                                            stroke-linecap="round"
                                                                                                            stroke-linejoin="round"
                                                                                                            stroke-width="2"
                                                                                                        />
                                                                                                        <path
                                                                                                            id="Vector-3"
                                                                                                            data-name="Vector"
                                                                                                            d="M0,2.588.186,1.447C.319.624.425,0,1.9,0H4.181C5.654,0,5.76.65,5.893,1.447l.186,1.128"
                                                                                                            transform="translate(4.792)"
                                                                                                            fill="none"
                                                                                                            stroke="#ff342d"
                                                                                                            stroke-linecap="round"
                                                                                                            stroke-linejoin="round"
                                                                                                            stroke-width="2"
                                                                                                        />
                                                                                                        <path
                                                                                                            id="Vector-4"
                                                                                                            data-name="Vector"
                                                                                                            d="M11.932,0l-.571,8.76c-.093,1.367-.173,2.429-2.6,2.429H3.172c-2.429,0-2.509-1.062-2.6-2.429L0,0"
                                                                                                            transform="translate(1.858 3.57)"
                                                                                                            fill="none"
                                                                                                            stroke="#ff342d"
                                                                                                            stroke-linecap="round"
                                                                                                            stroke-linejoin="round"
                                                                                                            stroke-width="2"
                                                                                                        />
                                                                                                    </g>
                                                                                                    <path id="Vector-5" data-name="Vector" d="M0,0H31.855V31.855H0Z" fill="none" opacity="0" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                ) : (
                                                                    <tr className="no-hover">
                                                                        <td colSpan={9}>
                                                                            <div className="not-found-container cstm-not-found">
                                                                                <div className="not-found-icon">
                                                                                    <TbDatabaseExclamation />
                                                                                </div>
                                                                                <h6 className="not-found-text">No Products Selected</h6>
                                                                                <button type="button" className="btn btn-primary btn-outlined-main" onClick={handleSelectRow}>
                                                                                    <FaPlus />
                                                                                    Add Products
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                            {selectedRowsData?.length > 0 ? (
                                                                <tfoot>
                                                                    <tr className="tr-nth">
                                                                        <td className="align-middle estimateCName" colSpan={3}></td>
                                                                        <td className="text-center">
                                                                            <label>{totalQuantity} pcs</label>
                                                                        </td>
                                                                        <td className="align-middle">Subtotal</td>
                                                                        <td className="align-middle">
                                                                            <label>
                                                                                {purchaseOrder?.currency === 'usd' ? ' $ ' : purchaseOrder?.currency === 'aed' ? ' AED ' : ' € '}{' '}
                                                                                {/* {totalPrice ? totalPrice?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0} */}
                                                                                {parseFloat(convertedTotalPrice[purchaseOrder?.currency]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                                            </label>
                                                                        </td>
                                                                        <td className="align-middle text-center"></td>
                                                                    </tr>
                                                                    {/* <tr className="tr-nth">
                                                                        <td className="align-middle estimateCName" colSpan={4}></td>
                                                                        <td className="align-middle">Discount</td>
                                                                        <td className="align-middle">
                                                                            
                                                                            <div className="custom-input-group payment-input-group">
                                                                                <InputGroup className="mb-3 d-flex">
                                                                                    <DropdownButton
                                                                                        variant="outline-secondary"
                                                                                        title={defaultDiscountValue}
                                                                                        id="input-group-dropdown-1"
                                                                                    >
                                                                                        <Dropdown.Item 
                                                                                            href="#"
                                                                                            // key={}
                                                                                        >
                                                                                            %
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item 
                                                                                            href="#"
                                                                                            // key={}
                                                                                        >
                                                                                            {currencies?.find((c) => c?.value == purchaseOrder?.currency)?.label}
                                                                                        </Dropdown.Item>
                                                                                    </DropdownButton>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control"
                                                                                        // id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                                                        value={purchaseOrder?.currency}
                                                                                        onFocus={(event) => {
                                                                                            if (event.target.value == '0.00' || event.target.value == '0') {
                                                                                                setAmountToPay('');
                                                                                            }
                                                                                            event.target.select();
                                                                                        }}
                                                                                        autoComplete={'off'}
                                                                                        autoFocus
                                                                                    />
                                                                                </InputGroup>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle text-center"></td>
                                                                    </tr> */}
                                                                    <tr className="">
                                                                        <td className="align-middle estimateCName" colSpan={4}></td>
                                                                        <td className="align-middle">Shipping</td>
                                                                        <td className="align-middle">
                                                                            
                                                                            <div class="input-group" style={{ display: 'flex' }}>
                                                                                <div class="input-group-prepend">
                                                                                    <span class="input-group-text" id="basic-addon1">

                                                                                        {purchaseOrder?.currency === 'usd'
                                                                                            ? '$'
                                                                                            : purchaseOrder?.currency === 'eur'
                                                                                                ? '€'
                                                                                                : purchaseOrder?.currency === 'aed'
                                                                                                    ? 'AED'
                                                                                                    : purchaseOrder?.currency
                                                                                        }
                                                                                    </span>
                                                                                </div>

                                                                                <input
                                                                                    className="form-control"
                                                                                    type="number"
                                                                                    value={`${purchaseOrder?.shippingFee}`}
                                                                                    placeholder="0"
                                                                                    maxLength={10}
                                                                                    // onChange={e => handleQuantityChange(index, e.target.value)}
                                                                                    // onChange={e => handlePurchasePriceChange(e, item, index)}

                                                                                    onChange={(e) => {
                                                                                        const value = e?.target?.value ? e?.target?.value : 0;
                                                                                        setPurchaseOrder({
                                                                                            ...purchaseOrder,
                                                                                            shippingFee: value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle text-center"></td>
                                                                    </tr>
                                                                    <tr className="">
                                                                        <td className="align-middle estimateCName" colSpan={4}></td>
                                                                        <td className="align-middle">Total</td>
                                                                        <td className="align-middle">
                                                                            <label>
                                                                                {purchaseOrder?.currency === 'usd' ? ' $ ' : purchaseOrder?.currency === 'aed' ? ' AED ' : ' € '}{' '}
                                                                                {/* {totalPrice ? totalPrice?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0} */}
                                                                                {
                                                                                    (parseFloat(convertedTotalPrice[purchaseOrder?.currency]) + parseFloat(purchaseOrder?.shippingFee ?? 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                                                                } 
                                                                            </label>
                                                                        </td>
                                                                        <td className="align-middle text-center"></td>
                                                                    </tr>
                                                                </tfoot>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </table>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {alertMessage?.success && <span className="px-3 down-error text-success">{alertMessage?.success}</span>}
                                {alertMessage?.failed && <span className="px-3 down-error text-danger">{alertMessage?.failed}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="section-head" style={{ marginTop: '-17px'}}>
                        <h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', marginLeft: '-10px' }}></h2>
                        <div className="actions">
                            {!edit ? (
                                <>
                                    <PermissionAllowed permissions={['Create Purchase Order']}>
                                        <button
                                            type="button"
                                            onClick={() => savePurchaseOrder()}
                                            className="btn btn-primary btn-outlined-main"
                                            disabled={isInternalLoading || isSaveButtonDisabled}
                                        >
                                            { isSaveButtonDisabled ? (<><FaSpinner className="fa-spin" /> Saving...</>) : (<><FaSave /> Save</>) }        
                                        </button>
                                    </PermissionAllowed>
                                </>
                            ) : (
                                <PermissionAllowed permissions={['Edit Purchase Order']}>
                                    <button
                                        type="button"
                                        onClick={() => updateSubmit()}
                                        className="btn btn-primary btn-outlined-main"
                                        disabled={isInternalLoading || isSaveButtonDisabled}
                                    >
                                        { isSaveButtonDisabled ? (<><FaSpinner className="fa-spin" /> Updating...</>) : (<><FaSave /> Update</>) }        
                                    </button>
                                </PermissionAllowed>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {openPopup && (
                <Modal show={openPopup} onHide={closeFound} centered size="lg">
                    <div>
                        <SelectionProvider>
                            <ProductList
                                selectedItems={selectedRowsData}
                                close={handleDoneClick}
                                currency={purchaseOrder?.currency}
                                handleClose={closePopup} />
                        </SelectionProvider>
                    </div>
                </Modal>
            )}
            {showDeleteModal && (
                <DeleteConfirmation
                    show={showDeleteModal}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to remove this product?`}
                    customDeleteButtonText={`Yes, Remove product`}
                />
            )}
        </>
    );
};

export default PurchasingForm;

import { setIn } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select'; // replace with actual import
import { IconArrowsExchange } from '@tabler/icons'; // replace with actual import

import { Tooltip } from '@mui/material';
import { FaSave, FaTimes } from 'react-icons/fa';
import { FaCheck, FaPen } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import us from 'assets/images/dbDummy/us.png';
import eu from 'assets/images/dbDummy/eu.png';
import gbp from 'assets/images/dbDummy/ae.png';

import { getCurrencySymbol, numberFormatter } from 'utils/currency';

const ProductModal = ({ onClose, onSubmit, currency = 'eur', preLoadedData, orderType, setOrderType, vendor, products, purchaseOrder }) => {
	const compRef = useRef(null);

	// ## Invoice Data Handling ## //
	const [items, setItems] = useState([
		{
			seqId: 1,
			qty: 1,
			model: '',
			vat: 1,
			unitPrice: 0,
			comment: '',
			commentToggle: false,
			invoice: '',
			modelOptions: [],
			modelOptionsLoading: false,
			customValue: ''
		}
	]);

	// ## To close the popup ## //
	const handleClose = () => {
		onClose && onClose();
	};

	// ## To close the popup when clicked outside ## //
	const handleClickOutside = (event) => {
		if (compRef.current && !compRef.current.contains(event.target)) {
			// handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	// ## Dropdown Data ## //
	const [models, setModels] = useState([]);
	const [invoiceList, setInvoiceList] = useState([]);
	const vatTypes = [{ label: 'Incl. VAT', value: 1, percentage: 0 }];
	const [amount, setAmount] = useState(1.0);
	const [fromCurrency, setFromCurrency] = useState('USD');
	const [toCurrency, setToCurrency] = useState('EUR');
	const [result, setResult] = useState(0);
	const getSelectOptions = () => {
		// Fetch Models
		// api.getSelectOptionsList('product_stocks').then((res) => {
		// 	// option_product_name: "11P256MIXAA+ - iPhone 11 Pro 256GB Mixed AA+ - 0 stock"
		// 	const tempList = res.data.map((item) => ({
		// 		...item,
		// 		label: `${item.option_product_name}`,
		// 		value: item.id,
		// 	}));
		// 	setModels(tempList);

		// 	// console.log('preaded data', purchaseOrder)
		// 	console.log('preaded data', preLoadedData)
		// });
		setModels(products);

		// api.getSelectOptionsList('invoice').then((res) => {
		// 	const tempList = res.data.map((item) => ({
		// 		...item,
		// 		label: item.invoice_number,
		// 		value: item.id
		// 	}));
		// 	setInvoiceList(tempList);
		// });
	};
	const [exchangeRate, setExchangeRate] = useState([]);
	const currencyOptions = [
		{ value: 'USD', label: 'USD', image: us },
		{ value: 'EUR', label: 'EUR', image: eu },
		{ value: 'AED', label: 'AED', image: gbp }
	];
	useEffect(() => {
		const today = new Date().toISOString().split('T')[0];
		const cachedExchangeRate = localStorage.getItem('exchangeRate');
		const cacheDate = localStorage.getItem('cacheDate');

		if (cachedExchangeRate && cacheDate === today) {
			setExchangeRate(JSON.parse(cachedExchangeRate));
		} else {
			api.getExchangeRate().then((res) => {
				const newExchangeRate = res;
				setExchangeRate(newExchangeRate);
				localStorage.setItem('exchangeRate', JSON.stringify(newExchangeRate));
				localStorage.setItem('cacheDate', today);
			});
		}
	}, []);

	useEffect(() => {
		// Convert the exchangeRate array into an object
		const exchangeRateObj = exchangeRate.reduce((acc, curr) => {
			acc[curr.name] = curr.rate;
			return acc;
		}, {});

		const baseCurrencyRate = exchangeRateObj[fromCurrency];
		const conversionCurrencyRate = exchangeRateObj[toCurrency];
		const convertedAmount = (amount / baseCurrencyRate) * conversionCurrencyRate;
		setResult(convertedAmount);
	}, [amount, fromCurrency, toCurrency, exchangeRate]);

	const handleAmountChange = (event) => {
		setAmount(event.target.value);
	};

	const handleFromCurrencyChange = (selectedOption) => {
		setFromCurrency(selectedOption);
	};

	const handleToCurrencyChange = (selectedOption) => {
		setToCurrency(selectedOption);
	};

	const handleIconClick = () => {
		const temp = fromCurrency;
		setFromCurrency(toCurrency);
		setToCurrency(temp);
	};



	useEffect(() => {
		if (preLoadedData) {
			if (preLoadedData?.selections) {
				setModels(preLoadedData?.selections?.models);
				setInvoiceList(preLoadedData?.selections?.invoiceList);
			} else {
				getSelectOptions();
			}

			if (preLoadedData?.items?.length > 0) {
				const preloadedItems = preLoadedData?.items?.map((item) => ({
						...item,
						model: item?.model?.value,
						vat: item?.vat?.value
					})) || []
					
				// Define the structure of the empty row
				const emptyRow = {
					seqId: preloadedItems.length,
					qty: 1,
					model: '',
					vat: 1,
					unitPrice: 0,
					comment: '',
					commentToggle: false,
					invoice: '',
					modelOptions: [],
					modelOptionsLoading: false,
					customValue: ''
				};

				const updatedPreloadedItems = [...preloadedItems, emptyRow];

				setItems(updatedPreloadedItems);
			} else {
				console.log('purchaseOrder', purchaseOrder?.items)

				const purchaseOrderItems = purchaseOrder?.items?.map((item, index) => ({
					...item,
					seqId: index,
					qty: 1,
					model: item?.product?.id,
					vat: 1,
					unitPrice: item?.price,
					comment: '',
					commentToggle: false,
				}))

				// Define the structure of the empty row
				const emptyRow = {
					seqId: purchaseOrderItems.length,
					qty: 1,
					model: '',
					vat: 1,
					unitPrice: 0,
					comment: '',
					commentToggle: false,
					invoice: '',
					modelOptions: [],
					modelOptionsLoading: false,
					customValue: ''
				};

				const updatedPurchaseOrderItems = [...purchaseOrderItems, emptyRow];

				setItems(updatedPurchaseOrderItems);
				// setItems(purchaseOrderItems);
				// console.log('ti', purchaseOrderItems?.length)
				// handleModelChange(updatedPurchaseOrderItems?.length, null)
			}


			setDiscount(preLoadedData?.discount || { discountType: 'percentage', discountValue: 0 });
		} else {
			getSelectOptions();
		}
	}, []);

	// const [shippingFee, setShippingFee] = useState(0);
	// const [transitFee, setTransitFee] = useState(0);
	const [discount, setDiscount] = useState({
		discountType: 'percentage', // percentage, amount
		discountValue: 0
	});
	const shippingQty = 1;

	const newRowTemplate = {
		seqId: items?.length + 1,
		qty: 1,
		model: '',
		vat: 1,
		unitPrice: 0,
		comment: '',
		commentToggle: false,
		invoice: '',
		modelOptions: [],
		modelOptionsLoading: false,
		customValue: ''
	};

	useEffect(() => {
		setItems((oldData) =>
			oldData.map((item) => ({
				...item,
				// qty: item?.qty > -1 ? item.qty * -1 : item?.qty
				qty: item?.qty
			}))
		);
	}, [orderType]);

	useEffect(() => {
		if (orderType != 'estimate') {
			if (items?.[0]?.qty < 0 && orderType != 'credit-note') {
				setOrderType('credit-note');
			} else if (items?.[0]?.qty >= 0 && orderType == 'credit-note') {
				setOrderType('invoice');
			}
		}
	}, [items?.[0]?.qty]);

	useEffect(() => {
		if (orderType != 'credit-note' && items?.every((it) => !!it.model)) {
			setItems((oldData) => [...oldData, newRowTemplate]);
		} else if (orderType == 'credit-note' && items?.every((it) => !!it.invoice)) {
			setItems((oldData) => [...oldData, newRowTemplate]);
		}
	}, [items]);

	const [invalidData, setInvalidData] = useState(false);
	const [invalidDataMessage, setInvalidDataMessage] = useState('');
	useEffect(() => {
		const itemsCopy = [...items];
		itemsCopy.pop();
		if (itemsCopy?.length <= 0) {
			setInvalidData(true);
			setInvalidDataMessage('Please add at least one item');
		// } else if (orderType == 'credit-note' && itemsCopy?.some((it) => it.qty > -1)) {
		// 	setInvalidData(true);
		// 	setInvalidDataMessage('Credit Note items should have negative quantity');
		// } else if (orderType != 'credit-note' && itemsCopy?.some((it) => it.qty < 1)) {
		// 	setInvalidData(true);
		// 	setInvalidDataMessage('Invoice items should have positive quantity');
		} else if (itemsCopy?.some((it) => !it.model)) {
			setInvalidData(true);
			setInvalidDataMessage('Please select a model for all items');
		} else if (itemsCopy?.some((it) => !it.unitPrice)) {
			setInvalidData(true);
			setInvalidDataMessage('Please enter unit price for all items');
		// } else if (orderType == 'credit-note' && itemsCopy?.some((it) => !it.invoice)) {
		// 	setInvalidData(true);
		// 	setInvalidDataMessage('Please select an invoice for all items');
		} else {
			setInvalidData(false);
			setInvalidDataMessage('');
		}
	}, [items]);

	// ## Handle Quantity Change ## //

	const handleQtyChange = (index, value) => {
		setItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, qty: value } : item)));
	};

	// ## Handle Unit Price Change ## //

	const handleUnitPriceChange = (index, value) => {
		setItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, unitPrice: value } : item)));
	};

	// ## Handle Comment Change ## //

	const handleCommentChange = (index, value) => {
		setItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, comment: value } : item)));
	};

	// ## Handle Add Comment Toggle ## //
	const handleAddCommentToggle = (index, status) => {
		setItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, commentToggle: status } : item)));
	};

	// ## Handle Model Change ## //
	// const handleModelChange = (index, e) => {
	// 	// if (isCreditModal) {
	// 	// 	const unitPrice = e?.converted_currency_unit_price?.[currency] || 0;
	// 	// 	setItems((oldData) =>
	// 	// 		oldData.map((item, i) =>
	// 	// 			i === index ? { ...item, model: e.value, unitPrice, customValue: e.label && !e.product_id ? e.label : null } : item
	// 	// 		)
	// 	// 	);
	// 	// } else {
	// 	const test = items?.map((item, i) => {
			
	// 			// console.log('itemqq', i, item)		
	// 			if (i === index) {
	// 				console.log('itemqq', i, { ...item, model: e.value, unitPrice: e.price })
	// 			} else {
	// 				console.log('itemszzz', i, item)

	// 			}
	// 	})
	// 	console.log('add another roiw', index)
	// 		setItems((oldData) => oldData.map((item, i) => (
	// 			i === index ? { ...item, model: e.value, unitPrice: e.price } : item
	// 		)));
	// 	// }
	// };
	const handleModelChange = (index, e) => {
		// Define the structure of the empty row
		const emptyRow = {
			seqId: items.length + 1,
			qty: 1,
			model: '',
			vat: 1,
			unitPrice: 0,
			comment: '',
			commentToggle: false,
			invoice: '',
			modelOptions: [],
			modelOptionsLoading: false,
			customValue: ''
		};
	
		
	
		// Update the selected item and conditionally append the empty row
		setItems((oldData) => {
			const updatedData = oldData.map((item, i) => (
				
				i === index ? { ...item, model: e.value, unitPrice: e?.price || '0.00' } : item
			));
	
			// Check if the last row is empty
			const lastRow = updatedData[updatedData.length - 1];
			const isLastRowEmpty = !lastRow.model && lastRow.qty === 1 && lastRow.unitPrice === 0;
	
			// Append the empty row only if the last row is not empty
			return isLastRowEmpty ? updatedData : [...updatedData, emptyRow];
		});
	
		
	};

	// ## Handle Invoice Change ## //
	// const handleInvoiceChange = (index, e) => {
	// 	setItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, invoice: e.value, modelOptionsLoading: true } : item)));

	// 	api.getEstimateProducts(e.value).then((res) => {
	// 		setItems((oldData) =>
	// 			oldData.map((item, i) =>
	// 				i === index
	// 					? {
	// 						...item,
	// 						invoice: e.value,
	// 						modelOptionsLoading: false,
	// 						modelOptions: res?.data?.map((r) => ({ ...r, label: r?.product?.product_name, value: r?.product_id })) || []
	// 					}
	// 					: item
	// 			)
	// 		);
	// 	});
	// };

	// ## Handle VAT Change ## //
	const handleVatChange = (index, e) => {
		setItems((oldData) => oldData.map((item, i) => (i === index ? { ...item, vat: e.value } : item)));
	};

	// ## Handle Remove Inv Item ## //
	const handleRemoveItem = (index) => {
		setItems((oldData) => oldData.filter((item, i) => i != index));
	};

	// ## Handle Shipping Change ## //
	// const handleShippingChange = (e) => {
	// 	// setShippingFee(e.target.value);
	// };

	// const handleTransitFeeChange = (e) => {
	// 	setTransitFee(e.target.value);
	// };

	// ## Handle Submit ## //
	const handleSubmit = () => {
		const preparedData = items.map((item) => ({
			...item,
			model:
				models?.find((m) => m.value == item.model),
			// invoice: invoiceList?.find((inv) => inv.value == item.invoice),
			vat: vatTypes?.find((v) => v.value == item.vat)
		}));
		// console.log('items submit', items)
		// console.log('items submitx', preparedData)
		// return false;

		preparedData.pop();

		onSubmit &&
			onSubmit({
				items: preparedData,
				// shippingFee,
				discount,
				selections: {
					// invoiceList,
					models
				}
			});
	};

	const subtotal = items?.reduce((acc, item) => acc + Number(item?.unitPrice) * Number(item.qty), 0) || 0;
	const discountTotal = discount.discountType == 'percentage' ? (subtotal * discount.discountValue) / 100 : discount.discountValue;
	// const total = Number(subtotal) + Number(shippingFee) * Number(shippingQty) - (orderType != 'credit-note' ? discountTotal : 0);
	const total = Number(subtotal) * Number(shippingQty) - (orderType != 'credit-note' ? discountTotal : 0);

	return (
		<div className="popup contact-popup product-popup d-none d-md-block" ref={compRef}>
			<div className="d-flex TableSerachBar align-items-center justify-content-between mb-2 ml-0 pl-0">
				<h5 className="h5 mb-0  font-weight-bold flex-grow-1 pt-0"></h5>
				<button className="btn modal-close-icon" onClick={handleClose}>
					<svg id="Component_127_1" height="16" width="16" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
						<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
					</svg>
				</button>
			</div>

			<div className="row m-0 p-0">
				<table className="table inv-prods-add-table">
					<thead>
						<tr>
							<th className="qty-col">Qty</th>
							<th className="model-col">Model</th>
							<th className="vat-col">VAT</th>
							<th className="uprice-col">Price</th>
							<th className="tprice-col">Total</th>
							<th className="remove-col"></th>
						</tr>
					</thead>
					<tbody>
						{items.map((item, index) => (
							<>
								<tr key={item?.seqId}>
									<td className="qty-col">
										<input
											type="number"
											className="form-control"
											value={item.qty}
											onChange={(e) => {
												handleQtyChange(index, e.target.value);
											}}
											onFocus={(event) => {
												event.target.select();
											}}
										/>
									</td>
									<td className="model-col">
                                        <div>
                                            <ReactSelect
                                                className="rs-container"
                                                classNamePrefix="rs"
                                                options={models?.map((m) => ({
                                                    ...m,
                                                    optionAttribute: m.attribute,
                                                })).filter((m) => items?.every((it) => it.model != m.value) || m.value == item?.model)}
                                                value={models?.find((v) => v?.value == item?.model)}
                                                menuPlacement="auto"
                                                menuPosition="fixed"
                                                placeholder="Select or type..."
                                                formatOptionLabel={({ label, optionAttribute }) => (
                                                    <div dangerouslySetInnerHTML={{ __html: label }}></div>
                                                )}
                                                onChange={(e) => {
                                                    handleModelChange(index, e);
                                                }}
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: '800px', // Adjust the width as needed
                                                        marginLeft: '-73px',
                                                        height: '370px', // Set the maximum height of the dropdown box
                                                        overflowY: 'auto', // Enable vertical scrolling
                                                    }),
                                                    option: (provided, { selectProps, data }) => {
                                                        // Find the index of the current option
                                                        const index = selectProps.options.findIndex(option => option.value === data.value);
                                                        return {
                                                        ...provided,
                                                        marginBottom: '5px', // Existing space between options
                                                        marginTop: index === 0 ? '10px' : 0, // Add more spacing on top of the first option
                                                        };
                                                    },
                                                }}
                                            />
                                            <div>
                                                {item.commentToggle ? (
                                                    <div className="comment-input">
                                                        <input
                                                            value={item.comment}
                                                            onChange={(e) => {
                                                                handleCommentChange(index, e.target.value);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleAddCommentToggle(index, false);
                                                                }
                                                            }}
                                                        />
                                                        <button
                                                            className="cls"
                                                            onClick={() => {
                                                                handleCommentChange(index, '');
                                                                handleAddCommentToggle(index, false);
                                                            }}
                                                        >

                                                            <FaTimes />
                                                        </button>
                                                        <button className="save" onClick={() => handleAddCommentToggle(index, false)}>
                                                            <FaCheck />
                                                        </button>
                                                    </div>
                                                ) : item?.comment ? (
                                                    <p className="add-comment-link">
                                                        {item.comment}
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => {
                                                                handleAddCommentToggle(index, true);
                                                            }}
                                                            style={{ fontSize: '10px', marginLeft: '5px' }}
                                                        >
                                                            <FaPen />
                                                        </Link>
                                                    </p>
                                                ) : (
                                                    <Link
                                                        to={'#'}
                                                        className="add-comment-link"
                                                        onClick={() => {
                                                            handleAddCommentToggle(index, true);
                                                        }}
                                                    >
                                                        + Add Comment
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
									</td>
									<td className="vat-col">
										<ReactSelect
											className="rs-container"
											classNamePrefix="rs"
											options={vatTypes}
											value={vatTypes?.find((v) => v?.value == item.vat)}
											onChange={(e) => {
												handleVatChange(index, e);
											}}
											menuPlacement="auto"
											menuPosition="fixed"
										/>
									</td>
									<td className="uprice-col">
										<input
											type="number"
											className="form-control"
											value={item.unitPrice}
											onChange={(e) => {
												handleUnitPriceChange(index, e.target.value);
											}}
											// onFocus={(event) => {
											// 	event.target.select();
											// }}
											onFocus={(event) => {
												if (event.target.value == '0.00' || event.target.value == '0') {
													handleUnitPriceChange(index, '');
												}
												event.target.select();
											}}
										/>
									</td>
									<td className="tprice-col">
										<div className="d-flex align-items-center" style={{ height: '32px' }}>
											{/* {getCurrencySymbol(currency)} {numberFormatter.format(Number(item.unitPrice) * Number(item.qty))} */}
											{Number(item.unitPrice) * Number(item.qty) !== 0 ? `${getCurrencySymbol(currency)} ${numberFormatter.format(Number(item.unitPrice) * Number(item.qty))}` : `${getCurrencySymbol(currency)} 0.00`}
										</div>
									</td>
									<td className="remove-col">
										<div className="d-flex align-items-center" style={{ height: '32px' }}>
											<button
												className="btn btn-remove"
												disabled={index == items?.length - 1}
												onClick={() => {
													handleRemoveItem(index);
												}}
											>
												<svg id="Component_127_1" height="12" width="12" data-name="Component 127 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.295 19.289">
													<path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.219,20.933l6.891-6.891a1.615,1.615,0,0,0-2.284-2.284L20.935,18.65l-6.891-6.891a1.615,1.615,0,1,0-2.284,2.284l6.891,6.891L11.76,27.825a1.615,1.615,0,1,0,2.284,2.284l6.891-6.891,6.891,6.891a1.615,1.615,0,1,0,2.284-2.284Z" transform="translate(-11.285 -11.289)" />
												</svg>
											</button>
										</div>
									</td>
								</tr>
							</>
						))}

						<tr className="hr-row">
							<td colSpan={orderType == 'credit-note' ? 7 : 6}>
								<hr style={{ marginTop: '10px', marginBottom: '10px' }} />
							</td>
						</tr>

						<tr className="shipping-row">
							<td rowSpan={6} colSpan={2}>

								<div className="row">
									<div className="col-8">
										<div className="card">
											<div className="card-body">
												<h3>Currency Exchange</h3>
												<form onSubmit={(event) => event.preventDefault()} style={{ width: '100%', height: '100%' }}>
													<div className="form-group" style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
														<div>
															<label htmlFor="exampleFormControlSelect1">Amount</label>
															<input
																type="number"
																step="0.01"
																className="form-control"
																defaultValue={amount}
																onChange={handleAmountChange}
																style={{ width: 120 }}
															/>
														</div>
														<a style={{ fontSize: '13px', marginTop: '20px', marginLeft: '10px' }}>
															={' '}
															<strong>
																{result.toFixed(2)} {toCurrency}
															</strong>
														</a>
													</div>
													<div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
														<div>
															<label htmlFor="fromCurrency">From</label>
															<Select
																className="custom-form custom-form-1"
																id="fromCurrency"
																value={currencyOptions.find((option) => option.value === fromCurrency)}
																onChange={(selectedOption) => handleFromCurrencyChange(selectedOption.value)}
																options={currencyOptions}
																formatOptionLabel={({ image, label }) => (
																	<div>
																		<img src={image} height="15px" width="15px" style={{ borderRadius: '50%', marginRight: 5 }} />
																		{label}
																	</div>
																)}
																styles={{
																	control: (provided) => ({
																		...provided
																	}),
																	dropdownIndicator: (provided) => ({
																		...provided,
																		backgroundColor: 'transparent',
																		width: '10px' // Adjust this value as needed
																	}),
																	indicatorSeparator: () => ({
																		display: 'none'
																	})
																}}
															/>
														</div>
														<div style={{ paddingTop: '35px' }}>
															<IconArrowsExchange size={20} onClick={handleIconClick} />
														</div>
														<div>
															<label htmlFor="toCurrency">To</label>
															<Select
																className="custom-form custom-form-1"
																id="toCurrency"
																value={currencyOptions.find((option) => option.value === toCurrency)}
																onChange={(selectedOption) => handleToCurrencyChange(selectedOption.value)}
																options={currencyOptions}
																formatOptionLabel={({ image, label }) => (
																	<div>
																		<img src={image} height="15px" width="15px" style={{ borderRadius: '50%', marginRight: 5 }} />
																		{label}
																	</div>
																)}
																styles={{
																	control: (provided) => ({
																		...provided
																	}),
																	dropdownIndicator: (provided) => ({
																		...provided,
																		backgroundColor: 'transparent',
																		width: '10px' // Adjust this value as needed
																	}),
																	indicatorSeparator: () => ({
																		display: 'none'
																	})
																}}
															/>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>

                        <tr className="shipping-row">
                            <td className='text-right' style={{ float: 'right' }}>
                                <div>

                                    <ReactSelect
                                        options={[
                                            { label: 'Percentage', value: 'percentage' },
                                            { label: 'Amount', value: 'amount' }
                                        ]}
                                        value={[
                                            { label: 'Percentage', value: 'percentage' },
                                            { label: 'Amount', value: 'amount' }
                                        ].find((v) => v.value == discount.discountType)}
                                        onChange={(e) => {
                                            setDiscount({ ...discount, discountType: e.value });
                                        }}
                                        placeholder="Discount"
                                        className="rs-container"
                                        classNamePrefix="rs"
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '122px',
                                            }),
                                        }}
                                    />
                                </div>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    className="form-control"
                                    style={{ verticalAlign: 'middle' }}
                                    value={discount.discountValue}
                                    onChange={(e) => {
                                        setDiscount({ ...discount, discountValue: e.target.value });
                                    }}
                                    onFocus={(event) => {
                                        if (event.target.value == '0.00' || event.target.value == '0') {
                                            setDiscount({ ...discount, discountValue: '' });
                                        }
                                        event.target.select();
                                    }}
                                />
                            </td>
                            <td>
                                <div style={{ marginTop: '8px' }}>
                                    {/* {getCurrencySymbol(currency)} {numberFormatter.format(discountTotal)} */}
									{Number(discountTotal) !== 0 ? `${getCurrencySymbol(currency)} ${numberFormatter.format(Number(discountTotal))}` : `${getCurrencySymbol(currency)} 0.00`}
                                </div>
                            </td>
                            <td></td>
                        </tr>

						<tr>
							<td colSpan={2} className="text-right" style={{ verticalAlign: 'middle', fontWeight: 600 }}>
								Subtotal
							</td>
							<td colSpan="2" style={{ verticalAlign: 'middle', fontWeight: 600 }}>
								{getCurrencySymbol(currency || 'eur')} {numberFormatter.format(subtotal || 0)}
							</td>
						</tr>
						<tr>
							<th colSpan={2} className="text-right" style={{ verticalAlign: 'middle', fontWeight: 600 }}>
								Total
							</th>
							<td colSpan="2" style={{ verticalAlign: 'middle', fontWeight: 600 }}>
								{getCurrencySymbol(currency || 'eur')} {numberFormatter.format(total || 0)}
							</td>
						</tr>
						<tr>
							<td colSpan={4}>&nbsp;</td>
						</tr>
						{orderType == 'credit-note' && (
							<>
								<tr>
									<td colSpan={4}>&nbsp;</td>
								</tr>
								<tr>
									<td colSpan={4}>&nbsp;</td>
								</tr>
							</>
						)}
					</tbody>
				</table>
			</div>

			<div className="btnarea mt-3">
				<Tooltip title={invalidData ? invalidDataMessage : null} arrow placement="top">
					<button className="btn btn-main" onClick={handleSubmit} disabled={invalidData}>
						<FaSave /> Save
					</button>
				</Tooltip>
			</div>
		</div>
	);
};

export default ProductModal;

import React, { useState, useEffect } from 'react';
import { GridView, ViewList } from '@mui/icons-material';
import { FaPlus } from 'react-icons/fa';
import PaymentItem from './PaymentItem';
import Scroller from 'ui-component/Scroller';
import { Avatar, Skeleton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import PaymentFormModal from './PaymentFormModal';
import PaymentGrid from './PaymentGrid';
import PaymentDetailsModal from './PaymentDetailsModal';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import toast from 'react-hot-toast';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';

function PaymentsComponent({
	paymentData,
	setPaymentData,
	onAddPayment,
	onUpdatePayment,
	onDeletePayment,
	estimateDetails,
	showAddPayment,
	setShowAddPayment,
	toggleExpand,
	showOnlyModal = false, 
	source,
}) {
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
	const [editPayment, setEditPayment] = useState(null);
	const [showPayment, setShowPayment] = useState(null);
	const [deletePayment, setDeletePayment] = useState(null);
	const [viewLayout, setViewLayout] = useState('grid');
	const [selectedEstimate, setSelectedEstimate] = useState(null);
	const [defaultType, setDefaultType] = useState(null);
	const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);

	const handlePaymentModal = (show, shipment = null) => {
		setShowPaymentModal(show);
		setEditPayment(shipment);
		setShowAddPayment(show);
	};

	const handleViewLayout = (event, value) => {
		if (value !== null) {
			setViewLayout(value);
		}
	};

	const handlePaymentModalClose = () => {
		handlePaymentModal(false, null);
		setShowAddPayment && setShowAddPayment(false);
	};

	const handleFormSubmit = (e) => {
		if (editPayment) {
			toast.promise(
				api.updateEstimatePayment({ ...e, estimation_id: estimateDetails?.id }).then((res) => {
					if (res.status) {
						onUpdatePayment && onUpdatePayment(res.data);
						handlePaymentModalClose();
					}

					return res?.status == true ? res : null;
				}),

				{
					loading: 'Updating Payment...',
					success: (data) => `${data.message}`,
					error: <b>Payment Update Failed</b>
				}
			);
		} else {
			if (source == 'sales_order') {
				toast.promise(
					api.addSalesOrderPayment({ ...e, sales_order_id: estimateDetails?.id }).then((res) => {
						if (res.status) {
							onAddPayment && onAddPayment(res.data);
							handlePaymentModalClose();
						}
	
						return res?.status == true ? res : null;
					}),
	
					{
						loading: 'Adding Payment...',
						success: (data) => `${data.message}`,
						error: <b>Payment Failed</b>
					}
				);
			} else {
				toast.promise(
					api.addInvoicePayment({ ...e, invoice_id: estimateDetails?.id }).then((res) => {
						if (res.status) {
							onAddPayment && onAddPayment(res.data);
							handlePaymentModalClose();
						}
	
						return res?.status == true ? res : null;
					}),
	
					{
						loading: 'Adding Payment...',
						success: (data) => `${data.message}`,
						error: <b>Payment Failed</b>
					}
				);
			}
		}
	};

	const openPaymentDetailsModal = (data) => {

		setShowPayment(data);
		setShowPaymentDetailsModal(true);
	};

	const closePaymentDetailsModal = () => {
		setShowPaymentDetailsModal(false);
		setShowPayment(null);
	};

	const handlePaymentOnEdit = (data) => {
		handlePaymentModal(true, data);
	};

	const handleDelete = () => {
		toast.promise(
			api.deleteEstimatePayment(deletePayment).then((res) => {
				if (res.status) {
					onDeletePayment && onDeletePayment(deletePayment);
					setDeletePayment(null);
				}

				return res?.status == true ? res : null;
			}),

			{
				loading: 'Deleting Payment...',
				success: (data) => `${data.message}`,
				error: <b>Payment Delete Failed</b>
			}
		);
	};

	const handleDeleteClose = () => {
		setDeletePayment(null);
	};
	const pStyle = {
		textAlign: 'center'
	};
	useEffect(() => {
		setDefaultType(null);
	}, []);
	const handlePaymentPreviewModal = (id) => {
		setDefaultType(null);
		api.getSalesOrderPaymentDetails(id).then((res) => {
			if (res.status) {
				setDefaultType(res?.data?.pay_type)
				setSelectedPaymentDetails(res?.data)
				setShowPaymentModal(id);
			}
		});
	}

	const handleProcessSelectedPayment = (selectedPaymentDetails) => {
		console.log('process mo to', selectedPaymentDetails)
		setDeletePayment(selectedPaymentDetails?.id);
	}

	return (
		<>
			{
				!showOnlyModal && (
					<>
						<div className="estimate-option-card mb-2">
							<div className="estimate-option-card-head">
								<h5>Payment Details</h5>
								<div className="actions">
									{/* <Link to={''} className="btn btn-primary btn-outlined-main">
											<FaPlus /> New Inspection
										</Link> */}
									<ToggleButtonGroup size="small" value={viewLayout} exclusive onChange={handleViewLayout} className="view-toggle">
										<ToggleButton value="grid" key="grid">
											<GridView />
										</ToggleButton>
										<ToggleButton value="list" key="list">
											<ViewList />
										</ToggleButton>
									</ToggleButtonGroup>
								</div>
							</div>
							<div className="estimate-option-items w-100">
								{paymentData?.length == 0 && <div className="no-records">No payments recorded</div>}
								<Scroller>
									{viewLayout == 'grid' ? (
										<PaymentGrid
											dataList={paymentData.map((payment) => {
												return {
													id: payment?.id,
													currency: (
														<>
															<span style={{ color: '#45ad51' }}>
																{getCurrencySymbol(payment?.currency ?? estimateDetails?.currency)}{' '}
															</span>
														</>
													),
													amountPaid: payment?.amount_paid,
													payType: payment?.pay_type,
													date: payment?.created_at,
													Image:
														payment?.payments?.payment_attachment?.payment_attachment_url || payment?.payment_attachment?.payment_attachment_url,
													priorityColor: payment?.priority_status?.color,
													quantity: payment?.total_qty,
													inprogress: !!payment?.start_at,
													bodyContent: (
														<>
															<p style={{ fontSize: '12px', color: 'grey', marginBlock: '10px', display: 'flex' }}>
																<Avatar sx={{ width: 20, height: 20 }} style={{ fontSize: 10 }}>
																	{payment?.user_created?.first_name?.slice(0, 1)}
																	{payment?.user_created?.last_name?.slice(0, 1)}
																</Avatar>
																<span style={{ marginLeft: '10px' }}>{payment?.user_created?.first_name}</span>
															</p>
														</>
													),
													// defaultCurrency: payment?.currency ?? estimateDetails?.currency
												};
											})}
											handlePaymentPreviewModal={handlePaymentPreviewModal}
											processSelectedPayment={handleProcessSelectedPayment}
										/>
									) : (
										<>
											<table className="table " style={pStyle}>
												<thead>
													<tr>
														<th scope="col" width="20%">
															Payment Date
														</th>
														<th scope="col">Payment Type</th>
														<th scope="col">Paid Amount</th>
														<th scope="col">Registerd By</th>
														<th scope="col">Action</th>
													</tr>
												</thead>
												<tbody>
													{paymentData.map((data, index) => (
														<PaymentItem
															data={data}
															onEditClicked={handlePaymentOnEdit}
															onViewClicked={openPaymentDetailsModal}
															onDeleteClicked={(e) => {
																setDeletePayment(e);
															}}
															toggleExpand={() => toggleExpand && toggleExpand(index)}
															currency={estimateDetails.currency == 'usd' ? '$' : estimateDetails.currency == 'aed' ? 'AED' : '€'}
														/>
													))}
												</tbody>
											</table>
										</>
									)}
								</Scroller>
							</div>
						</div>
					</>
				)
			}
			{((estimateDetails && showPaymentModal) || (estimateDetails && showAddPayment)) && (
				<PaymentFormModal
					showPaymentModal={showPaymentModal || showAddPayment}
					editPayment={editPayment}
					handleClose={handlePaymentModalClose}
					onFormSubmit={handleFormSubmit}
					estimate={estimateDetails}
					defaultPaymentType={(showAddPayment) ? null : defaultType}
					selectedPaymentDetails={(showAddPayment) ? null : selectedPaymentDetails}
					source={source}
				/>
			)}

			{estimateDetails && showPaymentDetailsModal && (
				<PaymentDetailsModal
					showPaymentModal={showPaymentDetailsModal}
					showPayment={showPayment}
					handleClose={closePaymentDetailsModal}
					estimate={estimateDetails}
				/>
			)}

			<DeleteConfirmation
				key="paymentDelete"
				show={deletePayment}
				handleClose={handleDeleteClose}
				handleDelete={handleDelete}
				title="Confirm"
				message={`Are you sure you want to delete this payment?`}
			/>
		</>
	);
}

export default PaymentsComponent;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ViewDetailsIcon, DeleteIcon2 } from 'ui-component/custom-icons/icons';
import Pagination from 'ui-component/Pagination';
import Scroller from 'ui-component/Scroller';
// import NewShipments from './AddShipments';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import moment from 'moment';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import usePageTitle from 'hooks/usePageTitle';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import StatusBadge from 'ui-component/StatusBadge';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import CurrencyValue from 'ui-component/CurrencyValue';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import ExportListItemsModal from 'ui-component/ExportListItemsModal';
import {
	storeHeaderActions,
    storeSearchVendorCredit
} from 'store/reducers/mainSlice';

const index = () => {
    const [setPageTitle] = usePageTitle('Vendor Credits');
    useEffect(() => {
        return () => {
            setPageTitle(null);
        };
    }, []);
	const [selectedCurrency] = useSelector((state) => [state.main.selectedCurrency]);
    const history = useNavigate();
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [vendorCreditList, setVendorCreditList] = useState([]);
    const [show, setShow] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [privacyMode, setPrivacyMode] = useState(false);
    const permission = useSelector((state) => state.main.user.permissionToAction);
    const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
    const [pagination, setpagination] = useState([]);
    const dispatch = useDispatch();

    const [searchField, setSearchField] = useState();
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});
    const [isFiltersloading, setIsFiltersloading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);


	const [showQuickStats, setShowQuickStats] = useState(false);
	const [quickStatsLoading, setQuickStatsLoading] = useState(false);
	const [quickStatsData, setQuickStatsData] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [exportCompIsLoading, setExportCompIsLoading] = useState(false);

	const storedSearch = useSelector((state) => state.main.searchVendorCredit);
	const storedFilter = useSelector((state) => state.main.filterVendorCredit);

    useEffect(() => {
        if (storedSearch) {
            handleSearch({ target: { value: storedSearch } });
        }
        const fetchData = async () => {
            if (storedSearch) {
                setIsLoading(true);
                await handleSearch({ target: { value: storedSearch } });
            } else if (!storedSearch) {
                if (storedFilter && Object.keys(storedFilter).length > 0) {
                    setIsLoading(true);
                    await handleFilterRedux();
                } else {
                    handleFilter()
                }
            }
        };

        fetchData();
    }, [storedSearch, searchField, activeFilters, storedFilter]);
    
	useEffect(() => {
		if (showQuickStats && !quickStatsLoading) {
			setQuickStatsLoading(true);
			api
				.quickSummarySalesOrder(
					// statusTypeList?.find((item) => item?.name == activeComponent)?.id,
					activeFilters,
					searchField?.length > 2 ? searchField : null,
					sortByFilter
				)
				.then((res) => {
					setQuickStatsData(res.data);
					setQuickStatsLoading(false);
				});
		}
	}, [showQuickStats]);

    const getVendorCredits = () => {
        setIsLoading(true);
        api
            .getVendorCredits()
            .then((res) => {
                setIsLoading(false);
                if (res.status) {
                    setVendorCreditList(res.data.data);
                    setpagination(res.data);
                } else {
                    setVendorCreditList([]);
                }
                isFirstLoad && setIsFirstLoad(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setVendorCreditList([]);
                console.error('Failed to fetch returns list:', error);
            });
    };
    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchField(text);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        dispatch(storeSearchVendorCredit(text));

        let timeout = setTimeout(() => {
            setIsLoading(true);
            if (text.length > 2) {
                api.filterVendorCredits(activeFilters, text).then((res) => {
                    setIsLoading(false);
                    setVendorCreditList(res.data.data);
                    setpagination(res.data);
                });
            } else if (text.length > 0 && text.length <= 2) {
                setIsLoading(false);
            } else {
                api.filterVendorCredits(activeFilters, null).then((res) => {
                    setIsLoading(false);
                    setVendorCreditList(res.data.data);
                    setpagination(res.data);
                });
            }
        }, 1000);

        setSearchTimeout(timeout);
    };

    const viewData = (id) => {
        history(`/vendor-credits/${id}`);
    };

    useEffect(() => {
        !isFirstLoad && handleFilter();
    }, [activeFilters]);

    const handleFilter = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterVendorCredits(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setVendorCreditList(res.data.data);
            setpagination(res.data);
        });
    };

    const handleFilterRedux = (activeF = null, searchF = null) => {
        setIsLoading(true);
        api.filterVendorCredits(storedFilter, searchField?.length > 2 ? searchField : null).then((res) => {
            setIsLoading(false);
            setVendorCreditList(res.data.data);
            setpagination(res.data);
        });
    };
    useEffect(() => {
        setTimeout(() => {
            verifyPrivacyMode();
        }, 100);
    }, []);

    const verifyPrivacyMode = () => {
        const getPrivacyMode = sessionStorage.getItem('privacyMode');
        if (!getPrivacyMode) {
        } else {
            if (getPrivacyMode === 'true') {
                setPrivacyMode(true);
            } else {
                setPrivacyMode(false);
            }
        }
    };


    const handleClose = () => setShow('');

    const handleData = (data) => {
        setVendorCreditList(data.data);
        setpagination(data);
    };

    useEffect(() => {
        if (storedSearch) {
        } else if (storedFilter && Object.keys(storedFilter).length > 0) {
        } else {
            getVendorCredits();
        }
        getFiltersList();
    }, []);

    const tableHeaderStyles = {
        position: 'sticky',
        top: 0,
        backgroundColor: '#f2f2f2',
        zIndex: 1
    };

    const getFiltersList = () => {
        setIsFiltersloading(true);
        api.getVendorCreditFilter().then((res) => {
            setFilters(res.data);
            setIsFiltersloading(false);
        });
    };

    const handleDelete = (id) => {
        toast.promise(
            api.deleteVendorCredit(id).then((res) => {
                if (res.status) {
                    setShow('');
                    api.getVendorCredits().then((res) => {
                        setIsLoading(false);
                        setVendorCreditList(res.data.data);
                        setpagination(res.data);
                        isFirstLoad && setIsFirstLoad(false);
                    });
                }
                return res?.status ? res : abc;
            }),
            {
                loading: 'Deleting Vendor Credit...',
                success: (data) => `${data.message}`,
                error: <b>Failed to delete vendor credit</b>
            }
        );
    };

    const deleteData = (data) => {
        setShow(data);
    };

	const exportListSubmitHandler = (selectedValues) => {
		setExportCompIsLoading(true);
		api.downloadListingVendorCredits('', selectedValues, '', selectedCurrency).then((res) => {
			const newFileName = `Projectmix - Vendor Credits`;
			if (res != 404) {
				var newBlob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(newBlob, `${newFileName}.xlsx`);
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.success('Downloaded Completed!');
				setShowExportModal(false);
				dispatch(
					storeHeaderActions({
						// showQuickStats: headerActions?.showQuickStats,
						showExport: false
					})
				);
			} else {
				// toast.dismiss(downloading);
				setExportCompIsLoading(false);
				toast.error(res == 404 ? 'No Records Found!' : 'Download Failed!');
			}
		});
	};

    return (
        <>
            <div className="row">
                {/* Area Chart */}
                <div className="col-xl-12 col-lg-12">
                    <div className="section-head-new" style={{ margin:'10px 20px 0px 1px'}}>

                        <ul className="topListCom">
                            <PermissionAllowed permissions={['Create Vendor Credit']}>
                                <button
                                    className="bulk-action btn-primary btn-outlined-main-primary  white-var"
                                    onClick={() => {
                                        history('/vendor-credits/create');
                                    }}
                                >
                                    <span className="menuIcon">
                                        <FaPlus style={{ fontSize: '13px', marginTop: '-3px' }} />
                                    </span>
                                    <span>New</span>
                                </button>
                            </PermissionAllowed>
						</ul>
						<div className="actions d-none d-md-flex">
							<PermissionAllowed permissions={['Export Vendor Credit']}>
								{isDisabled ? (
									<button className="btn btn-primary btn-outlined-main white-var btn-create-report" disabled>
										{' '}
										<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
									</button>
								) : (
									<button
										className="btn btn-primary btn-outlined-main white-var btn-create-report"
										onClick={() => {
											setShowExportModal(true);
										}}
									>
										<PiMicrosoftExcelLogoFill style={{ fontSize: '1.4em' }} /> Export
									</button>
								)}
							</PermissionAllowed>
						</div>
                    </div>
                    <div className="card shadow-sm bg-white mb-4">
                        <div className="d-flex align-items-center justify-content-between TableSerachBar">
                            <div>
                                <div className="actions" style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ margin: '0 5px' }}>
                                        <Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} multi={undefined} source={'vendor_credit'} />
                                    </div>
                                </div>
                            </div>
                            <form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control bg-white border"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="basic-addon2"
                                        onChange={handleSearch}
                                        style={{ width: '100%' }}
                                        value={searchField}
                                    />
                                    <span className="searchIcon">
                                        <FaSearch />
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="table-responsive-xl tableData customersList p-2">
                            <Scroller extraHeight={100}>
                                <table className="table pm-table">
                                    <thead>
                                        <tr className="sticky-header">
                                            <th scope="col" style={{ width: '50px'}}>Credit Note #</th>
                                            <th scope="col text-left">Reference Number</th>
                                            <th scope="col text-left">Purchase Order #</th>
                                            <th scope="col text-left">Vendor</th>
                                            <th scope="col text-left">Status</th>
                                            <th scope="col text-left">Amount</th>
                                            <th scope="col text-left">Balance</th>
                                            <th scope="col text-left">Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>C000001</td>
                                            <td>C345654</td>
                                            <td>PO1234567</td>
                                            <td>Used Trading</td>
                                            <td>Open</td>
                                            <td>1,000.00</td>
                                            <td>400.00</td>
                                            <td>14 Oct 2024 </td>
                                        </tr> */}
                                        {isLoading ? (
                                            <>
                                                {[...Array(15)].map((e, i) => (
                                                    <tr>
                                                        {[...Array(8)].map((e, i) => (
                                                            <td>
                                                                <Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    vendorCreditList?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index} className={ (hasEveryPermission(['View Vendor Credit Details'])) ? 'hover-row' : '' }>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >

                                                                    {
                                                                        row?.status_name == 'Draft' ? (
                                                                            <>
                                                                                <span style={{ fontStyle: 'italic', color: '#929598' }}>{row?.purchase_credit_number}</span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {row?.purchase_credit_number}
                                                                            </>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    {row?.reference_no}
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    {row?.purchase_invoice?.purchase_order_number}
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    <PrivacyModeContent>{row?.vendor?.name}</PrivacyModeContent>
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    <div style={{ maxWidth: '100px' }}>

                                                                        <StatusBadge itemStatus={row?.status_name} />
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <CurrencyValue currencyObject={row?.converted_currency_total} />
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <CurrencyValue currencyObject={row?.converted_currency_balance_total} />
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className="align-middle font14"
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                            viewData(row.id);
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        cursor: hasEveryPermission(['View Vendor Credit Details']) ? 'pointer' : 'not-allowed'
                                                                    }}
                                                                >

                                                                    {moment(row?.credit_date).format(CommonDateOnlyFormat)}
                                                                </td>
                                                                <PermissionAllowed permissions={['Delete Vendor Credit', 'View Vendor Credit Details']} hasSome>

                                                                    <td className="hover-button-container" >
                                                                        <div>
                                                                            <div className="hover-button">
                                                                                
                                                                                <Tooltip title={'View'} arrow placement="top">
                                                                                    <div
                                                                                        className='btn btn-primary btn-outlined-main white-var btn-sm'
                                                                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                                                                        onClick={() => {
                                                                                            if (hasEveryPermission(['View Vendor Credit Details'])) {
                                                                                                viewData(row.id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <ViewDetailsIcon height={'16'} width={'16'} /> 
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <Tooltip title={'Delete'} arrow placement="top">
                                                                                    <div
                                                                                        className='btn btn-danger btn-outlined-danger white-var btn-sm'
                                                                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                                                                        onClick={() => {
                                                                                            if (hasEveryPermission(['Delete Vendor Credit'])) {
                                                                                                deleteData(row);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <DeleteIcon2 height={'20'} width={'20'} /> 
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </PermissionAllowed>
                                                            </tr>
                                                        </>
                                                    );
                                                    })
                                                }
                                                {
                                                    vendorCreditList?.length < 1 && (
                                                        <tr className="no-hover">
                                                            <td colSpan={7} className="whitebg">
                                                                <div className="not-found-container">
                                                                    <div className="not-found-icon">
                                                                        <img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
                                                                    </div>
                                                                    <h6 className="not-found-text">No Data Available</h6>

                                                                    <PermissionAllowed permissions={['Create Vendor Credit']}>
                                                                        <Link to={'/vendor-credits/create'} className="btn btn-primary btn-outlined-main">
                                                                            Create Vendor Credit?
                                                                        </Link>
                                                                    </PermissionAllowed>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </Scroller>
                        </div>
                        <Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
                        <div style={{ marginBottom: '8px'}}></div>
                    </div>
                </div>
            </div>

            {show && (
                <DeleteConfirmation
                    show={show}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to delete ${show?.purchase_credit_number}?`}
                />
            )}


            {showExportModal && (
				<ExportListItemsModal
					openpopup={true}
					disablepopup={() => {
						setShowExportModal(false);
						dispatch(
							storeHeaderActions({
								// showQuickStats: headerActions?.showQuickStats,
								showExport: false
							})
						);
					}}
					filters={filters}
					moduleName={'Vendor Credits'}
					onSubmit={exportListSubmitHandler}
					historyApi={api.getExportsHistory('purchase_orders')}
					isLoading={exportCompIsLoading}
					exportHistoryPerms={['Show Estimation Export History (Button)']}
				/>
			)}
        </>
    );
};

export default index;

import React, { useEffect, useState, useRef } from 'react';
import '../estimation/estimateInvoice.scss';
import { Link, useNavigate } from 'react-router-dom';
import mgLogo from 'assets/images/mglogo.svg';
import utLogo from 'assets/images/used-trading-logo.png';
import glpLogo from 'assets/images/glplogo.png';
import cmLogo from 'assets/images/cmlogo.svg';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import config from '../../config';
import NoImage from 'assets/images/no-image.png';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import ProductCommentModal from '../estimation/modals/ProductCommentModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { EditIcon } from 'ui-component/custom-icons/icons';

function VendorCreditDetailPreview({ data }) {

	const [settingsDetails, setSettingsDetails] = useState();
	const [productComment, setProductComment] = useState('');
	const [productName, setProductName] = useState('');
	const [productImage, setProductImage] = useState('');
    const [commentModal, setCommentModal] = useState(false);
    const [isInternalLoading, setIsInternalLoading] = useState(true);
	const history = useNavigate();
	const statusAllowedToEdit = ['Draft', 'Open'];

	// const totalQty = data?.sales_order_items?.reduce((total, detail) => total + (detail?.qty || 0), 0);
    const totalQty = data?.converted_currency_total;

	const handleCommentModal = (product, image, comment) => {
		setProductComment(comment);
		setProductName(product);
		setProductImage(image);
		setCommentModal(true);
	}
	const handleCloseComment = () => {
		setProductComment('');
		setProductName('');
		setProductImage('');
		setCommentModal(false);
	}

	useEffect(() => {

		api.getSettings().then((res) => {
			setSettingsDetails(res?.data)
        });
        setIsInternalLoading(false);
        console.log('data', data);
	}, []);
	return (
		<>
			{/* <div className="est-inv-container" style={{ padding: '15px', maxWidth: '700px' }}> */}
			<div className="est-inv-container" style={{ padding: '15px' }}>
				<div className="row m-0">
					{data ? (
						<>

                            <div className="col-8 p-0">
                                <div className="est-detail-logo" style={{ height: '36px' }}>
                                    {
                                        settingsDetails?.logo_url ? (
                                            <img src={settingsDetails?.logo_url} height={'36px'} />
                                        ) : (
                                            <h3>{settingsDetails?.client_full_name}</h3>
                                        )
                                    }
                                    {/* {
                                        settingsDetails?.client_code == 'usedtrading' ? (
                                            <>
                                                <img src={utLogo} height={'36px'} />
                                            </>
                                        ) : (
                                            <>
                                                <img src={mgLogo} height={'36px'} />
                                            </>
                                        )
                                    } */}
                                </div>

                                <div className="customer-details" style={{ marginTop: '20px' }}>
                                    <p className="m-0">
                                        <b>
                                            <PrivacyModeContent>{data?.vendor?.name}</PrivacyModeContent>
                                        </b>{' '}
                                        <br />
                                        <PrivacyModeContent>{data?.vendor?.address}</PrivacyModeContent> <br />
                                        <PrivacyModeContent>
                                            {(data?.vendor?.city == 'null' ? data?.vendor?.city + ', ' : '') + data?.vendor?.zipcode}
                                        </PrivacyModeContent>{' '}
                                        <br />
                                        <PrivacyModeContent>{data?.vendor?.country}</PrivacyModeContent>
                                        {data?.vendor?.shipping_address ? (
                                            <>
                                                <br />
                                                <br />
                                                <b>Ship To: </b>
                                                <br />
                                                <PrivacyModeContent>{data?.vendor?.shipping_address}</PrivacyModeContent> <br />
                                                <PrivacyModeContent>
                                                    {data?.vendor?.shipping_city + ', ' + data?.vendor?.shipping_zipcode}
                                                </PrivacyModeContent>{' '}
                                                <br />
                                                <PrivacyModeContent>{data?.vendor?.shipping_country}</PrivacyModeContent>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="col-4">
                                {
                                    statusAllowedToEdit.includes(data?.status) && (
                                        <>
                                            <PermissionAllowed permissions={['Edit Vendor Credit']}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-outlined-main white-var"
                                                    style={{ minWidth: 'unset', position: 'absolute', right: '-15px', top: '-20px' }}
                                                    disabled={isInternalLoading || !data?.purchase_credit_number}
                                                    onClick={() => {
                                                        history(`/vendor-credits/edit/${data?.id}`);
                                                    }}
                                                >
                                                    <EditIcon height='17px' width='17px' />
                                                    Edit
                                                </button>
                                            </PermissionAllowed>
                                        </>
                                    )
                                }
                            </div>
						</>
					) : (
						<>
							<div className="col-6 p-0">
								<div className="est-detail-logo" style={{ height: '36px' }}>
									<Skeleton sx={{ height: '36px', width: '100px' }} />
								</div>

								<div className="customer-details" style={{ marginTop: '20px' }}>
									<Skeleton variant="text" />
									<Skeleton variant="text" />
									<Skeleton variant="text" />
								</div>
							</div>
							<div className="col-6 p-0">
								<div className="company-details text-right">
									<p className="m-0">
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
										<Skeleton variant="text" />
									</p>
								</div>
							</div>
						</>
					)}
				</div>

				<div className="row mx-0" style={{ marginTop: '20px', alignItems: 'center' }}>
					<div className="col-6 col-md-8 p-0">
						{data ? (
							<h3 className="est-ref m-0">
                                {
                                    data?.status == 'Draft' ? (
                                        <>
                                            <span style={{ fontStyle: 'italic', color: '#929598', fontSize: '20px', fontWeight: '400' }}> {data?.purchase_credit_number}</span>
                                        </>
                                    ) : (
                                        <>
                                            Vendor Credit {data?.purchase_credit_number}
                                        </>
                                    )
                                }
							</h3>
						) : (
							<Skeleton variant="rounded" />
						)}
					</div>
					<div className="col-6 col-md-4 p-0">
						{data ? (
                            <table className="w-100">
                                <tr>
                                    <td>Credit Date:</td>
                                    <td className="text-right">{moment(data?.credit_date).format(CommonDateOnlyFormat)}</td>
                                </tr>
                            </table>
						) : (
							<Skeleton variant="rounded" />
						)}
					</div>
				</div>

				<div className="est-inv-items" style={{ marginTop: '20px' }}>
					<table className="w-100">
						<thead>
							<tr>
								<th className="text-left">
                                    {data ? (
                                        <>
                                            Qty
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </th>
                                <th colSpan={2}>
                                    {data ? (
                                        <>
                                            Model
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </th>
								<th className="hide-on-mobile text-center">
                                    {data ? (
                                        <>
                                            Comment
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </th>
								<th className="text-right d-none d-md-block">
                                    {data ? (
                                        <>
                                            Price
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </th>
								<th className="text-right">
                                    {data ? (
                                        <>
                                            Total
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </th>
							</tr>
						</thead>
						<tbody>
							{data?.items?.map((item) => (
								<tr>
									<td className="text-left">{item?.qty}</td>
                                    <td className="hide-on-mobile">
                                        {
                                            item?.product?.thumbnail_url ? (
                                                <img src={item?.product?.thumbnail_url} style={{ width: '30px' }} />
                                            ) : (
                                                <img src={NoImage} style={{ width: '20px' }} />
                                            )
                                        }
                                    </td>
									<td>
										<p className="p-0 m-0">{item?.product?.product_name}</p>
                                        {
                                            item?.comment ? (
											    <p className="hide-on-non-mobile mobile-prod-comment-ui">{item?.comment}</p>
                                            ) : (
                                                ''
                                            )
                                        }
									</td>
									{data?.status != 'Credit Note' && (
										<td style={{ maxWidth: '80px' }} className="hide-on-mobile text-center">

											<div
												style={{ cursor: 'pointer' }}
												onClick={() => {
													handleCommentModal(item?.product?.product_name, item?.product?.thumbnail_url, item?.comment);
												}}
											>
												{
													item?.comment ? (
														<>
															<MdOutlineStickyNote2 size={18} />
														</>
													) : (
														<>
															-
														</>
													)
												}
											</div>
											{/* {item?.comment || '-'} */}
										</td>
									)}
									<td className="text-right d-none d-md-block">
										<div style={{ marginTop: '5px' }}>
											<CurrencyValue currencyObject={item?.converted_currency_unit_price} />
										</div>
									</td>
									<td className="text-right">
										<CurrencyValue currencyObject={item?.converted_currency_total_price} />
									</td>
								</tr>
							))}

                            <tr style={{ height: '10px' }}>
								<td></td>
							</tr>
							{data?.shipping_fee > 0 && (
								<tr>

									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile text-center">{totalQty}</td>
									<td className="d-md-none"></td>
									<td className="text-right">Shipping</td>
									<td className="text-right">
										<CurrencyValue currencyObject={data?.converted_currency_shipping_fee} />
									</td>
								</tr>
							)}

							{data?.total_discount > 0 && (
								<tr>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">Discount {data?.discount_type == 'percentage' ? `(${data?.discount}%)` : ''}</td>
									<td className="text-right">
										<CurrencyValue currencyObject={data?.converted_total_discount} />
									</td>
								</tr>
							)}

							{data?.vat_type == 'Excluding VAT' && (
								<tr>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="hide-on-mobile"></td>
									<td className="d-md-none"></td>
									<td className="text-right">VAT ({data?.vat_percent}%)</td>
									<td className="text-right font-weight-bold">
										<CurrencyValue currencyObject={data?.converted_currency_vat_amount} />
									</td>
								</tr>
							)}
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">
                                    {data ? (
                                        <>
                                            Subtotal
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </td>
								<td className="text-right font-weight-bold">
                                    {data ? (
                                        <>
                                            <CurrencyValue currencyObject={data?.converted_currency_subtotal} />
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
								</td>
							</tr>
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">
                                    {data ? (
                                        <>
                                            Total
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </td>
								<td className="text-right font-weight-bold">
                                    {data ? (
                                        <>
                                            <CurrencyValue currencyObject={data?.converted_currency_total} />
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
								</td>
							</tr>
							<tr>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="hide-on-mobile"></td>
								<td className="d-md-none"></td>
								<td className="text-right">
                                    {data ? (
                                        <>
                                            Credit Remaining
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
                                </td>
								<td className="text-right font-weight-bold">
                                    {data ? (
                                        <>
                                            <CurrencyValue currencyObject={data?.converted_currency_balance} />
                                        </>
                                    ) : (
                                        <Skeleton variant="rounded" />
                                    )}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			{productComment && (
				<ProductCommentModal
					name={productName}
					image={productImage}
					comment={productComment}
					// show={commentModal}
					handleClose={() => handleCloseComment()}
				/>
			)}
		</>
	);
}

export default VendorCreditDetailPreview;

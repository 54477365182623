import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import minusIcon from 'assets/images/icons/ic_minus.svg';
import nextIcon from 'assets/images/icons/arrow.svg';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import { storeInLocalStore } from 'store/reducers/mainSlice';
import { useDispatch } from 'react-redux';
import { element } from 'prop-types';
import { FaArrowRight, FaMinus, FaPlus } from 'react-icons/fa';
import Scroller from 'ui-component/Scroller';
import toast from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';

const NewShipments = ({ newShow, handleNewClose, onSubmit }) => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const invoiceRef = useRef();
	const shipWithRef = useRef();
	const trackingRef = useRef();
	const shipToRef = useRef();

	const [transitCompanies, setTransitCompanies] = useState([]);
	const [shippingPartners, setShippingPartners] = useState([]);
	const [packagesList, setPackagesList] = useState([]);

	const [summaryList, setSummaryList] = useState([]);

	const [shipToValue, setShipToValue] = useState(null);
	const [shipmentData, setShipmentData] = useState({
		packages: [],
		ship_with: '',
		tracking_number: null,
		ship_to: null
	});
	const [alertMessage, setAlertMessage] = useState({});

	const getInitialData = () => {
		api.getReadyToShip().then((res) => {
			setPackagesList(res?.data?.packages);
			setShippingPartners(res?.data?.shipping_companies);
		});

		api.getSelectOptionsList('transit_company').then((res) => {
			setTransitCompanies(res?.data);
		});
	};

	useEffect(() => {
		if (packagesList?.filter((p) => shipmentData?.packages?.includes(p?.id))?.length > 0) {
			setShipToValue(packagesList?.filter((p) => shipmentData?.packages?.includes(p?.id))?.[0]?.transit_company);
		} else {
			setShipToValue(null);
		}
	}, [shipmentData]);

	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (shipmentData?.packages?.length > 0) {
			const packages = [...packagesList?.filter((p) => shipmentData?.packages?.includes(p.id))];
			let summList = [];
			packages?.forEach((pkg) => {
				pkg?.items?.forEach((item) => {
					const existing = summList?.find((s) => s.estimation_id == item.estimation_id && s.product_id == item.product_id);
					const existingIndex = summList?.findIndex(
						(s) => s.estimation_id == item.estimation_id && s.product_id == item.product_id
					);
					if (existing) {
						const newItem = { ...existing, qty: Number(existing?.qty) + Number(item?.qty) };
						summList[existingIndex] = newItem;
						// summList?.map((s) => (s.estimation_id == item.estimation_id && s.product_id == item.product_id ? newItem : s));
					} else {
						summList.push(item);
					}
				});
			});
			setSummaryList();
			setSummaryList(summList);
		} else {
			setSummaryList([]);
		}
	}, [shipmentData]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!(shipmentData?.packages?.length > 0)) {
			toast.error('Select a minimum of 1 Package to ship');
		} else if (!shipmentData?.ship_with) {
			toast.error('Ship With is required');
		} else if (!!!shipmentData?.tracking_number) {
			toast.error('Tracking number is required');
		} else {
			onSubmit({
				...shipmentData,
				ship_to: shipToValue?.id
			});
		}
	};

	return (
		<>
			<Modal show={newShow} onHide={handleNewClose} centered size="xl">
				<div className="p-4">
					<Modal.Title className="text-center text-white mx-auto">New Shipment</Modal.Title>
					<form onSubmit={handleSubmit} className="userForm">
						<div className="row">
							<div className="col-12 col-md-6">
								{/* <div className="form-row form-card">
									{/* <div className="form-group col-md-12 col-xl-12 px-3 py-1">
										<label>Ship To</label>
										<Select
											ref={invoiceRef}
											className="rs-container"
											classNamePrefix="rs"
											isSearchable={true}
											name="invoice"
											value={transitCompanies
												?.map((s) => ({
													label: s?.name,
													value: s?.id
												}))
												?.find((s) => s?.value == shipmentData?.ship_to)}
											options={transitCompanies?.map((s) => ({
												label: s?.name,
												value: s?.id
											}))}
											onChange={(e) => {
												setShipmentData((oldData) => ({ ...oldData, ship_to: e?.value }));
											}}
											placeholder="Select"
										/>
										{alertMessage.invoice && (
											<>
												<span className="error-message text-danger">{alertMessage.invoice}</span>
											</>
										)}
									</div>
								</div> */}
								<div className="form-row form-card">
									<div className="form-group col-md-12 col-xl-12 px-3 py-1">
										<label>Packages</label>
										<Select
											className="rs-container"
											classNamePrefix="rs"
											isSearchable={true}
											isMulti={true}
											name="invoice"
											options={packagesList
												?.filter((s) => (shipToValue?.id ? s?.transit_company?.id == shipToValue?.id : true))
												?.map((s) => (
													 {
														label: s?.reference_no,
														value: s?.id
													
													}))}
											placeholder="Select"
											onChange={(e) => {
												setShipmentData((oldData) => ({ ...oldData, packages: e?.map((v) => v?.value), }));
											}}
										/>
										{alertMessage?.ship_to && (
											<>
												<span className="error-message text-danger">{alertMessage?.ship_to}</span>
											</>
										)}
									</div>
								</div>
								<div className="row m-0 p-0 form-card">
									<div className="form-group col-md-6 col-xl-6">
										<label>Ship with</label>
										<Select
											className="rs-container"
											classNamePrefix="rs"
											isSearchable={true}
											name="invoice"
											value={shippingPartners
												?.map((s) => ({
													label: s?.name,
													value: s?.id
												}))
												?.find((s) => s?.value == shipmentData?.ship_with)}
											options={shippingPartners?.map((s) => ({
												label: s?.name,
												value: s?.id
											}))}
											onChange={(e) => {
												setShipmentData((oldData) => ({ ...oldData, ship_with: e?.value }));
											}}
											placeholder="Select"
										/>
										{shipmentData && (
											<p>{console.log(shipToValue)}{console.log(shipmentData)}</p>
											
										)}
										{alertMessage.ship_with && (
											<>
												<span className="error-message text-danger">{alertMessage.ship_with}</span>
											</>
										)}
									</div>
									<div className="form-group col-md-6 col-xl-6">
										<label>Tracking Number</label>
										<input
											className="form-control"
											type="text"
											placeholder="Enter Tracking Number"
											value={shipmentData?.tracking_number}
											onChange={(e) =>
												setShipmentData((oldData) => ({ ...oldData, tracking_number: e?.target?.value }))
											}
										/>
										{alertMessage.tracking_id && (
											<>
												<span className="error-message text-danger">{alertMessage.tracking_id}</span>
											</>
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="userForm py-4">
									{/* <div className="form-row form-card">
										<div className="form-group col-md-12 col-xl-12 px-3 pt-1 pb-0 mb-0">
											<label>Quick Summary</label>
										</div>
									</div> */}
									<div className="form-row form-card">
										<div className="form-group col-md-12 col-xl-12 px-3 py-1">
											<label>Ship To</label>
											<p className="m-0">{shipToValue?.name || '-'}</p>
										</div>
									</div>
									<Scroller>
										<table className="table">
											<thead>
												<tr>
													<th>Product</th>
													<th>Estimate</th>
													<th>Quantity</th>
												</tr>
											</thead>
											<tbody>
												{summaryList?.map((summary) => (
													<tr>
														<td>{summary?.product_name}</td>
														<td>{summary?.invoice_number}</td>
														<td>{summary?.qty} Pcs</td>
													</tr>
												))}
											</tbody>
										</table>
									</Scroller>
								</div>
							</div>
							<div className="col-12">
								<div className="btnarea">
									<button onClick={handleNewClose} type="button" className="btn btn-link">
										Cancel
									</button>
									<PermissionAllowed permissions={['Create New Shipment (Action)']}>
										<button type="submit" className="btn btn-outlined-main">
											<FaPlus /> Create
										</button>
									</PermissionAllowed>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

export default NewShipments;

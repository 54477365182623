import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import Acceptpopup from '../estimation/Acceptpopup';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent, setEstimatedata, storeInLocalStore } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowRight, FaEdit, FaMinus, FaPlus, FaRegSave, FaSave, FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import { FaPaperclip, FaDownload, FaPen, FaRegTrashCan } from 'react-icons/fa6';
import PaymentsComponent from '../estimation/paymentComponents/PaymentsComponent';
import ShipmentsComponent from '../estimation/shipmentComponents/NewShipmentComponent';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Notes from 'ui-component/notes';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import config from 'config.js';
import { useQuery, useQueryClient } from 'react-query';
import { getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import md5 from 'md5-hash';
import AcceptOrderModal from 'ui-component/confirmation-dialogs/acceptOrderModal/AcceptOrderModal';
import { 
    RegisterPaymentIcon, 
    ProcessOrderIcon, 
    DeleteIcon2, 
    ApproveIcon,
    RefundIcon, 
    BillsIcon,
    SettingsDownloadIcon
} from 'ui-component/custom-icons/icons';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import agreementImage from 'assets/images/agreement.svg';
import StatusBadge from 'ui-component/StatusBadge';
import CurrencyValue from 'ui-component/CurrencyValue';

import ApplyToBillModal from './modals/ApplyToBillModal';
import PurchaseOrderModal from '../estimation/modals/PurchaseOrderModal';
import TimelineStep from 'ui-component/TimelineStep';
import VendorCreditDetailPreview from './VendorCreditDetailPreview';
import { set } from 'draft-js/lib/EditorState';
import { Skeleton } from '@mui/material';

const VendorCreditDetails = () => {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);

	const [isInternalLoading, setInternalLoading] = useState(false);
    const [allPermissions, hasEveryPermission] = usePermissions();
    const [selectedVendorCredit, setSelectedVendorCredit] = useState();
	const [showDeletePayment, setShowDeletePayment] = useState(null);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [selectedPaymentId, setSelectedPaymentId] = useState(null);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [showClosePopup, setShowClosePopup] = useState(false);
	const [sendingVendorCredit, setSendingVendorCredit] = useState(false);
	const [selectedForSending, setSelectedForSending] = useState('');
    const [showApplyToBillsModal, setShowApplyToBillsModal] = useState(false);
    const [showDeletePaymentModal,  setShowDeletePaymentModal] = useState(false);
	const statusAllowedToPay = ['Open'];
	const statusAllowedToDeleteDraft = ['Draft'];
	const statusAllowedToCancel = ['Draft', 'Open'];
	const statusNotAllowedToProcessOrder = ['Draft', 'Canceled', 'Closed'];
    const [showActions, setShowActions] = useState(false);
    const [notesList, setNotesList] = useState([]);



    const initializeData = async () => {


        api.getVendorCreditById(id).then((res) => {
            if (res?.data?.status) {
                setSelectedVendorCredit(res?.data?.data);
                setShowActions(true);
            }
        });
    }

    useEffect(() => {
        initializeData();
    }, [id])

	// // End Create
	const history = useNavigate();
	const [isDisable, setDisable] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState('');



    
    const handleOpenNewTab = (pageModule, id) => {
        let url = '';
        if (pageModule == 'purchase_order') {
            url = `/purchasing/view/${id}`;
        }
        if (url) {
            history(url);
        }
    };

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleClosePopup = () => {
		setShowDeletePopup('')	
        setShowDeletePaymentModal(false)
	}


    const closeModals = () => {
        setShowApplyToBillsModal(false);
        
    }

    const reloadData = () => {
        setShowApplyToBillsModal(false);
        initializeData()
    }

    const deletePayment = async (id) => {
        setSelectedPaymentId(id)
        setShowDeletePaymentModal(true)
    }

	const handleDeletePayment = () => {
		toast.promise(
			api.deleteVendorCreditPayment(selectedPaymentId).then((res) => {
				if (res.status) {
					handleClosePopup()
                    reloadData()
					setSelectedPaymentId(null);
				}

				return res?.status == true ? res : null;
			}),

			{
				loading: 'Deleting Payment...',
				success: (data) => `${data.message}`,
				error: <b>Payment Delete Failed</b>
			}
		);
	};

	const downloadVendorCredit = (template, to) => {
		api.downloadVendorCredit(id, { template, to }).then((res) => {
			// if (res?.status) {

			var newBlob = new Blob([res], { type: 'application/pdf' });
			saveAs(
				newBlob,
				`Projectmix - Vendor Credit ${selectedVendorCredit?.purchase_credit_number}.pdf`
			);
		});
	};

	const cancelRecord = (data) => {
		setDeleteMessage(`By canceling, this Vendor Credits ${ selectedVendorCredit?.purchase_credit_number} cannot be rolled back. Do you want to proceed?`);
		setShowCancelPopup(data);
	};

	const handleCancel = (id) => {
		toast.promise(
			api.cancelVendorCredit(id).then((res) => {
				if (res.status) {
					history(`/vendor-credits`);
				}
			}),
			{
				loading: 'Canceling vendor credit...',
				success: <b>Vendor credit cancelled</b>,
				error: <b>Could not cancel vendor credit.</b>
			}
		);
	}

    const handleApprove = async () => {

		toast.promise(
			api.saveVendorCredit(id).then((res) => {
				if (res?.status) {
					initializeData()
				}
				return res?.status ? res : abc;
			}),
			{
				loading: `approving vendor credit...`,
				success: (res) => res?.message,
				error: <b>Could not approve vendor credit.</b>
			}
		);
    }

	const deleteRecord = (data) => {
		setDeleteMessage(
			`Are you sure you want to delete <br />vendor credit ${ data?.purchase_credit_number}?`
		);
		setShowDeletePopup(data);
	};

    const handleProcessApplyToBills = () => {
        setShowApplyToBillsModal(true);
    }

	const handleDelete = (id) => {

		toast.promise(
			api.deleteVendorCredit(id).then((res) => {
				if (res?.status) {
					history(`/vendor-credits`);
				}
			}),
			{
				loading: 'Deleting Vendor Credit...',
				success: <b>Vendor Credit deleted</b>,
				error: <b>Could not delete vendor credit.</b>
			}
		);
	};


	const addNote = (noteInputValue, noteType, mentions, noteInputPlain) => {
		toast.promise(
			api
				.newVendorCreditNote(id, {
					details: noteInputValue,
					detail_plain: noteInputPlain,
					type: 'public',
					// todo: noteType == 'todo' ? true : false,
					todo: false,
					mentions
				})
				.then(
					(res) => {
						if (res.status) {
                            initializeData()
							// setSalesOrderRow((oldData) => ({
							// 	...oldData,
							// 	notes: [
							// 		{
							// 			id: res?.data?.id,
							// 			details: noteInputValue,
							// 			date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
							// 			time: moment(res?.data?.created_at).format('HH:mm'),
							// 			user_name: userName,
							// 			expand: false,
							// 			isDeletable: true
							// 		},
							// 		...oldData?.notes
							// 	]
							// }));
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeEstimateNote(id, salesOrderRow?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setSalesOrderRow((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
        setNotesList((oldData) => 
            oldData?.map((note, i) =>
                index === i
                    ? {
                        ...note,
                        expand: !note?.expand
                      }
                    : note
            )
        );
		// setNotesList((oldData) => ({
		// 	...oldData,
		// 	notes: oldData?.notes?.map((note, i) =>
		// 		index == i
		// 			? {
		// 					...note,
		// 					expand: !note?.expand
		// 			  }
		// 			: note
		// 	)
		// }));
	};


    useEffect(() => {


        let notesArray = [];

        selectedVendorCredit?.notes?.forEach((note) => {
            notesArray = [
                {
                    id: note?.id,
                    details: note?.details,
                    date: moment(note?.created_at).format('YYYY-MM-DD'),
                    time: moment(note?.created_at).format('HH:mm'),
                    user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
                    expand: false,
                    isDeletable: note?.is_deletable
                },
                ...notesArray
            ];
        });

        notesArray = _.sortBy(notesArray, (n) => -n.id);
        setNotesList(notesArray);
        // setSalesOrderRow({ ...tempSalesOrder, notes: notesArray });
    }, [selectedVendorCredit]);

	return (
		<>
			<div className="row m-0 w-100 est-nv-container">
				<div className="col-12 col-md-9 p-0 p-md-2 inv-preview">
					<div className="section-head-new pl-0 mb-1 mt-2">




						<div className="actions" style={{ gap: '5px', width: 'auto' }}>
							{showActions && (
								<>
                                    {
                                        selectedVendorCredit?.status === 'Draft' && (
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-outlined-main white-var"
                                                style={{ minWidth: 'unset' }}
                                                onClick={() => {
                                                    handleApprove()
                                                }}
                                                disabled={isInternalLoading || !selectedVendorCredit?.purchase_credit_number}
                                            >
                                                <ApproveIcon height='19px' width='19px' />
                                                Approve
                                            </button>
                                        )
                                    }
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-outlined-main white-var"
                                        style={{ minWidth: 'unset' }}
                                        disabled={isInternalLoading || !selectedVendorCredit?.purchase_credit_number}
                                        onClick={() => {
                                            downloadVendorCredit();
                                        }}
                                    >
                                        <SettingsDownloadIcon height="16px" width="16px" />
                                        Download
                                    </button>

									{
										statusAllowedToPay.includes(selectedVendorCredit?.status) && (
											<>
                                                {
                                                    (selectedVendorCredit?.purchase_credit_number && selectedVendorCredit?.converted_currency_balance[selectedVendorCredit?.currency] > 0) && (
                                                        <>

                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-outlined-main white-var m-0"
                                                                style={{ minWidth: 'unset', margin: '0 !important' }}
                                                                onClick={() => {
                                                                    handleProcessApplyToBills();
                                                                }}
                                                                disabled={isInternalLoading || !selectedVendorCredit?.purchase_credit_number}
                                                            >
                                                                <BillsIcon height="16px" width="16px" />
                                                                Apply to Bills
                                                            </button>
                                                        </>
                                                    )
                                                }
											</>
										)
                                    }
								</>
							)}
						</div>
						<div className="actions" style={{ gap: '5px' }}>
								
							{
								selectedVendorCredit?.payments?.length < 1 && (
									<>
										<PermissionAllowed permissions={['Delete Sales Order']}>
										{
											statusAllowedToDeleteDraft.includes(selectedVendorCredit?.status) && (
												<>
													<button
														type="button"
														className="btn btn-primary btn-outlined-danger white-var"
														style={{ minWidth: 'unset' }}
														disabled={isInternalLoading || !selectedVendorCredit?.purchase_credit_number}
														onClick={() => {
															deleteRecord(selectedVendorCredit);
														}}
													>
			
														<FaRegTrashCan />
														Delete Draft
													</button>
												</>
											)
										}
										</PermissionAllowed>

										{

										(selectedVendorCredit?.status && selectedVendorCredit?.status !== 'Draft') && (
											<>
												{
													statusAllowedToCancel.includes(selectedVendorCredit?.status) && (
														<>

															<PermissionAllowed permissions={['Cancel Vendor Credit']}>
																<Dropdown className="btnDel est-btn iconbtn" align={'end'}>
																	<Dropdown.Toggle as="span">
																		<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
																			Change status <LuChevronDown />
																		</Link>
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
                                                                    {
                                                                        statusAllowedToCancel.includes(selectedVendorCredit?.status) && (
                                                                            <>
                                                                                <PermissionAllowed permissions={['Cancel Vendor Credit']}>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => {
                                                                                            cancelRecord(selectedVendorCredit);
                                                                                        }}
                                                                                    >
                                                                                        Cancel
                                                                                    </Dropdown.Item>
                                                                                </PermissionAllowed>
                                                                            </>
                                                                        )
                                                                    }

															</Dropdown.Menu>
														</Dropdown>
													</PermissionAllowed>
												</>
											)}
										</>
										)}
									</>
								)
							}




							<div className="est-detail-item">
                                <StatusBadge itemStatus={selectedVendorCredit?.is_draft == 1 ? 'Draft' : selectedVendorCredit?.status} />
							</div>
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
							<VendorCreditDetailPreview data={selectedVendorCredit} />
							<div style={{ height: '0px' }}></div>
							
							{
								selectedVendorCredit?.payments?.length > 0 && (
									<>
										<div style={{ padding: '0px 20px 20px 16px' }}>

											<div className="row mb10">
												<div className="col-12">
													<h2 className="d-flex font600" style={{ fontSize: '18px' }}>Bills Credited</h2>
												</div>
											</div>
											
											<div className="row">
												<div className="col-md-3 font600">Date</div>
												<div className="col-md-5 font600">Purchase Order #</div>
												<div className="col-md-4 font600">Amount Credited</div>
											</div>
											<hr className="mt8 mb8" />
											{
												selectedVendorCredit?.payments?.map((payment, index) => (
													<>
														<div className="row hover-row">
															<div className="col-md-3">
                                                                { payment?.payment_date ? moment(payment?.payment_date).format(CommonDateOnlyFormat) : 'N/A' }
                                                            </div>
															<div className="col-md-5">
                                                                
                                                                <div
                                                                    className={`${hasEveryPermission(['View Purchase Order']) ? 'text-primary cursor-pointer' : '' }`}
                                                                    onClick={() => {
                                                                        if (hasEveryPermission(['View Purchase Order'])) {
                                                                            handleOpenNewTab('purchase_order', payment?.purchase_order?.id)
                                                                        }
                                                                    }}
                                                                >
                                                                    {payment?.purchase_order?.purchase_order_number}
                                                                </div>
															</div>
															<div className="col-md-4">
                                                                <CurrencyValue currencyObject={payment?.converted_amount} />
																{/* {getCurrencySymbol(selectedSalesOrder?.currency)} {numberFormatter.format(payment?.amount_paid)} */}
															</div>
															<div className="hover-button-container-lg mr-20 mt0">
																<div>
																	<div className="hover-button">
																		
																		{
																			payment?.attachment && (
																				<>
																					<div
																						className='btn btn-primary btn-outlined-main white-var btn-sm cursor-pointer'
																						style={{ marginLeft: '8px' }}
																						onClick={() => {
																							window.open(payment.attachment, '_blank');
																						}}
																					>
																						<div style={{ margin: 'auto 5px auto 3px' }}>
																							<FaPaperclip  />
																						</div>
																					</div>
																				</>
																			)
																		}
																		<PermissionAllowed permissions={['Delete Vendor Credit']}>
																			<div
																				className='btn btn-danger btn-outlined-danger white-var btn-sm cursor-pointer'
																				style={{ marginLeft: '8px' }}
																				onClick={() => {
																					deletePayment(payment?.id);
																				}}
																			>
																				<div>
																					<DeleteIcon2 width={'20px'} height={'20px'} />
																				</div>
																			</div>
																		</PermissionAllowed>
																	</div>
																</div>
															</div>
														</div>
														<hr className="mt8 mb8" />
													</>
												))
											}
										</div>
									</>
								)
							}


						</div>
                        
					</div>
				</div>
				<div className="col-12 col-md-3 inv-details">
					<div className="mt-20">
						<div className="est-detail-item">
                            {selectedVendorCredit ? (
                                <>
                                    <label htmlFor="inputName" className="est-labels">
                                        Currency & Rate
                                    </label>
                                    
                                    <p>
                                        {selectedVendorCredit?.currency?.toUpperCase()}, 1 ={' '}
                                        {
                                            selectedVendorCredit?.currency == 'eur' ? `$ ${((1 / selectedVendorCredit?.currency_rate?.currency_rates[1]?.rate) * selectedVendorCredit?.currency_rate?.currency_rates[2]?.rate).toFixed(4)}` : 
                                            selectedVendorCredit?.currency == 'usd' ? `€ ${((1 / selectedVendorCredit?.currency_rate?.currency_rates[2]?.rate) * selectedVendorCredit?.currency_rate?.currency_rates[1]?.rate).toFixed(4)}` : 
                                            selectedVendorCredit?.currency == 'aed' ? `€ ${((1 / selectedVendorCredit?.currency_rate?.currency_rates[0]?.rate) * selectedVendorCredit?.currency_rate?.currency_rates[1]?.rate).toFixed(4)}` : ''
                                        }
                                    </p>
                                </>
                            ) : (
                                <Skeleton variant="text" sx={{ width: '100%' }}  />
                            )}
						</div>
                        <div className="est-detail-item">
                            {selectedVendorCredit ? (
                                <>
                                    <label htmlFor="inputName" className="est-labels">
                                        Vendor
                                    </label>

                                    <Link to={`/contacts/${selectedVendorCredit?.vendor?.id}`}>
                                        <PrivacyModeContent>{selectedVendorCredit?.vendor?.name ?? 'N/A'}</PrivacyModeContent>
                                    </Link>
                                </>
                            ) : (
                                <Skeleton variant="text" sx={{ width: '100%' }}  />
                            )}
                        </div>
                        <div className="est-detail-item">
                            {selectedVendorCredit ? (
                                <>
                                    <label htmlFor="inputName" className="est-labels">
                                        Purchase Order
                                    </label>

                                    <Link to={`/purchasing/view/${selectedVendorCredit?.purchase_invoice?.id}`}>
                                        {selectedVendorCredit?.purchase_invoice?.purchase_invoice_number ?? 'N/A'}
                                    </Link>
                                </>
                            ) : (
                                <Skeleton variant="text" sx={{ width: '100%' }}  />
                            )}
                        </div>
                        <div className="est-detail-item">
                            {selectedVendorCredit ? (
                                <>
                                    <label htmlFor="inputName" className="est-labels">
                                        Reference #
                                    </label>
        
                                    <p className="p-0 m-0 text-right">{selectedVendorCredit?.reference_no || 'N/A'}</p>
                                </>
                            ) : (
                                // <Skeleton variant="rounded" />
                                <Skeleton variant="text" sx={{ width: '100%' }}  />
                            )}
                        </div>
                        

                        {selectedVendorCredit ? (
                            <>
                                <Notes
                                    // notes={selectedVendorCredit?.notes}
                                    notes={notesList}
                                    addNote={addNote}
                                    deleteNotePermission={[]}
                                    removeNote={removeNote}
                                    toggleNoteExpand={toggleNoteExpand}
                                    parentId={1}
                                    type="estimate"
                                />
                            </>
                        ) : (
                            <div className="est-detail-item" style={{ height: '50px' }}>
                                <Skeleton sx={{ height: '50px', width: '100%' }} />
                            </div>
                        )}
					</div>
				</div>
                {
                    showApplyToBillsModal && (
                        <ApplyToBillModal
                            openPopup={showApplyToBillsModal}
                            data={selectedVendorCredit}
                            handleClose={closeModals}
                            handleReloadData={reloadData}
                        />
                    )
                }

				{showDeletePopup && (
					<DeleteConfirmation
						show={showDeletePopup}
						handleClose={handleClosePopup}
						handleDelete={handleDelete}
						title="Confirm"
						message={`${deleteMessage}`}
					/>
				)}

				{showCancelPopup && (
					<DeleteConfirmation
						show={showCancelPopup}
						handleClose={handleClosePopup}
						handleDelete={handleCancel}
						title="Are you sure?"
						message={`${deleteMessage}`}
						// customDeleteButtonText="Confirm"
						customDeleteButtonText={`Yes, Cancel this vendor credit`}
					/>
				)}
				{showClosePopup && (
					<DeleteConfirmation
						show={showClosePopup}
						handleClose={handleCloseSalesOrder}
						handleDelete={handleCloseSalesOrder}
						title="Confirm"
						message={`${deleteMessage}`}
						customDeleteButtonText="Confirm"
					/>
				)}
				{
					showDeletePaymentModal && (
						<>
							<DeleteConfirmation
								key="paymentDelete"
								show={showDeletePaymentModal}
								handleClose={handleClosePopup}
								handleDelete={handleDeletePayment}
								title="Confirm"
								message={`Are you sure you want to delete this record?`}
							/>
						</>
					)
				}
			</div>
		</>
	);
};

export default VendorCreditDetails;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaMinus, FaSave, FaTimes, FaChevronRight } from 'react-icons/fa';
import FileUpload from 'ui-component/FileUpload';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import Select from 'react-select';
import { toast } from 'react-hot-toast';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactSelect from 'react-select';



function PaymentFormModal({ showPaymentModal, editPayment, handleClose, onFormSubmit, estimate, defaultPaymentType, selectedPaymentDetails, source }) {
    const [disableStatus, setDisablestatus] = useState(false);
    const [imageId, setImageId] = useState(null);
    const [files, setFiles] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [selectedCreditNoteId, setSelectedCreditNoteId] = useState(null);
    const [transitCompanyCreditNotes, setTransitCompanyCreditNotes] = useState([]);
    const [creditNoteLists, setCreditNoteLists] = useState([]);
    const [remainingBalance, setRemainingBalance] = useState(0);
    const [remainingCredit, setRemainingCredit] = useState(0);
    const [amountToPay, setAmountToPay] = useState(0);
    const [allowInvoiceSettlement, setAllowInvoiceSettlement] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [totalOpen, setTotalOpen] = useState(0);
    const [convertedTotalOpen, setConvertedTotalOpen] = useState(0);
    const [defaultCurrency, setDefaultCurrency] = useState('USD($)');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedCreditNote, setSelectedCreditNote] = useState(null);

    const bankPaymentTypes = [
        { label: 'Bank Transfer', value: 'Bank Transfer' },
        { label: 'Bank Charges', value: 'Bank Charges' },
        { label: 'Credit Card', value: 'Credit Card' },
        { label: 'Bank Cheque', value: 'Bank Cheque' },
        { label: 'Cash', value: 'Cash' },
        { label: 'Credit Note', value: 'Credit Note' },
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm();

    const handlePayFileOnChange = (e) => {
        if (e.target.files) {
            setPaymentFile(e.target.files[0]);
        }
    };

    const currencies = [
        { label: 'USD($)', value: 'usd' },
        { label: 'EUR(€)', value: 'eur' },
        { label: 'AED(AED)', value: 'aed' }
    ];     

    const formatOptionLabel = ({ label, value }) => (
        <div dangerouslySetInnerHTML={{ __html: label }} />
    );


    useEffect(() => {
        
        const id = estimate?.transit_company_id || estimate?.customer_id;
		api.getSelectOptionsListWithId('get_available_credit_notes_by_invoice', id).then((res) => {
            if (res?.status) {
                const tempList = res.data.map((item) => ({
                    ...item,
                    label: `${item.option_label_name}`,
                    value: item.id,
                }));
                setCreditNoteLists(tempList);
            }
		});
    }, [])
    
    useEffect(() => {
        if (estimate?.customer?.credit_notes?.length > 0) {

            estimate?.customer?.credit_notes?.map((credit) => {
                if (credit?.remaining_credit > 0) {
                    setAllowInvoiceSettlement(true);
                    setTransitCompanyCreditNotes((prev) => [
                        ...prev,
                        {
                            label: `<div class="font500">🔴 Credit Note ${credit?.invoice_number} ${credit?.customer?.name
                                ? ('<span style="margin-left: 5px; margin-right: 5px">•</span> ' + credit?.customer?.name + '')
                                : ''} <span style="margin-left: 5px; margin-right: 5px">•</span> ${estimate?.customer?.name}</div> <div>${credit?.start_date} <span style="margin-left: 5px; margin-right: 5px">•</span> ${credit?.currency} ${credit?.remaining_credit}</div><div>${credit?.estimation_details?.map((item => item.details)).join(', ')}</div>`,
                            value: credit?.id,
                            remainingCredit: credit?.remaining_credit
                        }
                    ]);
                }
            });
        }
        // setTransitCompanyCreditNotes(estimate?.customer?.credit_notes || [])
        console.log('dito yan: ')
        reset({
            // paymentType: editPayment?.pay_type || 'Bank Transfer',
            paymentAmount: editPayment?.amount_paid || 0,
            paymentDate: editPayment?.payment_date ? moment(editPayment?.payment_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        });
        setImageId(editPayment?.payment_attachment?.id || null);
        setTotalOpen(estimate?.open_balance?.open);
        setConvertedTotalOpen(estimate?.converted_currency_open_balance[estimate?.currency])

        if (estimate?.currency === 'eur') {
            setDefaultCurrency('EUR(€)');
        } else if (estimate?.currency === 'usd') {
            setDefaultCurrency('USD($)');
        } else if (estimate?.currency === 'aed') {
            setDefaultCurrency('AED(AED)');
        } else {
            setDefaultCurrency('USD($)');
        }
        setSelectedCurrency(estimate?.currency);
        

    }, [showPaymentModal, editPayment]);

    useEffect(() => {
        
        const subscription = watch((value, { name, type, currency }) => {
            
            if (name == 'paymentAmount' && type == 'change') {
                calculateNewOpen(value?.paymentAmount, selectedCurrency);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, selectedCurrency]);

    useEffect(() => {
        if (defaultPaymentType != null) {
            setSelectedPaymentType(defaultPaymentType)
        } else {
            setSelectedPaymentType('')
        }
        if (selectedPaymentDetails != null) {
            setAmountToPay(selectedPaymentDetails?.amount_paid);
            setTotalOpen(Number(estimate?.open_balance?.open) + Number(selectedPaymentDetails?.amount_paid));
            setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[selectedCurrency]) + Number(selectedPaymentDetails?.amount_paid));
        } else {
            setSelectedPaymentType('')
        }
    }, [defaultPaymentType, selectedPaymentDetails])

    const calculateNewOpen = (paid, currency) => {
        setAmountToPay(paid)
        if (editPayment) {
            setTotalOpen(Number(estimate?.open_balance?.open) + Number(editPayment?.amount_paid) - Number(paid));
            setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[currency]) + Number(editPayment?.amount_paid) - Number(paid));
        } else {
            setTotalOpen(Number(estimate?.open_balance?.open) - Number(paid));
            setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[currency]) - Number(paid));
        }
    };

    const handleOnSubmit = (data) => {
        console.log('sa data: ', selectedPaymentType)
        if (!selectedPaymentType) {
            toast.error('Payment Type is required');
            return false;
        }
        if (amountToPay === '0.00' || amountToPay === 0 || amountToPay === '') {
            toast.error('Amount is required');
            return false
        }
        onFormSubmit &&
            onFormSubmit({
                payment_type: selectedPaymentType,
                credit_note_id: selectedCreditNote?.id,
                payment_date: data?.paymentDate,
                // amount_paid: data?.paymentAmount,
                amount_paid: amountToPay,
                currency: selectedCurrency,
                estimation_attachment_id: imageId,
                payment_id: editPayment?.id || undefined
            });
    };

    const handleFileUpload = (e) => {
        const files = e.target.files;
        setFiles(files);
        setDisablestatus(true);
        if (files && files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('payment_file', files[i]);
            }
            if (source === 'invoice') {
                formData.append('estimation_id', estimate?.id);
                api.addInvoicePaymentFile(formData).then((res) => {
                    setDisablestatus(false);
                    setImageId(res.data);
                });
            } else {
                formData.append('sales_order_id', estimate?.id);
                api.addSalesOrderPaymentFile(formData).then((res) => {
                    setDisablestatus(false);
                    setImageId(res.data);
                });
            }

        }
    };

    const handleChangeCurrency = (currency) => {
        setConvertedTotalOpen(estimate?.converted_currency_open_balance[currency?.value])
        setDefaultCurrency(currency.label);
        setSelectedCurrency(currency?.value);
    }

    const handleCreditChange = (e) => {
        setSelectedCreditNote(e);
        setAmountToPay(e?.amount);
        setSelectedCurrency(e?.currency);
        
        // setPaymentDetails({ ...paymentDetails, amount: e?.amount, currency: e?.currency });
    }

    useEffect(() => { 
        const computeOpenBalance = estimate?.converted_currency_open_balance[selectedCurrency] - amountToPay;
        setConvertedTotalOpen(computeOpenBalance)
    }, [selectedCurrency, amountToPay])
    
    return (
        <Modal show={showPaymentModal} onHide={handleClose} centered size="md">
            <Modal.Body>
                <div className="" style={{ padding: '20px' }}>
                    <form onSubmit={handleSubmit(handleOnSubmit)}>

                        <div >
                            <div className="" style={{ marginBottom: '20px' }}>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center">
                                            <h2 className="h4 mb-0 font-weight-bold">Register payment</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '15px' }}>
                                    <div className="col-12 text-center">
                                        You are going to settle an amount for {source === 'invoice' ? 'Invoice ' : 'Sales Order '}
                                        <span className='text-primary font500'>{estimate?.invoice_number ?? estimate?.estimation_number}</span>.
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                {/* {
                                    selectedPaymentType === 'Credit Note' ? (
                                        <>

                                            <div className="form-group col-md-12">
                                                <label htmlFor="paymentType">Invoice</label>

                                                <Select
                                                    className="rs-container"
                                                    classNamePrefix="rs"
                                                    isSearchable={true}
                                                    // name="invoice"
                                                    options={transitCompanyCreditNotes}
                                                    placeholder="Select Credit Note"
                                                    formatOptionLabel={formatOptionLabel}
                                                    onChange={(selected) => {
                                                        setSelectedCreditNoteId(selected.value)

                                                        setRemainingBalance(selected.remainingCredit);
                                                        setRemainingCredit(0)
                                                        setOptionSelected(true);
                                                    }}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            height: optionSelected && '77px',
                                                        }),
                                                    }}
                                                // {...register('invoice', { required: true })}
                                                />

                                                {errors.invoice && <p className="mt-1 text-danger">Invoice is required</p>}
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentType">Payment Type</label>
                                                

                                                <Select
                                                    menuPosition={'fixed'}
                                                    options={bankPaymentTypes}
                                                    value={bankPaymentTypes?.find((y) => y?.value == selectedPaymentType)}
                                                    className="rs-container"
                                                    classNamePrefix="rs"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        setSelectedPaymentType(e?.value);
                                                    }}
                                                />

                                                {errors.paymentType && <p className="mt-1 text-danger">Payment Type is required</p>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentAmount">Date</label>
                                                <input type="date" className="form-control" id="paymentDate" {...register('paymentDate', { required: true })} />
                                                {errors.paymentDate && <p className="mt-1 text-danger">Invalid Date</p>}
                                            </div>
                                                <div className="form-group col-md-6">
                                                    
                                                <label htmlFor="paymentAmount">Amount ({getCurrencySymbol(estimate?.currency)})</label>
                                                <input type="number"
                                                    className="form-control"
                                                    id="paymentAmount"
                                                    {...register('paymentAmount', { required: true, min: 1 })}
                                                    value={remainingCredit}
                                                    onChange={(e) => {
                                                        if (Number(e.target.value) <= remainingBalance) {
                                                            setRemainingCredit(e.target.value);
                                                        }
                                                    }}
                                                    onFocus={(event) => {
                                                        if (event.target.value == '0.00' || event.target.value == '0') {
                                                            setRemainingCredit('');
                                                        }
                                                        event.target.select();
                                                    }}
                                                />
                                                {errors.paymentAmount && <p className="mt-1 text-danger">Invalid Amount</p>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentAmount">Open ({getCurrencySymbol(estimate?.currency)})</label>
                                                <input type="text" className="form-control" value={numberFormatter.format(totalOpen)} readOnly />
                                            </div>
                                        </>
                                    ) : (
                                        <> */}
                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentType">Payment Type</label>
                                                {/* <input type="text" className="form-control" value={selectedPaymentType} id="paymentType" readOnly /> */}

                                                <Select
                                                    menuPosition={'fixed'}
                                                    options={bankPaymentTypes}
                                                    value={bankPaymentTypes?.find((y) => y?.value == selectedPaymentType)}
                                                    className="rs-container"
                                                    classNamePrefix="rs"
                                                    placeholder="Select or type..."
                                                    onChange={(e) => {
                                                        setSelectedPaymentType(e?.value);
                                                    }}
                                                />

                                                {errors.paymentType && <p className="mt-1 text-danger">Payment Type is required</p>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentAmount">Date</label>
                                                <input type="date" className="form-control" id="paymentDate" {...register('paymentDate', { required: true })} />
                                                {errors.paymentDate && <p className="mt-1 text-danger">Invalid Date</p>}
                                            </div>
                                            {
                                                selectedPaymentType === 'Credit Note' && (
                                                    <>
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="paymentType">Available Credit Note</label>
            
                                                            {/* <Select
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                isSearchable={true}
                                                                // name="invoice"
                                                                options={transitCompanyCreditNotes}
                                                                placeholder="Select Credit Note"
                                                                formatOptionLabel={formatOptionLabel}
                                                                onChange={(selected) => {
                                                                    setSelectedCreditNoteId(selected.value)
            
                                                                    setRemainingBalance(selected.remainingCredit);
                                                                    setRemainingCredit(0)
                                                                    setOptionSelected(true);
                                                                }}
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        height: optionSelected && '77px',
                                                                    }),
                                                                }}
                                                            // {...register('invoice', { required: true })}
                                                            /> */}
                                                            {/* <Select
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                isSearchable={true}
                                                                // name="invoice"
                                                                options={creditNoteLists}
                                                                placeholder="Select Credit Note"
                                                                formatOptionLabel={formatOptionLabel}
                                                                onChange={(selected) => {
                                                                    setSelectedCreditNoteId(selected.value)
            
                                                                    setRemainingBalance(selected.remainingCredit);
                                                                    setRemainingCredit(0)
                                                                    setOptionSelected(true);
                                                                }}
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        height: optionSelected && '77px',
                                                                    }),
                                                                }}
                                                            // {...register('invoice', { required: true })}
                                                            /> */}
            
                                                            <ReactSelect
                                                                className="rs-container"
                                                                classNamePrefix="rs"
                                                                options={[...creditNoteLists]?.map((m) => ({
                                                                    ...m,
                                                                    optionAttribute: m.attribute,
                                                                }))}
                                                                // value={models?.find((v) => v?.value == item.model)}
                                                                menuPlacement="auto"
                                                                menuPosition="fixed"
                                                                placeholder="Select or type..."
                                                                formatOptionLabel={({ label, optionAttribute }) => (
                                                                    <div dangerouslySetInnerHTML={{ __html: label }}></div>
                                                                )}
                                                                onChange={(e) => {
                                                                    handleCreditChange(e);
                                                                }}
                                                                // onChange={(e) => {
                                                                //     handleCreditChange(e);
                                                                // }}
                                                                // onChange={(selected) => {
                                                                //     setSelectedCreditNoteId(selected.value)
            
                                                                //     setRemainingBalance(selected.remainingCredit);
                                                                //     setRemainingCredit(0)
                                                                //     setOptionSelected(true);
                                                                // }}
                                                                styles={{
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        // width: '800px', // Adjust the width as needed
                                                                        // marginLeft: '-73px',
                                                                        // height: '370px', // Set the maximum height of the dropdown box
                                                                        overflowY: 'auto', // Enable vertical scrolling
                                                                    }),
                                                                    option: (provided, { selectProps, data }) => {
                                                                        // Find the index of the current option
                                                                        const index = selectProps.options.findIndex(option => option.value === data.value);
                                                                        return {
                                                                        ...provided,
                                                                        marginBottom: '5px', // Existing space between options
                                                                        marginTop: index === 0 ? '10px' : 0, // Add more spacing on top of the first option
                                                                        };
                                                                    },
                                                                    // option: (provided) => ({
                                                                    //   ...provided,
                                                                    // 	marginBottom: '5px', // Add space between options
                                                                        
                                                                    // }),
                                                                    // Additional custom styles can be added here
                                                                }}
                                                            />
            
                                                            {errors.invoice && <p className="mt-1 text-danger">Invoice is required</p>}
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentAmount">Amount </label>
                                                <div className="custom-input-group payment-input-group">
                                        
                                                    {
                                                        selectedPaymentType === 'Credit Note' ? (
                                                            <>
                                                                <div className=" d-flex input-group-2-form input-group-2-form-currency">
                        
                                                                    <input
                                                                        type="text"
                                                                        className="form-control left-input"
                                                                        id="inputName"
                                                                        value={selectedCreditNote?.currency_label}
                                                                        disabled={true}
                                                                        style={{ width: '75px' }}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        className="form-control right-input"
                                                                        // id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                                        value={amountToPay}
                                                                        onFocus={(event) => {
                                                                            if (event.target.value == '0.00' || event.target.value == '0') {
                                                                                setAmountToPay('');
                                                                            }
                                                                            event.target.select();
                                                                        }}
                                                                        autoComplete={'off'}
                                                                        onClick={(e) => {
                                                                            if (e.target.value === '0' || e.target.value === '0.00') {
                                                                                setAmountToPay('');
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (e.target.value === '' || e.target.value === '0' || e.target.value === '0.00') {
                                                                                setAmountToPay('0.00');
                                                                                // setPaymentDetails({ ...paymentDetails, amount: '0.00' });
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setAmountToPay(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                    
                                                                <InputGroup className="mb-3 d-flex">
                                                                    <DropdownButton
                                                                        variant="outline-secondary"
                                                                        title={defaultCurrency}
                                                                        id="input-group-dropdown-1"
                                                                    >
                                                                        {
                                                                            currencies.map((currency) => { 
                                                                                return (
                                                                                    <Dropdown.Item
                                                                                        href="#"
                                                                                        key={currency.value}
                                                                                        onClick={() => { 
                                                                                            handleChangeCurrency(currency)
                                                                                        }}
                                                                                    >
                                                                                        {currency.label}
                                                                                    </Dropdown.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </DropdownButton>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        // id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                                        // value={amountToPay}
                                                                        onFocus={(event) => {
                                                                            if (event.target.value == '0.00' || event.target.value == '0') {
                                                                                setAmountToPay('');
                                                                            }
                                                                            event.target.select();
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setAmountToPay(e.target.value);
                                                                        }}
                                                                        autoComplete={'off'}
                                                                        autoFocus
                                                                    />
                                                                </InputGroup>
                                                            </>
                                                        )
                                                    }
                                        
                                                </div>
                                                
                                                {/* <input
                                                    type="number"
                                                    className="form-control"
                                                    id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
                                                    value={amountToPay}
                                                    onFocus={(event) => {
                                                        if (event.target.value == '0.00' || event.target.value == '0') {
                                                            setAmountToPay('');
                                                        }
                                                        event.target.select();
                                                    }}
                                                /> */}
                                    
                                                {errors.paymentAmount && <p className="mt-1 text-danger">Invalid Amount</p>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="paymentAmount">Open ({getCurrencySymbol(selectedCurrency)})</label>
                                                {/* <input type="text" className="form-control" value={numberFormatter.format(totalOpen)} readOnly /> */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    // value={numberFormatter.format(totalOpen)}
                                                    value={numberFormatter.format(convertedTotalOpen || 0)}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="shipmentTracking">Attachment</label>
                                                <FileUpload
                                                    accept=".png,.jpg,.jpeg,.pdf"
                                                    loading={disableStatus}
                                                    onChange={(e) => {
                                                        handleFileUpload(e);
                                                    }}
                                                    selectedValue={editPayment?.payment_attachment}
                                                />
                                            </div>
                                        {/* </>
                                    )
                                } */}
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '15px' }}>
                            {/* <div className="col-md-8 col-12">
                                <nav className="d-none d-sm-block">
                                    <ol class="cd-breadcrumb triangle">
                                        <li className={!selectedPaymentType && ('current')} style={{
                                            borderLeft: '1px solid #eaeaea',
                                        }}>
                                            <a href="javascript:void(0)"
                                                className="font14"
                                                style={{
                                                    textDecoration: 'none',
                                                    borderTop: '1px solid #eaeaea',
                                                    borderBottom: '1px solid #eaeaea',
                                                }}
                                                onClick={() => {
                                                    if (defaultPaymentType == null) {
                                                        setSelectedPaymentType(null);

                                                        setOptionSelected(false);
                                                    }
                                                }}
                                            >
                                                Choose payment method
                                            </a>
                                        </li>
                                        <li className={selectedPaymentType && ('current')}>
                                            <a href="javascript:void(0)" className="font14" style={{ textDecoration: 'none' }}>
                                                Register payment
                                            </a>
                                        </li>

                                    </ol>
                                </nav>
                            </div> */}
                            <div className="col-12 d-flex justify-right items-center">
                                <button
                                    className="btn btn-primary btn-outlined-main"
                                    type="submit"
                                    disabled={disableStatus}
                                    style={{ marginRight: '10px' }}
                                >
                                    <FaSave /> {editPayment ? 'Update' : 'Save'}
                                </button>
                                <button className="btn btn-primary btn-outlined-main" onClick={handleClose}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PaymentFormModal;









// import moment from 'moment';
// import React, { useEffect, useState } from 'react';
// import { Modal } from 'react-bootstrap';
// import { useForm } from 'react-hook-form';
// import { FaMinus, FaSave, FaTimes, FaChevronRight } from 'react-icons/fa';
// import FileUpload from 'ui-component/FileUpload';
// import { getCurrencySymbol, numberFormatter } from 'utils/currency';
// import Select from 'react-select';
// import { toast } from 'react-hot-toast';
// import InputGroup from 'react-bootstrap/InputGroup';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Dropdown from 'react-bootstrap/Dropdown';



// function PaymentFormModal({ showPaymentModal, editPayment, handleClose, onFormSubmit, estimate, defaultPaymentType, selectedPaymentDetails, source }) {
//     const [disableStatus, setDisablestatus] = useState(false);
//     const [imageId, setImageId] = useState(null);
//     const [files, setFiles] = useState([]);
//     const [selectedPaymentType, setSelectedPaymentType] = useState('');
//     const [selectedCreditNoteId, setSelectedCreditNoteId] = useState(null);
//     const [transitCompanyCreditNotes, setTransitCompanyCreditNotes] = useState([]);
//     const [remainingBalance, setRemainingBalance] = useState(0);
//     const [remainingCredit, setRemainingCredit] = useState(0);
//     const [amountToPay, setAmountToPay] = useState(0);
//     const [allowInvoiceSettlement, setAllowInvoiceSettlement] = useState(false);
//     const [optionSelected, setOptionSelected] = useState(false);
//     const [totalOpen, setTotalOpen] = useState(0);
//     const [convertedTotalOpen, setConvertedTotalOpen] = useState(0);
//     const [defaultCurrency, setDefaultCurrency] = useState('USD($)');
//     const [selectedCurrency, setSelectedCurrency] = useState('');

//     const bankPaymentTypes = [
//         { label: 'Bank Transfer', value: 'Bank Transfer' },
//         { label: 'Bank Charges', value: 'Bank Charges' },
//         { label: 'Credit Card', value: 'Credit Card' },
//         { label: 'Bank Cheque', value: 'Bank Cheque' },
//         { label: 'Cash', value: 'Cash' },
//     ];

//     const {
//         register,
//         handleSubmit,
//         formState: { errors },
//         reset,
//         watch
//     } = useForm();

//     const handlePayFileOnChange = (e) => {
//         if (e.target.files) {
//             setPaymentFile(e.target.files[0]);
//         }
//     };

//     const currencies = [
//         { label: 'USD($)', value: 'usd' },
//         { label: 'EUR(€)', value: 'eur' },
//         { label: 'AED(AED)', value: 'aed' }
//     ];     

//     const formatOptionLabel = ({ label, value }) => (
//         <div dangerouslySetInnerHTML={{ __html: label }} />
//     );

//     useEffect(() => {
//         if (estimate?.customer?.credit_notes?.length > 0) {

//             estimate?.customer?.credit_notes?.map((credit) => {
//                 if (credit?.remaining_credit > 0) {
//                     setAllowInvoiceSettlement(true);
//                     setTransitCompanyCreditNotes((prev) => [
//                         ...prev,
//                         {
//                             label: `<div class="font500">🔴 Credit Note ${credit?.invoice_number} ${credit?.customer?.name
//                                 ? ('<span style="margin-left: 5px; margin-right: 5px">•</span> ' + credit?.customer?.name + '')
//                                 : ''} <span style="margin-left: 5px; margin-right: 5px">•</span> ${estimate?.customer?.name}</div> <div>${credit?.start_date} <span style="margin-left: 5px; margin-right: 5px">•</span> ${credit?.currency} ${credit?.remaining_credit}</div><div>${credit?.estimation_details?.map((item => item.details)).join(', ')}</div>`,
//                             value: credit?.id,
//                             remainingCredit: credit?.remaining_credit
//                         }
//                     ]);
//                 }
//             });
//         }
//         // setTransitCompanyCreditNotes(estimate?.customer?.credit_notes || [])
//         reset({
//             // paymentType: editPayment?.pay_type || 'Bank Transfer',
//             paymentAmount: editPayment?.amount_paid || 0,
//             paymentDate: editPayment?.payment_date ? moment(editPayment?.payment_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
//         });
//         setImageId(editPayment?.payment_attachment?.id || null);
//         setTotalOpen(estimate?.open_balance?.open);
//         setConvertedTotalOpen(estimate?.converted_currency_open_balance[estimate?.currency])

//         if (estimate?.currency === 'eur') {
//             setDefaultCurrency('EUR(€)');
//         } else if (estimate?.currency === 'usd') {
//             setDefaultCurrency('USD($)');
//         } else if (estimate?.currency === 'aed') {
//             setDefaultCurrency('AED(AED)');
//         } else {
//             setDefaultCurrency('USD($)');
//         }
//         setSelectedCurrency(estimate?.currency);
        

//     }, [showPaymentModal, editPayment]);

//     useEffect(() => {
//         const subscription = watch((value, { name, type, currency }) => {
//             console.log('dito yan', selectedCurrency)
//             if (name == 'paymentAmount' && type == 'change') {
//                 calculateNewOpen(value?.paymentAmount, selectedCurrency);
//             }
//         });
//         return () => subscription.unsubscribe();
//     }, [watch, selectedCurrency]);

//     useEffect(() => {
//         if (defaultPaymentType != null) {
//             setSelectedPaymentType(defaultPaymentType)
//         } else {
//             setSelectedPaymentType('')
//         }
//         if (selectedPaymentDetails != null) {
//             setAmountToPay(selectedPaymentDetails?.amount_paid);
//             setTotalOpen(Number(estimate?.open_balance?.open) + Number(selectedPaymentDetails?.amount_paid));
//             setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[selectedCurrency]) + Number(selectedPaymentDetails?.amount_paid));
//         } else {
//             setSelectedPaymentType('')
//         }
//     }, [defaultPaymentType, selectedPaymentDetails])

//     const calculateNewOpen = (paid, currency) => {
//         setAmountToPay(paid)
//         if (editPayment) {
//             setTotalOpen(Number(estimate?.open_balance?.open) + Number(editPayment?.amount_paid) - Number(paid));
//             setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[currency]) + Number(editPayment?.amount_paid) - Number(paid));
//         } else {
//             setTotalOpen(Number(estimate?.open_balance?.open) - Number(paid));
//             setConvertedTotalOpen(Number(estimate?.converted_currency_open_balance[currency]) - Number(paid));
//         }
//     };

//     const handleOnSubmit = (data) => {
//         if (selectedPaymentType == 'Credit Note' && !selectedCreditNoteId) {
//             toast.error('Invoice is Required');
//             return false;
//         }
//         onFormSubmit &&
//             onFormSubmit({
//                 payment_type: selectedPaymentType,
//                 credit_note_id: selectedCreditNoteId,
//                 payment_date: data?.paymentDate,
//                 amount_paid: data?.paymentAmount,
//                 currency: selectedCurrency,
//                 estimation_attachment_id: imageId,
//                 payment_id: editPayment?.id || undefined
//             });
//     };

//     const handleFileUpload = (e) => {
//         const files = e.target.files;
//         setFiles(files);
//         setDisablestatus(true);
//         if (files && files.length > 0) {
//             const formData = new FormData();
//             for (let i = 0; i < files.length; i++) {
//                 formData.append('payment_file', files[i]);
//             }

//             formData.append('sales_order_id', estimate?.id);
//             api.addSalesOrderPaymentFile(formData).then((res) => {
//                 setDisablestatus(false);
//                 setImageId(res.data);
//             });
//         }
//     };

//     const handleChangeCurrency = (currency) => {
//         setConvertedTotalOpen(estimate?.converted_currency_open_balance[currency?.value])
//         setDefaultCurrency(currency.label);
//         setSelectedCurrency(currency?.value);
//     }
    
//     return (
//         <Modal show={showPaymentModal} onHide={handleClose} centered size="lg">
//             <Modal.Body>
//                 <div className="modal-card-padding" style={{ padding: '20px' }}>
//                     <form onSubmit={handleSubmit(handleOnSubmit)}>
//                         {
//                             !selectedPaymentType ? (
//                                 <>
//                                     <div className="payment-step-1">

//                                         <div className="" style={{ marginBottom: '20px' }}>

//                                             <div className="row">
//                                                 <div className="col-12">
//                                                     <div className="d-flex justify-content-center">
//                                                         <h2 className="h4 mb-0 font-weight-bold">How was the invoice paid?</h2>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             <div className="row" style={{ marginTop: '15px' }}>
//                                                 <div className="col-12 text-center">
//                                                     You have indicated that you want to register a payment for {source === 'invoice' ? 'invoice ' : 'sales order '} {' '}
//                                                     <span className='text-primary font500'>{estimate?.invoice_number ?? estimate?.estimation_number}</span>.{' '}
//                                                     Please indicate below how this invoice was paid.
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="row" style={{ marginTop: '15px' }}>
//                                             <div className="col-12 text-center">
//                                                 <div className="card modal-card">
//                                                     <div className="row">
//                                                         <div className="col-12 card-body-hoverable"
//                                                             onClick={() => {
//                                                                 setSelectedPaymentType('Bank Transfer');
//                                                             }}
//                                                         >
//                                                             <div className="card-body" style={{ borderBottom: '1px solid #eaeaea' }}>
//                                                                 <div className="row">
//                                                                     <div className="col-11 text-left">
//                                                                         <div className="font500 card-body-hoverable-text">Bank Transfer</div>
//                                                                         {/* <div className="textGray ">Link your bank transaction</div> */}
//                                                                     </div>
//                                                                     <div className="col-1 cardbody-chevron-container" >
//                                                                         <FaChevronRight className="card-body-hoverable-text" />
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="row">
//                                                         <div className={allowInvoiceSettlement ? 'col-12 card-body-hoverable' : 'col-12 card-body-hoverable disabled'}
//                                                             onClick={() => {
//                                                                 setSelectedPaymentType('Credit Note');
//                                                             }}
//                                                         >
//                                                             <div className={allowInvoiceSettlement ? 'card-body' : 'card-body disabled'}
//                                                                 style={{ borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
//                                                             >
//                                                                 <div className="row">
//                                                                     <div className="col-11 text-left">
//                                                                         <div className="font500 card-body-hoverable-text">Invoice Settlement</div>

//                                                                     </div>
//                                                                     <div className="col-1 cardbody-chevron-container" >
//                                                                         <FaChevronRight className="card-body-hoverable-text" />
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>
//                             ) : (
//                                 <>

//                                     <div className="payment-step-2">
//                                         <div className="" style={{ marginBottom: '20px' }}>

//                                             <div className="row">
//                                                 <div className="col-12">
//                                                     <div className="d-flex justify-content-center">
//                                                         <h2 className="h4 mb-0 font-weight-bold">Register payment</h2>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                             <div className="row" style={{ marginTop: '15px' }}>
//                                                 <div className="col-12 text-center">
//                                                     You are going to settle an amount for {source === 'invoice' ? 'Invoice ' : 'Sales Order '}
//                                                     <span className='text-primary font500'>{estimate?.invoice_number ?? estimate?.estimation_number}</span>.
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="row m-0">
//                                             {
//                                                 selectedPaymentType === 'Credit Note' ? (
//                                                     <>

//                                                         <div className="form-group col-md-12">
//                                                             <label htmlFor="paymentType">Invoice</label>

//                                                             <Select
//                                                                 className="rs-container"
//                                                                 classNamePrefix="rs"
//                                                                 isSearchable={true}
//                                                                 // name="invoice"
//                                                                 options={transitCompanyCreditNotes}
//                                                                 placeholder="Select Credit Note"
//                                                                 formatOptionLabel={formatOptionLabel}
//                                                                 onChange={(selected) => {
//                                                                     setSelectedCreditNoteId(selected.value)

//                                                                     setRemainingBalance(selected.remainingCredit);
//                                                                     setRemainingCredit(0)
//                                                                     setOptionSelected(true);
//                                                                 }}
//                                                                 styles={{
//                                                                     control: (provided) => ({
//                                                                         ...provided,
//                                                                         height: optionSelected && '77px',
//                                                                     }),
//                                                                 }}
//                                                             // {...register('invoice', { required: true })}
//                                                             />

//                                                             {errors.invoice && <p className="mt-1 text-danger">Invoice is required</p>}
//                                                         </div>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentType">Payment Type</label>

//                                                             <input type="text" className="form-control" value={selectedPaymentType} id="paymentType" readOnly />

//                                                             {errors.paymentType && <p className="mt-1 text-danger">Payment Type is required</p>}
//                                                         </div>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentAmount">Date</label>
//                                                             <input type="date" className="form-control" id="paymentDate" {...register('paymentDate', { required: true })} />
//                                                             {errors.paymentDate && <p className="mt-1 text-danger">Invalid Date</p>}
//                                                         </div>
//                                                             <div className="form-group col-md-6">
                                                                
//                                                             <label htmlFor="paymentAmount">Amount ({getCurrencySymbol(estimate?.currency)})</label>
//                                                             <input type="number"
//                                                                 className="form-control"
//                                                                 id="paymentAmount"
//                                                                 {...register('paymentAmount', { required: true, min: 1 })}
//                                                                 value={remainingCredit}
//                                                                 onChange={(e) => {
//                                                                     if (Number(e.target.value) <= remainingBalance) {
//                                                                         setRemainingCredit(e.target.value);
//                                                                     }
//                                                                 }}
//                                                                 onFocus={(event) => {
//                                                                     if (event.target.value == '0.00' || event.target.value == '0') {
//                                                                         setRemainingCredit('');
//                                                                     }
//                                                                     event.target.select();
//                                                                 }}
//                                                             />
//                                                             {errors.paymentAmount && <p className="mt-1 text-danger">Invalid Amount</p>}
//                                                         </div>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentAmount">Open ({getCurrencySymbol(estimate?.currency)})</label>
//                                                             <input type="text" className="form-control" value={numberFormatter.format(totalOpen)} readOnly />
//                                                         </div>
//                                                     </>
//                                                 ) : (
//                                                     <>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentType">Payment Type</label>
//                                                             {/* <input type="text" className="form-control" value={selectedPaymentType} id="paymentType" readOnly /> */}

//                                                             <Select
//                                                                 menuPosition={'fixed'}
//                                                                 options={bankPaymentTypes}
//                                                                 value={bankPaymentTypes?.find((y) => y?.value == selectedPaymentType)}
//                                                                 className="rs-container"
//                                                                 classNamePrefix="rs"
//                                                                 placeholder=""
//                                                                 onChange={(e) => {
//                                                                     setSelectedPaymentType(e?.value);
//                                                                 }}
//                                                             />

//                                                             {errors.paymentType && <p className="mt-1 text-danger">Payment Type is required</p>}
//                                                         </div>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentAmount">Date</label>
//                                                             <input type="date" className="form-control" id="paymentDate" {...register('paymentDate', { required: true })} />
//                                                             {errors.paymentDate && <p className="mt-1 text-danger">Invalid Date</p>}
//                                                         </div>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentAmount">Amount </label>
//                                                             <div className="custom-input-group payment-input-group">
//                                                                 <InputGroup className="mb-3 d-flex">
//                                                                     <DropdownButton
//                                                                         variant="outline-secondary"
//                                                                         title={defaultCurrency}
//                                                                         id="input-group-dropdown-1"
//                                                                     >
//                                                                         {
//                                                                             currencies.map((currency) => { 
//                                                                                 return (
//                                                                                     <Dropdown.Item
//                                                                                         href="#"
//                                                                                         key={currency.value}
//                                                                                         onClick={() => { 
//                                                                                             handleChangeCurrency(currency)
//                                                                                         }}
//                                                                                     >
//                                                                                         {currency.label}
//                                                                                     </Dropdown.Item>
//                                                                                 )
//                                                                             })
//                                                                         }
//                                                                     </DropdownButton>
//                                                                     <input
//                                                                         type="number"
//                                                                         className="form-control"
//                                                                         id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
//                                                                         value={amountToPay}
//                                                                         onFocus={(event) => {
//                                                                             if (event.target.value == '0.00' || event.target.value == '0') {
//                                                                                 setAmountToPay('');
//                                                                             }
//                                                                             event.target.select();
//                                                                         }}
//                                                                         autoComplete={'off'}
//                                                                         autoFocus
//                                                                     />
//                                                                 </InputGroup>
//                                                             </div>
                                                            
//                                                             {/* <input
//                                                                 type="number"
//                                                                 className="form-control"
//                                                                 id="paymentAmount" {...register('paymentAmount', { required: true, min: 1 })}
//                                                                 value={amountToPay}
//                                                                 onFocus={(event) => {
//                                                                     if (event.target.value == '0.00' || event.target.value == '0') {
//                                                                         setAmountToPay('');
//                                                                     }
//                                                                     event.target.select();
//                                                                 }}
//                                                             /> */}
//                                                             {errors.paymentAmount && <p className="mt-1 text-danger">Invalid Amount</p>}
//                                                         </div>
//                                                         <div className="form-group col-md-6">
//                                                             <label htmlFor="paymentAmount">Open ({getCurrencySymbol(selectedCurrency)})</label>
//                                                             {/* <input type="text" className="form-control" value={numberFormatter.format(totalOpen)} readOnly /> */}
//                                                             <input
//                                                                 type="text"
//                                                                 className="form-control"
//                                                                 // value={numberFormatter.format(totalOpen)}
//                                                                 value={numberFormatter.format(convertedTotalOpen || 0)}
//                                                                 readOnly
//                                                             />
//                                                         </div>
//                                                         <div className="form-group col-md-12">
//                                                             <label htmlFor="shipmentTracking">Attachment</label>
//                                                             <FileUpload
//                                                                 accept=".png,.jpg,.jpeg,.pdf"
//                                                                 loading={disableStatus}
//                                                                 onChange={(e) => {
//                                                                     handleFileUpload(e);
//                                                                 }}
//                                                                 selectedValue={editPayment?.payment_attachment}
//                                                             />
//                                                         </div>
//                                                     </>
//                                                 )
//                                             }
//                                         </div>
//                                     </div>

//                                 </>
//                             )
//                         }
//                         <div className="row" style={{ marginTop: '15px' }}>
//                             <div className="col-md-8 col-12">
//                                 <nav className="d-none d-sm-block">
//                                     <ol class="cd-breadcrumb triangle">
//                                         <li className={!selectedPaymentType && ('current')} style={{
//                                             borderLeft: '1px solid #eaeaea',
//                                         }}>
//                                             <a href="javascript:void(0)"
//                                                 className="font14"
//                                                 style={{
//                                                     textDecoration: 'none',
//                                                     borderTop: '1px solid #eaeaea',
//                                                     borderBottom: '1px solid #eaeaea',
//                                                 }}
//                                                 onClick={() => {
//                                                     if (defaultPaymentType == null) {
//                                                         setSelectedPaymentType(null);

//                                                         setOptionSelected(false);
//                                                     }
//                                                 }}
//                                             >
//                                                 Choose payment method
//                                             </a>
//                                         </li>
//                                         <li className={selectedPaymentType && ('current')}>
//                                             <a href="javascript:void(0)" className="font14" style={{ textDecoration: 'none' }}>
//                                                 Register payment
//                                             </a>
//                                         </li>

//                                     </ol>
//                                 </nav>
//                             </div>
//                             <div className="col-md-4 col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <button
//                                     className="btn btn-primary btn-outlined-main"
//                                     type="submit"
//                                     disabled={disableStatus}
//                                     style={{ marginRight: '10px' }}
//                                 >
//                                     <FaSave /> {editPayment ? 'Update' : 'Save'}
//                                 </button>
//                                 <button className="btn btn-primary btn-outlined-main" onClick={handleClose}>
//                                     Cancel
//                                 </button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </Modal.Body>
//         </Modal>
//     );
// }

// export default PaymentFormModal;

import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus, FaSave, FaTimes, FaFastForward, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { ImSpinner2 } from 'react-icons/im';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';
import mgLogo from 'assets/images/mglogo.svg';
import glpLogo from 'assets/images/glplogo.png';
import utLogo from 'assets/images/used-trading-logo.png';
import agreementImage from 'assets/images/agreement.svg';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';

// import './orderform.scss';
import ContactPopup from '../estimation/OrderForm/ContactPopup';
import { FaPen } from 'react-icons/fa6';
import ProductsPopup from '../estimation/OrderForm/ProductsPopup';
import Notes from 'ui-component/notes';
import config from '../../config';
import OrderAgreementsPopup from '../estimation/OrderForm/OrderAgreementsPopup';
import ProductsPopupMobile from '../estimation/OrderForm/ProductsPopupMobile';
import useWindowDimensions from 'hooks/useWindowDimensions';
import TimelineStep from 'ui-component/TimelineStep';

const SalesOrderForm = ({ edit, draft, type = 'sales-order' }) => {
	const { id } = useParams();
	const history = useNavigate();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const [draftLoading, setDraftLoading] = useState(false);
	const [draftId, setDraftId] = useState(draft ? id : null);
	const [nextDraftNumber, setNextDraftNumber] = useState(null);
	const [currentRefNumber, setCurrentRefNumber] = useState(null);
	const [orderId, setOrderId] = useState(!draft ? id : null);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [skipAgreement, setSkipAgreement] = useState(false);
	const { width: windowWidth } = useWindowDimensions();
	const [products, setProducts] = useState([]);
	const [isProductsLoaded, setIsProductsLoaded] = useState(false);
	
	const [isProcessing, setIsProcessing] = useState(false);
	const [settingsDetails, setSettingsDetails] = useState();

	const [orderType, setOrderType] = useState(type);

	const location = useLocation();

	useEffect(() => {
		setOrderType('sales-order');
		setDraftId(draft ? id : null);
		setOrderId(!draft ? id : null);
	}, [type, draft]);
	// ## States and Methods for Contact Modal ## //
	const [contactModal, setContactModal] = useState(false);

	const handleContactModalOpen = () => {
		setContactModal(true);
	};
	const handleContactModalClose = () => {
		setContactModal(false);
	};

	useEffect(() => {
		// alert('qwe')


		const handleEscKey = (event) => {
			if (event.keyCode === 27) {
				handleContactModalClose();
				handleProductsModalClose();
			}
		};

		document.addEventListener('keydown', handleEscKey, false);

		return () => {
			document.removeEventListener('keydown', handleEscKey, false);
		};
	}, []); // Empty array ensures effect is only run on mount and unmount

	// ## States and Methods for Products Modal ## //
	const [productsModal, setProductsModal] = useState(false);

	const handleProductsModalOpen = () => {
		setProductsModal(true);
	};
	const handleProductsModalClose = () => {
		setProductsModal(false);
	};

	// ## States and Methods for Order Agreements Modal ## //
	const [orderAgreementsModal, setOrderAgreementsModal] = useState(false);

	const handleOrderAgreementsModalOpen = () => {
		setOrderAgreementsModal(true);
	};
	const handleOrderAgreementsModalClose = () => {
		setOrderAgreementsModal(false);
	};
	const handleSkipAgreement = (data) => {
		setOrderAgreementsModal(false);
		setSkipAgreement(data);
		// setSkipOrderAgreements(data)
	};

	// ## Common variable to check if any modal is active ## //
	const modalActive = contactModal || productsModal;

	// ## Handle Contact Submit ## //
	const [salesOrderData, setOrderDetails] = useState(null);
	const handleContactSubmit = (data) => {
		setOrderDetails(data);
		setContactModal(false);
		if (!orderId) {
			handleDraftSave({
				draftIdVar: draftId,
				salesOrderDataVar: data
			});
		}
	};

	const [productsData, setProductsData] = useState([]);
	const [shippingFee, setShippingFee] = useState(0);
	const [discount, setDiscount] = useState({
		discountType: 'percentage', // percentage, amount
		discountValue: 0
	});
	const [preloadedSelectionsProdModals, setPreloadedSelectionsProdModals] = useState(null);
	const shippingQty = 1;
	const handleProductSubmit = (data) => {
		setProductsData(data?.invItems);
		setShippingFee(data?.shippingFee);
		setDiscount(data?.discount);
		setPreloadedSelectionsProdModals(data?.selections);
		setProductsModal(false);
		if (!orderId) {
			handleDraftSave({
				draftIdVar: draftId,
				salesOrderDataVar: salesOrderData,
				productsDataVar: data?.invItems,
				shippingFeeVar: data?.shippingFee,
				discountVar: data?.discount
			});
		}
	};

	// ## Handle Order Agreements Submit ## //
	const [orderAgreementsData, setOrderAgreementsData] = useState([]);
	const [orderAgreementsSelections, setOrderAgreementsSelections] = useState(null);
	const handleOrderAgreementsSubmit = (data) => {
		const preparedData = data?.selectedAgreements?.sort((a, b) => a?.agreement?.id - b?.agreement?.id);
		setOrderAgreementsData(preparedData);
		setOrderAgreementsSelections(data?.selections);
		setOrderAgreementsModal(false);
		if (!orderId) {
			handleDraftSave({
				orderAgreementsVar: preparedData
			});
		}
	};

	const validToSubmit = productsData?.length > 0 && !!salesOrderData?.customer;
	// ## Handle Notes ## //
	const [notes, setNotes] = useState([]);
	const addNote = (noteInputValue) => {
		if (edit && id) {
			toast.promise(
				api
					.newSalesOrderNote(id, {
						details: noteInputValue,
						type: 'public'
					})
					.then(
						(res) => {
							if (res.status) {
								setNotes((oldData) => [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at).format('HH:mm'),
										expand: false,
										isDeletable: true
									},
									...oldData
								]);
							}

							return res?.status ? res : null;
						},
						() => { }
					),
				{
					loading: 'Adding Note...',
					success: (data) => `${data.message}`,
					error: <b>Failed to add note</b>
				}
			);
		} else {
			setNotes((oldData) => [
				{
					details: noteInputValue,
					date: moment().format('YYYY-MM-DD'),
					time: moment().format('HH:mm'),
					user_name: userName,
					expand: false,
					isDeletable: true
				},
				...oldData
			]);

			handleDraftSave({
				draftIdVar: draftId,
				salesOrderDataVar: salesOrderData,
				productsDataVar: productsData,
				shippingFeeVar: shippingFee,
				notesVar: [
					{
						details: noteInputValue,
						date: moment().format('YYYY-MM-DD'),
						time: moment().format('HH:mm'),
						user_name: userName,
						expand: false,
						isDeletable: true
					},
					...notes
				]
			});
		}
	};

	const toggleNoteExpand = (index) => {
		setNotes((oldData) =>
			oldData?.map((note, i) =>
				index == i
					? {
						...note,
						expand: !note?.expand
					}
					: note
			)
		);
	};

	const removeNote = (index) => {
		if (edit && id && notes?.[index]?.id) {
			toast.promise(
				api.removeSalesOrderNote(id, notes?.[index]?.id).then(
					(res) => {
						if (res.status) {
							setNotes((oldData) => oldData?.filter((note, i) => index != i));
						}
						return res?.status ? res : null;
					},
					() => { }
				),
				{
					loading: 'Deleting Note...',
					success: (data) => `${data.message}`,
					error: <b>Failed to delete note</b>
				}
			);
		} else {
			setNotes((oldData) => oldData?.filter((note, i) => index != i));
			handleDraftSave({
				draftIdVar: draftId,
				salesOrderDataVar: salesOrderData,
				productsDataVar: productsData,
				shippingFeeVar: shippingFee,
				notesVar: [
					{
						details: noteInputValue,
						date: moment().format('YYYY-MM-DD'),
						time: moment().format('HH:mm'),
						user_name: userName,
						expand: false,
						isDeletable: true
					},
					...notes
				]
			});
		}
	};

	const [firstLoad, setFirstLoad] = useState(true);

	// ## Handle Draft Save ## //
	const handleDraftSave = (data) => {
		const interimData = {
			...{
				draftIdVar: draftId,
				salesOrderDataVar: salesOrderData,
				productsDataVar: productsData,
				shippingFeeVar: shippingFee,
				notesVar: notes,
				orderAgreementsVar: orderAgreementsData,
				discountVar: discount
			},
			...data
		};

		const { draftIdVar, salesOrderDataVar, productsDataVar, shippingFeeVar, notesVar, orderAgreementsVar, discountVar } = interimData;

		const modelIds = [...productsDataVar.map((item) => (item?.model?.value ? item?.model?.value : null))];
		const invoiceIds = [...productsDataVar.map((item) => (item?.invoice?.value ? item?.invoice?.value : null))];
		const quantities = [...productsDataVar.map((item) => item?.qty)];
		const unitPrices = [...productsDataVar.map((item) => item?.unitPrice)];
		const comments = [...productsDataVar.map((item) => item?.comment)];
		const customValue = [...productsDataVar.map((item) => item?.customValue)];
		const estDetailIds = [...productsDataVar.map((item) => item?.id || null)];
		setDraftLoading(true);
		api
			.saveDraftSalesOrder({
				id: draftIdVar,
				customer_id: salesOrderDataVar?.customer?.id || null,
				shipping_fee: shippingFeeVar || 0,
				shipping_qty: shippingQty || 1,
				start_at: salesOrderDataVar?.dateOrdered || moment().format('YYYY-MM-DD'),
				expires_at: moment(salesOrderDataVar?.dateOrdered).add(Number(7), 'day').format('YYYY-MM-DD'),
				transit_company_id: salesOrderDataVar?.transitCompany?.id || null,
				sales_person_id: salesOrderDataVar?.salesManager?.id || '',
				vat_type: 'Including VAT',
				vat_percent: 0,
				currency: salesOrderDataVar?.currency || 'eur',
				products: modelIds,
				qty: quantities,
				unit_price: unitPrices,
				comments: comments,
				customValues: customValue,
				notes: notesVar?.map((n) => n?.details),
				order_agreements:
					orderAgreementsVar?.map((oa) => ({
						agreement_id: oa?.agreement?.id,
						response_ids: oa?.agreement?.type == 'multiselect' ? oa?.response?.map((r) => r?.id) : [oa?.response?.id]
					})) || [],
				
				estimate_detail_ids: estDetailIds,
				discount_type: discountVar?.discountType,
				discount: discountVar?.discountValue
			})
			.then((res) => {
				!draftId && setDraftId(res?.data?.draft_id);
				setDraftLoading(false);
			});
	};

	const handleSaveOrder = () => {
		// console.log(salesOrderData?.customer);
		if (!salesOrderData?.customer?.email) {
			console.log(salesOrderData?.customer?.name)
			toast.error(
				`Customer's email address is required. Go to ${salesOrderData?.customer?.name} and add email address.`
			);
			return false;
		}
		setIsProcessing(true);
		toast.promise(
			api.saveSalesOrder(draftId).then((res) => {
				if (res?.status) {
					setIsProcessing(false);
					history(`/sales/sales-orders/${draftId}`);
				}
				return res?.status ? res : abc;
			}),
			{
				loading: `Saving record...`,
				success: (res) => res?.message,
				error: <b>Could not save sales order.</b>
			}
		);
	};

	const handleUpdateOrder = () => {
		console.log('productsData', productsData)
		const modelIds 		= [...productsData.map((item) => (item?.model?.value ? item?.model?.value : null))];
		const invoiceIds 	= [...productsData.map((item) => (item?.invoice?.value ? item?.invoice?.value : null))];
		const quantities 	= [...productsData.map((item) => item?.qty)];
		const unitPrices 	= [...productsData.map((item) => item?.unitPrice)];
		const comments 		= [...productsData.map((item) => item?.comment)];
		const customValue 	= [...productsData.map((item) => item?.customValue)];
		const itemIds 		= [...productsData.map((item) => item?.id || null)];

		setDraftLoading(true);
		toast.promise(
			api
				.updateSalesOrder(orderId, {
					customer_id: salesOrderData?.customer?.id || null,
					shipping_fee: shippingFee || 0,
					shipping_qty: shippingQty || 1,
					start_at: salesOrderData?.dateOrdered || moment().format('YYYY-MM-DD'),
					expires_at: moment(salesOrderData?.dateOrdered).add(Number(7), 'day').format('YYYY-MM-DD'),
					transit_company_id: salesOrderData?.transitCompany?.id || null,
					sales_person_id: salesOrderData?.salesManager?.id || '',
					// private_note: '',
					vat_type: 'Including VAT',
					vat_percent: 0,
					currency: salesOrderData?.currency || 'eur',
					products: modelIds,
					qty: quantities,
					unit_price: unitPrices,
					comments: comments,
					customValues: customValue,
					// creditedInvoices: invoiceIds,
					order_agreements:
						orderAgreementsData?.map((oa) => ({
							agreement_id: oa?.agreement?.id,
							response_ids: oa?.agreement?.type == 'multiselect' ? oa?.response?.map((r) => r?.id) : [oa?.response?.id]
						})) || [],
					sales_order_item_ids: itemIds,
					discount_type: discount?.discountType,
					discount: discount?.discountValue
				})
				.then((res) => {
					setDraftLoading(false);
					history(`/sales/sales-orders/${orderId}`);
					return res?.status ? res : abc;
				}),
			{
				loading: `Updating sales order...`,
				success: <b>Sales order updated!</b>,
				error: <b>Could not update sales order.</b>
			}
		);
	};

	const subtotal = productsData?.reduce((acc, item) => acc + Number(item?.unitPrice) * Number(item.qty), 0);
	const discountTotal = discount.discountType == 'percentage' ? (subtotal * discount.discountValue) / 100 : discount.discountValue;
	const total = Number(subtotal) + Number(shippingFee) * Number(shippingQty) - discountTotal;

	// ## Lifecycle Hooks ## //

	useEffect(() => {
		initiateData();
		loadProducts();
	}, []);

	const initiateData = () => {
		if (!orderId) {
			api.getDraftNumber().then((res) => {
				if (res.status) {
					setNextDraftNumber(res.data);
				}
			});
		}
		api.getSettings().then((res) => {
			setSettingsDetails(res?.data)
		});
		if (!id) {
			getNextRefNumber();
		}
		if (draft && id) {
			getAndSetDraftData();
		}
		if (edit && id) {
			getAndSetSalesOrderData();
		}
	};

	const getNextRefNumber = () => {
		setCurrentRefNumber(`${settingsDetails?.sales_order_prefix}${settingsDetails?.sales_order_sequence_no + 1}`);
	};
	useEffect(() => {
		if (!id && !edit) {
			getNextRefNumber();
		}
	}, [window.location.href]);

	const getAndSetDraftData = () => {
		api.getDraftById(id).then((res) => {
			if (!res.data) {
				history(`/sales/sales-orders`);
			} else {
				setOrderDetails(getFormattedDraftLoadContactData(res?.data));
				setProductsData(getFormattedDraftLoadProductsData(res?.data?.sales_order_items));
				setOrderAgreementsData(getFormattedDraftLoadAgreementsData(res?.data?.order_agreements));
				setShippingFee(res?.data?.shipping_fee);
				setCurrentRefNumber(res?.data?.draft_number);

				let notesArray = [];

				res?.data?.notes?.forEach((note) => {
					notesArray = [
						{
							id: note?.id,
							details: note?.details,
							date: moment(note?.created_at).format('YYYY-MM-DD'),
							time: moment(note?.created_at).format('HH:mm'),
							user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
							isDeletable: true,
							expand: false
						},
						...notesArray
					];
				});

				notesArray = _.sortBy(notesArray, (n) => -n.id);

				setNotes(notesArray);
			}
		});
	};

	const getAndSetSalesOrderData = () => {
		api.getSalesOrderById(id).then((res) => {
			if (!res.data) {
				history(`/sales/sales-orders`);
			} else {
				const orderStatus = res?.data?.data?.master_status;

				setCurrentRefNumber(res?.data?.data?.sales_order_number);
				setNextDraftNumber(res?.data?.data?.sales_order_number);
				
				setOrderDetails(getFormattedDraftLoadContactData(res?.data?.data));
				setProductsData(
					res?.data?.data?.sales_order_items?.map((item, index) => ({
						seqId: index + 1,
						id: item?.id,
						model: {
								...item?.product,
								value: item?.product?.id,
								label: item?.product?.product_name,
								product_id: item?.product?.id
						},
						qty: item?.qty,
						vat: { label: 'Including VAT', value: 1, percentage: 0 },
						unitPrice: item?.unit_price,
						comment: item?.comment,
						commentToggle: false,
						// invoice: item?.credited_invoice
						// 	? {
						// 		...item?.credited_invoice,
						// 		value: item?.credited_invoice?.id,
						// 		label: item?.credited_invoice?.invoice_number
						// 	}
						// 	: null,
						// modelOptions: item?.model_options
						// 	? item?.model_options?.map((m) => ({ ...m, label: m?.product?.product_name, value: m?.product_id }))
						// 	: [],
						modelOptionsLoading: false,
					}))
				);
				setDiscount({
					discountType: res?.data?.data?.discount_type || 'percentage',
					discountValue: res?.data?.data?.discount || 0
				});
				setOrderAgreementsData(getFormattedDraftLoadAgreementsData(res?.data?.data?.order_agreements));
				setShippingFee(Number(res?.data?.data?.shipping_fee));

				
				

				const queryParams = new URLSearchParams(location.search);

				const callbackParam = queryParams.get('callback-action-accept-order');
				if (callbackParam == 'modify-agreement') {
					setOrderAgreementsModal(true);
				}
				let notesArray = [];

				res?.data?.data?.notes?.forEach((note) => {
					notesArray = [
						{
							id: note?.id,
							details: note?.details,
							date: moment(note?.created_at).format('YYYY-MM-DD'),
							time: moment(note?.created_at).format('HH:mm'),
							user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
							isDeletable: note?.is_deletable,
							expand: false
						},
						...notesArray
					];
				});

				notesArray = _.sortBy(notesArray, (n) => -n.id);

				setNotes(notesArray);
			}
		});
	};

	const getFormattedDraftLoadContactData = (data) => {
		return {
			customer: data?.customer
				? {
					...data?.customer,
					value: data?.customer?.id,
					label: data?.customer?.name,
					address: {
						address: data?.customer?.address,
						city: { city: data?.customer?.city },
						zipcode: data?.customer?.zipcode,
						country: { name: data?.customer?.country }
					}
				}
				: '',
			logistic: data?.logistic ? { ...data?.logistic, value: data?.logistic?.id, label: data?.logistic?.name } : '',
			transitCompany: data?.transit_company
				? { ...data?.transit_company, value: data?.transit_company?.id, label: data?.transit_company?.name }
				: '',
			salesManager: data?.sales_person
				? {
					...data?.sales_person,
					value: data?.sales_person?.id,
					label: `${data?.sales_person?.first_name} ${data?.sales_person?.last_name}`
				}
				: '',
			dateOrdered: data?.start_at || moment().format('YYYY-MM-DD'),
			currency: data?.currency,
		};
	};

	const getFormattedDraftLoadProductsData = (data) => {
		return data?.map((draft, index) => ({
			seqId: index + 1,
			id: draft?.id,
			model: draft?.product
				? { ...draft?.product, value: draft?.product?.id, label: draft?.product?.product_name, product_id: draft?.product?.id }
				: '',
			qty: draft?.qty,
			vat: { label: 'Including VAT', value: 1, percentage: 0 },
			unitPrice: draft?.unit_price,
			comment: draft?.comment,
			commentToggle: false,
			invoice: draft?.credited_invoice
				? { ...draft?.credited_invoice, value: draft?.credited_invoice?.id, label: draft?.credited_invoice?.invoice_number }
				: null,
			modelOptions: draft?.model_options
				? draft?.model_options?.map((m) => ({ ...m, label: m?.product?.product_name, value: m?.product_id }))
				: [],
			modelOptionsLoading: false,
			customValue: draft?.custom_product
		}));
	};

	const getFormattedDraftLoadAgreementsData = (data) => {
		return data?.map((item) => ({
			agreement: {
				id: item?.id,
				title: item?.title,
				type: item?.type,
				is_required: item?.is_required
			},
			response:
				item?.type == 'multiselect'
					? item?.responses?.map((r) => ({
						id: r?.option?.id,
						option: r?.option?.option,
						label: r?.option?.option,
						value: r?.option?.id
					}))
					: item?.responses?.[0]
						? {
							id: item?.responses?.[0]?.option?.id,
							option: item?.responses?.[0]?.option?.option,
							label: item?.responses?.[0]?.option?.option,
							value: item?.responses?.[0]?.option?.id
						}
						: null
		}));
	};

	const loadProducts = async () => {

		api.getSelectOptionsList('product_stocks').then((res) => {
			// option_product_name: "11P256MIXAA+ - iPhone 11 Pro 256GB Mixed AA+ - 0 stock"
			const tempList = res.data.map((item) => ({
				...item,
				label: `${item.option_product_name}`,
				value: item.id,
			}));
			setProducts(tempList);
			setIsProductsLoaded(true)
		});
	}

	return (
		<>
			{modalActive && <div className="popup-bg">&nbsp;</div>}
			<div className="order-form printarea">

				<div className="row m-0 w-100 est-nv-container">
					<div className="col-12 col-md-8 p-0 p-md-2 inv-preview">
						<div className="col-inv">
							<div className="section-head pl-0 mb-1 mt-2">
								<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', textWrap: 'nowrap' }}>
									{edit ? 'Edit ' : 'New '} Sales Order
								</h2>

								<div className="actions" style={modalActive ? { visibility: 'hidden' } : {}}>
									{!edit ? (
										<>
											{draftLoading && (
												<p className="m-0" style={{ display: 'flex', gap: '3px', alignItems: 'center', fontSize: '12px' }}>
													{/* <ImSpinner2 className="fa-spin" /> Autosaving Draft */}
													<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512">
														<path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
													</svg>{' '}
													Autosaving Draft
												</p>
											)}
											<PermissionAllowed permissions={['Create Sales Order']}>
												<button
													type="button"
													// className="btn btn-primary btn-sm btn-outlined-main"

													className="btn btn-primary btn-sm btn-outlined-main"
													disabled={draftLoading || !validToSubmit || isProcessing}
													onClick={handleSaveOrder}
												>
													{isProcessing ? (
														<>
															<FaSpinner className="spinner" /> Saving
														</>
													) : (
														<>
															<FaSave /> Save
														</>
													)}
												</button>
											</PermissionAllowed>
										</>
									) : (
										<>
											{draftLoading && (
												<p className="m-0" style={{ display: 'flex', gap: '3px', alignItems: 'center', fontSize: '12px' }}>
													<ImSpinner2 className="fa-spin" /> Updating
												</p>
											)}
											<button
												type="button"
												className="btn btn-primary btn-outlined-main"
												style={{ minWidth: 'auto' }}
												onClick={handleUpdateOrder}
												disabled={draftLoading || !validToSubmit}
											>
												<FaSave /> Update
											</button>
										</>
									)}

									<button
										type="button"
										style={{ minWidth: 'auto' }}
										className="btn btn-primary btn-sm btn-outlined-main"
										onClick={() => {
											if (id) {
												history(`/sales/sales-orders/${id}`);
											} else {
												history(`/sales/sales-orders`);
											}
										}}
									>
										<FaTimes /> Cancel
									</button>
								</div>
							</div>
							<div className="row m-0">
								<div className="card shadow-sm rounded bg-white mb-md-4 p-3 p-xl-4 w-100">
									{/* <Scroller extraHeight={60}> */}
									<div className="row orf-head">
										<div className="col-12 col-md-8 p-0 d-flex align-items-center company-logo">

											{
												settingsDetails?.logo_url ? (
													<div className="est-detail-logo" style={{ height: '36px' }}>
														<img src={settingsDetails?.logo_url} height={'36px'} />
													</div>
												) : (
													<div className="est-detail-logo" style={{ height: '36px' }}>
														<h3>{settingsDetails?.client_full_name}</h3>
													</div>
												)
											}
											{/* {
												settingsDetails?.client_code == 'glpwireless' ? (
													<div className="est-detail-logo" style={{ height: '56px' }}>
														<img src={glpLogo} height={'100%'} />
													</div>
												) : settingsDetails?.client_code == 'usedtrading' ? (
													<div className="est-detail-logo" style={{ height: '36px' }}>
														<img src={utLogo} height={'100%'} />
													</div>
												) : (
													<div className="est-detail-logo" style={{ height: '36px' }}>
														<img src={mgLogo} height={'100%'} />
													</div>
												)
											} */}
										</div>
										<div className="col-12 col-md-4 p-0">
											<div className="company-details">
												{
													settingsDetails?.client_code && (
														<>
															{

																settingsDetails?.client_code == 'glpwireless' ? (
																	<p className="m-0">
																		<b> GLP Wireless</b> <br />
																		Dubai Airport Free Zone Authority <br />
																		2W Building, Office# 406, Dubai <br />
																		info@glpwireless.com <br />
																		www.glpwireless.com <br />
																	</p>
																	// ) : settingsDetails?.client_code == 'usedtrading' ? (
																	// 	<p className="m-0">
																	// 		<b> {settingsDetails?.client_full_name}</b> <br />
																	// 		Burgemeester Matsersingel 200 <br />
																	// 		6843 NZ, Arnhem, Netherlands <br />
																	// 		info@usedtrading.eu <br />
																	// 		www.usedtrading.eu <br />
																	// 	</p>
																) : (
																	<p className="m-0">
																		<b> {settingsDetails?.client_full_name}</b> <br />
																		{
																			settingsDetails?.invoice_details1 && (
																				<>
																					{settingsDetails?.invoice_details1} <br />
																				</>
																			)
																		}
																		{
																			settingsDetails?.invoice_details2 && (
																				<>
																					{settingsDetails?.invoice_details2} <br />
																				</>
																			)
																		}
																		{
																			settingsDetails?.invoice_details3 && (
																				<>
																					{settingsDetails?.invoice_details3} <br />
																				</>
																			)
																		}
																		{
																			settingsDetails?.invoice_details4 && (
																				<>
																					{settingsDetails?.invoice_details4} <br />
																				</>
																			)
																		}
																		{
																			settingsDetails?.invoice_details5 && (
																				<>
																					{settingsDetails?.invoice_details5}
																				</>
																			)
																		}
																	</p>
																)
															}
														</>
													)
												}
											</div>
										</div>
									</div>
									<div className="row m-0 p-0 est-contact-section">
										{!salesOrderData?.customer ? (
											<div className="add-contact-action">
												<button
													className="btn btn-primary btn-outlined-main d-flex justify-content-between"
													onClick={handleContactModalOpen}
													style={{ minWidth: '120px' }}
												>
													<span>Add Contact</span>
													<span>
														<FaPlus />
													</span>
												</button>
											</div>
										) : (
											<>
												<div className="border-left-blue1">
													<div className="order-form-edit-sections">Edit</div>
													<div className="order-form-section-effect" onClick={handleContactModalOpen}>
														<div className="contact-details-edit2">
															<div className="div-btn">
																<button
																	className="btn btn-primary btn-outlined-main d-flex justify-content-between"
																	style={{ minWidth: 'unset' }}
																>
																	<span>Click to Edit</span>
																	<span>
																		<FaPen />
																	</span>
																</button>
															</div>
															<div className="contact-container">
																{salesOrderData?.customer?.name && (
																	<>
																		<b>{salesOrderData?.customer?.name}</b> <br />
																	</>
																)}
																{salesOrderData?.customer?.address?.address && (
																	<>
																		{salesOrderData?.customer?.address?.address} <br />
																	</>
																)}
																{(salesOrderData?.customer?.address?.zipcode || salesOrderData?.customer?.address?.city) && (
																	<>
																		{salesOrderData?.customer?.address?.zipcode}, {salesOrderData?.customer?.address?.city?.city} <br />
																	</>
																)}
																{salesOrderData?.customer?.address?.country && (
																	<>
																		{salesOrderData?.customer?.address?.country?.name} <br />
																	</>
																)}
															</div>
														</div>
													</div>
												</div>
											</>
										)}
									</div>
									<div style={{ height: '30px' }}></div>

									<div className="border-left-blue2">
										<div className="order-form-edit-sections">Edit</div>

										<div className="row m-0 p-0 mt-2">
											<div className="col-12 col-md-8 m-0 p-0 d-flex align-items-center" style={{ gap: '10px' }}>
												<div style={{ marginLeft: '15px' }}>
													<h4 className="est-ref m-0" style={!orderId ? { fontStyle: 'italic', color: 'grey' } : {}}>
														{ draft ? 'Draft' : '' } {nextDraftNumber}
													</h4>
												</div>
											</div>
											<div className="col-12 col-md-4 m-0 p-0 d-flex">
												<table>
													<tr>
														<td className="" style={{ paddingRight: '10px' }}>
															<b>
																Date Ordered
															</b>
														</td>
														<td className="">
															{salesOrderData?.dateOrdered ? moment(salesOrderData?.dateOrdered).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}
														</td>
													</tr>
													<tr>
														<td className="" style={{ paddingRight: '10px' }}>
															<b>Due Date</b>
														</td>
														<td className="">
															{salesOrderData?.dateOrdered
																? moment(salesOrderData?.dateOrdered)?.add(7, 'days').format('DD/MM/YYYY')
																: moment()?.add(7, 'days').format('DD/MM/YYYY')}
														</td>
													</tr>
												</table>
											</div>
										</div>

										<div 
											className="order-form-section-effect" 
											// onClick={handleProductsModalOpen}
											onClick={() => {
												if (isProductsLoaded) {
													handleProductsModalOpen()
												}
											}}
										>
											<div className="product-details-edit">
												<div className="div-btn orderform-product-btn" style={{ marginBottom: '-25px' }}>
													<button className="btn btn-primary btn-outlined-main d-flex justify-content-between" style={{ minWidth: 'unset' }}>
														{
															isProductsLoaded ? (
																<>
																	<span>Click to {productsData.length > 0 ? 'Edit' : 'Add'}</span>
																	<span>
																		<FaPen />
																	</span>
																</>
															) : (
																<>
																	<span>
																		<FaSpinner className='fa-spin' />
																	</span>
																	<span>Loading Products</span>
																</>
															)
														}
													</button>
												</div>

												<div className="products-container">
													<div className="inv-table-container" style={{ fontSize: '12px' }}>
														<div className="table-responsive-xl tableData p-0">
															<table className="table table-md inv-table">
																<thead>
																	<tr>
																		<th className="text-left qty-col">QTY</th>
																		{orderType == 'credit-note' && <th className="text-left">Invoice</th>}
																		<th className="text-left product-col">Model</th>
																		{orderType != 'credit-note' && <th className="text-left comment-col">Comment</th>}

																		<th className="text-right price-col">
																			Total
																		</th>
																		<th className="text-left text-right vat-col">VAT</th>
																	</tr>
																</thead>
																<tbody>
																	{productsData?.map((item, index) => (
																		<tr>
																			<td className="qty-col text-left align-middle">
																				{item.qty} <span className="d-none d-md-inline">x</span>
																			</td>
																			{orderType == 'credit-note' && <td className="align-left align-middle">{item?.invoice?.label}</td>}
																			<td className="product-col text-left align-middle">{item?.model?.product_name || item?.customValue}</td>
																			{orderType != 'credit-note' && <td className="comment-col align-middle">{item?.comment || '-'}</td>}
																			<td className="text-right align-middle price-col">
																				{getCurrencySymbol(salesOrderData?.currency || 'eur')}&nbsp;
																				{numberFormatter.format(Number(item?.unitPrice) * Number(item.qty))}
																			</td>
																			<td className="text-right vat-col align-middle">{item?.vat?.percentage}%</td>
																		</tr>
																	))}

																	{productsData?.length <= 0 && (
																		<tr>
																			<td className="text-left">{orderType == 'credit-note' ? -1 : 1} x</td>
																			<td className="text-left" colSpan={2}>
																				No models added yet
																			</td>
																			<td className="text-right">{getCurrencySymbol(salesOrderData?.currency || 'eur')}&nbsp;0.00</td>
																			<td className="text-right">0%</td>
																		</tr>
																	)}
																</tbody>
																<tfoot>
																	<tr>
																		<td colSpan={3} className="text-right font-weight-bold">
																			Shipping
																		</td>
																		<td className="font-weight-bold text-right">
																			{getCurrencySymbol(salesOrderData?.currency || 'eur')}{' '}
																			{numberFormatter.format(Number(shippingFee) * Number(shippingQty)) || 0}
																		</td>
																		<td></td>
																	</tr>
																	<tr>
																		<td colSpan={3} className="text-right font-weight-bold">
																			Subtotal
																		</td>
																		<td className="font-weight-bold text-right">
																			{getCurrencySymbol(salesOrderData?.currency || 'eur')} {numberFormatter.format(subtotal) || 0}
																		</td>
																		<td></td>
																	</tr>
																	{discountTotal > 0 && (
																		<tr>
																			<td colSpan={3} className="text-right font-weight-bold">
																				Discount (
																				{discount.discountType == 'percentage'
																					? discount.discountValue + '%'
																					: getCurrencySymbol(salesOrderData?.currency || 'eur')}
																				)
																			</td>
																			<td className="font-weight-bold text-right">
																				{getCurrencySymbol(salesOrderData?.currency || 'eur')} {numberFormatter.format(discountTotal) || 0}
																			</td>
																			<td></td>
																		</tr>
																	)}
																	<tr>
																		<td colSpan={3} className="text-right font-weight-bold">
																			Total
																		</td>
																		<td className="font-weight-bold text-right">
																			{getCurrencySymbol(salesOrderData?.currency || 'eur')}&nbsp;{numberFormatter.format(total) || 0}
																		</td>
																		<td></td>
																	</tr>
																</tfoot>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{skipAgreement == true ? (
										<>
											<div className="mt-3">
												<button onClick={handleOrderAgreementsModalOpen} className="btn btn-main-success ">
													Skipped Agreements <FaFastForward />
												</button>
											</div>
										</>
									) : (
										<>
											{salesOrderData?.customer && orderAgreementsData?.length <= 0 && (
												<div className="mt-3">
													<button onClick={handleOrderAgreementsModalOpen} className="btn btn-main-primary d-flex justify-content-between">
														Add Agreements
													</button>
												</div>
											)}
										</>
									)}
									<div className="row m-0 p-0">
										<p className="inv-footer-text">
											We kindly request payment of{' '}
											<b>
												{getCurrencySymbol(salesOrderData?.currency)} {numberFormatter.format(total)}
											</b>{' '}
											before{' '}
											{salesOrderData?.dateOrdered
												? moment(salesOrderData?.dateOrdered).add(7, 'days').format('MMMM DD, YYYY')
												: moment().add(7, 'days').format('MMMM DD, YYYY')}
											. Please ensure that the payment reference "
											Sales Order { edit ? currentRefNumber : nextDraftNumber}" is used. If you have any inquiries, don't
											hesitate to contact your sales manager ({salesOrderData?.salesManager?.label})
										</p>
									</div>
									{/* </Scroller> */}
								</div>
							</div>

									{orderAgreementsData?.length > 0 && (
										<div className="row">
											<div className="col-12">
												<div className="card shadow-sm rounded bg-white mb-md-4 p-3 p-xl-4 w-100">
													<div className="row">
														<div className="col-12">
															<div className="text-center mb-3 pt-2">
																<img src={agreementImage} alt="" height="70px" className="agreement-img" />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-12">
															<h4 className="text-center mb-3">SALES AGREEMENT</h4>
														</div>
													</div>
													<div className="row">
														<div className="col-md-10 offset-md-1">
															<p className="text-center mb-3">
																This agreement is made on <Link to="#">{moment(salesOrderData?.dateOrdered).format('DD MMMM YYYY')}</Link> between{' '}
																<Link to={`/contacts/${salesOrderData?.customer?.id}`} target="_blank">
																	<PrivacyModeContent>{salesOrderData?.customer?.label}</PrivacyModeContent>
																</Link>
																,{' '}
																{salesOrderData?.customer?.address?.address && (
																	<>
																		located at{' '}
																		<Link to="#">
																			<PrivacyModeContent>
																				{salesOrderData?.customer?.address?.address ? `${salesOrderData?.customer?.address?.address} ` : ''}
																				{salesOrderData?.customer?.address?.zipcode ? `${salesOrderData?.customer?.address?.zipcode}, ` : ''}
																				{salesOrderData?.customer?.address?.city?.city ? `${salesOrderData?.customer?.address?.city?.city}, ` : ''}
																				{salesOrderData?.customer?.address?.country?.name
																					? `${salesOrderData?.customer?.address?.country?.name}`
																					: ''}
																			</PrivacyModeContent>
																		</Link> (The Client){' '}
																	</>
																)}
																{/* and{' '}
																{appName == 'glpwireless' ? (
																	<>GLP Wireless, Dubai Airport Free Zone Authority, 2W Building, Office# 406, Dubai.</>
																) : (
																	<>  */}
																{salesOrderData?.transitCompany?.name && (
																	<>
																		<Link to={`/contacts/${salesOrderData?.transitCompany?.id}`} target="_blank">
																			{salesOrderData?.transitCompany?.name}
																		</Link>{' '}
																		{salesOrderData?.transitCompany?.address && (
																			<>
																				located at{' '}
																				<Link to="#">
																					{/* { console.log('XXX') }
																									{ console.log(salesOrderData?.transitCompany) }
																									{ console.log('XXX') } */}
																					{salesOrderData?.transitCompany?.address?.address ? (
																						<>
																							{salesOrderData?.transitCompany?.address?.address
																								? `${salesOrderData?.transitCompany?.address?.address}, `
																								: ''}
																							{salesOrderData?.transitCompany?.address?.zipcode
																								? `${salesOrderData?.transitCompany?.address?.zipcode}, `
																								: ''}
																						</>
																					) : (
																						<>
																							{salesOrderData?.transitCompany?.address ? `${salesOrderData?.transitCompany?.address}, ` : ''}
																							{salesOrderData?.transitCompany?.zipcode ? `${salesOrderData?.transitCompany?.zipcode}, ` : ''}
																							{salesOrderData?.transitCompany?.city ? `${salesOrderData?.transitCompany?.city}, ` : ''}
																							{salesOrderData?.transitCompany?.country ? `${salesOrderData?.transitCompany?.country}` : ''}
																						</>
																					)}
																				</Link>{' '}
																			</>
																		)}
																		(The Supplier).
																	</>
																)}
																{/* </>
																)} */}
															</p>
														</div>
													</div>

													<div className="row">
														<div className="col-md-10 offset-md-1">
															<p className="text-center mb-3">
																Both parties confirm that they have mutually agreed upon the following conditions, as negotiated between{' '}
																<b>{salesOrderData?.salesManager?.label}</b>,{' '}
																{salesOrderData?.transitCompany?.label && <>representing {salesOrderData?.transitCompany?.label}</>}, and{' '}
																{salesOrderData?.customer?.contact_name ? (
																	<>
																		<Link to="#">
																			<PrivacyModeContent>{salesOrderData?.customer?.contact_name}</PrivacyModeContent>
																		</Link>{' '}
																		the representative of{' '}
																	</>
																) : (
																	<></>
																)}
																<Link to={`/contacts/${salesOrderData?.customer?.id}`} target="_blank">
																	<PrivacyModeContent>{salesOrderData?.customer?.label}</PrivacyModeContent>
																</Link>
																.
															</p>
														</div>
													</div>

													<div className="order-agreements-detail-section">
														{orderAgreementsData?.length > 0 && (
															<>
																{orderAgreementsData?.map((ag) => (
																	<div className="order-agreements-detail" style={{ marginBottom: '20px' }}>
																		<h6>{ag?.agreement?.title}</h6>
																		{ag?.agreement?.type == 'multiselect' ? (
																			ag?.response?.map((res) => <p>{res?.label}</p>)
																		) : (
																			<p>{ag?.response?.label}</p>
																		)}
																	</div>
																))}
																<div className="row">
																	<div className="col-12">
																		<p
																			className="d-flex justify-content-center align-items-center text-center mb-4 pt-4"
																			style={{ gap: '10px', color: '#838383', fontStyle: 'italic' }}
																		>
																			Note: <br />
																			Please be aware that a 2% charge will be applied to the entire order amount if payment is not made on
																			time, {' '}
																			{/* <br /> */}
																			due to fluctuations in USD and EUR currency values.
																		</p>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12">
																		<div className="order-agreement-action-group">
																			{!edit && (
																				<>
																					<PermissionAllowed permissions={['Create Sales Order']}>
																						<button
																							type="button"
																							// className="btn btn-primary btn-sm btn-outlined-main"

																							className="btn btn-main order-agreement-action-confirm"
																							disabled={draftLoading || !validToSubmit}
																							onClick={handleSaveOrder}
																						>
																							Confirm
																						</button>
																					</PermissionAllowed>
																				</>
																			)}
																			<Link to="#" onClick={handleOrderAgreementsModalOpen} className="order-agreement-action-edit">
																				Edit Order Agreements
																			</Link>
																		</div>
																	</div>
																</div>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									)}
							{contactModal && <ContactPopup onClose={handleContactModalClose} onSubmit={handleContactSubmit} preLoadedData={salesOrderData} transactionType={orderType} />}
							{productsModal && windowWidth > 744 && (
								<ProductsPopup
									onClose={handleProductsModalClose}
									onSubmit={handleProductSubmit}
									currency={salesOrderData?.currency}
									orderType={orderType}
									setOrderType={setOrderType}
									customer={salesOrderData?.customer}
									transitCompany={salesOrderData?.transitCompany}
									preLoadedData={{
										invItems: productsData,
										shippingFee,
										discount,
										selections: preloadedSelectionsProdModals
									}}
									products={products}
								/>
							)}
							{productsModal && windowWidth <= 744 && (
								<ProductsPopupMobile
									onClose={handleProductsModalClose}
									onSubmit={handleProductSubmit}
									currency={salesOrderData?.currency}
									orderType={orderType}
									setOrderType={setOrderType}
									customer={salesOrderData?.customer}
									transitCompany={salesOrderData?.transitCompany}
									preLoadedData={{
										invItems: productsData,
										shippingFee,
										discount,
										selections: preloadedSelectionsProdModals
									}}
								/>
							)}
							{orderAgreementsModal && (
								<OrderAgreementsPopup
									onClose={handleOrderAgreementsModalClose}
									onSubmit={handleOrderAgreementsSubmit}
									preLoadedData={{
										selectedAgreements: orderAgreementsData,
										selections: orderAgreementsSelections
									}}
									contactData={salesOrderData}
									skipAgreement={handleSkipAgreement}
								/>
							)}
						</div>
					</div>

					<div className="col-12 col-md-4 p-0 p-md-2 inv-details">
						<div className="col-details">
							<div className="est-detail-item est-detail-item-new">
								<label htmlFor="inputName" className="est-labels">

									{
										!orderId ? (
											'Draft ID:'
										) : (
											'Sales Order ID:'
										)
									}
								</label>

								<p className="p-0 m-0 text-right">

									{
										typeof orderId === 'undefined' ? (
											nextDraftNumber
										) : (
											currentRefNumber
										)
									}
								</p>
							</div>
							<div className="est-detail-item est-detail-item-new">
								<label htmlFor="inputName" className="est-labels">
									Customer:
								</label>

								<p className="p-0 m-0 text-right">{salesOrderData?.customer?.label || 'No contact selected'}</p>
							</div>
							<div className="est-detail-item est-detail-item-new">
								<label htmlFor="inputName" className="est-labels">
									Transit Company:
								</label>

								<p className="p-0 m-0 text-right">{salesOrderData?.transitCompany?.label || 'No Transit company'}</p>
							</div>
							{orderType != 'credit-note' && (
								<div className="est-detail-item est-detail-item-new">
									<label htmlFor="inputName" className="est-labels">
										Sales Manager:
									</label>

									<p className="p-0 m-0 text-right">{salesOrderData?.salesManager?.label || 'N/A'}</p>
								</div>
							)}

							<Notes
								notes={notes}
								addNote={addNote}
								deleteNotePermission={[]}
								removeNote={removeNote}
								toggleNoteExpand={toggleNoteExpand}
								parentId={1}
								type="quotation"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SalesOrderForm;

import React, { useRef, useState, useEffect } from 'react';
import Scroller from 'ui-component/Scroller';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { IoCartOutline } from 'react-icons/io5';
import StatusBadge from 'ui-component/StatusBadge';
// import AgreementModel from '../estimates/EstimationDetails';
import Pagination from 'ui-component/Pagination';
import SearchBar from '../layout/SearchBar';
import { setSearchBar } from 'store/reducers/mainSlice';
import InvoiceDetailsModal from './components/modals/InvoiceDetailsModal'
import { RegisterPaymentIcon, ProcessOrderIcon, DeleteIcon2, InvoiceIcon, TimelineIcon, PreviewIcon } from 'ui-component/custom-icons/icons';
import { set } from 'draft-js/lib/EditorState';
import { Skeleton, Checkbox } from '@mui/material';
import TimelineDrawer from './components/drawers/TimelineDrawer'

const Invoices = () => {
	const inputRef = useRef(null);

	const [isInProgress, setIsInProgress] = useState(false);
	const [searchField, setSearchField] = useState();
	const clientUser = useSelector((state) => state.main.client);
	const [data, setData] = useState([]);
	const [id, setId] = useState();
	const type = 'invoice';
	const [agreementPopup, setAgreemmentPopup] = useState(false);
	const [pending, setPending] = useState(false);
	const [autoSuggest, setAutoSuggest] = useState([]);
	const dispatch = useDispatch();
	const searchBar = useSelector((state) => state.main.searchBar);
	const [selectedPreviewData, setSelectedPreviewData] = useState(null);
	const [showPreviewModal, setShowPreviewModal] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

	const [showButtons, setShowButtons] = useState([]);
	const [pagination, setpagination] = useState();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [selectedData, setSelectedData] = useState(null);
	const [isDrawerDataLoaded, setIsDrawerDataLoaded] = useState(false);
	const [drawerData, setDrawerData] = useState([]);
    const drawerRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 650);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchBar && inputRef.current) {
			inputRef.current.focus();
		}
	}, [searchBar]);
	// const data = [
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Processing', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Pending', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Completed', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Completed', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Processing', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Completed', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	//     {'order': 'INV24-0987', 'products': '4', 'total': '€ 4,350.00', 'status': 'Pending', 'reference': 'Universal', 'orderedOn': '22–02-2024 12:35'},
	// ]

	useEffect(() => {
		if (clientUser?.data?.is_approved === 2) {
			api
				.getClientIvoices(clientUser?.data?.contact_id)
				.then((res) => {
					console.log(res);
					setData(res?.data?.data);
					setpagination(res.data);
					setIsLoading(false);
					setAutoSuggest(res?.data?.data.map((item) => item.invoice_number).slice(0, 10));
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, []);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// useEffect(() => {
	// 	api
	// 		.getClientIvoices(clientUser?.data?.contact_id)
	// 		.then((res) => {
	// 			console.log(res);
	// 			setData(res?.data?.data);
	// 			setIsLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }, []);

	const toggleButtons = (id) => {
		const newShowButtons = [...showButtons];
		const index = newShowButtons.indexOf(id);
		if (index !== -1) {
			newShowButtons.splice(index, 1);
		} else {
			newShowButtons.push(id);
		}
		setShowButtons(newShowButtons);
	};
	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);
		if (text.length > 2) {
			api.getClientIvoices(clientUser?.data?.contact_id, undefined, text).then((res) => {
				setData(res?.data?.data);
				setAutoSuggest(res?.data?.data.map((item) => item.invoice_number).slice(0, 10));
				setpagination(res.data);
			});
		} else if (text.length > 0 && text.length <= 2) {
		} else {
			api.getClientIvoices(clientUser?.data?.contact_id).then((res) => {
				setData(res?.data?.data);
				setAutoSuggest(res?.data?.data.map((item) => item.invoice_number).slice(0, 10));
				setpagination(res.data);
			});
		}
	};

	const handleData = (data) => {
		setData(data.data);
		setpagination(data);
	};

	const closeagreementPopup = () => {
		setAgreemmentPopup(false);
	};
	const EstimationDetails = (id, status) => {
		setAgreemmentPopup(true);
		return false;
		setId(id);
		setPending(status === 'Pending Customer' ? true : false);
	};

	const handleShowDrawer = (id) => {
		setSelectedData(id);
		setIsDrawerOpen(true);
	}
	const handleCloseDrawer = () => {
		setIsDrawerOpen(false)
		setSelectedData(null)
	}


	return (
		<>
			{isMobile && (
				<div className="approved-mobile" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
					<div className="verified-mobile">
						<svg xmlns="http://www.w3.org/2000/svg" width="18.329" height="21.985" viewBox="0 0 18.329 21.985">
							<path
								id="template"
								d="M18.45,5.078,15.261,1.889A6.378,6.378,0,0,0,10.724.01H6.582A4.57,4.57,0,0,0,2,4.583v12.83a4.584,4.584,0,0,0,4.582,4.582h9.164a4.584,4.584,0,0,0,4.582-4.582v-7.8A6.378,6.378,0,0,0,18.45,5.078ZM17.158,6.37a4.658,4.658,0,0,1,.742.962H13.923a.919.919,0,0,1-.916-.916V2.438a4.658,4.658,0,0,1,.962.742ZM18.5,17.413a2.757,2.757,0,0,1-2.749,2.749H6.582a2.757,2.757,0,0,1-2.749-2.749V4.583A2.757,2.757,0,0,1,6.582,1.834h4.133a3.68,3.68,0,0,1,.449.018V6.416a2.757,2.757,0,0,0,2.749,2.749h4.564a3.605,3.605,0,0,1,.018.449ZM14.372,11H7.957a2.292,2.292,0,0,0-2.291,2.291v2.749a2.292,2.292,0,0,0,2.291,2.291h6.415a2.292,2.292,0,0,0,2.291-2.291V13.289A2.292,2.292,0,0,0,14.372,11Zm.458,5.04a.454.454,0,0,1-.458.458H7.957a.454.454,0,0,1-.458-.458V13.289a.454.454,0,0,1,.458-.458h6.415a.454.454,0,0,1,.458.458ZM5.666,8.249a.919.919,0,0,1,.916-.916H8.415a.916.916,0,1,1,0,1.833H6.582A.919.919,0,0,1,5.666,8.249Z"
								transform="translate(-2 -0.01)"
								fill="currentColor"
							/>
						</svg>
						<span style={{ marginLeft: 5, fontSize: 16 }}>Invoices</span>
					</div>
				</div>
			)}
			<div className="main-page-client">
				{!isLoading ? (
					<div className="order-client-content">
						{!isMobile && (
							<div
								className="d-flex align-items-center TableSerachBar"
								style={{
									justifyContent: isMobile ? 'flex-end' : 'space-between' // Add this line
								}}
							>
								<div className="heading-order-client-tbl">{!isMobile && <h3>Invoices</h3>}</div>
								<form className="form-inline navbar-search" style={{ display: 'flex', gap: '5px' }} onSubmit={(e) => e.preventDefault()}>
									<div className="input-group">
										<input
											ref={inputRef}
											type="text"
											className="form-control bg-white border"
											placeholder="Search"
											aria-label="Search"
											aria-describedby="basic-addon2"
											value={searchField}
											onChange={(e) => {
												handleSearch(e);
											}}
											onFocus={() => {
												dispatch(setSearchBar(true));
											}}
											onBlur={() => {
												dispatch(setSearchBar(!searchBar));
											}}
										/>
										<span className="searchIcon">
											<FaSearch />
										</span>
									</div>
								</form>
							</div>
						)}
						{!isMobile ? (
							<div className="estimation-table-area" style={{ height: isMobile ? 'auto' : '93%' }}>
								<div className="table-side">
									<div
										className="table-responsive-xl tableData estimationList"
										style={{ height: isMobile ? 'auto' : '100%', overflowY: isMobile ? 'none' : 'auto' }}
									>
										{data.length > 0 ? (
											<>
												<table className="order-client-tb min-width-unset pm-table d-sm-table d-md-table">
													<thead
														style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', borderBottom: '1px solid #cacaca' }}
													>
														<tr>
															<th scope="col" className="text-left">
																Reference
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Products
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Total
															</th>

															<th scope="col" style={{ textAlign: 'left' }}>
																Date
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Status
															</th>
															<th scope="col" style={{ textAlign: 'left' }}>
																Actions
															</th>
														</tr>
													</thead>
													<tbody>
														{data.map((item, index) => (
															<tr 
																key={index} 
																// onClick={() => EstimationDetails(item.id, item?.master_status?.name)}
															>
																<td 
																	className="text-left ml-5"
																	onClick={() => {
																		setSelectedPreviewData(item?.id);
																		setShowPreviewModal(true)
																	}}
																>
																	<Link to={''} className="ml-1">
																		{item.invoice_number}
																	</Link>
																</td>
																<td
																	onClick={() => {
																		setSelectedPreviewData(item?.id);
																		setShowPreviewModal(true)
																	}}
																>
																	{item.estimation_details.reduce((total, detail) => total + detail.qty, 0)}
																</td>
																<td 
																	onClick={() => {
																		setSelectedPreviewData(item?.id);
																		setShowPreviewModal(true)
																	}}
																>
																	{' '}
																	{item.currency === 'eur' ? '€' : item.currency === 'usd' ? '$' : 'AED'}{' '}
																	{Number(item.total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</td>

																<td 
																	onClick={() => {
																		setSelectedPreviewData(item?.id);
																		setShowPreviewModal(true)
																	}}
																> 
																	{moment(item?.estimation_date, 'DD-MM-YYYY').format('DD MMMM YYYY')}
																</td>
																<td
																	onClick={() => {
																		setSelectedPreviewData(item?.id);
																		setShowPreviewModal(true)
																	}}
																>
																	<div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
																		<div style={{ width: (windowWidth >= 650) & (windowWidth < 800) ? '100%' : '80%' }}>
																			<StatusBadge itemStatus={item.master_status.name == 'Paid' ? 'Paid' : 'Open'} />
																		</div>
																	</div>
																</td>
																<td>
																	<div className="d-flex justify-content-start" style={{ gap: '5px' }}>
																		{/* <div 
																			className="cursor-pointer"
																			onClick={() => {
																				setSelectedPreviewData(item?.id);
																				setShowPreviewModal(true)
																			}}
																		>
																			<PreviewIcon width="15px" height="15px" />
																		</div> */}
																		<div 
																			className="cursor-pointer timeline-trigger-action"
																			onClick={() => {
																				handleShowDrawer(item?.id);
																			}}
																		>
																			<TimelineIcon width="15px" height="15px" />
																		</div>
																	</div>
																</td>
															</tr>
														))}
													</tbody>
												</table>
												<div
													style={{
														position: 'sticky',
														bottom: '-1px',
														width: '100%',
														backgroundColor: '#fff',
														textAlign: isMobile ? 'center' : 'right',
														marginTop: 10
													}}
												>
													<Pagination pagination={pagination} data={handleData} />
												</div>
											</>
										) : (
											<div style={{ width: '100%' }}>
												<div className="no-hover" style={{ width: '100%' }}>
													<div className="whitebg">
														<div className="not-found-container ">
															<IoCartOutline size={120} color="black" />

															<h6
																className="not-found-text"
																style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10 }}
															>
																Add your first order and enjoy the convenience!
															</h6>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
									{/* <Pagination pagination={paginationData} data={handleData} onLoading={(e) => {}} reactQuery /> */}
								</div>
							</div>
						) : (
							<>
								{data.length > 0 ? (
									<>
										<div className="order-list-client">
											<div className="row">
												<div className="col-5" style={{ padding: 10, paddingLeft: 20 }}>
													<span style={{ fontWeight: 600 }}>Reference</span>
												</div>
												<div className="col-5" style={{ padding: 10, display: 'flex', justifyContent: 'start', paddingRight: 20 }}>
													<span style={{ fontWeight: 600 }}>Status</span>
												</div>
											</div>
										</div>
										<div className="order-container">
											{data.map((item) => (
												<div
													className={`row order-item-client ${showButtons.includes(item.id) ? 'active' : ''}`}
													key={item.id}
													// onClick={() => EstimationDetails(item.qoutation_id, item?.master_status?.name)}
													onClick={() => EstimationDetails(item.id, item?.master_status?.name)}
												>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingLeft: 20,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<a>{item.invoice_number}</a>
													</div>
													<div
														className="col-5"
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
													>
														<div style={{ width: 100 }}>
															<StatusBadge itemStatus={item.master_status.name == 'Paid' ? 'Paid' : 'Open'} />
														</div>
													</div>
													<div
														className="col-2"
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															backgroundColor: 'white',
															zIndex: 1,
															paddingRight: 20,
															paddingTop: 10,
															paddingBottom: 10
														}}
														onClick={(event) => {
															event.stopPropagation();
															// EstimationDetails(item.qoutation_id, item?.master_status?.name);
															EstimationDetails(item.id, item?.master_status?.name)
														}}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21.985 21.985">
															<path
																id="search_1_"
																data-name="search (1)"
																d="M10.649,15.458a.859.859,0,1,1,0,1.718h-1.2a.859.859,0,1,1,0-1.718Zm7.042-4.594A.859.859,0,0,0,16.832,10H9.447a.859.859,0,1,0,0,1.718h7.386A.859.859,0,0,0,17.691,10.864ZM9.447,6.57h7.386a.859.859,0,1,0,0-1.718H9.447a.859.859,0,1,0,0,1.718ZM6.011,15.458H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.453H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,1,0,0-1.718Zm0-5.153H5.153a.859.859,0,1,0,0,1.718h.859a.859.859,0,0,0,0-1.718ZM21.733,20.519a.859.859,0,0,1-1.215,1.215l-1.453-1.453A3.869,3.869,0,1,1,20.31,19.1ZM17.09,19.108a2.147,2.147,0,1,0-2.147-2.147A2.149,2.149,0,0,0,17.09,19.108ZM18.55,0H3.435A3.439,3.439,0,0,0,0,3.435V18.55a3.439,3.439,0,0,0,3.435,3.435h8.845a.859.859,0,1,0,0-1.718H3.435A1.72,1.72,0,0,1,1.718,18.55V3.435A1.72,1.72,0,0,1,3.435,1.718H18.55a1.72,1.72,0,0,1,1.718,1.718v8.5a.859.859,0,0,0,1.718,0v-8.5A3.439,3.439,0,0,0,18.55,0Zm0,0"
															/>
														</svg>
													</div>
												</div>
											))}
										</div>
										<div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
											<Pagination pagination={pagination} data={handleData} />
										</div>
									</>
								) : (
									<>
										<div style={{ width: '100%' }}>
											<div className="no-hover" style={{ width: '100%' }}>
												<div className="whitebg">
													<div className="not-found-container ">
														<IoCartOutline size={120} color="black" />

														<h6 className="not-found-text" style={{ fontStyle: 'italic', color: '#000', fontSize: '18px', marginTop: 10 }}>
															Add your first order and enjoy the convenience!
														</h6>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
					</div>
				) : (
					<></>
				)}
				{/* {agreementPopup && (
					<AgreementModel id={id} openAgreementPopup={agreementPopup} pending={pending} handleClose={closeagreementPopup} type={type} />
				)} */}
				{
					showPreviewModal && <InvoiceDetailsModal id={selectedPreviewData} handleClose={() => setShowPreviewModal(false)} />
				}
				<SearchBar searchField={searchField} handleSearch={handleSearch} autoSuggest={autoSuggest} />

				{
					isDrawerOpen && (
						<>
							<TimelineDrawer
								id={selectedData}
								handleCloseDrawer={handleCloseDrawer}
							 />
						</>
					)
				}
			</div>
		</>
	);
};

export default Invoices;

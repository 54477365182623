import DashboardDefault from 'views/dashboard/Default';
import SettingsDashboard from 'views/dashboard/SettingsDashboard';
import SettingsSales from 'views/dashboard/SettingsSales';
import MainLayout from 'layout/MainLayout';
import Profilesetting from 'views/profilesettings';
import Settings from 'views/settings/Main';
import UserView from 'views/users';
import ReportView from 'views/reports';
import ReportType from 'views/reports/report';
import TodayReport from 'views/reports/v2reports/today';
import ViewUserView from 'views/users/Viewuser';
import ProductsList from 'views/products';
import ProductView from 'views/products/ProductView';
import ProductForm from 'views/products/ProductForm';
import ExportBackorders from 'views/backorders/ExportBackorders';
import ExportProduct from 'views/products/ExportProduct';
import ImportProduct from 'views/products/ImportProduct';
import SerialsList from 'views/serials';
import SerialsDetails from 'views/serials/SerialsDetails';
import PartsList from 'views/parts';
import CreateParts from 'views/parts/CreateParts';
import Outbound from 'views/stocks/outbound';
import Repairs from 'views/repairs';
import Shipments from 'views/shipments';
import CreateRoles from 'views/settings/CreateRoles';
import EditRoles from 'views/settings/EditRoles';
import ViewRoleDetails from 'views/settings/ViewRoleDetail';
import Auth from 'ui-component/Auth';
import Estimation from 'views/estimation';
import Backorders from 'views/backorders';
import PurchaseList from 'views/purchaseList/PurchaseList';
import PurchaseOrder from 'views/purchaseList/purchaseOrders';
import PurchaseForm from 'views/purchaseList/PurchaseForm';
import PurchaseDetails from 'views/purchaseList/PurchaseDetails';
import PurchaseReceiptCreate from 'views/purchaseList/comonents/receipts/create';
import PurchaseStock from 'views/purchaseList/comonents/stocks/index';
import InspectionDetails from 'views/Operations/Inspection/InspectionDetails';
import CollectionDetails from 'views/Operations/Collection/CollectionDetails';
import TransitShipments from 'views/Operations/TransitShipments';
import TransitShipmentDetails from 'views/Operations/TransitShipments/TransitShipmentDetails';
import ProductCategories from 'views/productCategories';
import CategoryForm from 'views/productCategories/CategoryForm';
import Inspection from 'views/Operations/Inspection';
import Collection from 'views/Operations/Collection';
import Segregation from 'views/Operations/Segregation';
import SegregationDetails from 'views/Operations/Segregation/SegregationDetails';
import Testing from 'views/Operations/Testing';
import TestingDetails from 'views/Operations/Testing/TestingDetails';
import InboundDetails from 'views/Operations/Inbounding/InboundDetails';
import Inbounding from 'views/Operations/Inbounding';
import QualityControl from 'views/Operations/QualityControl';
import QualityControlDetails from 'views/Operations/QualityControl/QualityControlDetails';
import Repairing from 'views/Operations/Repairing';
import Packing from 'views/Operations/Packing';
import RepairDetails from 'views/Operations/Repairing/RepairDetails';
import PackingDetails from 'views/Operations/Packing/PackingDetails';
import Inbound from 'views/stocks/Inbound';
import ProductUpdate from 'views/stocks/Inbound/ProductUpdate';
import ProductsView from 'views/stocks/Inbound/ProductsView';
import Productadd from 'views/stocks/Inbound/Productadd';
import Multipleproduct from 'views/stocks/Multipleproduct';
import Retesting from 'views/Operations/Retesting';
import RetestingDetails from 'views/Operations/Retesting/RetestingDetails';
import ShipmentDetails from 'views/shipments/ShipmentDetails';
import Notifications from 'views/Notifications';
import EstimationDetails from 'views/estimation/EstimationDetails';
import RolesList from 'views/settings/RolesList';
import UserForm from 'views/users/UserForm';
import Contacts from 'views/contacts';
import ContactForm from 'views/contacts/ContactForm';
import ContactsApprove from 'views/contacts/contactsApprove';
import ContactDetails from 'views/contacts/ContactDetails';
import CreditNoteForm from 'views/credit-notes/CreditNoteForm';
import CreditNotes from 'views/credit-notes';
import CreditNoteDetails from 'views/credit-notes/CreditNoteDetails';
import PurchaseInvoices from 'views/purchase-invoices';
import PurchaseInvoiceDetails from 'views/purchase-invoices/PurchaseInvoiceDetails';
import Warehouses from 'views/warehouses';
import WarehouseDetails from 'views/warehouses/WarehouseDetails';
import WarehouseForm from 'views/warehouses/WarehouseForm';
import ComingSoon from 'views/coming-soon';
import OrderForm from 'views/estimation/OrderForm/OrderForm';

// Returns
import Returns from 'views/returns';
import ReturnsDetails from 'views/returns/ReturnsDetails';


// Purchasing 
import Purchasing from 'views/purchasing';
import PurchasingForm from 'views/purchasing/PurchasingForm';
import PurchasingDetails from 'views/purchasing/PurchasingDetails';
import PurchasingInvoiceForm from 'views/purchasing/PurchasingInvoiceForm';
import PurchasingReceiptForm from 'views/purchasing/PurchasingReceiptForm';


// Vendor Credits
import VendorCredit from 'views/vendorCredits';
import VendorCreditForm from 'views/vendorCredits/VendorCreditForm';
import VendorCreditDetails from 'views/vendorCredits/VendorCreditDetails';


// Operations
import Operation from 'views/Operations';
import OperationDetails from 'views/Operations/OperationDetails';
import OperationInboundView from 'views/Operations/OperationInboundView';
import OperationQualityControlView from 'views/Operations/OperationQualityControlView';
import OperationRepairsView from 'views/Operations/OperationRepairsView';


// Transactions
import Transactions from 'views/transaction';


// Sales Orders
import SalesOrders from 'views/SalesOrders';
import SalesOrderForm from 'views/SalesOrders/SalesOrderForm';
import SalesOrderDetails from 'views/SalesOrders/SalesOrderDetails';

// Picklists
import Picklist from 'views/picklists';
import PicklistDetails from 'views/picklists/PicklistDetails';


// Settings
import SettingsCompanyDetails from 'views/settings/SettingsCompanyDetails';
import SettingsGeneralSettings from 'views/settings/SettingsGeneralSettings';
import SettingsGrades from 'views/settings/SettingsGrades';
import SettingsColors from 'views/settings/SettingsColors';
import SettingsBanks from 'views/settings/SettingsBanks';
import SettingsShippingCompany from 'views/settings/SettingsShippingCompany';
import SettingsRepairs from 'views/settings/SettingsRepairs';
import SettingsSpecifications from 'views/settings/SettingsSpecifications';
import SettingsInterests from 'views/settings/SettingsInterests';


// Testings
import TestPage from 'views/testpage';

// // let globalPrivacyMode = false;
// var globalPrivacyMode = false
// // ==============================|| MAIN ROUTING ||============================== //
// const handleGlobalizedPrivacyMode = (privacyMode) => {
// 	// const getPrivacyMode = sessionStorage.getItem('privacyMode');
// 	// // if (getPrivacyMode === true) {
// 	// // 	globalPrivacyMode = true;
// 	// // } else {
// 	// // 	globalPrivacyMode = false
// 	// // }
// 	alert(`sa main route: ${privacyMode}`);
// 	globalPrivacyMode = privacyMode;
// }
// onGlobalizePrivacyMode={handleGlobalizedPrivacyMode}
const MainRoutes = {
	path: '/',
	element: <MainLayout />,
	children: [
		{
			path: 'dashboard',
			children: [
				{
					path: '',
					element: (
						<Auth>
							<DashboardDefault />
						</Auth>
					)
				}
			]
		},
		{
			path: 'dashboard-settings',
			children: [
				{
					path: '',
					element: (
						<Auth>
							<SettingsDashboard />
						</Auth>
					)
				}
			]
		},
		{
			path: 'sales-settings',
			children: [
				{
					path: '',
					element: (
						<Auth>
							<SettingsSales />
						</Auth>
					)
				}
			]
		},
		{
			path: 'coming-soon',
			children: [
				{
					path: '',
					element: (
						<Auth>
							<ComingSoon />
						</Auth>
					)
				}
			]
		},
		{
			path: 'contacts',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Contact (Page)']}>
							<Contacts />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Contact (Page)']}>
							<ContactForm />
						</Auth>
					)
				},
				{
					path: 'portal',
					element: <ContactsApprove />
				},

				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Contact Details (Page)']}>
							<ContactDetails />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth requiredPermissions={['Edit Contact (Page)']}>
							<ContactForm edit />
						</Auth>
					)
				}
				//Customers
				// {
				// 	path: 'customer',
				// 	element: (
				// 		<Auth requiredPermissions={['List Customer (Page)']}>
				// 			<Customers />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'customer/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Customer Details (Page)']}>
				// 			<CustomerDetails />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'customer/edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Edit Customer (Page)']}>
				// 			<CustomerForm edit />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'customer/create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Customer (Page)']}>
				// 			<CustomerForm />
				// 		</Auth>
				// 	)
				// },

				//Transit Company
				// {
				// 	path: 'transit-company',
				// 	element: (
				// 		<Auth requiredPermissions={['List Transit Company (Page)']}>
				// 			<TransitCompany />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'transit-company/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Transit Company Details (Page)']}>
				// 			<TransitCompanyDetails />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'transit-company/edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Update Transit Company (Page)']}>
				// 			<TransitCompanyForm edit/>
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'transit-company/create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Transit Company (Page)']}>
				// 			<TransitCompanyForm />
				// 		</Auth>
				// 	)
				// },

				//Supplier
				// {
				// 	path: 'supplier',
				// 	element: (
				// 		<Auth requiredPermissions={['List Supplier (Page)']}>
				// 			<SupplierList />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'supplier/create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Supplier (Page)']}>
				// 			<SupplierForm />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'supplier/edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Update Supplier (Page)']}>
				// 			<SupplierForm edit />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'supplier/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Supplier Details (Page)']}>
				// 			<SupplierDetails />
				// 		</Auth>
				// 	)
				// }
			]
		},

		// // Inbounding
		// {
		// 	path: 'inbounding',
		// 	element: (
		// 		<Auth>
		// 			<Inbounding />
		// 		</Auth>
		// 	)
		// },
		// {
		// 	path: 'inbounding/add-imeis/:id',
		// 	element: (
		// 		<Auth>
		// 			<InboundImeis />
		// 		</Auth>
		// 	)
		// },
		// Model
		{
			path: 'settings',
			children: [
				{
					path: 'company-details',
					element: (
						<Auth>
							<SettingsCompanyDetails />
						</Auth>
					)
				},
				{
					path: 'general-settings',
					element: (
						<Auth>
							<SettingsGeneralSettings />
						</Auth>
					)
				},
				{
					path: 'grades',
						element: (
						<Auth>
							<SettingsGrades />
						</Auth>
					)
				},
				{
					path: 'colors',
					element: (
						<Auth>
							<SettingsColors />
						</Auth>
					)
				},
				{
					path: 'banks',
					element: (
						<Auth>
							<SettingsBanks />
						</Auth>
					)
				},
				{
					path: 'repairs',
					element: (
						<Auth>
							<SettingsRepairs />
						</Auth>
					)
				},
				{
					path: 'specifications',
					element: (
						<Auth>
							<SettingsSpecifications />
						</Auth>
					)
				},
				{
					path: 'interests',
					element: (
						<Auth>
							<SettingsInterests />
						</Auth>
					)
				},
				{
					path: 'shipping-companies',
					element: (
						<Auth>
							<SettingsShippingCompany />
						</Auth>
					)
				},
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List User (Page)']}>
							<Settings />
						</Auth>
					)
				},
				{
					path: 'user',
					element: (
						<Auth requiredPermissions={['List User (Page)']}>
							<UserView />
						</Auth>
					)
				},
				{
					path: 'user/:id',
					element: (
						<Auth requiredPermissions={['View User Details (Page)']}>
							<ViewUserView />
						</Auth>
					)
				},
				{
					path: 'user/edit/:id',
					element: (
						<Auth requiredPermissions={['Update User (Page)']}>
							<UserForm edit />
						</Auth>
					)
				},
				{
					path: 'user/create',
					element: (
						<Auth requiredPermissions={['Create New User (Page)']}>
							<UserForm />
						</Auth>
					)
				},

				// //Models
				// {
				// 	path: 'model',
				// 	element: (
				// 		<Auth requiredPermissions={['List Model (Page)']}>
				// 			<ModelList />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'model/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Model Details (Page)']}>
				// 			<ViewModel />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'model/create/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Update Model (Page)']}>
				// 			<AddModel edit />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'model/create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Model (Page)']}>
				// 			<AddModel />
				// 		</Auth>
				// 	)
				// },

				//Product Categories
				{
					path: 'productcategories',
					children: [
						{
							path: '',
							element: (
								<Auth>
									<ProductCategories />
								</Auth>
							)
						},
						{
							path: 'create',
							element: (
								<Auth>
									<CategoryForm />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth>
									<CategoryForm edit />
								</Auth>
							)
						}
					]
				}

				// Products
				// {
				// 	path: 'product',
				// 	element: (
				// 		<Auth>
				// 			<ProductListOld />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'productcreate',
				// 	element: (
				// 		<Auth>
				// 			<Productadd />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'productedit/:id',
				// 	element: (
				// 		<Auth>
				// 			<Productsupdate />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'productview/:id',
				// 	element: (
				// 		<Auth>
				// 			<ProductView />
				// 		</Auth>
				// 	)
				// }
			]
		},
		{
			path: 'report',
			children: [
				{
					path: '',
					element: <ReportView />
				},
				{
					path: ':id',
					element: <ReportType />
				},
				{
					path: 'today',
					element: <TodayReport />
				}
			]
		},

		{
			path: 'credit-note',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Credit Note (Page)']}>
							<CreditNotes />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Credit Note (Page)']}>
							<CreditNoteForm />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['Credit Note Details (Page)']}>
							<CreditNoteDetails />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth requiredPermissions={['Update Credit Note (Page)']}>
							<CreditNoteForm edit />
						</Auth>
					)
				}
			]
		},

		{
			path: 'order',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Estimation (Page)']}>
							{/* <Estimation globalPrivacyMode={globalPrivacyMode} /> */}
							<Estimation />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Estimation Details (Page)']}>
							<EstimationDetails />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Estimation (Page)']}>
							<OrderForm type="estimate" />
						</Auth>
					)
				},
				{
					path: 'create/invoice',
					element: (
						<Auth requiredPermissions={['Create Estimation (Page)']}>
							<OrderForm type="invoice" />
						</Auth>
					)
				},
				{
					path: 'create/credit-note',
					element: (
						<Auth requiredPermissions={['Create Estimation (Page)']}>
							<OrderForm type="credit-note" />
						</Auth>
					)
				},
				{
					path: 'create2',
					element: (
						<Auth requiredPermissions={['Create Estimation (Page)']}>
							<OrderForm />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth requiredPermissions={['Edit Estimation (Page)']}>
							<OrderForm edit />
						</Auth>
					)
				},
				{
					path: 'draft/:id',
					element: (
						<Auth requiredPermissions={['Edit Estimation (Page)']}>
							<OrderForm draft />
						</Auth>
					)
				}
			]
		},

		{
			path: 'sales',
			children: [
				{
					path: 'quotations',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Estimation (Page)']}>
									<Estimation />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Estimation Details (Page)']}>
									<EstimationDetails />
								</Auth>
							)
						},
						{
							path: 'edit/:id',
							element: (
								<Auth requiredPermissions={['Edit Estimation (Page)']}>
									<OrderForm edit />
								</Auth>
							)
						},
						{
							path: 'create',
							element: (
								<Auth requiredPermissions={['Create Estimation (Page)']}>
									<OrderForm type="estimate" />
								</Auth>
							)
						},
						{
							path: 'draft/:id',
							element: (
								<Auth requiredPermissions={['Edit Estimation (Page)']}>
									<OrderForm edit type="draft-estimate" />
								</Auth>
							)
						}
					]
				},
				{
					path: 'estimates',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Estimation (Page)']}>
									<Estimation />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Estimation Details (Page)']}>
									<EstimationDetails />
								</Auth>
							)
						},
						{
							path: 'edit/:id',
							element: (
								<Auth requiredPermissions={['Edit Estimation (Page)']}>
									<OrderForm edit />
								</Auth>
							)
						},
						{
							path: 'create',
							element: (
								<Auth requiredPermissions={['Create Estimation (Page)']}>
									<OrderForm type="estimate" />
								</Auth>
							)
						},
						{
							path: 'draft/:id',
							element: (
								<Auth requiredPermissions={['Edit Estimation (Page)']}>
									<OrderForm edit type="draft-estimate" />
								</Auth>
							)
						}
					]
				},
				{
					path: 'invoices',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Estimation (Page)']}>
									<Estimation />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Estimation Details (Page)']}>
									<EstimationDetails />
								</Auth>
							)
						},
						{
							path: 'edit/:id',
							element: (
								<Auth requiredPermissions={['Edit Estimation (Page)']}>
									<OrderForm edit />
								</Auth>
							)
						},
						{
							path: 'create',
							element: (
								<Auth requiredPermissions={['Create Estimation (Page)']}>
									<OrderForm type="invoice" />
								</Auth>
							)
						},
						{
							path: 'draft/:id',
							element: (
								<Auth requiredPermissions={['Edit Estimation (Page)']}>
									<OrderForm edit type="draft-invoice" />
								</Auth>
							)
						}
					]
				},
				{
					path: 'credit-note/create',
					element: (
						<Auth requiredPermissions={['Create Estimation (Page)']}>
							<OrderForm type="credit-note" />
						</Auth>
					)
				},
				// transactions
				{
					path: 'transactions',
					element: (
						<Auth requiredPermissions={['Edit Estimation (Page)']}>
							<Transactions />
						</Auth>
					)
				},
				// Sales Orders
				{
					path: 'sales-orders',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Sales Orders']}>
									<SalesOrders />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Sales Orders Details']}>
									<SalesOrderDetails />
								</Auth>
							)
						},
						{
							path: 'edit/:id',
							element: (
								<Auth requiredPermissions={['Edit Sales Order']}>
									<SalesOrderForm edit />
								</Auth>
							)
						},
						{
							path: 'create',
							element: (
								<Auth requiredPermissions={['Create Sales Order']}>
									<SalesOrderForm />
								</Auth>
							)
						},
						{
							path: 'draft/:id',
							element: (
								<Auth requiredPermissions={['Edit Sales Order']}>
									<OrderForm edit type="draft-sales-order" />
								</Auth>
							)
						}
					]
				},
				// {
				// 	path: 'estimates/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Estimation Details (Page)']}>
				// 			<EstimationDetails />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Estimation (Page)']}>
				// 			<OrderForm type="estimate" />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create/invoice',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Estimation (Page)']}>
				// 			<OrderForm type="invoice" />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create/credit-note',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Estimation (Page)']}>
				// 			<OrderForm type="credit-note" />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create2',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Estimation (Page)']}>
				// 			<OrderForm />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Edit Estimation (Page)']}>
				// 			<OrderForm edit />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'draft/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Edit Estimation (Page)']}>
				// 			<OrderForm draft />
				// 		</Auth>
				// 	)
				// }
			]
		},

		{
			path: 'backorders',
			children: [
				{
					path: '',
					element: (
						// <Auth requiredPermissions={['List Backorder (Page)']}>
						<Backorders />
						// </Auth>
					)
				},
				{
					path: 'export',
					element: <ExportBackorders />
				},
				{
					path: ':id',
					element: <Auth requiredPermissions={['View Backorder Details (Page)']}>{/* <BackorderDetails /> */}</Auth>
				}
			]
		},

		// Picklists
		{
			path: 'picklists',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Picklists (Page)']}>
							<Picklist />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Picklists (Button)']}>
							<PicklistDetails />
						</Auth>
					)
				},
				// {
				// 	path: 'edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Edit Estimation (Page)']}>
				// 			<OrderForm edit />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Estimation (Page)']}>
				// 			<OrderForm type="invoice" />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'draft/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Picklists (Button))']}>
				// 			<OrderForm edit type="draft-invoice" />
				// 		</Auth>
				// 	)
				// }
			]
		},
		{
			path: 'user',
			children: [
				{
					path: 'profile',
					element: (
						<Auth>
							<Profilesetting />
						</Auth>
					)
				}
			]
		},
		,
		{
			path: 'settings',
			children: [
				{
					path: 'rolecreate',
					element: (
						<Auth>
							<CreateRoles />
						</Auth>
					)
				}
			]
		},
		{
			path: 'settings',
			children: [
				{
					path: 'roleedit/:id',
					element: (
						<Auth>
							<EditRoles />
						</Auth>
					)
				}
			]
		},
		{
			path: 'settings',
			children: [
				{
					path: 'roleview/:id',
					element: (
						<Auth>
							<ViewRoleDetails />
						</Auth>
					)
				}
			]
		},
		{
			path: 'settings',
			children: [
				{
					path: 'role',
					element: (
						<Auth requiredPermissions={['List Role (Page)']}>
							<RolesList />
						</Auth>
					)
				}
			]
		},
		{
			path: 'purchaseorder',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchaseOrder />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchaseForm />
						</Auth>
					)
				}
			]
		},
		// Purchase List
		{
			path: 'purchasing',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<Purchasing />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchasingForm />
						</Auth>
					)
				},
				{
					path: 'view/:id',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchasingDetails />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchasingForm edit />
							{/* <PurchasingInvoiceForm /> */}
						</Auth>
					)
				},
				{
					path: 'create/:id/receipt',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchasingReceiptForm />
						</Auth>
					)
				},
				{
					path: 'view/:id/receipt',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchasingReceiptForm edit />
						</Auth>
					)
				},
			]
		},
		{
			path: 'vendor-credits',
			children: [
				{
					path: '',
					element: (
						// <Auth requiredPermissions={['Display Purchase List (Page)']}>
							<VendorCredit />
						// </Auth>
					)
				},
				{
					path: 'create',
					element: (
						// <Auth requiredPermissions={['Display Purchase List (Page)']}>
							<VendorCreditForm />
						// </Auth>
					)
				},
				{
					path: ':id',
					element: (
						// <Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<VendorCreditDetails />
						// </Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						// <Auth requiredPermissions={['Edit Sales Order']}>
							<VendorCreditForm edit />
						// </Auth>
					)
				},
				// {
				// 	path: 'edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
				// 			<PurchasingForm edit />
				// 			{/* <PurchasingInvoiceForm /> */}
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create/:id/receipt',
				// 	element: (
				// 		<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
				// 			<PurchasingReceiptForm />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'view/:id/receipt',
				// 	element: (
				// 		<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
				// 			<PurchasingReceiptForm edit />
				// 		</Auth>
				// 	)
				// },
			]
		},
		{
			path: 'purchaseall',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchaseList />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchaseForm />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchaseForm />
						</Auth>
					)
				},
				{
					path: 'view/:id',
					element: (
						<Auth requiredPermissions={['Display Purchase List (Page)']}>
							<PurchaseDetails />
						</Auth>
					)
				}
			]
		},

		// Purchase Invoice
		{
			path: 'purchase-invoice',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Purchase Invoice (Page)']}>
							<PurchaseInvoices />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchaseInvoiceDetails />
						</Auth>
					)
				}
			]
		},
		//Purchase Receipt
		{
			path: 'purchase-receipt',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Purchase Invoice (Page)']}>
							<PurchaseInvoices />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchaseReceiptCreate />
						</Auth>
					)
				},
				{
					path: 'create/:id',
					element: (
						<Auth requiredPermissions={['Purchase Invoice Details (Page)']}>
							<PurchaseReceiptCreate />
						</Auth>
					)
				}
			]
		},
		//Purchase Stock

		{
			path: 'purchase-stock',
			children: [
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['List Purchase Invoice (Page)']}>
							<PurchaseStock />
						</Auth>
					)
				}
			]
		},
		// Operations

		{
			path: 'operations',
			children: [
			
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Operations']}>
							<Operation />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Operations']}>
							<OperationDetails />
						</Auth>
					)
				},
				{
					path: 'edit/:id/inbound',
					element: (
						<Auth requiredPermissions={['View Operations Details']}>
							<OperationDetails edit />
						</Auth>
					)
				},
				{
					path: 'view',
					children: [
						{
							path: ':id/',
							children: [
								{
									path: 'inbound',
									element: (
										<Auth requiredPermissions={['View Operations Details']}>
											<OperationInboundView edit />
										</Auth>
									)
								},
								{
									path: 'qualitycontrol',
									element: (
										<Auth requiredPermissions={['List Quality Control']}>
											<OperationQualityControlView edit />
										</Auth>
									)
								},
								{
									path: 'repairs',
									element: (
										<Auth requiredPermissions={['List Operation Repairs']}>
											<OperationRepairsView edit />
										</Auth>
									)
								},
							]
						},
					]
				},
				//Task board Inspection
				{
					path: 'inspection',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Inspection (Page)']}>
									<Inspection />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Inspection Details (Page)']}>
									<InspectionDetails />
								</Auth>
							)
						}
					]
				},
				//Task board Collection
				{
					path: 'collection',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Collection (Page)']}>
									<Collection />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Collection Details (Page)']}>
									<CollectionDetails />
								</Auth>
							)
						}
					]
				},
				//Task board Segregation
				{
					path: 'segregation',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Segregation (Page)']}>
									<Segregation />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Segregation Details (Page)']}>
									<SegregationDetails />
								</Auth>
							)
						}
					]
				},
				//Task board Testing
				{
					path: 'testing',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Testing (Page)']}>
									<Testing />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Testing Details (Page)']}>
									<TestingDetails />
								</Auth>
							)
						}
					]
				},

				//Task board Inbounding
				{
					path: 'inbounding',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Inbounding (Page)']}>
									<Inbounding />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Inbounding Details (Page)']}>
									<InboundDetails />
								</Auth>
							)
						}
					]
				},

				//Task board Quality Control
				{
					path: 'quality-control',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Quality Control (Page)']}>
									<QualityControl />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Quality Control Details (Page)']}>
									<QualityControlDetails />
								</Auth>
							)
						}
					]
				},

				//Task board Repairing
				{
					path: 'repairing',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Repairing (Page)']}>
									<Repairing />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Repairing Details (Page)']}>
									<RepairDetails />
								</Auth>
							)
						}
					]
				},

				//Task board Retesting
				{
					path: 'retesting',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Retesting (Page)']}>
									<Retesting />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Retesting Details (Page)']}>
									<RetestingDetails />
								</Auth>
							)
						}
					]
				},

				//Task board Packing
				{
					path: 'packing',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Task Packing (Page)']}>
									<Packing />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Task Packing Details (Page)']}>
									<PackingDetails />
								</Auth>
							)
						}
					]
				},

				//Task board Transit Shipment
				{
					path: 'transitshipments',
					children: [
						{
							path: '',
							element: (
								<Auth requiredPermissions={['List Transit Shipment (Page)']}>
									<TransitShipments />
								</Auth>
							)
						},
						{
							path: ':id',
							element: (
								<Auth requiredPermissions={['View Transit Shipment (Button)']}>
									<TransitShipmentDetails />
								</Auth>
							)
						}
					]
				}
			]
		},

		{
			path: 'notifications',
			element: (
				<Auth>
					<Notifications />
				</Auth>
			)
		},

		// Stocks
		{
			path: 'inventory',
			children: [
				{
					path: 'multiplecreate',
					element: (
						<Auth>
							<Multipleproduct />
						</Auth>
					)
				},
				{
					path: 'inbound',
					element: (
						<Auth requiredPermissions={['List Stock (Page)']}>
							<Inbound />
						</Auth>
					)
				},
				{
					path: 'outbound',
					element: (
						<Auth requiredPermissions={['List Stock (Page)']}>
							<Outbound />
						</Auth>
					)
				},
				{
					path: 'repairs',
					element: (
						<Auth requiredPermissions={['List Stock (Page)']}>
							<Repairs />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth>
							<Productadd />
						</Auth>
					)
				},
				{
					path: 'view/:id',
					element: (
						<Auth>
							<ProductsView />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth>
							<ProductUpdate />
						</Auth>
					)
				},
				{
					path: 'parts',
					element: (
						<Auth>
							<PartsList />
						</Auth>
					)
				},
				{
					path: 'parts/create',
					element: (
						<Auth>
							<CreateParts />
						</Auth>
					)
				}
			]
		},
		{
			path: 'shipments',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Shipment (Page)']}>
							<Shipments />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Shipment Details (Page)']}>
							<ShipmentDetails />
						</Auth>
					)
				}
			]
		},
		{
			path: '*',
			element: (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						zIndex: 99999,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100dvh',
						width: '100vw',
						background: '#f9f9f9',
						flexDirection: 'column',
						gap: '0px'
					}}
				>
					<h1>404</h1>
					<h6>Page Not Found</h6>
				</div>
			)
		},
		//Task board Inbounding
		{
			path: 'serials',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Product (Page)']}>
							<SerialsList />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Product (Button)']}>
							<SerialsDetails />
						</Auth>
					)
				}
				// {
				// 	path: ':id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Task Inbounding Details (Page)']}>
				// 			<InboundDetails />
				// 		</Auth>
				// 	)
				// }
			]
		},
		{
			path: 'warehouses',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Warehouse (Page)']}>
							<Warehouses />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Warehouse Details (Page)']}>
							<WarehouseDetails />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Warehouse (Page)']}>
							<WarehouseForm />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth requiredPermissions={['Update Warehouse (Page)']}>
							<WarehouseForm edit />
						</Auth>
					)
				}
			]
		},
		{
			path: 'returns',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Returns (Page)']}>
							<Returns />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Returns (Page)']}>
							<ReturnsDetails />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Returns Details (Page)']}>
							<ReturnsDetails edit />
						</Auth>
					)
				}
				// {
				// 	path: ':id',
				// 	element: (
				// 		<Auth requiredPermissions={['View Warehouse Details (Page)']}>
				// 			<WarehouseDetails />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'create',
				// 	element: (
				// 		<Auth requiredPermissions={['Create Warehouse (Page)']}>
				// 			<WarehouseForm />
				// 		</Auth>
				// 	)
				// },
				// {
				// 	path: 'edit/:id',
				// 	element: (
				// 		<Auth requiredPermissions={['Update Warehouse (Page)']}>
				// 			<WarehouseForm edit />
				// 		</Auth>
				// 	)
				// }
			]
		},

		{
			path: 'products',
			children: [
				{
					path: '',
					element: (
						<Auth requiredPermissions={['List Model (Page)']}>
							<ProductsList />
						</Auth>
					)
				},
				{
					path: 'inactive',
					element: (
						<Auth requiredPermissions={['List Model (Page)']}>
							<ProductsList />
						</Auth>
					)
				},
				{
					path: ':id',
					element: (
						<Auth requiredPermissions={['View Model Details (Page)']}>
							<ProductView />
						</Auth>
					)
				},
				{
					path: 'create',
					element: (
						<Auth requiredPermissions={['Create Model (Page)']}>
							<ProductForm />
						</Auth>
					)
				},
				{
					path: 'edit/:id',
					element: (
						<Auth requiredPermissions={['Update Warehouse (Page)']}>
							<ProductForm edit />
						</Auth>
					)
				},
				{
					path: 'export',
					element: (
						<Auth requiredPermissions={['List Model (Page)']}>
							<ExportProduct />
						</Auth>
					)
				},
				{
					path: 'import',
					element: (
						<Auth requiredPermissions={['List Model (Page)']}>
							<ImportProduct />
						</Auth>
					)
				}
			]
		},
		// //Models
		// {
		// 	path: 'model',
		// 	element: (
		// 		<Auth requiredPermissions={['List Model (Page)']}>
		// 			<ModelList />
		// 		</Auth>
		// 	)
		// },
		// {
		// 	path: 'model/:id',
		// 	element: (
		// 		<Auth requiredPermissions={['View Model Details (Page)']}>
		// 			<ViewModel />
		// 		</Auth>
		// 	)
		// },
		// {
		// 	path: 'model/create/:id',
		// 	element: (
		// 		<Auth requiredPermissions={['Update Model (Page)']}>
		// 			<AddModel edit />
		// 		</Auth>
		// 	)
		// },
		// {
		// 	path: 'model/create',
		// 	element: (
		// 		<Auth requiredPermissions={['Create Model (Page)']}>
		// 			<AddModel />
		// 		</Auth>
		// 	)
		// },
		{
			path: 'test',
			children: [
				{
					path: '',
					element: (
						<Auth>
							<TestPage />
						</Auth>
					)
				}
			]
		},
	]
};

export default MainRoutes;

// const [privacyMode, setPrivacyMode] = useState(false);

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerlist, setLoading, storeInLocalStore } from 'store/reducers/mainSlice';
import Loader from 'ui-component/Loader';
import IconSearch from 'assets/images/magnifying-glass.svg';
import Tableheader from 'ui-component/Tableheader';
import { Dropdown, InputGroup } from 'react-bootstrap';
import Pagination from 'ui-component/Pagination';
import editIcon from 'assets/images/icons/ic_edit.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import plusIcon from 'assets/images/icons/ic_plus.svg';
import Scroller from 'ui-component/Scroller';
import StatusBadge from 'ui-component/StatusBadge';
import moment from 'moment';
import { FaDownload, FaPlus, FaSearch } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import Filter from 'ui-component/filter-menu/Filter';
import { Skeleton } from '@mui/material';
import { TbDatabaseExclamation } from 'react-icons/tb';
import NoRecordFoundIcon from 'assets/images/icons/no-records-found.svg';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import usePermissions from 'hooks/usePermissions';

import NoImage from 'assets/images/no-image.png';

const Index = () => {
	const [setPageTitle] = usePageTitle('Serials');
	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	const history = useNavigate();
	const [productList, setProductList] = useState([]);
	const [allPermissions, hasEveryPermission] = usePermissions();
	const [show, setShow] = useState('');
	const [createShow, setCreateShow] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const permission = useSelector((state) => state.main.user.permissionToAction);
	const currentUserRole = useSelector((state) => state.main.user?.role[0]?.name);
	const customerList = useSelector((state) => state.main.setcustomerlist);
	const [pagination, setpagination] = useState([]);
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState();
	const [searchTimeout, setSearchTimeout] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({});
	const [isFiltersloading, setIsFiltersloading] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const getSerialsList = (internal = false) => {
		// setIsLoading(true);
		api.getSerialsList()
			.then((res) => {
				setProductList(res.data.data);
				setpagination(res.data);
				setIsLoading(false);
				isFirstLoad && setIsFirstLoad(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const deleteuserData = (data) => {
		setShow(data);
	};

	const handleClose = () => setShow('');

	const handleCreateClose = () => setCreateShow(false);

	const updateProduct = (id) => {
		const currentProduct = productList?.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedProduct: currentProduct[0] }));
		setTimeout(() => {
			history(`/inventory/edit/${id}`);
		}, 1000);
	};

	const handleDelete = (id) => {
		api.deleteCustomer(id).then((res) => {
			if (res.status) {
				setShow('');
				getSerialsList();
				dispatch(storeInLocalStore({ stateAlertmessage: 'Customer Deleted Successfully.' }));
			}
		});
	};
	const viewUserData = (id) => {
		const currentProduct = productList.filter((item) => item.id === id);
		dispatch(storeInLocalStore({ selectedUser: currentProduct[0] }));
		setTimeout(() => {
			history(`/inventory/view/${id}`);
		}, 1000);
	};

	const viewData = (id) => {
		history(`/serials/${id}`);
	};

	const handleSearch = (e) => {
		let text = e.target.value;
		setSearchField(text);

		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		let timeout = setTimeout(() => {
			setIsLoading(true);
			if (text.length > 2) {
				api.filterSerials(activeFilters, text).then((res) => {
					setIsLoading(false);
					setProductList(res.data.data);
					setpagination(res.data);
				});
			} else if (text.length > 0 && text.length <= 2) {
				setIsLoading(false);
			} else {
				api.filterSerials(activeFilters, null).then((res) => {
					setIsLoading(false);
					setProductList(res.data.data);
					setpagination(res.data);
				});
			}
		}, 1000);

		setSearchTimeout(timeout);
	};

	const handleData = (data) => {
		setProductList(data.data);
		setpagination(data);
	};
	useEffect(() => {
		getSerialsList();
		getFiltersList();
	}, []);

	useEffect(() => {
		!isFirstLoad && handleFilter();
	}, [activeFilters]);

	const handleFilter = (activeF = null, searchF = null) => {
		setIsLoading(true);
		api.filterSerials(activeFilters, searchField?.length > 2 ? searchField : null).then((res) => {
			setIsLoading(false);
			setProductList(res.data.data);
			setpagination(res.data);
		});
	};

	const getFiltersList = () => {
		setIsFiltersloading(true);
		api.getStockFilters('inbound').then((res) => {
			setFilters(res.data);
			setIsFiltersloading(false);
		});
	};

	const tableHeaderStyles = {
		position: 'sticky',
		top: 0,
		backgroundColor: '#f2f2f2',
		zIndex: 1
	};

	return (
		<>
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="section-head-new" style={{ margin: '10px 0' }}>
						<h2></h2>
						<div className="actions">
							<>
								<Link to={''} className="btn btn-primary btn-outlined-main">
									<FaDownload /> Download
								</Link>
								{/* <Link to={'/inventory/create'} className="btn btn-primary btn-outlined-main">
										<FaPlus /> Create
									</Link> */}
							</>
						</div>
					</div>
					<div className="card shadow-sm bg-white mb-4 table-card">
						<div className="d-flex align-items-center justify-content-between TableSerachBar">
							<div>
								<Filter activeFilters={activeFilters} onSubmit={setActiveFilters} filters={filters} />
							</div>
							<form className="form-inline navbar-search" onSubmit={(e) => e.preventDefault()}>
								<div className="input-group">
									<input
										type="text"
										className="form-control bg-white border"
										placeholder="Search"
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={handleSearch}
										style={{ width: '100%' }}
									/>
									<span className="searchIcon">
										<FaSearch />
									</span>
								</div>
							</form>
						</div>
						<div className="table-responsive-xl tableData customersList p-2">
							<Scroller extraHeight={108}>
								<table className="table pm-table">
									<thead>
										<tr className='sticky-header'>
											<th scope="col" colSpan={2}>Product</th>
											<th scope="col">IMEI</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{isLoading ? (
											<>
												{[...Array(25)].map((e, i) => (
													<tr>
														{[...Array(4)].map((e, i) => (
															<td>
																<Skeleton variant="rounded" width={'100%'} height={20}></Skeleton>
															</td>
														))}
													</tr>
												))}
											</>
										) : (
											<>
												{
													productList && productList.length > 0 && productList?.map((row, index) => {
															// let cdate = row.collected_on || row.created_at;
															let repairPrice = row?.repaires?.length > 0 ? row.repaires[0] : 0;
															// let repairreadyprice = repairPrice?.currency_cost?.cost_usd || 0;

															return (
																<>
																	<tr key={index}>
																		<td className={`align-middle`}
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{ width: '50px' }}
																		>
																			<div>

																				{row?.product?.thumbnail ? (
																					<img src={row?.product?.thumbnail_url} style={{ width: '30px' }} alt="product" />
																				) : (
																					<img src={NoImage} style={{ width: '30px' }} alt="product" />
																				)}
																			</div>
																		</td>
																		<td className={`align-middle`}
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{row?.product}
																		</td>
																		{/* <td className="align-middle"
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{row?.supplier?.name}
																		</td> */}
																		<td className="align-middle"
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{row?.imei}
																		</td>
																		{/* <td className="align-middle"
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{row?.invoice_number}
																		</td> */}
																		<td className="align-middle"
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			<div style={{ width: '110px' }}>
																				{
																					row?.status === 'inbound' ? (
																						<>
																							<StatusBadge itemStatus={ 'Available' } />
																						</>
																					) : (
																						<>
																							<StatusBadge itemStatus={ 'Not Available' } />
																						</>
																					)
																				}
																			</div>
																		</td>
																		{/* <td className="align-middle"
																			onClick={() => {
																				if (hasEveryPermission(['View Product (Button)'])) {
																					viewData(row.id);
																				}
																			}}
																			style={{
																				cursor: hasEveryPermission(['View Product (Button)']) ? 'pointer' : 'not-allowed'
																			}}
																		>
																			{(row?.outbound_date) ? moment(row?.outbound_date).format(CommonDateOnlyFormat) : ''}
																		</td> */}
																	</tr>
																</>
															);
														})
													}
												{
													productList?.length < 1 && !isLoading && (
														<tr className="no-hover">
															<td colSpan={6}>
																<div className="not-found-container">
																	<div className="not-found-icon">
																		<img src={NoRecordFoundIcon} style={{ height: '200px', width: '200px' }} />
																	</div>
																	<h6 className="not-found-text">No Data Available</h6>

																</div>
															</td>
														</tr>
													)
												}
											</>
										)}
									</tbody>
								</table>
							</Scroller>
						</div>
						<Pagination pagination={pagination} data={handleData} onLoading={(e) => setIsLoading(e)} />
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title>Confirm Delete</Modal.Title>
					<p>Are you sure you want to delete {`${show.name}`}</p>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="m-2"
						onClick={() => {
							handleDelete(show.id);
						}}
					>
						Delete
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Index;
import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {


	async getPicklistLists() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/picklists`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
    
    
	getPicklistFilter() {
		return this.handelAlert((res, rej, loadObj) => {
			const url = `${base}/api/picklists/filter`;
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


    filterPicklists(filters, search = null) {
        let url = `${base}/api/picklists?`;

        Object.keys(filters).forEach((key) => {
            if (key != 'startDate' && key != 'endDate') {
                url += `${key}=${filters[key].value}&`;
            } else {
                url += `${key}=${filters[key]}&`;
            }
        });

        if (search) {
            url += `search=${search}`;
        }

        return this.handelAlert((res, rej, loadObj) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
                            }`
                    }
                })
                .then((response) => {
                    res(response.data);
                })
                .catch((rej) => {
                    apiResponseErrorHandler(rej);
                    res(rej.response.data);
                });
        }, false);
	},

	getPicklistDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/picklist/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
	addSerialToPicklist(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/picklist/${id}/add-serial-to-picklist`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	createShipmentFromPicklist(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/picklist/${id}/create-shipment`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	removeSerialToPicklist(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/picklist/${id}/remove-serial-to-picklist`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	cancelPicklist(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/picklist/${id}/cancel`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	downloadPicklistSerials(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/picklist/${id}/download-picklist-serials`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					},
					// responseType: 'blob'
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},


	deletePicklist(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/picklist/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response);
				});
		}, false);
	},
}
import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'react-phone-number-input/style.css';
import { useNavigate, useParams } from 'react-router';
import Scroller from 'ui-component/Scroller';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'store/reducers/mainSlice';
import { FaPlus, FaSave, FaSpinner } from 'react-icons/fa';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import ReactSelect from 'react-select';
import Select from 'react-select';
import usePageTitle from 'hooks/usePageTitle';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { set } from 'draft-js/lib/EditorState';
import { CloseIcon, ArrowRightIcon, EqualIcon } from 'ui-component/custom-icons/icons';
import CustomMUISwitch from 'ui-component/CustomMUISwitch';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';



const SettingsGeneralSettings = ({ edit }) => {
	const { id } = useParams();
    const [setPageTitle] = usePageTitle('Settings');
    useEffect(() => {
        return () => {
            setPageTitle('Settings');
        };
	}, []);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isFormProcessing, setIsFormProcessing] = useState(false);
	const history = useNavigate();
    const [currencyList, setCurrencyList] = useState([]);
    const [showDeleteCurrencyConversionPopup, setShowDeleteCurrencyConversionPopup] = useState(false);
    const [selectedCurrencyConversion, setSelectedCurrencyConversion] = useState(null);
    const [defaultCurrency, setDefaultCurrency] = useState('USD($)');

	const [generalSettingsDetails, setGeneralSettingsDetails] = useState({
        additionalWarrantyDay: '',
        creditNotePrefix: '',
        creditNoteSequenceNo: '',
        draftPrefix: '',
        draftSequenceNo: '',
        estimateDurationDays: '',
        estimatePrefix: '',
        estimateSequenceNo: '',
        invoiceDurationDays: '',
        invoicePrefix: '',
        invoiceSequenceNo: '',
        isManualCurrencyConversionActive: '',
        operationPrefix: '',
        operationSequenceNo: '',
        paymentTransactionPrefix: '',
        paymentTransactionSequenceNo: '',
        picklistPrefix: '',
        picklistSequenceNo: '',
        purchaseOrderPrefix: '',
        purchaseOrderSequenceNo: '',
        receiptPrefix: '',
        receiptSequenceNo: '',
        returnsPrefix: '',
        returnsSequenceNo: '',
        salesOrderPrefix: '',
        salesOrderSequenceNo: '',
        shipmentPrefix: '',
        shipmentSequenceNo: '',
        referenceNoLength: 0,
    });
    
    const [currencyConversionLists, setCurrencyConversionLists] = useState([]);

    const currencies = [
        { label: 'USD($)', value: 'usd' },
        { label: 'EUR(€)', value: 'eur' },
        { label: 'AED(AED)', value: 'aed' }
    ];     

	useEffect(() => {
		initializeData()
	}, []);

	const initializeData = () => {

        api.getSelectOptionsList('currency').then((res) => {
            const collection = res?.data?.map((item) => ({ id: item.id, label: item.currency, value: item.id }));
            setCurrencyList(collection);
		});
		
		api.getSettingsGeneralSettings().then((res) => {
			setLoading(false);
			if (res.status) {
                setGeneralSettingsDetails({
                    ...generalSettingsDetails,
                    additionalWarrantyDay: res?.data?.additional_warranty_day || '',
                    creditNotePrefix: res?.data?.credit_note_prefix || '',
                    creditNoteSequenceNo: res?.data?.credit_note_sequence_no || '',
                    draftPrefix: res?.data?.draft_prefix || '',
                    draftSequenceNo: res?.data?.draft_sequence_no || '',
                    estimateDurationDays: res?.data?.estimate_duration_days || '',
                    estimatePrefix: res?.data?.estimate_prefix || '',
                    estimateSequenceNo: res?.data?.estimate_sequence_no || '',
                    invoiceDurationDays: res?.data?.invoice_duration_days || '',
                    invoicePrefix: res?.data?.invoice_prefix || '',
                    invoiceSequenceNo: res?.data?.invoice_sequence_no || '',
                    isManualCurrencyConversionActive: res?.data?.is_manual_currency_conversion_active || '',
                    operationPrefix: res?.data?.operation_prefix || '',
                    operationSequenceNo: res?.data?.operation_sequence_no || '',
                    paymentTransactionPrefix: res?.data?.payment_transaction_prefix || '',
                    paymentTransactionSequenceNo: res?.data?.payment_transaction_sequence_no || '',
                    picklistPrefix: res?.data?.picklist_prefix || '',
                    picklistSequenceNo: res?.data?.picklist_sequence_no || '',
                    purchaseOrderPrefix: res?.data?.purchase_order_prefix || '',
                    purchaseOrderSequenceNo: res?.data?.purchase_order_sequence_no || '',
                    receiptPrefix: res?.data?.receipt_prefix || '',
                    receiptSequenceNo: res?.data?.receipt_sequence_no || '',
                    returnsPrefix: res?.data?.returns_prefix || '',
                    returnsSequenceNo: res?.data?.returns_sequence_no || '',
                    salesOrderPrefix: res?.data?.sales_order_prefix || '',
                    salesOrderSequenceNo: res?.data?.sales_order_sequence_no || '',
                    shipmentPrefix: res?.data?.shipment_prefix || '',
                    shipmentSequenceNo: res?.data?.shipment_sequence_no || '',
                    referenceNoLength: res?.data?.reference_no_length || '',
                })

                const currencyConversionCollection = res?.data?.currency_conversions?.map((item) => ({
                    id: item.id,
                    originCurrencyId: item.origin_currency_id,
                    originCurrencyName: item.origin_currency_name,
                    convertedCurrencyId: item.converted_currency_id,
                    convertedCurrencyName: item.converted_currency_name,
                    rate: item.rate
                }));
                setCurrencyConversionLists(currencyConversionCollection);
				setIsDataLoaded(true);
			}
		});
	}


    const handleAddCurrencyConversion = () => {
        const newRow = {
            id: '',
            originCurrencyId: '',
            convertedCurrencyId: '',
            rate: ''
        }

        setCurrencyConversionLists([...currencyConversionLists, newRow]);
    }


	const dispatch = useDispatch();
	const nameRef = useRef();
    const [alertMessage, setAlertmessage] = useState({});
    
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);


    const handleSubmit = () => {
        if (generalSettingsDetails?.referenceNoLength === '' || generalSettingsDetails?.referenceNoLength === null) {
            toast.error('Reference number length is required');
			return false;
        } else if (generalSettingsDetails?.draftPrefix?.trim() === '' || generalSettingsDetails?.draftPrefix === null) {
            toast.error('Draft prefix is required');
			return false;
        } else if (generalSettingsDetails?.draftSequenceNo?.trim() === '' || generalSettingsDetails?.draftSequenceNo === null) {
            toast.error('Next draft sequence number is required');
			return false;
        } else if (generalSettingsDetails?.estimatePrefix?.trim() === '' || generalSettingsDetails?.estimatePrefix === null) {
            toast.error('Quotation prefix is required');
			return false;
        } else if (generalSettingsDetails?.estimateSequenceNo?.trim() === '' || generalSettingsDetails?.estimateSequenceNo === null) {
            toast.error('Next quotation sequence number is required');
			return false;
        } else if (generalSettingsDetails?.salesOrderPrefix?.trim() === '' || generalSettingsDetails?.salesOrderPrefix === null) {
            toast.error('Sales order prefix is required');
			return false;
        } else if (generalSettingsDetails?.salesOrderSequenceNo?.trim() === '' || generalSettingsDetails?.salesOrderSequenceNo === null) {
            toast.error('Next sales order sequence number is required');
			return false;
        } else if (generalSettingsDetails?.invoicePrefix?.trim() === '' || generalSettingsDetails?.invoicePrefix === null) {
            toast.error('Invoice prefix is required');
			return false;
        } else if (generalSettingsDetails?.invoiceSequenceNo?.trim() === '' || generalSettingsDetails?.invoiceSequenceNo === null) {
            toast.error('Next invoice sequence number is required');
			return false;
        } else if (generalSettingsDetails?.creditNotePrefix?.trim() === '' || generalSettingsDetails?.creditNotePrefix === null) {
            toast.error('Credit note prefix is required');
			return false;
        } else if (generalSettingsDetails?.creditNoteSequenceNo?.trim() === '' || generalSettingsDetails?.creditNoteSequenceNo === null) {
            toast.error('Next credit note sequence number is required');
			return false;
        } else if (generalSettingsDetails?.returnsPrefix?.trim() === '' || generalSettingsDetails?.returnsPrefix === null) {
            toast.error('Returns prefix is required');
			return false;
        } else if (generalSettingsDetails?.returnsSequenceNo?.trim() === '' || generalSettingsDetails?.returnsSequenceNo === null) {
            toast.error('Next returns sequence number is required');
			return false;
        } else if (generalSettingsDetails?.purchaseOrderPrefix?.trim() === '' || generalSettingsDetails?.purchaseOrderPrefix === null) {
            toast.error('Purchase order prefix is required');
			return false;
        } else if (generalSettingsDetails?.purchaseOrderSequenceNo?.trim() === '' || generalSettingsDetails?.purchaseOrderSequenceNo === null) {
            toast.error('Next purchase order sequence number is required');
			return false;
        } else if (generalSettingsDetails?.receiptPrefix?.trim() === '' || generalSettingsDetails?.receiptPrefix === null) {
            toast.error('Receipt prefix is required');
			return false;
        } else if (generalSettingsDetails?.receiptSequenceNo?.trim() === '' || generalSettingsDetails?.receiptSequenceNo === null) {
            toast.error('Next receipt sequence number is required');
			return false;
        } else if (generalSettingsDetails?.operationPrefix?.trim() === '' || generalSettingsDetails?.operationPrefix === null) {
            toast.error('Operations prefix is required');
			return false;
        } else if (generalSettingsDetails?.operationSequenceNo?.trim() === '' || generalSettingsDetails?.operationSequenceNo === null) {
            toast.error('Next operations sequence number is required');
			return false;
        } else if (generalSettingsDetails?.paymentTransactionPrefix?.trim() === '' || generalSettingsDetails?.paymentTransactionPrefix === null) {
            toast.error('Payment transaction prefix is required');
			return false;
        } else if (generalSettingsDetails?.paymentTransactionSequenceNo?.trim() === '' || generalSettingsDetails?.paymentTransactionSequenceNo === null) {
            toast.error('Next payment transaction sequence number is required');
			return false;
        } else if (generalSettingsDetails?.picklistPrefix?.trim() === '' || generalSettingsDetails?.picklistPrefix === null) {
            toast.error('Picklist prefix is required');
			return false;
        } else if (generalSettingsDetails?.picklistSequenceNo?.trim() === '' || generalSettingsDetails?.picklistSequenceNo === null) {
            toast.error('Next picklist sequence number is required');
			return false;
        } else if (generalSettingsDetails?.shipmentPrefix?.trim() === '' || generalSettingsDetails?.shipmentPrefix === null) {
            toast.error('Shipment prefix is required');
			return false;
        } else if (generalSettingsDetails?.shipmentSequenceNo?.trim() === '' || generalSettingsDetails?.shipmentSequenceNo === null) {
            toast.error('Next shipment sequence number is required');
			return false;
		} else {
			setIsFormProcessing(true)
            let data = generalSettingsDetails;
            data.currencyConversionLists = currencyConversionLists;
			toast.promise(
				api
					.updateGeneralSettingsDetails(data)
					.then(
						(res) => {
							setIsFormProcessing(false)
                            initializeData()
							return res?.status ? res : null;
						},
						() => {
							setIsSubmitDisabled(false);
						}
					),
				{
					loading: 'Updating General Settings...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update general settings</b>
				}
			);
		}

    };
    
    const handleChangeOriginCurrency = (index, e) => {
        const newCurrencyConversionLists = currencyConversionLists;
        newCurrencyConversionLists[index].originCurrencyId = e.value;
        newCurrencyConversionLists[index].originCurrencyName = e.label;
        setCurrencyConversionLists([...newCurrencyConversionLists]);
    }

    const handleChangeCovertedCurrency = (index, e) => {
        const newCurrencyConversionLists = currencyConversionLists;
        newCurrencyConversionLists[index].convertedCurrencyId = e.value;
        newCurrencyConversionLists[index].convertedCurrencyName = e.label;
        setCurrencyConversionLists([...newCurrencyConversionLists]);
    }

    const handleChangeRateCurrency = (index, e) => {
        const newCurrencyConversionLists = currencyConversionLists;
        newCurrencyConversionLists[index].rate = e.target.value;
        setCurrencyConversionLists([...newCurrencyConversionLists]);
    }

    const handleClose = () => {
        setShowDeleteCurrencyConversionPopup(false);
    }

    const handleDeleteCurrencyConversion = (index, item) => {
        if (item?.id) {

            setShowDeleteCurrencyConversionPopup(true)
            setSelectedCurrencyConversion(item)

            // api.deleteCurrencyConversion(id).then((res) => {
            //     if (res.status) {
            //         const newCurrencyConversionLists = currencyConversionLists;
            //         newCurrencyConversionLists.splice(index, 1);
            //         setCurrencyConversionLists([...newCurrencyConversionLists]);
            //     }
            // });
        } else {
            const newCurrencyConversionLists = currencyConversionLists;
            newCurrencyConversionLists.splice(index, 1);
            setCurrencyConversionLists([...newCurrencyConversionLists]);
        }
    }

    const handleDelete = () => {
        const id = selectedCurrencyConversion?.id;

			toast.promise(
				api
					.deleteCurrencyConversion(id)
					.then(
						(res) => {
                            const index = currencyConversionLists.findIndex((item) => item.id === id);
                            const newCurrencyConversionLists = currencyConversionLists;
                            newCurrencyConversionLists.splice(index, 1);
                            
                            setCurrencyConversionLists([...newCurrencyConversionLists]);
                            setShowDeleteCurrencyConversionPopup(false);
                            setSelectedCurrencyConversion(null);

							return res?.status ? res : null;
						},
						() => {
							setIsSubmitDisabled(false);
						}
					),
				{
					loading: 'Deleting Currency Conversion...',
					success: (data) => `${data.message}`,
					error: <b>Failed to delete currency conversion</b>
				}
            );
        

        // api.deleteCurrencyConversion(id).then((res) => {
        //     if (res.status) {
        //         const newCurrencyConversionLists = currencyConversionLists;
        //         newCurrencyConversionLists.splice(selectedCurrencyConversion, 1);
        //         setCurrencyConversionLists([...newCurrencyConversionLists]);
        //         setShowDeleteCurrencyConversionPopup(false);
        //     }
        // });
    }



    const handleSetUseManualConversion = () => {
        setGeneralSettingsDetails({
            ...generalSettingsDetails,
            isManualCurrencyConversionActive: !generalSettingsDetails?.isManualCurrencyConversionActive
        });
    }
	return (
		<>
			<div className="row">
				<div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-12 mt15 mb30">
                    <div className="section-head m-0 p-0 ml15">
                        <h5 className="m-0 p-0 pb-2 mt15" style={{ fontWeight: '500' }}>
                            General Settings
                        </h5>
                    </div>

                    

                    {/* Sales Settings */}

                    <div className="row">
                        <div className="col-12">

                            <div className="card w-100 h-100 mt8">
                                <div className="card-body">

                                    
                                    <div className="section-head m-0 p-0">
                                        <h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
                                            Sales Settings
                                        </h5>
                                    </div>
                                    <div className="form-section">
                                        <div className="form-section-head">
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-12">

                                            <div className="text-right">
                                                <label htmlFor="">
                                                    Additional warranty in days
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 form-group">

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputName"
                                                value={generalSettingsDetails?.additionalWarrantyDay}
                                                onChange={(e) => {
                                                    setGeneralSettingsDetails({ ...generalSettingsDetails, additionalWarrantyDay: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-12">

                                            <div className="text-right">
                                                <label htmlFor="">
                                                    Quotation validity in days
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 form-group">

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputName"
                                                value={generalSettingsDetails?.estimateDurationDays}
                                                onChange={(e) => {
                                                    setGeneralSettingsDetails({ ...generalSettingsDetails, estimateDurationDays: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-12">

                                            <div className="text-right">
                                                <label htmlFor="">
                                                    Invoice validity in days
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputName"
                                                value={generalSettingsDetails?.invoiceDurationDays}
                                                onChange={(e) => {
                                                    setGeneralSettingsDetails({ ...generalSettingsDetails, invoiceDurationDays: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Currency Settings */}
                    <div className="row">
                        <div className="col-12 mt30">

                            <div className="card w-100 h-100 mt8">
                                <div className="card-body">

                                    

                                    <div className="form-section">
                                        <div className="form-section-head section-head-new">
                                            <h5 className="m-0 p-0 pb-2 ml-15" style={{ fontWeight: '500', height: '31px' }}>
                                                Currency
                                            </h5>
                                            {
                                                generalSettingsDetails?.isManualCurrencyConversionActive && (
                                                    <>
                                                        <div className="actions">
                                                            <button
                                                                className="btn btn-primary btn-outlined-main white-var"
                                                                style={{ minWidth: 'unset', paddingBlock: '4.1px' }}
                                                                onClick={() => {
                                                                    handleAddCurrencyConversion();
                                                                }}
                                                            >
                                                                Add Conversion
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>

                                    </div>
                                    <div className="row mt14 mb-5">
                                        <div className="col-lg-4 col-md-4 col-12">

                                            <div className="text-right">
                                                <label htmlFor="">
                                                    Use manual conversion for payments
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">

                                            <span
                                                className="pddmi-action"
                                                onClick={(e) => {
                                                    handleSetUseManualConversion();
                                                }}
                                            >
                                                <CustomMUISwitch checked={generalSettingsDetails?.isManualCurrencyConversionActive} />
                                            </span>
                                        </div>
                                    </div>



                                    {
                                        generalSettingsDetails?.isManualCurrencyConversionActive && (
                                            <>
                                                <div className="row form-group"></div>
                                                {
                                                    currencyConversionLists?.length > 0 && currencyConversionLists?.map((item, index) => (
                                                        <>
                                                            
                                                            <div className="row mt12">
                                                                <div className="col-lg-10 offset-lg-1">

                                                                    <div className="row">
                                                                        <div className="col-5 d-flex input-group-2-form input-group-2-form-currency">
                                                                            
                                                                            <Select
                                                                                options={currencyList}
                                                                                className="rs-container left-input"
                                                                                classNamePrefix="rs"
                                                                                placeholder="Select"
                                                                                isMulti={false}
                                                                                onChange={(e) => {
                                                                                    handleChangeOriginCurrency(index, e)
                                                                                }}
                                                                                value={currencyList?.find(option => option.id === item?.originCurrencyId) || null}
                                                                            />
                                                                            <input
                                                                                type="text"
                                                                                className="form-control h36 right-input ml6"
                                                                                id="inputName"
                                                                                value={1}
                                                                                placeholder='0.00'
                                                                                disabled={true}
                                                                            />
                                                                        </div>
                                                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                                                            <EqualIcon height='15px' width='15px' />
                                                                        </div>
                                                                        <div className="col-5 d-flex input-group-2-form input-group-2-form-currency">
                                                                            <Select
                                                                                options={currencyList}
                                                                                className="rs-container left-input"
                                                                                classNamePrefix="rs"
                                                                                placeholder="Select"
                                                                                isMulti={false}
                                                                                onChange={(e) => {
                                                                                    handleChangeCovertedCurrency(index, e)
                                                                                }}
                                                                                value={currencyList?.find(option => option.id === item?.convertedCurrencyId) || null}
                                                                            />
                                                                            <input
                                                                                type="text"
                                                                                className="form-control h36 right-input ml6"
                                                                                id="inputName"
                                                                                value={item?.rate}
                                                                                placeholder='0.00'
                                                                                onChange={(e) => {
                                                                                    handleChangeRateCurrency(index, e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-1">
                                                                            <button
                                                                                className='btn btn-danger btn-outlined-danger white-var btn-sms settings-currency-rate-field'
                                                                                onClick={() => { 
                                                                                    handleDeleteCurrencyConversion(index, item)
                                                                                }}
                                                                            >
                                                                                <CloseIcon height='12px' width='12px' />
                                                                            </button>
                                                                        
                                                                        </div>
                                                                        {/* <div className="col-1 d-flex justify-content-center align-items-center">
                                                                            <EqualIcon height='28px' width='28px' />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-2">

                                                                    <input
                                                                        type="text"
                                                                        className="form-control h36"
                                                                        id="inputName"
                                                                        value={item?.rate}
                                                                        placeholder='0.00'
                                                                        onChange={(e) => {
                                                                            handleChangeRateCurrency(index, e)
                                                                        }}
                                                                    />
                                                                </div> */}
                                                                {/* <div className="col-lg-1">
                                                                    <button
                                                                        className='btn btn-danger btn-outlined-danger white-var btn-sms settings-currency-rate-field'
                                                                        onClick={() => { 
                                                                            handleDeleteCurrencyConversion(index, item?.id)
                                                                        }}
                                                                    >
                                                                        <CloseIcon height='12px' width='12px' />
                                                                    </button>
                                                                
                                                                </div> */}
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                        
                                            </>
                                        )
                                    }
                                
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Reference Number Settings */}
                    <div className="row">
                        <div className="col-12 mt30">

                            <div className="card w-100 h-100 mt8">
                                <div className="card-body">

                                    
                                    <div className="section-head m-0 p-0">
                                        <h5 className="m-0 p-0 pb-2" style={{ fontWeight: '500' }}>
                                            Reference Numbers
                                        </h5>
                                    </div>
                                    {/* <Scroller> */}
                                        <div className="form-section">
                                            <div className="form-section-head"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Reference number length <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group">

                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.referenceNoLength}
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, referenceNoLength: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Draft <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.draftPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, draftPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.draftSequenceNo}
                                                    placeholder='Next draft sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, draftSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Quotation <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group input-group-2-form">
                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        className="form-control left-input"
                                                        id="inputName"
                                                        value={generalSettingsDetails?.estimatePrefix}
                                                        placeholder='Prefix'
                                                        onChange={(e) => {
                                                            setGeneralSettingsDetails({ ...generalSettingsDetails, estimatePrefix: e.target.value });
                                                        }}
                                                    />
                                                    <input
                                                        type="text"
                                                        className="form-control right-input"
                                                        id="inputName"
                                                        value={generalSettingsDetails?.estimateSequenceNo}
                                                        placeholder='Next quotation sequence number'
                                                        onChange={(e) => {
                                                            setGeneralSettingsDetails({ ...generalSettingsDetails, estimateSequenceNo: e.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Sales Order <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.salesOrderPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, salesOrderPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.salesOrderSequenceNo}
                                                    placeholder='Next sales order sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, salesOrderSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        

                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Invoice <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.invoicePrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, invoicePrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.invoiceSequenceNo}
                                                    placeholder='Next invoice sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, invoiceSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Credit Note <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.creditNotePrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, creditNotePrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.creditNoteSequenceNo}
                                                    placeholder='Next credit note sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, creditNoteSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Returns <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.returnsPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, returnsPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.returnsSequenceNo}
                                                    placeholder='Next returns sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, returnsSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Purchase Order <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.purchaseOrderPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, purchaseOrderPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.purchaseOrderSequenceNo}
                                                    placeholder='Next purchase order sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, purchaseOrderSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Receipt <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.receiptPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, receiptPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.receiptSequenceNo}
                                                    placeholder='Next receipt sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, receiptSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Operations <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.operationPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, operationPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.operationSequenceNo}
                                                    placeholder='Next operations sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, operationSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Payment Transaction <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.paymentTransactionPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, paymentTransactionPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.paymentTransactionSequenceNo}
                                                    placeholder='Next payment transaction sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, paymentTransactionSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Picklist <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 form-group d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.picklistPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, picklistPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.picklistSequenceNo}
                                                    placeholder='Next picklist sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, picklistSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">

                                                <div className="text-right">
                                                    <label htmlFor="">
                                                        Shipment <sup className="text-danger">*</sup>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 d-flex input-group-2-form">

                                                <input
                                                    type="text"
                                                    className="form-control left-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.shipmentPrefix}
                                                    placeholder='Prefix'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, shipmentPrefix: e.target.value });
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control right-input"
                                                    id="inputName"
                                                    value={generalSettingsDetails?.shipmentSequenceNo}
                                                    placeholder='Next shipment sequence number'
                                                    onChange={(e) => {
                                                        setGeneralSettingsDetails({ ...generalSettingsDetails, shipmentSequenceNo: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        
                                    {/* </Scroller> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="row mt30">
                        <div className="col-12 text-center d-flex align-items-center justify-content-center">

                            {/* <PermissionAllowed permissions={['Save Warehouse Details (Action)']}> */}
                                <button
                                    type="button"
                                    className="bulk-action btn-primary btn-outlined-main-primary  white-var"
                                    disabled={!isDataLoaded || isFormProcessing}
                                    onClick={() => {
                                        if (!isFormProcessing) {
                                            handleSubmit()
                                        }
                                    }}
                                    style={{ lineHeight: '25px' }}
                                >
                                    {
                                        isFormProcessing ? (
                                            <>
                                                <FaSpinner className="fa-spin" /> Saving...
                                            </>
                                        ) : (
                                            <>
                                                <FaSave /> Save Details
                                            </>
                                        )
                                    }
                                    
                                </button>
                            {/* </PermissionAllowed> */}
                        </div>
                    </div>

				</div>
			</div>

            {showDeleteCurrencyConversionPopup && (
                <DeleteConfirmation
                    show={showDeleteCurrencyConversionPopup}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                    title="Confirm"
                    message={`Are you sure you want to delete conversion of ${selectedCurrencyConversion?.originCurrencyName} to ${selectedCurrencyConversion?.convertedCurrencyName}?`}
                />
            )}
		</>
	);
};

export default SettingsGeneralSettings;

import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	// get Invoice list
	getInvoiceList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipment/invoice-list`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	verifyIMEINumber(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/shipment/verify-products`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	createShipments(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/shipments`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getShipmentList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipments`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getShipmentDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipments/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getShipmentItemList(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipments/${id}/items`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	newShipmentNote(ship_id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/shipments/${ship_id}/notes`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	removeShipmentNote(est_id, note_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/shipments/${est_id}/delete/${note_id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	filterShipments(filters, search = null) {
		let url = `${base}/api/shipments?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getShipmentFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipments/filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	searchShipment(query) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipment/list?search=${query}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},

	getReadyToShip() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments/get-ready-to-ship`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getTransitShipmentList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	createTransitShipments(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/transit-shipments`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	filterTransitShipments(filters, search = null) {
		let url = `${base}/api/transit-shipments?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getTransitShipmentFilters() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments/filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getTransitShipmentItemFilters(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments/${id}/filter`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getTransitShipmentDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments/${id}`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	getTransitShipmentItemList(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments/${id}/items`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	filterTransitShipmentItem(id, filters, search = null) {
		let url = `${base}/api/transit-shipments/${id}/items?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	newTransitShipmentNote(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/transit-shipments/${id}/notes`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	removeTransitShipmentNote(est_id, note_id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/transit-shipments/${est_id}/delete/${note_id}`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	updateTransitShipmentInvoiceStatus(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/transit-shipments/${id}/update-invoice-status`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	updateTransitShipmentAwb(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/transit-shipments/${id}/update-awb`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	downloadTransitShipmentImei(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/transit-shipments/${id}/download-imeis`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateShipmentAwb(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/shipments/${id}/update-awb`, data, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	downloadShipmentImei(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/shipments/${id}/download-imeis`, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	newShipmentNote(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/shipments/${id}/notes`, data, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},


	async filterShipmentList(status, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/shipments?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		const result = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${
					JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
				}`
			}
		});
		return result;
	},

	filterPurchaseListApiUrl(status, filters, search = null, sortByFilter = null) {
		let url = `${base}/api/shipments?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		if (sortByFilter) {
			url += `&sortBy=${sortByFilter?.field}&sortOrder=${sortByFilter?.direction}`;
		}

		return url;
	},

	uploadShipmentAttachment(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				// .post(`${base}/api/shipments/${id}/upload`, data, {
				.post(`${base}/api/shipments/attachments/upload`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
    
	shipmentCreateAwb(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/shipments/${id}/create-awb-details`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	

	deleteShipmentAttachment(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/shipments/${id}/delete-shipment-attachment`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
	deleteShipmentTracking(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/shipments/${id}/delete-shipment-tracking`, {
					headers: {
						Authorization: `Bearer ${(JSON.parse(localStorage.getItem('user'))?.token  || JSON.parse(sessionStorage.getItem('user')).token) || token}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
};

import React, { useEffect, useState, useRef, useCallback } from 'react';
//import './estimateInvoice.scss';
import { Link } from 'react-router-dom';
import mgLogo from 'assets/images/mglogo.svg';
import glpLogo from 'assets/images/glplogo.png';
import cmLogo from 'assets/images/cmlogo.svg';
import { Modal } from 'react-bootstrap';
import { taskboardProgresses } from 'assets/helperdata/client/order-client-progress';
import moment from 'moment';
import CurrencyValue from 'ui-component/CurrencyValue';
import { Skeleton } from '@mui/material';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import { CommonDateOnlyFormat } from 'helpers/common.helper';
import { FaPlus, FaTimes } from 'react-icons/fa';
import agreementImage from 'assets/images/agreement.svg';

function TimelineDrawer({ id, handleCloseDrawer}) {

    const [data, setData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
    const drawerRef = useRef(null);

    const fetchData = useCallback(async () => {

		const request = {
			id: id,
			type: 'client_invoice'
		}
		api.getClientTimeline(request).then((res) => {
			if (res?.status) {
				setData(res?.data);
				setIsDataLoaded(true);
			} else {
				setIsDrawerOpen(false)
				setSelectedData(null)
			}
		});

    }, [id]);
	

    // Add click event listener
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                // setIsDrawerOpen(false);
                handleCloseDrawer()
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [drawerRef]);


    useEffect(() => {
        fetchData()
    }, [id]);

	return (
		<>

            <div className="drawer-overlay"></div>

            <div ref={drawerRef} id="drawer-right-example" class={`drawer-container open`} tabindex="-1" aria-labelledby="drawer-right-label">
                <h5 id="drawer-right-label" class="drawer-header">
                    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg> Timeline
                </h5>
                <button 
                    type="button" 
                    data-drawer-hide="drawer-right-example" 
                    aria-controls="drawer-right-example" 
                    class="drawer-close-button" 
                    onClick={() => {
                        handleCloseDrawer()
                    }}
                >
                    <div style={{ padding: '5px' }}>

                        <svg width={'14px'} height={'14px'} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close menu</span>
                    </div>
                </button>

                {
                    !isDataLoaded ? (
                        <>
                            <div>

                                <div id="preloader">
                                    <div id="status"> 
                                        <div class="spinner">
                                        <div class="rect1"></div>
                                        <div class="rect2"></div>
                                        <div class="rect3"></div>
                                        <div class="rect4"></div>
                                        <div class="rect5"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                {
                                    data?.qoutation?.create?.status === true && (
                                        <>
                                            <div className="row drawer-container-header-row">
                                                <div className="col-12 drawer-content-header-container">
                                                    <div className="row">
                                                        <div className="col-12 pl-20 text-primary">
                                                            Quotations
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt10">
                                                <div className="row">
                                                    <div className="col-12">
                                                        
                                                        <div class="rb-container">
                                                            <ul class="rb">
                                                                <li class={`rb-item ${ data?.qoutation?.create?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.qoutation?.create?.status ? data?.qoutation?.create?.title : 'Quotation created' }</div>
                                                                        {
                                                                            data?.qoutation?.create?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.qoutation?.create?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content text-gray-2">{ data?.qoutation?.create?.status ? data?.qoutation?.create?.message : 'No details' }</div>
                                                                </li>
                                                                <li class={`rb-item ${ data?.qoutation?.approval?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.qoutation?.approval?.status ? data?.qoutation?.approval?.title : 'Approval sent' }</div>
                                                                        {
                                                                            data?.qoutation?.approval?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.qoutation?.approval?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.qoutation?.approval?.status ? data?.qoutation?.approval?.message : 'No details' }</div>
                                                                </li>
        
                                                                <li class={`rb-item ${ data?.qoutation?.accepted?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.qoutation?.accepted?.status ? data?.qoutation?.accepted?.title : 'Approved' }</div>
                                                                        {
                                                                            data?.qoutation?.accepted?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.qoutation?.accepted?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </> 
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.qoutation?.accepted?.status ? data?.qoutation?.accepted?.message : 'No details' }</div>
                                                                </li>
                                                            </ul>
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    data?.sales_order?.create?.status === true ? (
                                        <>
                                            <div className="row drawer-container-header-row">
                                                <div className="col-12 drawer-content-header-container">
                                                    <div className="row">
                                                        <div className="col-12 pl-20 text-primary">
                                                            Sales Order
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt10">
        
                                                <div className="row">
                                                    <div className="col-12">
                                                                    
                                                        <div class="rb-container">
                                                            <ul class="rb">
                                                                <li class={`rb-item ${ data?.sales_order?.create?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.create?.status ? data?.sales_order?.create?.title : 'Sales order created' }</div>
                                                                        {
                                                                            data?.sales_order?.create?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.create?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content text-gray-2">{ data?.sales_order?.create?.status ? data?.sales_order?.create?.message : 'No details' }</div>
                                                                </li>
                                                                <li class={`rb-item ${ data?.sales_order?.approved?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.approved?.status ? data?.sales_order?.approved?.title : 'Approved' }</div>
                                                                        {
                                                                            data?.sales_order?.approved?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.approved?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.sales_order?.approved?.status ? data?.sales_order?.approved?.message : 'No details' }</div>
                                                                </li>
        
                                                                <li class={`rb-item ${ data?.sales_order?.billed?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.billed?.status ? data?.sales_order?.billed?.title : 'Sales order billed' }</div>
                                                                        {
                                                                            data?.sales_order?.billed?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.billed?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.sales_order?.billed?.status ? data?.sales_order?.billed?.message : 'No details' }</div>
                                                                </li>
                                                                
                                                                <li class={`rb-item ${ data?.sales_order?.payment?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.payment?.status ? data?.sales_order?.payment?.title : 'Payment received' }</div>
                                                                        {
                                                                            data?.sales_order?.payment?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.payment?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.sales_order?.payment?.status ? data?.sales_order?.payment?.message : 'No details' }</div>
                                                                </li>
                                                                
                                                            </ul>
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row drawer-container-header-row">
                                                <div className="col-12 drawer-content-header-container">
                                                    <div className="row">
                                                        <div className="col-12 pl-20 text-primary">
                                                            Invoice
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt10">
        
                                                <div className="row">
                                                    <div className="col-12">
                                                                    
                                                        <div class="rb-container">
                                                            <ul class="rb">
                                                                <li class={`rb-item ${ data?.invoice?.create?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.invoice?.create?.status ? data?.invoice?.create?.title : 'Invoice created' }</div>
                                                                        {
                                                                            data?.invoice?.create?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.invoice?.create?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content text-gray-2">{ data?.invoice?.create?.status ? data?.invoice?.create?.message : 'No details' }</div>
                                                                </li>
                                                                <li class={`rb-item ${ data?.invoice?.payment?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.invoice?.payment?.status ? data?.invoice?.payment?.title : 'Payment received' }</div>
                                                                        {
                                                                            data?.invoice?.payment?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.invoice?.payment?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content text-gray-2">{ data?.invoice?.payment?.status ? data?.invoice?.payment?.message : 'No details' }</div>
                                                                </li>
                                                                {/* <li class={`rb-item ${ data?.sales_order?.approved?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.approved?.status ? data?.sales_order?.approved?.title : 'Approved' }</div>
                                                                        {
                                                                            data?.sales_order?.approved?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.approved?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.sales_order?.approved?.status ? data?.sales_order?.approved?.message : 'No details' }</div>
                                                                </li>
        
                                                                <li class={`rb-item ${ data?.sales_order?.billed?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.billed?.status ? data?.sales_order?.billed?.title : 'Sales order billed' }</div>
                                                                        {
                                                                            data?.sales_order?.billed?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.billed?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.sales_order?.billed?.status ? data?.sales_order?.billed?.message : 'No details' }</div>
                                                                </li>
                                                                
                                                                <li class={`rb-item ${ data?.sales_order?.payment?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.sales_order?.payment?.status ? data?.sales_order?.payment?.title : 'Payment received' }</div>
                                                                        {
                                                                            data?.sales_order?.payment?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.sales_order?.payment?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.sales_order?.payment?.status ? data?.sales_order?.payment?.message : 'No details' }</div>
                                                                </li> */}
                                                                
                                                            </ul>
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {/* {
                                    data?.operation?.processing?.status === true && (
                                        <> */}
                                            <div className="row drawer-container-header-row">
                                                <div className="col-12 drawer-content-header-container">
                                                    <div className="row">
                                                        <div className="col-12 pl-20 text-primary">
                                                            Operations
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt10">
        
                                                <div className="row">
                                                    <div className="col-12">
                                                                    
                                                        <div class="rb-container">
                                                            <ul class="rb">
                                                                <li class={`rb-item ${ data?.operation?.processing?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.operation?.processing?.status ? data?.operation?.processing?.title : 'Order processed' }</div>
                                                                        {
                                                                            data?.operation?.processing?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.operation?.processing?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content text-gray-2">{ data?.operation?.processing?.status ? data?.operation?.processing?.message : 'No details' }</div>
                                                                </li>
                                                                <li class={`rb-item ${ data?.operation?.ready?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.operation?.ready?.status ? data?.operation?.ready?.title : 'Waiting for shipment' }</div>
                                                                        {
                                                                            data?.operation?.ready?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.operation?.ready?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.operation?.ready?.status ? data?.operation?.ready?.message : 'No details' }</div>
                                                                </li>
                                                                <li class={`rb-item ${ data?.operation?.shipment?.status && 'active' }`} ng-repeat="itembx">
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">{ data?.operation?.shipment?.status ? data?.operation?.shipment?.title : 'Order shipped' }</div>
                                                                        {
                                                                            data?.operation?.shipment?.status && (
                                                                                <>
                                                                                    <div className="drawer-date-container">{ moment(data?.operation?.shipment?.date).format(CommonDateOnlyFormat) }</div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div class="item-content">{ data?.operation?.shipment?.status ? data?.operation?.shipment?.message : 'No details' }</div>
                                                                </li>
                                                                <li class={`rb-item ${ data?.operation?.shipment?.status && 'active' }`} ng-repeat="itembx">
                                                                    
                                                                    <div class="timestamp">
                                                                        <div className="drawer-title-container">Track shipment</div>
                                                                    </div>
                                                                    <div class="item-content">
                                                                        {
                                                                            data?.operation?.shipment?.shipments?.length > 0 && (
                                                                                <>
                                                                                    <div class="rb-container" style={{ marginLeft: '-1em' }}>
                                                                                        <ul class="rb">
                                                                                            {
                                                                                                data?.operation?.shipment?.shipments?.map((shipment, index) => (
                                                                                                    <>
                                                                                                        <li class={`rb-item active`} ng-repeat="itembx">
                                                                                                            <div class="timestamp">
                                                                                                                <div className="drawer-title-container text-primary">{shipment?.shipping_company}</div>
                                                                                                            </div>
                                                                                                            <div class="item-content text-gray-2">
                                                                                                                Track order here {' '}
                                                                                                                <span 
                                                                                                                    className="cursor-pointer text-primary"
                                                                                                                    onClick={() => {
                                                                                                                        window.open(shipment?.tracking_url, '_blank')
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {shipment?.tracking_no}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </li>
        
                                                            </ul>
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        {/* </>
                                    )
                                } */}
                            </div>
                        </>
                    )
                }
                
            </div>


		</>
	);
}

export default TimelineDrawer;

import { apiResponseErrorHandler } from 'helpers/common.helper';
import axios from 'axios';

export default {
	// get Invoice list
	getReturnsList() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/returns`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getUnassignedInvoiceNumberList(estimationId = null) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=invoice_customer${estimationId ? `&estimation_id=${estimationId}` : ''}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getEstimationIdByItemId(imei) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/returns/estimation-by-item/${imei}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getInvoiceNumberCustomer(estimationId) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=invoice_customer_details&estimation_id=${estimationId}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReturnsEstimationItems(estimationId) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/returns/${estimationId}/get-estimation-items`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getAvailableReturnsImei(estimationId, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/${estimationId}/get-avaiable-items`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReturnsStandardRepairTypes() {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=standard_repair_types`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReturnsImei(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/get-stocks-items/imeis`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	addBackToStock(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/add-back-to-stock`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateStatusSelected(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/update-return-status-selected`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getCreateReturns(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getReturnsDetails(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/returns/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user')).token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateReturnsEstimateDetails(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/${id}/update-estimate-detail`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	addReturnedProducts(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/${id}/add-returned-products`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	addReplacementProducts(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/${id}/add-replacement-products`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateReturnedProducts(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/returns/${id}/update-returned-products`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteReturnedProducts(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/returns/${id}/delete-returned-products`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteReturns(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/returns/${id}`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateReplacementProducts(id, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.put(`${base}/api/returns/${id}/update-replacement-products`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	deleteReplacementProducts(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.delete(`${base}/api/returns/${id}/delete-replacement-products`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	getReturnsStatusList(estimationId = null) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(`${base}/api/selections?type=returns_status`, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	updateReturnsStatus(returnsId, data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/${returnsId}/update-status`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	getReportResult(data) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(`${base}/api/returns/generate-report`, data, {
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
							}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},
	approveReturns(id) {
		return this.handelAlert((res, rej, loadObj) => {
			axios
				.post(
					`${base}/api/returns/approve-returns/${id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
								}`
						}
					}
				)
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej);
					res(rej.response.data);
				});
		}, false);
	},

	filterReturns(filters, search = null) {
		let url = `${base}/api/returns?`;

		Object.keys(filters).forEach((key) => {
			if (key != 'startDate' && key != 'endDate') {
				url += `${key}=${filters[key].value}&`;
			} else {
				url += `${key}=${filters[key]}&`;
			}
		});

		if (search) {
			url += `search=${search}`;
		}

		return this.handelAlert((res, rej, loadObj) => {
			axios
				.get(url, {
					headers: {
						Authorization: `Bearer ${
							JSON.parse(localStorage.getItem('user'))?.token || JSON.parse(sessionStorage.getItem('user'))?.token || token
						}`
					}
				})
				.then((response) => {
					res(response.data);
				})
				.catch((rej) => {
					apiResponseErrorHandler(rej)
					res(rej.response.data);
				});
		}, false);
	},
};
